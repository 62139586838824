// ** MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu';

// ** Components
// import Autocomplete from 'layouts/components/Autocomplete'
import UserDropdown from '@core/layouts/components/shared-components/UserDropdown';
import SelectAssignmentList from 'app/student/components/main/elements/assignments/selectAssignmentList';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'iconsax-react';
import { useAppSelector } from 'store/store';

const AppBarContent = (props) => {
  const { user } = useAppSelector((state) => state.authReducer);
  const location = useLocation();
  const data = location?.state;
  const [videoTopic, setVideoTopic] = useState(data ? data : '');
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;

  return (
    <div className="w-full">
      <Box className="hidden w-full justify-between xl:flex bg-white p-5 rounded-3xl">
        <Box
          className="actions-left max-w-5xl"
          sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
        >
          <div>
            <h1 className="text-[#2C3E50] !text-sm xl:!text-base 2xl:!text-lg font-medium">
              Earn $G3MS for Daily Practice. Complete at least 6 per day here or
              on the mobile app!
            </h1>
            <SelectAssignmentList
              videoTopic={videoTopic}
              setVideoTopic={setVideoTopic}
            />
          </div>

          {hidden ? (
            <IconButton
              color="inherit"
              sx={{ ml: -2.75 }}
              onClick={toggleNavVisibility}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          {/* <Autocomplete hidden={hidden} settings={settings} /> */}
        </Box>
        <Box
          className="actions-right"
          sx={{ display: 'flex', alignItems: 'start' }}
        >
          <UserDropdown settings={settings} />
        </Box>
      </Box>

      <div className="flex justify-between items-center gap-4 bg-white p-5 rounded-3xl xl:hidden">
        <IconButton
          color="inherit"
          sx={{ ml: -2.75 }}
          onClick={toggleNavVisibility}
        >
          <MenuIcon className="!size-8 sm:!size-12 md:size-!14" />
        </IconButton>

        <Link to="/app/student/" className="flex items-center gap-2">
          <img src="/svg/logo.svg" alt="logo" className="w-16 md:w-24 " />
        </Link>

        <button className="bg-[#3AAAFF26] flex items-center justify-center gap-2 px-3 py-3 rounded-full">
          <img src="/svg/diamond.svg" alt="diamond" className="w-6 md:w-8" />
          <span className="text-lg md:text-xl font-medium text-[#3AAAFF]">
            {user?.totalGems}$
          </span>
        </button>
      </div>
    </div>
  );
};

export default AppBarContent;
