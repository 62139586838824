import { useState } from "react";
import styles from "./modal.module.scss"

const Modal = ({ open, setOpen, children, overlayStyles, contentStyles }) => {

    if (!open)
        return (<> </>)

    return (
        <>
            <div onClick={() => {
                setOpen(false)
            }} className={`${styles.overlay} ${overlayStyles}`} />
            <div className={`${styles.content} ${contentStyles}`}>
                {children}
            </div>
        </>
    )
}

export default Modal;