import styles from './textField.module.scss';

// textfiled component that can be a password or a text field depending on the props passed in and has a icon in the front
const TextField = ({
  type,
  placeholder,
  icon,
  onChange,
  value,
  isRequired = false,
}) => {
  return (
    <div className={styles.textField}>
      <img src={icon} alt={'icon'} />
      <input
        autoComplete={'off'}
        autoSave={'off'}
        autoCorrect={'off'}
        spellCheck={'false'}
        type={type}
        placeholder={placeholder}
        onChange={(target) => {
          onChange(target.target.value);
        }}
        value={value}
        {...(isRequired && { required: true })}
      />
    </div>
  );
};

export default TextField;
