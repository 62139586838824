import React from "react";

export default function DailyLesson() {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2920_18342)">
      <path d="M9.99938 10.6944C12.1737 10.6944 13.9363 9.04019 13.9363 6.99957C13.9363 4.95894 12.1737 3.30469 9.99938 3.30469C7.8251 3.30469 6.0625 4.95894 6.0625 6.99957C6.0625 9.04019 7.8251 10.6944 9.99938 10.6944Z" fill="#666666"/>
      <path d="M6.36388 10.8588C5.226 9.91554 4.51172 8.53581 4.51172 7.00018C4.51172 5.46454 5.226 4.08481 6.36388 3.1416" stroke="#666666" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M13.6367 3.1416C14.7746 4.08481 15.4972 5.46454 15.4972 7.00018C15.4972 8.53581 14.7746 9.91554 13.6367 10.8588" stroke="#666666" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M5.53111 12.3161C3.67064 11.1858 2.44141 9.22925 2.44141 6.99985C2.44141 4.77045 3.67064 2.81388 5.53111 1.68359" stroke="#666666" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M14.4688 1.68359C16.3292 2.81388 17.5585 4.77045 17.5585 6.99985C17.5585 9.22925 16.3292 11.1858 14.4688 12.3161" stroke="#666666" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M4.69978 13.6101C2.15825 12.3707 0.414062 9.87629 0.414062 6.99989C0.414062 4.1235 2.15825 1.62907 4.69978 0.389648" stroke="#666666" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M15.3008 0.389648C17.8423 1.62907 19.5865 4.1235 19.5865 6.99989C19.5865 9.87629 17.8423 12.3707 15.3008 13.6101" stroke="#666666" stroke-miterlimit="10" stroke-linecap="round"/>
      </g>
      <defs>
      <clipPath id="clip0_2920_18342">
      <rect width="20" height="14" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}
