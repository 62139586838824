import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, TextField } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { Circles } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

export default function Waiting({ status }) {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(`/app/student`);
  };
  return (
    <Box
      sx={{
        '& .MuiOutlinedInput-root ': {
          fontSize: '2rem',
        },
        width: '50%',
        bgcolor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: '12px',
        position: 'relative',
        p: 12,
        gap: 12,
      }}
    >
      <Box onClick={handleClose}>
        <Close sx={{ position: 'absolute', right: '10px', top: '10px' }} />
      </Box>
      <Typography className="!font-bold !text-5xl !text-black text-center">
        {`Waiting For Teacher To ${status}.`}
      </Typography>
      <Box>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Circles
            height="130"
            width="130"
            color="#C73E9D"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </Box>
    </Box>
  );
}
