import { apiSlice } from '..';

export const topicsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTopics: builder.query({
      query: () => {
        return {
          url: 'topics',
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetTopicsQuery, useLazyGetTopicsQuery } = topicsApiSlice;
