import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setUserDetails,
} from 'store/Slice/authSlice';
import API from 'store/api';

export const updateUserProfile = createAsyncThunk(
  'updateUserProfile',
  async (_request, { dispatch, rejectWithValue }) => {
    let url = `/api/v1/user/profile/me`;
    try {
      const response = await API.get(url);
      console.log("=========> Get user profile <==========", response.data);
      localStorage.setItem('userData', JSON.stringify(response.data.data));
      dispatch(setUserDetails(response.data.data));
      return response;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
