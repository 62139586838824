import React from 'react';

export default function PlayIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 0.0126953C5.87257 0.0126953 0.5 5.38527 0.5 12.0127C0.5 18.6401 5.87257 24.0126 12.5 24.0126C19.1274 24.0126 24.5 18.6401 24.5 12.0127C24.4929 5.38823 19.1245 0.0197768 12.5 0.0126953ZM17.5536 12.395C17.4706 12.5617 17.3355 12.6968 17.1688 12.7798V12.7841L10.3117 16.2127C9.88822 16.4243 9.37343 16.2526 9.16179 15.8291C9.10162 15.7087 9.07065 15.5758 9.07139 15.4413V8.58414C9.07119 8.11073 9.45474 7.72682 9.92815 7.72657C10.0613 7.72651 10.1926 7.75746 10.3117 7.81698L17.1688 11.2456C17.5925 11.4567 17.7648 11.9713 17.5536 12.395Z"
        fill="currentColor"
      />
    </svg>
  );
}
