import STEModal from 'pages/ShareToEarn/modals/STEModal';
import React from 'react';
import cn from 'utils/cn';

export default function Invitation({
  avatar = '/images/demo-invitation.png',
  userName = 'user',
  status = 'Accepted',
}) {
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showFailure, setShowFailure] = React.useState(false);

  return (
    <div className="flex items-center justify-between gap-4 flex-wrap py-2">
      <div className="grid grid-cols-[auto,1fr] items-center gap-4">
        <div className="bg-invitation-img-bg size-12 p-1 rounded-full overflow-hidden">
          <div className="size-full p-1 rounded-full overflow-hidden bg-white">
            <img
              src={avatar}
              alt={userName}
              className="size-full object-cover"
            />
          </div>
        </div>

        <h3 className="text-[#051532] font-semibold text-xs sm:text-sm lg:text-base">
          {userName}
        </h3>
      </div>

      <button
        className={cn(
          'text-[#20A58A] bg-[#E9F6F3] font-medium text-xs lg:text-sm py-1 px-2 lg:py-2 lg:px-4 rounded-2xl min-w-24',
          {
            'text-[#20A58A] bg-[#E9F6F3]': status === 'Accepted',
            'text-[#FF4D4F] bg-[#FEEFEE]': status === 'Failed',
          }
        )}
        onClick={() => {
          if (status === 'Accepted') {
            setShowSuccess(true);
          } else {
            setShowFailure(true);
          }
        }}
      >
        {status}
      </button>

      <STEModal open={showSuccess} handleClose={() => setShowSuccess(false)}>
        <STEModal.Image
          src="/images/share-success.png"
          alt="success"
          className="w-24 h-24"
        />
        <STEModal.Title>
          🎉 Awesome!
          <br />
          Your friend signed up, and you’ve both earned $100 G3MS!
        </STEModal.Title>
        <STEModal.Subtitle>
          Thanks for spreading the word! Your G3MS balance has been updated.
        </STEModal.Subtitle>
        <STEModal.Button onClick={() => setShowSuccess(false)}>
          Check My G3MS Balance
        </STEModal.Button>
      </STEModal>
      <STEModal open={showFailure} handleClose={() => setShowFailure(false)}>
        <STEModal.Image
          src="/images/share-failed.png"
          alt="success"
          className="w-24 h-24"
        />
        <STEModal.Title>
          ⚠️ Oops!
          <br />
          Please ask your friend to enter a valid referral code or share the app
          with someone else to earn rewards.
        </STEModal.Title>
        <STEModal.Subtitle>
          Thanks for spreading the word! Your G3MS balance has been updated.
        </STEModal.Subtitle>
        <STEModal.Button onClick={() => setShowSuccess(false)}>
          Share with Someone Else
        </STEModal.Button>
      </STEModal>
    </div>
  );
}
