import {
  Box,
  Typography,
} from '@mui/material';

export const ClassItem = ({ title, sx }) => {
  return (
    <Box sx={{ background: '#F8F8F8', borderRadius: '4px', p: 2, ...sx }}>
      <span>{title}</span>
    </Box>
  );
};

export const TypoItem = ({ title, detail }) => {
  return (
    <Box>
      <Typography sx={{ color: '#929292', fontSize: '12px' }}>{title}</Typography>
      <Typography sx={{ fontWeight: 400, color: '#131515', fontSize: '14px' }}>
        {detail}
      </Typography>
    </Box>
  );
};
