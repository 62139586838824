import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function QuizWarning(props) {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/app/student');
      console.log('Delayed for 1 second.');
    }, 60000);
  }, []);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Box textAlign={'center'}>
          <Typography variant="h6" sx={{ fontWeight: '700' }}>
            Are you still there?
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: '500', my: 2 }}>
            Click on YES button and continue your quiz!
          </Typography>
          <Box
            mt={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button
              sx={{ mr: 2, minWidth: '120px' }}
              variant="contained"
              onClick={() => props.handlePopupResponse('yes')}
            >
              Yes
            </Button>
            <Button
              sx={{ mr: 2, minWidth: '120px' }}
              variant="outlined"
              onClick={() => navigate('/app/student')}
            >
              No
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
