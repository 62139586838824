import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'store/store';

export default function StudentProgressHeader() {
  const { studentDetail } = useAppSelector((state) => state.userReducer);

  return (
    <Box
      mb={2}
      mt={0}
      style={{ justifyContent: 'space-around', gap: '10px', display: 'flex' }}
    >
      <Box
        bgcolor={'#20A58A'}
        textAlign={'center'}
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          px: 2,
          py: 1,
        }}
      >
        <Typography
          style={{
            color: '#fff',
            fontWeight: 600,
            fontSize: '14px',
          }}
        >
          Total Assignments
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#fff'}
        >
          <strong>{studentDetail?.totalAssignments || 0}</strong>
        </Typography>
      </Box>
      <Box
        bgcolor={'#20A58A'}
        textAlign={'center'}
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          px: 2,
          py: 1,
        }}
      >
        <Typography
          style={{
            color: '#fff',
            fontWeight: 600,
            fontSize: '14px',
          }}
        >
          Completed
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#fff'}
        >
          <strong>{studentDetail?.completedAssignments || 0}</strong>
        </Typography>
      </Box>
      <Box
        bgcolor={'#20A58A'}
        textAlign={'center'}
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          px: 2,
          py: 1,
        }}
      >
        <Typography
          style={{
            color: '#fff',
            fontWeight: 600,
            fontSize: '14px',
          }}
        >
          Not Started
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#fff'}
        >
          <strong>{studentDetail?.pendingAssignments || 0}</strong>
        </Typography>
      </Box>
      <Box
        bgcolor={'#20A58A'}
        textAlign={'center'}
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          px: 2,
          py: 1,
        }}
      >
        <Typography
          style={{
            color: '#fff',
            fontWeight: 600,
            fontSize: '14px',
          }}
        >
          Total Average Score
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#F41F52'}
        >
          <strong>{studentDetail?.averageScore || 0}%</strong>
        </Typography>
      </Box>
      <Box
        bgcolor={'#20A58A'}
        textAlign={'center'}
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          px: 2,
          py: 1,
        }}
      >
        <Typography
          style={{
            color: '#fff',
            fontWeight: 600,
            fontSize: '14px',
          }}
        >
          Watch Time
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#fff'}
        >
          <strong>{studentDetail?.totalWatchHours || 0}</strong>{' '}
        </Typography>
      </Box>
      <Box
        bgcolor={'#20A58A'}
        textAlign={'center'}
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          px: 2,
          py: 1,
        }}
      >
        <Typography
          style={{
            color: '#fff',
            fontWeight: 600,
            fontSize: '14px',
          }}
        >
          Total G3MS
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#fff'}
        >
          <strong>{studentDetail?.totalGems || 0}</strong>
        </Typography>
      </Box>
    </Box>
  );
}
