import styles from './syle.module.scss';

const YesResBtn = ({
  options = [],
  handleOptionSelect,
  isDisabled,
}) => {
  

  return (
    <div className={styles.buttonContainer}>
      {options?.map((item, _index) => (
        <button
          className="bg-[#20A58A] h-[3rem] px-4  rounded-xl text-sm sm:text-base md:text-base text-white font-bold w-full even:bg-[#FF6B6B]
          [box-shadow:_0px_4px_12px_0px_#FFFFFF66_inset]
          even:[box-shadow:_0px_4px_12px_0px_#FFFFFF66_inset]
          "
          disabled={isDisabled}
          onClick={() => {
            if (isDisabled) return;
            handleOptionSelect(item);
          }}
        >
          {item?.label}
        </button>
      ))}
    </div>
  );
};

export default YesResBtn;
