const Index = ({ selectedCanvasObj }) => {
  return (
    <>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="100%" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
<path fill='transparent' opacity="1.000000" stroke="none" 
	d="
M691.000000,1001.000000 
	C460.666656,1001.000000 230.833328,1001.000000 1.000000,1001.000000 
	C1.000000,667.666687 1.000000,334.333344 1.000000,1.000000 
	C334.333344,1.000000 667.666687,1.000000 1001.000000,1.000000 
	C1001.000000,334.333344 1001.000000,667.666687 1001.000000,1001.000000 
	C897.833313,1001.000000 794.666687,1001.000000 691.000000,1001.000000 
M506.000000,398.000000 
	C506.000000,398.000000 505.950745,397.946533 505.460083,398.130432 
	C505.246704,398.402771 505.033325,398.675140 504.293640,399.203888 
	C503.497589,399.723877 502.701508,400.243835 501.452850,401.070038 
	C501.235748,401.359375 501.018646,401.648682 500.206879,402.249542 
	C490.216248,409.212494 480.510712,416.635651 470.173767,423.039673 
	C450.548157,435.198303 430.815552,447.230804 410.647400,458.453094 
	C393.031586,468.255188 375.141144,477.760590 356.664764,485.764343 
	C337.966187,493.864319 318.771149,501.348145 299.125977,506.608856 
	C269.657074,514.500244 239.657562,520.463623 209.777618,526.742065 
	C196.785202,529.472046 183.542511,531.038757 170.387482,532.955322 
	C163.875473,533.904053 157.299408,534.405518 150.762070,535.190491 
	C146.353546,535.719788 141.933945,537.042175 137.569092,536.854126 
	C129.964264,536.526611 123.102867,539.191956 115.271332,541.029541 
	C113.142052,541.988159 111.012779,542.946838 108.233246,543.980530 
	C107.142677,544.944824 106.052116,545.909119 104.368271,547.195251 
	C103.230957,548.433533 102.093651,549.671753 100.327271,551.000610 
	C99.528374,551.614685 98.729477,552.228760 97.383461,553.135254 
	C96.580917,554.057190 95.778366,554.979187 94.376793,556.222290 
	C92.898193,558.444153 91.419601,560.666016 89.355095,563.279053 
	C87.863983,566.501709 86.372871,569.724304 84.293678,573.362183 
	C82.012955,581.224121 79.848755,589.122498 77.404518,596.933289 
	C76.096001,601.114807 77.100037,604.349792 80.997101,605.999756 
	C89.407974,609.560730 97.717293,614.067261 106.538200,615.815979 
	C128.993378,620.267761 151.680389,623.590515 174.324875,627.034546 
	C193.473892,629.946838 212.652740,632.755188 231.895096,634.931213 
	C246.129272,636.540894 260.482239,637.825195 274.790192,637.909180 
	C306.382172,638.094788 337.979889,637.488159 369.573730,637.085754 
	C381.843842,636.929443 394.110901,636.485596 406.377441,636.110291 
	C415.483093,635.831726 424.588776,635.519653 433.689026,635.104248 
	C454.673645,634.146301 475.656616,633.149048 496.636993,632.102844 
	C508.523743,631.510132 520.403748,630.783325 532.287354,630.126831 
	C550.445984,629.123718 568.603394,628.098389 586.764465,627.141113 
	C600.302612,626.427490 613.844177,625.772827 627.387024,625.153381 
	C643.923889,624.396912 660.464233,623.715027 677.002258,622.981812 
	C690.871826,622.366943 704.740601,621.732544 718.609009,621.092712 
	C732.975891,620.429871 747.341003,619.728210 761.708740,619.085999 
	C770.137207,618.709290 778.570251,618.436279 786.998047,618.047241 
	C787.726318,618.013611 788.430603,617.458618 789.860718,617.047363 
	C790.578735,616.983826 791.296753,616.920288 792.844238,616.960449 
	C794.932251,616.700928 797.020264,616.441467 799.932251,616.042236 
	C806.290527,615.314026 812.648804,614.585815 819.876709,613.962219 
	C823.255737,613.595764 826.634827,613.229309 830.859985,612.978333 
	C835.913086,612.272827 840.966125,611.567383 846.852722,610.970764 
	C849.436462,610.653015 852.039368,610.443787 854.599182,609.991028 
	C858.087402,609.374023 861.542725,608.571106 865.836304,607.959595 
	C868.229675,607.593323 870.622986,607.227051 873.770813,606.952881 
	C883.061157,603.795105 882.834717,603.832947 881.207458,594.099487 
	C879.827881,585.847717 879.160706,577.479553 877.982971,569.190674 
	C876.208069,556.698364 874.128662,544.248535 872.429016,531.746643 
	C870.793152,519.714783 869.324524,507.655426 868.067200,495.578522 
	C867.129211,486.569427 866.737671,477.504913 865.936890,468.479767 
	C864.701782,454.558594 863.433472,440.638428 861.958801,426.741241 
	C861.290710,420.444672 860.093445,414.204285 859.054016,407.274292 
	C858.994202,406.832916 858.934387,406.391510 858.975586,405.154022 
	C858.691956,403.409851 858.408386,401.665710 858.051453,399.264404 
	C857.991150,398.828552 857.930908,398.392731 857.971802,397.192352 
	C857.689087,395.769226 857.406372,394.346100 857.051147,392.262573 
	C856.992065,391.824371 856.932922,391.386169 856.973694,390.185120 
	C856.690735,388.764984 856.407837,387.344849 856.050232,385.262390 
	C855.990356,384.822845 855.930481,384.383270 855.973938,383.079620 
	C855.000061,376.343323 854.026123,369.607025 853.035034,361.986908 
	C852.999512,360.005371 853.135193,358.003296 852.899963,356.045776 
	C851.655823,345.692963 850.393982,335.341034 848.984680,325.009796 
	C848.156921,318.941986 847.043091,312.913239 846.032898,306.007690 
	C845.066956,300.646851 844.100952,295.285980 843.040405,289.265717 
	C842.984985,288.825256 842.929565,288.384827 842.974731,287.261261 
	C842.667786,286.465942 842.360840,285.670624 842.027527,284.150299 
	C841.717041,283.061157 841.406494,281.972015 841.014587,280.427277 
	C841.014587,280.427277 840.910522,279.976318 840.857361,279.491333 
	C840.674255,279.163086 840.397583,279.031616 839.460999,278.759308 
	C838.647095,278.141144 837.833191,277.522980 836.539856,276.324677 
	C833.716492,275.926910 830.893127,275.529114 827.385437,275.052155 
	C826.927856,275.074127 826.470215,275.096100 825.218140,275.353241 
	C821.263428,275.566071 817.304871,276.009308 813.354919,275.942627 
	C805.701111,275.813416 797.907349,276.211456 790.439758,274.883392 
	C782.083191,273.397186 773.961487,270.429932 765.847717,267.771057 
	C745.643982,261.150299 725.397827,254.633896 705.367676,247.515778 
	C687.418274,241.137100 669.332520,235.919403 649.759705,235.465790 
	C649.190369,235.346344 648.621033,235.226898 647.112183,235.024979 
	C642.652771,235.014511 638.192566,235.046478 633.734497,234.963562 
	C632.629761,234.943024 630.755920,234.837799 630.550659,234.284027 
	C628.458191,228.638977 624.550598,231.051056 620.264587,232.032303 
	C618.505493,232.741531 616.746338,233.450775 614.332458,234.322403 
	C612.521667,235.916687 610.061707,237.167877 609.007690,239.162155 
	C603.123413,250.295288 597.579346,261.608246 591.335022,273.258728 
	C582.746216,291.764893 574.174988,310.279205 565.558777,328.772583 
	C562.721436,334.862579 559.767822,340.898407 556.269592,347.273743 
	C551.497437,354.159363 546.725281,361.044983 541.339050,368.224091 
	C533.528809,375.104523 525.718506,381.984985 517.334229,388.947479 
	C516.857361,389.219696 516.380493,389.491913 515.467041,390.062622 
	C515.291992,390.360382 515.116943,390.658142 514.999756,391.000000 
	C514.999756,391.000000 514.954346,390.943298 514.466003,391.133972 
	C514.252686,391.406738 514.039307,391.679474 513.262939,391.957764 
	C512.803162,392.220612 512.343323,392.483459 511.451202,393.047638 
	C511.284027,393.347565 511.116882,393.647522 510.996979,394.001221 
	C510.996979,394.001221 510.944214,393.950623 510.466949,394.120392 
	C510.290558,394.399048 510.114197,394.677704 509.992676,395.002899 
	C509.992676,395.002899 509.957550,394.940369 509.471252,395.136780 
	C509.259552,395.410889 509.047882,395.684998 508.272156,395.964600 
	C507.812866,396.228638 507.353577,396.492706 506.460724,397.057220 
	C506.288910,397.355560 506.117096,397.653900 506.000000,398.000000 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M100.956345,550.910034 
	C102.093651,549.671753 103.230957,548.433533 104.708679,547.394531 
	C107.419403,549.566956 109.657890,551.730591 112.184158,553.478271 
	C120.645134,559.331726 123.936951,567.711609 120.719154,577.210632 
	C119.209045,581.668579 120.608093,584.443115 124.440315,586.110291 
	C128.067215,587.688110 131.863541,589.416626 135.720123,589.834229 
	C153.796188,591.791382 171.902924,594.446838 190.028900,594.701294 
	C210.542847,594.989197 231.103027,593.203125 251.614716,591.878967 
	C260.827332,591.284302 270.077301,590.149170 279.122803,588.328003 
	C295.812286,584.967773 312.351532,580.866821 328.975494,577.174316 
	C346.070007,573.377258 363.213867,569.800049 380.289978,565.923279 
	C387.841522,564.208923 395.270111,561.957825 402.770966,560.013916 
	C413.134369,557.328247 423.621429,555.057190 433.851105,551.938049 
	C441.927612,549.475342 449.686096,545.975464 457.612579,543.007507 
	C458.646118,542.620544 459.849365,542.710938 460.957153,542.494080 
	C467.054596,541.300232 473.196594,540.284241 479.227783,538.815002 
	C486.323242,537.086670 493.374451,535.127319 500.340912,532.937500 
	C504.482086,531.635742 509.109558,530.524292 512.307983,527.869202 
	C518.510864,522.720032 526.321289,518.302063 526.399902,508.512421 
	C526.403137,508.107147 527.032104,507.743134 527.221802,507.297089 
	C528.214050,504.964264 529.256470,502.645386 530.093140,500.255768 
	C530.993958,497.682892 530.915527,493.618225 532.616760,492.693939 
	C537.370178,490.111450 539.909424,487.302277 541.086853,481.577759 
	C542.577881,474.328796 546.348511,467.540863 549.208130,460.583740 
	C551.448303,455.133820 553.938538,449.782990 556.052246,444.286133 
	C557.591858,440.282318 558.970947,436.175201 559.937073,432.001923 
	C561.493408,425.279297 563.409180,418.505402 563.780029,411.677460 
	C564.225525,403.475159 563.227661,395.192749 562.821106,386.946716 
	C562.678772,384.060577 562.728210,381.122711 562.181152,378.307343 
	C560.921204,371.823334 559.430420,365.658295 561.146606,358.724457 
	C562.007996,355.244171 562.797241,349.191742 556.867371,346.958984 
	C559.767822,340.898407 562.721436,334.862579 565.558777,328.772583 
	C574.174988,310.279205 582.746216,291.764893 591.783508,273.378448 
	C593.208008,274.749695 594.184082,276.001221 595.754395,278.014740 
	C598.341064,274.938660 600.382629,272.420929 602.522888,269.990143 
	C604.809570,267.393036 606.717590,263.630768 609.608093,262.537445 
	C624.086792,257.060913 638.942017,252.285034 654.663147,256.467102 
	C669.216064,260.338409 683.737732,264.450256 697.981323,269.311249 
	C705.556152,271.896362 712.967468,275.619629 719.599487,280.093689 
	C724.653748,283.503418 728.421631,288.851471 732.654419,293.436310 
	C735.542664,296.564850 738.860596,299.518585 740.906372,303.157654 
	C745.488281,311.307770 749.497437,319.788269 753.547058,328.226715 
	C756.969971,335.359161 760.392334,342.513489 763.327576,349.853088 
	C767.333618,359.870483 773.247864,369.217773 774.326416,380.315765 
	C774.429626,381.377441 775.176636,382.559387 775.966492,383.339233 
	C779.079651,386.412811 778.180420,389.555908 776.564331,392.921783 
	C771.289673,403.907043 766.111755,414.938782 760.873474,425.941559 
	C759.106384,429.653137 757.544128,433.499176 755.410950,436.992249 
	C750.007385,445.840607 744.013794,454.343475 738.926453,463.362610 
	C736.918518,466.922302 737.095276,471.645325 735.553528,475.553070 
	C733.266663,481.349426 730.705566,487.109253 727.552307,492.467468 
	C725.362061,496.189209 721.894897,499.141266 719.156311,502.563690 
	C716.604675,505.752380 713.906677,508.400726 709.425049,508.524414 
	C708.638550,508.546143 707.934998,510.149292 707.119019,510.946228 
	C706.372253,511.675568 705.618225,512.487305 704.707703,512.945068 
	C697.007141,516.816223 689.287903,520.651550 681.529663,524.405518 
	C679.834351,525.225769 677.978943,526.143372 676.165955,526.214111 
	C669.968384,526.456177 668.797058,527.756104 668.947693,534.029419 
	C669.058899,538.662659 671.773315,540.298462 676.498291,540.700806 
	C675.747742,542.817078 674.692505,544.450684 674.717651,546.067505 
	C674.747864,548.010010 675.108887,550.850769 676.379883,551.671448 
	C678.617432,553.116211 681.699585,553.187561 684.347961,554.073608 
	C692.622925,556.842285 700.867004,559.703735 709.108826,562.570129 
	C712.036560,563.588440 714.901123,564.789673 717.833862,565.792053 
	C723.569336,567.752319 729.419067,569.408875 735.062073,571.601318 
	C741.967957,574.284363 748.734985,577.332458 755.514587,580.329956 
	C760.069458,582.343750 764.565979,584.494080 769.045959,586.671204 
	C776.445190,590.266968 783.748413,594.068298 791.227600,597.486572 
	C793.409119,598.483643 796.135864,599.119263 798.467529,598.813538 
	C808.879395,597.447998 817.753845,593.952881 824.336121,584.527649 
	C833.655945,571.182617 837.678223,556.149414 838.605164,540.717957 
	C839.461243,526.466980 837.315613,511.986267 835.914734,497.663147 
	C834.532959,483.534637 833.242920,469.311249 830.423828,455.431854 
	C827.206482,439.591614 823.234497,423.795410 815.648132,409.262817 
	C813.819336,405.759491 811.556335,402.422485 810.192505,398.749298 
	C807.411316,391.258301 808.295959,383.284546 809.794739,375.877289 
	C812.241577,363.784454 816.309021,352.027771 819.424500,340.059479 
	C821.838440,330.786224 823.506592,321.310791 826.124756,312.101074 
	C828.670715,303.145508 828.123291,293.293640 834.184265,285.280457 
	C835.871460,283.049927 836.114014,279.726746 837.019287,276.904785 
	C837.833191,277.522980 838.647095,278.141144 839.839233,279.121277 
	C840.393982,279.724213 840.625000,279.888580 840.910522,279.976318 
	C840.910522,279.976318 841.014587,280.427277 841.025879,280.700928 
	C841.037109,280.974548 840.970276,281.059296 840.647156,281.084167 
	C839.881897,282.406067 839.439758,283.703033 838.997559,285.332916 
	C838.997620,286.109741 838.997620,286.553650 838.645142,287.056824 
	C837.869934,287.579834 837.336853,287.982483 837.040771,288.516510 
	C832.260742,297.136444 833.388855,306.858704 832.087158,316.102234 
	C830.201843,329.490540 829.457031,343.055206 827.200623,356.370941 
	C825.028259,369.190918 825.407776,381.919006 826.902039,394.598145 
	C828.037964,404.235992 830.573303,413.715454 832.607117,423.239563 
	C836.366394,440.843201 840.250793,458.417877 841.043152,476.495178 
	C841.253113,481.285736 841.704468,486.069031 842.157837,490.844940 
	C843.406555,503.999725 845.346558,517.125061 845.841858,530.306030 
	C846.476074,547.183716 845.974670,563.991333 840.266174,580.374451 
	C836.084229,592.376343 830.627136,603.087219 820.365234,610.945618 
	C819.611267,611.523010 819.446045,612.869019 819.007080,613.857666 
	C812.648804,614.585815 806.290527,615.314026 799.270752,615.800903 
	C796.411133,615.992004 794.212952,616.424377 792.014771,616.856750 
	C791.296753,616.920288 790.578735,616.983826 789.269897,616.801025 
	C787.453369,616.374084 786.227661,616.193420 784.938477,615.655029 
	C778.207275,611.512024 771.634827,607.544495 764.842529,603.997864 
	C759.166077,601.033875 753.238525,598.548950 747.410950,595.876526 
	C740.381104,592.652832 733.514282,588.969788 726.263062,586.356201 
	C714.346252,582.060852 702.154480,578.533936 690.121948,574.552307 
	C681.968811,571.854431 674.087830,567.755554 665.737244,566.350525 
	C647.096619,563.213928 628.296265,560.516663 609.452576,559.234985 
	C589.339722,557.867004 569.102966,558.227539 548.918457,558.060059 
	C542.212952,558.004517 535.494995,558.552551 528.794739,559.003479 
	C522.046570,559.457825 515.314392,560.147522 508.573273,560.710388 
	C497.404572,561.642944 486.199066,562.258667 475.075867,563.573242 
	C466.320190,564.607971 457.660614,566.469055 448.966339,568.008118 
	C431.405457,571.116638 413.842529,574.214905 396.296417,577.405334 
	C390.452393,578.467896 384.683685,579.950439 378.833710,580.973206 
	C368.747772,582.736755 358.608246,584.194031 348.523010,585.961548 
	C339.568542,587.530884 330.699005,589.625122 321.714783,590.972778 
	C313.392944,592.221008 304.951111,592.645325 296.593597,593.685486 
	C282.650024,595.420837 268.930634,599.125122 254.671906,598.070068 
	C251.577637,597.841064 248.414566,598.467407 245.288528,598.757629 
	C238.703171,599.368896 232.115219,600.599670 225.539398,600.530090 
	C212.279144,600.389771 199.024323,599.593201 185.771378,598.978699 
	C180.645447,598.741028 175.509323,598.447937 170.414795,597.859985 
	C154.172318,595.985413 137.961807,593.826782 121.709518,592.046753 
	C112.808563,591.071838 108.030716,584.182678 110.086311,575.701355 
	C111.597130,569.467834 110.778564,563.241150 106.457039,557.948303 
	C104.574318,555.642395 102.786995,553.258484 100.956345,550.910034 
M593.708801,364.378113 
	C594.501038,370.187500 595.303101,375.995544 596.081238,381.806824 
	C596.454895,384.597656 596.760315,387.397644 597.138062,390.187897 
	C597.599182,393.594360 596.916382,398.053375 598.821838,400.191010 
	C603.692505,405.655151 599.546814,411.687317 600.788208,417.346191 
	C601.387756,420.079285 598.477539,424.395935 599.804016,428.492584 
	C600.106201,429.425842 598.839661,430.792175 598.513428,432.017334 
	C597.404785,436.181152 594.188232,441.758423 595.774902,444.280884 
	C599.907349,450.850372 595.197021,456.619049 596.201050,462.618134 
	C596.579407,464.879333 596.570435,467.215027 596.620544,469.519348 
	C596.712219,473.731140 599.612793,478.241608 596.072571,481.032684 
	C596.925415,484.906097 596.729553,488.666412 598.500244,490.776886 
	C600.345154,492.975830 604.091797,493.573029 606.992004,494.903076 
	C607.266174,495.028839 607.387146,495.517303 607.658997,495.614746 
	C609.034058,496.107666 610.709045,497.252747 611.781189,496.848602 
	C616.914917,494.913544 620.379517,497.068665 622.186646,499.670319 
	C626.535034,498.908600 630.088440,497.270050 633.092468,497.974457 
	C639.087524,499.380188 647.635437,499.247498 651.157654,493.126282 
	C654.700928,495.373138 657.691528,495.692902 661.035522,491.953491 
	C662.840820,489.934692 666.674622,489.729858 670.463013,488.401733 
	C672.213745,487.079468 674.881592,485.062317 677.551880,483.048431 
	C679.867981,481.301636 682.545532,479.874268 684.422729,477.741760 
	C687.427124,474.328705 691.901489,471.871765 692.382996,466.555206 
	C692.434692,465.983948 693.907959,465.122925 694.730652,465.112762 
	C698.236694,465.069397 699.834473,463.350616 700.234497,459.977386 
	C700.333923,459.139160 701.218384,458.185547 701.996765,457.668518 
	C709.938538,452.393524 716.269470,445.538971 722.067749,438.042938 
	C725.031677,434.211121 728.811218,431.020721 731.941711,427.304291 
	C735.204590,423.430664 738.301270,419.388184 741.128784,415.189362 
	C742.481140,413.181183 743.058044,410.646667 743.958130,408.339630 
	C744.496033,406.960846 744.634277,405.256348 745.546265,404.222107 
	C750.050781,399.113708 750.246643,392.652374 750.862183,386.546112 
	C751.659790,378.635254 750.853149,370.943024 747.156555,363.394043 
	C742.099976,353.067688 738.567139,342.001160 733.639893,331.603119 
	C730.669556,325.334930 726.275940,319.743835 722.552490,313.829193 
	C718.956116,308.116425 715.601074,302.237091 711.728333,296.719910 
	C710.103882,294.405731 707.539673,292.670502 705.192322,290.960876 
	C700.001953,287.180542 694.589233,283.702972 689.437927,279.872314 
	C685.515625,276.955536 682.276184,272.797516 677.987122,270.767822 
	C670.412476,267.183289 662.468323,263.682709 654.308044,262.231018 
	C646.329895,260.811646 637.844482,261.520172 629.673889,262.282501 
	C626.646973,262.564911 623.570984,265.307831 621.065430,267.542999 
	C616.507324,271.609222 612.296265,276.074432 608.055420,280.483795 
	C602.643982,286.110138 596.863647,291.477020 592.147217,297.647430 
	C588.108582,302.931244 586.156738,309.676971 580.722290,314.181152 
	C579.551575,315.151398 579.505737,317.819855 579.443726,319.722382 
	C579.261414,325.312561 579.538757,330.923309 579.206665,336.500458 
	C579.062500,338.920654 580.041260,339.855927 581.474792,341.642700 
	C585.196411,346.281433 587.870117,351.778870 590.806580,357.020721 
	C591.976807,359.109680 592.737976,361.427765 593.708801,364.378113 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M836.779541,276.614746 
	C836.114014,279.726746 835.871460,283.049927 834.184265,285.280457 
	C828.123291,293.293640 828.670715,303.145508 826.124756,312.101074 
	C823.506592,321.310791 821.838440,330.786224 819.424500,340.059479 
	C816.309021,352.027771 812.241577,363.784454 809.794739,375.877289 
	C808.295959,383.284546 807.411316,391.258301 810.192505,398.749298 
	C811.556335,402.422485 813.819336,405.759491 815.648132,409.262817 
	C823.234497,423.795410 827.206482,439.591614 830.423828,455.431854 
	C833.242920,469.311249 834.532959,483.534637 835.914734,497.663147 
	C837.315613,511.986267 839.461243,526.466980 838.605164,540.717957 
	C837.678223,556.149414 833.655945,571.182617 824.336121,584.527649 
	C817.753845,593.952881 808.879395,597.447998 798.467529,598.813538 
	C796.135864,599.119263 793.409119,598.483643 791.227600,597.486572 
	C783.748413,594.068298 776.445190,590.266968 769.045959,586.671204 
	C764.565979,584.494080 760.069458,582.343750 755.514587,580.329956 
	C748.734985,577.332458 741.967957,574.284363 735.062073,571.601318 
	C729.419067,569.408875 723.569336,567.752319 717.833862,565.792053 
	C714.901123,564.789673 712.036560,563.588440 709.108826,562.570129 
	C700.867004,559.703735 692.622925,556.842285 684.347961,554.073608 
	C681.699585,553.187561 678.617432,553.116211 676.379883,551.671448 
	C675.108887,550.850769 674.747864,548.010010 674.717651,546.067505 
	C674.692505,544.450684 675.747742,542.817078 676.498291,540.700806 
	C671.773315,540.298462 669.058899,538.662659 668.947693,534.029419 
	C668.797058,527.756104 669.968384,526.456177 676.165955,526.214111 
	C677.978943,526.143372 679.834351,525.225769 681.529663,524.405518 
	C689.287903,520.651550 697.007141,516.816223 704.707703,512.945068 
	C705.618225,512.487305 706.372253,511.675568 707.119019,510.946228 
	C707.934998,510.149292 708.638550,508.546143 709.425049,508.524414 
	C713.906677,508.400726 716.604675,505.752380 719.156311,502.563690 
	C721.894897,499.141266 725.362061,496.189209 727.552307,492.467468 
	C730.705566,487.109253 733.266663,481.349426 735.553528,475.553070 
	C737.095276,471.645325 736.918518,466.922302 738.926453,463.362610 
	C744.013794,454.343475 750.007385,445.840607 755.410950,436.992249 
	C757.544128,433.499176 759.106384,429.653137 760.873474,425.941559 
	C766.111755,414.938782 771.289673,403.907043 776.564331,392.921783 
	C778.180420,389.555908 779.079651,386.412811 775.966492,383.339233 
	C775.176636,382.559387 774.429626,381.377441 774.326416,380.315765 
	C773.247864,369.217773 767.333618,359.870483 763.327576,349.853088 
	C760.392334,342.513489 756.969971,335.359161 753.547058,328.226715 
	C749.497437,319.788269 745.488281,311.307770 740.906372,303.157654 
	C738.860596,299.518585 735.542664,296.564850 732.654419,293.436310 
	C728.421631,288.851471 724.653748,283.503418 719.599487,280.093689 
	C712.967468,275.619629 705.556152,271.896362 697.981323,269.311249 
	C683.737732,264.450256 669.216064,260.338409 654.663147,256.467102 
	C638.942017,252.285034 624.086792,257.060913 609.608093,262.537445 
	C606.717590,263.630768 604.809570,267.393036 602.522888,269.990143 
	C600.382629,272.420929 598.341064,274.938660 595.754395,278.014740 
	C594.184082,276.001221 593.208008,274.749695 592.075500,273.178955 
	C597.579346,261.608246 603.123413,250.295288 609.007690,239.162155 
	C610.061707,237.167877 612.521667,235.916687 614.668945,234.595184 
	C615.336182,236.244644 615.666870,237.621307 615.731262,239.117889 
	C614.975037,239.824097 614.485107,240.410400 613.679810,241.135193 
	C612.504517,241.776825 611.264648,242.074539 610.859497,242.823669 
	C609.859314,244.673019 609.241821,246.729401 608.470520,248.702576 
	C610.513550,249.438385 612.643127,250.942535 614.578735,250.724533 
	C617.280396,250.420273 619.765930,248.561874 622.470093,247.842636 
	C628.990662,246.108261 635.568420,243.431335 642.152222,243.344650 
	C654.112183,243.187195 665.688171,246.676117 676.790100,250.998230 
	C695.933655,258.451019 714.699707,266.907104 733.989380,273.940460 
	C748.898926,279.376740 758.772461,289.576141 766.937561,302.530304 
	C771.908020,310.416046 775.537476,319.210510 782.740601,325.720032 
	C787.020996,329.588287 792.626770,331.395416 794.809204,327.957245 
	C798.405762,322.291229 801.501343,316.302002 804.702454,310.391785 
	C807.541138,305.150696 810.180420,299.801239 813.045044,294.574921 
	C813.566406,293.623627 814.656799,292.539612 815.640991,292.356018 
	C824.505615,290.702911 827.385864,283.076324 831.764343,276.777283 
	C830.400085,276.169464 829.234924,275.650391 828.069763,275.131287 
	C830.893127,275.529114 833.716492,275.926910 836.779541,276.614746 
M767.146423,459.720551 
	C766.795837,462.072632 766.604736,464.462006 766.064697,466.769745 
	C764.458130,473.635437 761.670044,480.403564 761.235901,487.328430 
	C760.253723,502.996124 751.471619,514.399231 742.412720,526.033630 
	C742.837646,527.182556 743.891113,528.603882 743.740906,529.883911 
	C743.226196,534.270569 742.614502,538.698669 741.427673,542.939636 
	C740.062439,547.817932 741.656677,550.680542 745.941772,553.045532 
	C752.153381,556.473877 758.016418,560.529358 764.187134,564.037781 
	C769.817566,567.238892 773.469543,573.355408 780.336060,574.713562 
	C785.632202,575.761108 790.865356,577.126221 796.160583,578.179016 
	C807.228027,580.379456 817.816223,574.368347 821.930908,563.918579 
	C825.148010,555.748535 828.631897,547.764099 828.156738,538.612366 
	C827.755859,530.891052 828.456909,523.106750 827.942200,515.398926 
	C827.537292,509.334534 825.909058,503.360840 825.026245,497.317352 
	C823.595398,487.521851 822.749695,477.619110 820.830994,467.925110 
	C819.753052,462.478821 816.049255,457.503052 815.248962,452.062256 
	C813.956604,443.275848 809.100769,437.399170 802.558411,432.347687 
	C800.212891,430.536743 797.710266,427.733307 795.220276,427.669006 
	C793.074219,427.613556 790.857910,430.823242 788.638000,432.544647 
	C788.265442,432.833527 787.574951,432.760864 787.270691,433.082031 
	C782.542603,438.073029 775.128723,440.349121 773.163940,448.240051 
	C772.207397,452.081818 769.476257,455.481720 767.146423,459.720551 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M556.568481,347.116364 
	C562.797241,349.191742 562.007996,355.244171 561.146606,358.724457 
	C559.430420,365.658295 560.921204,371.823334 562.181152,378.307343 
	C562.728210,381.122711 562.678772,384.060577 562.821106,386.946716 
	C563.227661,395.192749 564.225525,403.475159 563.780029,411.677460 
	C563.409180,418.505402 561.493408,425.279297 559.937073,432.001923 
	C558.970947,436.175201 557.591858,440.282318 556.052246,444.286133 
	C553.938538,449.782990 551.448303,455.133820 549.208130,460.583740 
	C546.348511,467.540863 542.577881,474.328796 541.086853,481.577759 
	C539.909424,487.302277 537.370178,490.111450 532.616760,492.693939 
	C530.915527,493.618225 530.993958,497.682892 530.093140,500.255768 
	C529.256470,502.645386 528.214050,504.964264 527.221802,507.297089 
	C527.032104,507.743134 526.403137,508.107147 526.399902,508.512421 
	C526.321289,518.302063 518.510864,522.720032 512.307983,527.869202 
	C509.109558,530.524292 504.482086,531.635742 500.340912,532.937500 
	C493.374451,535.127319 486.323242,537.086670 479.227783,538.815002 
	C473.196594,540.284241 467.054596,541.300232 460.957153,542.494080 
	C459.849365,542.710938 458.646118,542.620544 457.612579,543.007507 
	C449.686096,545.975464 441.927612,549.475342 433.851105,551.938049 
	C423.621429,555.057190 413.134369,557.328247 402.770966,560.013916 
	C395.270111,561.957825 387.841522,564.208923 380.289978,565.923279 
	C363.213867,569.800049 346.070007,573.377258 328.975494,577.174316 
	C312.351532,580.866821 295.812286,584.967773 279.122803,588.328003 
	C270.077301,590.149170 260.827332,591.284302 251.614716,591.878967 
	C231.103027,593.203125 210.542847,594.989197 190.028900,594.701294 
	C171.902924,594.446838 153.796188,591.791382 135.720123,589.834229 
	C131.863541,589.416626 128.067215,587.688110 124.440315,586.110291 
	C120.608093,584.443115 119.209045,581.668579 120.719154,577.210632 
	C123.936951,567.711609 120.645134,559.331726 112.184158,553.478271 
	C109.657890,551.730591 107.419403,549.566956 105.005318,547.233643 
	C106.052116,545.909119 107.142677,544.944824 108.845726,544.143005 
	C112.662720,546.547791 115.569191,549.700806 119.126526,550.864929 
	C130.706589,554.654480 137.048859,563.750610 140.388046,573.995789 
	C143.133011,582.417786 149.793610,584.338806 155.349564,584.560486 
	C172.161652,585.231140 188.680145,589.590027 205.803894,587.262329 
	C219.673508,585.376953 233.844040,586.209656 247.713715,583.095337 
	C263.487122,579.553589 279.568085,577.352966 295.292542,573.628601 
	C317.820526,568.292908 340.196075,562.286987 362.541626,556.217163 
	C377.685242,552.103577 392.664368,547.384888 407.719543,542.945251 
	C411.980408,541.688782 416.408661,540.839600 420.477142,539.130188 
	C426.083771,536.774414 431.298553,533.486511 436.904175,531.127869 
	C444.938629,527.747192 453.185791,524.874023 461.309967,521.701843 
	C462.336243,521.301086 463.254730,520.494324 464.075989,519.719666 
	C469.225403,514.862610 473.912811,509.394989 479.553894,505.208374 
	C487.863861,499.041077 493.548523,490.972290 498.852570,482.410400 
	C500.734619,479.372406 502.928711,476.529297 504.929291,473.562744 
	C509.273651,467.120697 514.060425,460.916321 517.772583,454.126190 
	C521.282043,447.706696 523.984070,440.780029 526.413452,433.856171 
	C528.417236,428.145203 530.342468,422.177551 530.807983,416.207123 
	C531.596436,406.097076 534.375305,396.802399 538.550476,387.656586 
	C541.357056,381.508759 545.387268,375.277985 541.953064,367.930573 
	C546.725281,361.044983 551.497437,354.159363 556.568481,347.116364 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M541.646057,368.077332 
	C545.387268,375.277985 541.357056,381.508759 538.550476,387.656586 
	C534.375305,396.802399 531.596436,406.097076 530.807983,416.207123 
	C530.342468,422.177551 528.417236,428.145203 526.413452,433.856171 
	C523.984070,440.780029 521.282043,447.706696 517.772583,454.126190 
	C514.060425,460.916321 509.273651,467.120697 504.929291,473.562744 
	C502.928711,476.529297 500.734619,479.372406 498.852570,482.410400 
	C493.548523,490.972290 487.863861,499.041077 479.553894,505.208374 
	C473.912811,509.394989 469.225403,514.862610 464.075989,519.719666 
	C463.254730,520.494324 462.336243,521.301086 461.309967,521.701843 
	C453.185791,524.874023 444.938629,527.747192 436.904175,531.127869 
	C431.298553,533.486511 426.083771,536.774414 420.477142,539.130188 
	C416.408661,540.839600 411.980408,541.688782 407.719543,542.945251 
	C392.664368,547.384888 377.685242,552.103577 362.541626,556.217163 
	C340.196075,562.286987 317.820526,568.292908 295.292542,573.628601 
	C279.568085,577.352966 263.487122,579.553589 247.713715,583.095337 
	C233.844040,586.209656 219.673508,585.376953 205.803894,587.262329 
	C188.680145,589.590027 172.161652,585.231140 155.349564,584.560486 
	C149.793610,584.338806 143.133011,582.417786 140.388046,573.995789 
	C137.048859,563.750610 130.706589,554.654480 119.126526,550.864929 
	C115.569191,549.700806 112.662720,546.547791 109.170853,544.105469 
	C111.012779,542.946838 113.142052,541.988159 115.638374,541.363159 
	C117.136909,542.879272 118.060112,544.684814 119.433449,545.143860 
	C128.864975,548.296936 138.418610,551.083130 147.869659,554.180481 
	C150.837952,555.153198 153.641571,556.657410 156.469162,558.024658 
	C163.252655,561.304565 169.708145,565.650024 176.830704,567.763733 
	C193.492310,572.708374 210.796539,573.673157 228.002655,571.855103 
	C245.028732,570.055969 262.160004,568.064209 278.818726,564.279907 
	C299.281830,559.631348 319.504608,553.651917 339.456787,547.106201 
	C358.489288,540.862366 377.049866,533.176147 395.796875,526.068542 
	C400.511993,524.280823 405.502716,522.861267 409.745239,520.259216 
	C419.029663,514.564697 427.963287,508.289795 436.941559,502.108643 
	C440.674316,499.538818 444.425568,496.885406 447.684143,493.764374 
	C452.110870,489.524506 455.552948,484.127716 460.341522,480.421082 
	C470.692566,472.408722 477.106140,461.305756 484.858795,451.247101 
	C489.183777,445.635651 493.290527,439.852600 497.394104,434.074799 
	C502.190155,427.321960 508.376770,421.053802 511.196564,413.538483 
	C514.153625,405.657288 518.254700,399.112793 523.520081,392.897186 
	C525.611755,390.428040 527.259949,387.583221 529.110107,384.909424 
	C528.661377,384.518311 528.212585,384.127167 527.763855,383.736053 
	C525.541321,385.293854 523.384338,386.961029 521.061890,388.352051 
	C520.212830,388.860626 518.970886,388.713440 517.908203,388.865417 
	C525.718506,381.984985 533.528809,375.104523 541.646057,368.077332 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M517.621216,388.906433 
	C518.970886,388.713440 520.212830,388.860626 521.061890,388.352051 
	C523.384338,386.961029 525.541321,385.293854 527.763855,383.736053 
	C528.212585,384.127167 528.661377,384.518311 529.110107,384.909424 
	C527.259949,387.583221 525.611755,390.428040 523.520081,392.897186 
	C518.254700,399.112793 514.153625,405.657288 511.196564,413.538483 
	C508.376770,421.053802 502.190155,427.321960 497.394104,434.074799 
	C493.290527,439.852600 489.183777,445.635651 484.858795,451.247101 
	C477.106140,461.305756 470.692566,472.408722 460.341522,480.421082 
	C455.552948,484.127716 452.110870,489.524506 447.684143,493.764374 
	C444.425568,496.885406 440.674316,499.538818 436.941559,502.108643 
	C427.963287,508.289795 419.029663,514.564697 409.745239,520.259216 
	C405.502716,522.861267 400.511993,524.280823 395.796875,526.068542 
	C377.049866,533.176147 358.489288,540.862366 339.456787,547.106201 
	C319.504608,553.651917 299.281830,559.631348 278.818726,564.279907 
	C262.160004,568.064209 245.028732,570.055969 228.002655,571.855103 
	C210.796539,573.673157 193.492310,572.708374 176.830704,567.763733 
	C169.708145,565.650024 163.252655,561.304565 156.469162,558.024658 
	C153.641571,556.657410 150.837952,555.153198 147.869659,554.180481 
	C138.418610,551.083130 128.864975,548.296936 119.433449,545.143860 
	C118.060112,544.684814 117.136909,542.879272 115.995880,541.305420 
	C123.102867,539.191956 129.964264,536.526611 137.569092,536.854126 
	C141.933945,537.042175 146.353546,535.719788 150.762070,535.190491 
	C157.299408,534.405518 163.875473,533.904053 170.387482,532.955322 
	C183.542511,531.038757 196.785202,529.472046 209.777618,526.742065 
	C239.657562,520.463623 269.657074,514.500244 299.125977,506.608856 
	C318.771149,501.348145 337.966187,493.864319 356.664764,485.764343 
	C375.141144,477.760590 393.031586,468.255188 410.647400,458.453094 
	C430.815552,447.230804 450.548157,435.198303 470.173767,423.039673 
	C480.510712,416.635651 490.216248,409.212494 500.779297,401.997864 
	C501.536285,401.418701 501.720856,401.091278 501.905426,400.763855 
	C502.701508,400.243835 503.497589,399.723877 504.813385,398.995850 
	C505.539001,398.507385 505.744904,398.226959 505.950745,397.946533 
	C505.950745,397.946533 506.000000,398.000000 506.218933,397.859283 
	C506.590027,397.397949 506.742157,397.077332 506.894287,396.756775 
	C507.353577,396.492706 507.812866,396.228638 508.808716,395.875977 
	C509.549377,395.505005 509.753479,395.222687 509.957550,394.940369 
	C509.957550,394.940369 509.992676,395.002899 510.198273,394.886475 
	C510.583954,394.496918 510.764069,394.223755 510.944214,393.950623 
	C510.944214,393.950623 510.996979,394.001221 511.219238,393.857117 
	C511.588806,393.390778 511.736176,393.068542 511.883514,392.746307 
	C512.343323,392.483459 512.803162,392.220612 513.800476,391.872620 
	C514.543457,391.506073 514.748901,391.224670 514.954346,390.943298 
	C514.954346,390.943298 514.999756,391.000000 515.217712,390.861389 
	C515.591675,390.403229 515.747620,390.083679 515.903625,389.764130 
	C516.380493,389.491913 516.857361,389.219696 517.621216,388.906433 
M384.003174,478.498596 
	C371.460327,484.607880 359.084808,491.097595 346.323547,496.710358 
	C335.425262,501.503723 324.277954,505.936890 312.887543,509.350891 
	C299.936035,513.232849 286.646271,516.067261 273.389771,518.806396 
	C268.756378,519.763733 264.403809,526.191711 259.030029,520.216125 
	C258.931854,520.106934 258.411987,520.348938 258.101868,520.462585 
	C252.804962,522.403381 246.337265,521.377991 242.359436,526.735779 
	C241.939774,527.300964 240.493439,527.877136 240.168243,527.622986 
	C235.587463,524.043396 231.267700,527.269104 226.929733,528.083252 
	C215.763626,530.179138 204.670593,532.678589 193.481140,534.631653 
	C183.574219,536.361023 173.584015,537.625122 163.615433,538.983459 
	C159.937805,539.484558 156.220886,539.727051 152.514435,539.977112 
	C149.775925,540.161804 147.025787,540.218445 144.280060,540.242493 
	C138.580673,540.292419 132.679398,538.759094 127.271927,542.729431 
	C134.043045,545.098022 140.842758,546.821533 147.740921,547.935608 
	C162.573975,550.331116 177.464218,552.370178 192.318359,554.638245 
	C201.293518,556.008667 210.211212,558.407654 219.216095,558.755432 
	C229.005569,559.133423 238.931229,558.164429 248.678894,556.881531 
	C260.270935,555.355896 272.028442,553.860107 283.188843,550.569458 
	C299.938324,545.630676 316.824005,540.924011 332.643372,533.070984 
	C352.050323,523.437073 371.884827,514.666565 391.347015,505.139771 
	C395.050415,503.326935 397.643341,499.375977 401.158600,497.024292 
	C412.446075,489.472961 423.947693,482.242584 435.324493,474.823639 
	C445.368530,468.273773 454.886200,461.102264 462.425781,451.602875 
	C463.743286,449.942871 465.458588,448.598511 466.990173,447.108398 
	C466.747040,446.767822 466.503906,446.427246 466.260773,446.086670 
	C464.343964,446.608215 462.427124,447.129761 460.510315,447.651337 
	C460.327301,447.396393 460.144287,447.141449 459.961273,446.886536 
	C463.321045,443.274048 466.680817,439.661591 470.040588,436.049133 
	C469.749542,435.653259 469.458496,435.257355 469.167480,434.861481 
	C467.534424,435.382294 465.777649,435.672302 464.292389,436.468781 
	C460.386566,438.563354 456.660339,440.994324 452.743988,443.067383 
	C449.815277,444.617706 446.622742,445.667511 443.683929,447.201233 
	C423.990265,457.478882 404.333160,467.826538 384.003174,478.498596 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M84.881775,572.946960 
	C86.372871,569.724304 87.863983,566.501709 89.808502,563.412109 
	C95.215118,569.775391 93.505867,577.509583 95.029533,584.514832 
	C95.465790,586.520569 95.682419,589.417236 97.020699,590.235352 
	C102.355278,593.496277 107.814148,597.528198 113.732231,598.680908 
	C132.849625,602.404785 152.174103,605.092224 171.452255,607.953491 
	C182.070877,609.529480 192.754395,610.671082 203.412628,611.976257 
	C212.095566,613.039490 220.781586,614.078979 229.471817,615.080688 
	C233.414490,615.535156 237.371796,615.861572 241.320587,616.264343 
	C253.487198,617.505371 265.650635,618.778137 277.819916,619.992188 
	C284.070831,620.615784 290.321045,621.369873 296.590271,621.659729 
	C307.781189,622.177063 318.728668,625.498657 330.209961,623.376221 
	C336.004456,622.305054 342.240753,623.784058 348.279633,623.834656 
	C356.092285,623.900208 364.102173,624.751038 371.677002,623.358704 
	C383.984161,621.096619 395.992096,617.228821 408.153931,614.147888 
	C423.857300,610.169800 439.593323,606.320618 455.304199,602.371826 
	C462.203003,600.637939 468.992401,598.318298 475.978058,597.116821 
	C485.732086,595.439270 495.637115,594.659363 505.451141,593.304138 
	C512.162231,592.377319 518.792664,590.546204 525.521484,590.131287 
	C542.079590,589.110229 558.668823,588.442505 575.255188,588.094238 
	C584.200745,587.906494 593.200745,588.286011 602.114502,589.091797 
	C614.781128,590.236816 627.489990,591.448730 640.000427,593.654663 
	C656.228577,596.516113 671.278259,603.350830 686.257446,609.913818 
	C691.733521,612.313171 696.148132,617.135132 700.575562,620.896606 
	C694.774475,620.960938 689.434937,621.172424 684.120178,620.899841 
	C682.161194,620.799316 679.674072,620.085632 678.452087,618.728943 
	C675.633972,615.600281 672.365662,615.795105 668.845581,616.204407 
	C666.552490,616.471069 664.261108,616.755127 661.965027,616.993469 
	C647.460754,618.499512 633.874939,613.056580 619.787415,611.385986 
	C605.689392,609.714172 591.661987,607.000183 577.523865,606.253540 
	C566.351196,605.663391 555.054443,607.316223 543.818909,608.064453 
	C538.214233,608.437683 532.436584,608.274170 527.057251,609.626648 
	C499.622192,616.523987 472.285950,623.815369 444.933228,631.037415 
	C442.119232,631.780396 439.468658,633.674194 436.668427,633.848511 
	C421.942627,634.765381 407.199188,635.559753 392.450348,635.909607 
	C369.010651,636.465515 345.562592,636.773560 322.116486,636.906189 
	C311.998718,636.963501 301.826477,635.532715 291.768555,636.176819 
	C275.528961,637.216736 260.218109,632.871216 244.692444,629.677734 
	C230.314880,626.720398 216.094345,622.996582 201.710617,620.073120 
	C180.825562,615.828064 159.838501,612.087463 138.929596,607.956360 
	C127.930161,605.783142 116.734764,604.075623 106.109764,600.659180 
	C90.936157,595.779907 88.558105,592.726257 87.338333,576.143677 
	C87.255966,575.023926 85.734123,574.010071 84.881775,572.946960 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M701.046997,620.851868 
	C696.148132,617.135132 691.733521,612.313171 686.257446,609.913818 
	C671.278259,603.350830 656.228577,596.516113 640.000427,593.654663 
	C627.489990,591.448730 614.781128,590.236816 602.114502,589.091797 
	C593.200745,588.286011 584.200745,587.906494 575.255188,588.094238 
	C558.668823,588.442505 542.079590,589.110229 525.521484,590.131287 
	C518.792664,590.546204 512.162231,592.377319 505.451141,593.304138 
	C495.637115,594.659363 485.732086,595.439270 475.978058,597.116821 
	C468.992401,598.318298 462.203003,600.637939 455.304199,602.371826 
	C439.593323,606.320618 423.857300,610.169800 408.153931,614.147888 
	C395.992096,617.228821 383.984161,621.096619 371.677002,623.358704 
	C364.102173,624.751038 356.092285,623.900208 348.279633,623.834656 
	C342.240753,623.784058 336.004456,622.305054 330.209961,623.376221 
	C318.728668,625.498657 307.781189,622.177063 296.590271,621.659729 
	C290.321045,621.369873 284.070831,620.615784 277.819916,619.992188 
	C265.650635,618.778137 253.487198,617.505371 241.320587,616.264343 
	C237.371796,615.861572 233.414490,615.535156 229.471817,615.080688 
	C220.781586,614.078979 212.095566,613.039490 203.412628,611.976257 
	C192.754395,610.671082 182.070877,609.529480 171.452255,607.953491 
	C152.174103,605.092224 132.849625,602.404785 113.732231,598.680908 
	C107.814148,597.528198 102.355278,593.496277 97.020699,590.235352 
	C95.682419,589.417236 95.465790,586.520569 95.029533,584.514832 
	C93.505867,577.509583 95.215118,569.775391 90.101456,563.216553 
	C91.419601,560.666016 92.898193,558.444153 94.686981,556.438660 
	C95.430504,557.695557 95.610275,558.979309 96.337753,559.737671 
	C101.585350,565.208191 102.366898,571.470215 100.362274,578.543396 
	C98.963577,583.478638 101.259232,587.591675 105.098099,590.267761 
	C108.162003,592.403625 111.877716,594.063354 115.533165,594.804138 
	C125.732819,596.871216 136.006332,598.720703 146.333038,599.981873 
	C164.871460,602.245911 183.437576,604.447205 202.054169,605.853333 
	C215.981293,606.905090 230.007080,606.624268 243.986649,607.017761 
	C252.945908,607.269897 261.909943,608.090881 270.855835,607.901550 
	C283.611359,607.631531 296.348846,606.589783 309.101288,606.048462 
	C313.182007,605.875305 317.327423,606.675171 321.371277,606.284790 
	C334.202179,605.046021 346.996124,603.427734 359.810791,602.014954 
	C364.743103,601.471130 369.774963,601.504028 374.620117,600.553711 
	C393.583099,596.834412 412.451843,592.630920 431.427124,588.978760 
	C453.005341,584.825745 474.403625,579.680664 496.547424,578.778320 
	C512.899658,578.111877 529.203064,576.272156 545.533875,575.029846 
	C548.517517,574.802917 551.528625,574.895996 554.526794,574.908020 
	C570.606384,574.972656 586.717773,574.466064 602.755493,575.325806 
	C615.421936,576.004761 627.971497,578.628723 640.620483,579.926208 
	C656.414246,581.546143 671.523254,585.890320 686.281006,591.319336 
	C693.505371,593.976868 700.013855,598.528015 706.988098,601.931702 
	C714.752625,605.720947 722.692505,609.149292 730.528076,612.794739 
	C734.726624,614.748169 738.866394,616.827942 742.567505,618.887329 
	C728.417236,619.566711 714.732117,620.209290 701.046997,620.851868 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M700.575562,620.896606 
	C714.732117,620.209290 728.417236,619.566711 743.008545,618.918701 
	C750.039185,618.596558 756.166443,618.324951 762.286011,617.932434 
	C763.877258,617.830261 765.444397,617.350403 767.487915,617.036194 
	C773.636169,616.689819 779.319092,616.351318 785.001953,616.012817 
	C786.227661,616.193420 787.453369,616.374084 788.912537,616.850220 
	C788.430603,617.458618 787.726318,618.013611 786.998047,618.047241 
	C778.570251,618.436279 770.137207,618.709290 761.708740,619.085999 
	C747.341003,619.728210 732.975891,620.429871 718.609009,621.092712 
	C704.740601,621.732544 690.871826,622.366943 677.002258,622.981812 
	C660.464233,623.715027 643.923889,624.396912 627.387024,625.153381 
	C613.844177,625.772827 600.302612,626.427490 586.764465,627.141113 
	C568.603394,628.098389 550.445984,629.123718 532.287354,630.126831 
	C520.403748,630.783325 508.523743,631.510132 496.636993,632.102844 
	C475.656616,633.149048 454.673645,634.146301 433.689026,635.104248 
	C424.588776,635.519653 415.483093,635.831726 406.377441,636.110291 
	C394.110901,636.485596 381.843842,636.929443 369.573730,637.085754 
	C337.979889,637.488159 306.382172,638.094788 274.790192,637.909180 
	C260.482239,637.825195 246.129272,636.540894 231.895096,634.931213 
	C212.652740,632.755188 193.473892,629.946838 174.324875,627.034546 
	C151.680389,623.590515 128.993378,620.267761 106.538200,615.815979 
	C97.717293,614.067261 89.407974,609.560730 80.997101,605.999756 
	C77.100037,604.349792 76.096001,601.114807 77.404518,596.933289 
	C79.848755,589.122498 82.012955,581.224121 84.587723,573.154541 
	C85.734123,574.010071 87.255966,575.023926 87.338333,576.143677 
	C88.558105,592.726257 90.936157,595.779907 106.109764,600.659180 
	C116.734764,604.075623 127.930161,605.783142 138.929596,607.956360 
	C159.838501,612.087463 180.825562,615.828064 201.710617,620.073120 
	C216.094345,622.996582 230.314880,626.720398 244.692444,629.677734 
	C260.218109,632.871216 275.528961,637.216736 291.768555,636.176819 
	C301.826477,635.532715 311.998718,636.963501 322.116486,636.906189 
	C345.562592,636.773560 369.010651,636.465515 392.450348,635.909607 
	C407.199188,635.559753 421.942627,634.765381 436.668427,633.848511 
	C439.468658,633.674194 442.119232,631.780396 444.933228,631.037415 
	C472.285950,623.815369 499.622192,616.523987 527.057251,609.626648 
	C532.436584,608.274170 538.214233,608.437683 543.818909,608.064453 
	C555.054443,607.316223 566.351196,605.663391 577.523865,606.253540 
	C591.661987,607.000183 605.689392,609.714172 619.787415,611.385986 
	C633.874939,613.056580 647.460754,618.499512 661.965027,616.993469 
	C664.261108,616.755127 666.552490,616.471069 668.845581,616.204407 
	C672.365662,615.795105 675.633972,615.600281 678.452087,618.728943 
	C679.674072,620.085632 682.161194,620.799316 684.120178,620.899841 
	C689.434937,621.172424 694.774475,620.960938 700.575562,620.896606 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M767.022583,617.044067 
	C765.444397,617.350403 763.877258,617.830261 762.286011,617.932434 
	C756.166443,618.324951 750.039185,618.596558 743.473755,618.881958 
	C738.866394,616.827942 734.726624,614.748169 730.528076,612.794739 
	C722.692505,609.149292 714.752625,605.720947 706.988098,601.931702 
	C700.013855,598.528015 693.505371,593.976868 686.281006,591.319336 
	C671.523254,585.890320 656.414246,581.546143 640.620483,579.926208 
	C627.971497,578.628723 615.421936,576.004761 602.755493,575.325806 
	C586.717773,574.466064 570.606384,574.972656 554.526794,574.908020 
	C551.528625,574.895996 548.517517,574.802917 545.533875,575.029846 
	C529.203064,576.272156 512.899658,578.111877 496.547424,578.778320 
	C474.403625,579.680664 453.005341,584.825745 431.427124,588.978760 
	C412.451843,592.630920 393.583099,596.834412 374.620117,600.553711 
	C369.774963,601.504028 364.743103,601.471130 359.810791,602.014954 
	C346.996124,603.427734 334.202179,605.046021 321.371277,606.284790 
	C317.327423,606.675171 313.182007,605.875305 309.101288,606.048462 
	C296.348846,606.589783 283.611359,607.631531 270.855835,607.901550 
	C261.909943,608.090881 252.945908,607.269897 243.986649,607.017761 
	C230.007080,606.624268 215.981293,606.905090 202.054169,605.853333 
	C183.437576,604.447205 164.871460,602.245911 146.333038,599.981873 
	C136.006332,598.720703 125.732819,596.871216 115.533165,594.804138 
	C111.877716,594.063354 108.162003,592.403625 105.098099,590.267761 
	C101.259232,587.591675 98.963577,583.478638 100.362274,578.543396 
	C102.366898,571.470215 101.585350,565.208191 96.337753,559.737671 
	C95.610275,558.979309 95.430504,557.695557 94.986496,556.278076 
	C95.778366,554.979187 96.580917,554.057190 97.707138,553.347412 
	C99.013443,554.866028 99.803673,556.394714 101.011864,557.440674 
	C106.369751,562.079407 106.924942,568.130188 105.636391,574.356506 
	C103.502449,584.667786 107.630226,591.327454 118.295464,593.001038 
	C130.357086,594.893738 142.508392,596.211304 154.577881,598.058655 
	C171.080841,600.584534 187.624191,602.133606 204.347443,602.078979 
	C219.568176,602.029297 234.810806,603.149353 250.007416,602.648987 
	C269.180908,602.017883 288.325409,600.371521 307.465515,598.937439 
	C313.709778,598.469543 319.921844,597.422119 326.111633,596.412903 
	C334.416260,595.058899 342.662720,593.342590 350.974670,592.040405 
	C361.730591,590.355286 372.562622,589.139648 383.295929,587.330811 
	C396.652924,585.079834 409.929291,582.354919 423.265686,579.975891 
	C433.505310,578.149231 443.761566,576.389587 454.050659,574.872986 
	C468.245209,572.780762 482.468719,570.877197 496.697266,569.026917 
	C504.557404,568.004761 512.439819,567.099243 520.336731,566.430847 
	C527.584167,565.817383 534.860962,565.375366 542.131226,565.260193 
	C548.824341,565.153992 555.527283,565.779602 562.223511,565.733398 
	C573.932495,565.652710 585.667603,564.696350 597.339661,565.235107 
	C609.526978,565.797668 621.631653,568.477356 633.810852,568.805664 
	C649.210632,569.220764 662.969177,575.735107 677.522095,579.145935 
	C688.826660,581.795349 699.475769,587.294556 710.361755,591.662781 
	C723.721130,597.023621 737.106140,602.335571 750.313049,608.054321 
	C756.096191,610.558350 761.465393,614.018250 767.022583,617.044067 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M767.487915,617.036194 
	C761.465393,614.018250 756.096191,610.558350 750.313049,608.054321 
	C737.106140,602.335571 723.721130,597.023621 710.361755,591.662781 
	C699.475769,587.294556 688.826660,581.795349 677.522095,579.145935 
	C662.969177,575.735107 649.210632,569.220764 633.810852,568.805664 
	C621.631653,568.477356 609.526978,565.797668 597.339661,565.235107 
	C585.667603,564.696350 573.932495,565.652710 562.223511,565.733398 
	C555.527283,565.779602 548.824341,565.153992 542.131226,565.260193 
	C534.860962,565.375366 527.584167,565.817383 520.336731,566.430847 
	C512.439819,567.099243 504.557404,568.004761 496.697266,569.026917 
	C482.468719,570.877197 468.245209,572.780762 454.050659,574.872986 
	C443.761566,576.389587 433.505310,578.149231 423.265686,579.975891 
	C409.929291,582.354919 396.652924,585.079834 383.295929,587.330811 
	C372.562622,589.139648 361.730591,590.355286 350.974670,592.040405 
	C342.662720,593.342590 334.416260,595.058899 326.111633,596.412903 
	C319.921844,597.422119 313.709778,598.469543 307.465515,598.937439 
	C288.325409,600.371521 269.180908,602.017883 250.007416,602.648987 
	C234.810806,603.149353 219.568176,602.029297 204.347443,602.078979 
	C187.624191,602.133606 171.080841,600.584534 154.577881,598.058655 
	C142.508392,596.211304 130.357086,594.893738 118.295464,593.001038 
	C107.630226,591.327454 103.502449,584.667786 105.636391,574.356506 
	C106.924942,568.130188 106.369751,562.079407 101.011864,557.440674 
	C99.803673,556.394714 99.013443,554.866028 97.980698,553.201172 
	C98.729477,552.228760 99.528374,551.614685 100.641815,550.955322 
	C102.786995,553.258484 104.574318,555.642395 106.457039,557.948303 
	C110.778564,563.241150 111.597130,569.467834 110.086311,575.701355 
	C108.030716,584.182678 112.808563,591.071838 121.709518,592.046753 
	C137.961807,593.826782 154.172318,595.985413 170.414795,597.859985 
	C175.509323,598.447937 180.645447,598.741028 185.771378,598.978699 
	C199.024323,599.593201 212.279144,600.389771 225.539398,600.530090 
	C232.115219,600.599670 238.703171,599.368896 245.288528,598.757629 
	C248.414566,598.467407 251.577637,597.841064 254.671906,598.070068 
	C268.930634,599.125122 282.650024,595.420837 296.593597,593.685486 
	C304.951111,592.645325 313.392944,592.221008 321.714783,590.972778 
	C330.699005,589.625122 339.568542,587.530884 348.523010,585.961548 
	C358.608246,584.194031 368.747772,582.736755 378.833710,580.973206 
	C384.683685,579.950439 390.452393,578.467896 396.296417,577.405334 
	C413.842529,574.214905 431.405457,571.116638 448.966339,568.008118 
	C457.660614,566.469055 466.320190,564.607971 475.075867,563.573242 
	C486.199066,562.258667 497.404572,561.642944 508.573273,560.710388 
	C515.314392,560.147522 522.046570,559.457825 528.794739,559.003479 
	C535.494995,558.552551 542.212952,558.004517 548.918457,558.060059 
	C569.102966,558.227539 589.339722,557.867004 609.452576,559.234985 
	C628.296265,560.516663 647.096619,563.213928 665.737244,566.350525 
	C674.087830,567.755554 681.968811,571.854431 690.121948,574.552307 
	C702.154480,578.533936 714.346252,582.060852 726.263062,586.356201 
	C733.514282,588.969788 740.381104,592.652832 747.410950,595.876526 
	C753.238525,598.548950 759.166077,601.033875 764.842529,603.997864 
	C771.634827,607.544495 778.207275,611.512024 784.938477,615.655029 
	C779.319092,616.351318 773.636169,616.689819 767.487915,617.036194 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M865.012146,607.847900 
	C861.542725,608.571106 858.087402,609.374023 854.599182,609.991028 
	C852.039368,610.443787 849.436462,610.653015 846.499512,610.573120 
	C847.666687,607.589417 850.207581,605.127686 850.540405,602.396851 
	C852.577881,585.680542 854.533936,568.931152 855.655151,552.135315 
	C856.288086,542.653687 855.547485,533.044128 854.930359,523.524353 
	C854.160339,511.646606 853.018127,499.787598 851.784912,487.946716 
	C850.330872,473.986572 848.595703,460.055817 847.005066,446.109680 
	C845.881775,436.260468 844.757568,426.411133 843.702942,416.554443 
	C842.103760,401.607391 839.532959,386.674744 839.231018,371.698517 
	C838.721680,346.441315 839.666382,321.154816 840.002441,295.880646 
	C840.013489,295.048676 839.759277,294.028381 840.144165,293.424377 
	C840.960693,292.143005 842.117615,291.078552 843.134949,289.925171 
	C844.100952,295.285980 845.066956,300.646851 846.025146,306.892792 
	C845.693176,313.226013 844.834290,318.704468 845.141296,324.116760 
	C846.208313,342.931549 847.603455,361.729187 849.025452,380.521881 
	C849.910400,392.217590 851.037659,403.895508 852.111755,415.576294 
	C853.714478,433.006012 855.419556,450.426453 856.979248,467.859955 
	C858.098450,480.369080 859.036987,492.894318 860.082153,505.410217 
	C861.374146,520.883606 862.729919,536.351746 863.981262,551.828369 
	C864.447876,557.600403 864.933472,563.387390 864.997620,569.172119 
	C865.140564,582.062500 865.023315,594.955811 865.012146,607.847900 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M827.727600,275.091736 
	C829.234924,275.650391 830.400085,276.169464 831.764343,276.777283 
	C827.385864,283.076324 824.505615,290.702911 815.640991,292.356018 
	C814.656799,292.539612 813.566406,293.623627 813.045044,294.574921 
	C810.180420,299.801239 807.541138,305.150696 804.702454,310.391785 
	C801.501343,316.302002 798.405762,322.291229 794.809204,327.957245 
	C792.626770,331.395416 787.020996,329.588287 782.740601,325.720032 
	C775.537476,319.210510 771.908020,310.416046 766.937561,302.530304 
	C758.772461,289.576141 748.898926,279.376740 733.989380,273.940460 
	C714.699707,266.907104 695.933655,258.451019 676.790100,250.998230 
	C665.688171,246.676117 654.112183,243.187195 642.152222,243.344650 
	C635.568420,243.431335 628.990662,246.108261 622.470093,247.842636 
	C619.765930,248.561874 617.280396,250.420273 614.578735,250.724533 
	C612.643127,250.942535 610.513550,249.438385 608.470520,248.702576 
	C609.241821,246.729401 609.859314,244.673019 610.859497,242.823669 
	C611.264648,242.074539 612.504517,241.776825 613.950623,241.422241 
	C616.967651,241.439072 619.414612,241.441254 621.826416,241.150925 
	C629.522461,240.224487 637.234253,238.182373 644.887451,238.401993 
	C661.795166,238.887222 677.765564,244.144928 693.222107,250.688690 
	C708.340637,257.089355 723.291931,263.891052 738.244629,270.675507 
	C749.277893,275.681641 760.432861,280.503601 771.075684,286.255096 
	C777.474670,289.713196 783.393005,292.094543 790.754333,290.058167 
	C800.269470,287.425995 809.982483,285.510468 819.502319,282.893555 
	C823.091003,281.907043 826.093079,279.588440 826.012634,275.118073 
	C826.470215,275.096100 826.927856,275.074127 827.727600,275.091736 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M843.087646,289.595459 
	C842.117615,291.078552 840.960693,292.143005 840.144165,293.424377 
	C839.759277,294.028381 840.013489,295.048676 840.002441,295.880646 
	C839.666382,321.154816 838.721680,346.441315 839.231018,371.698517 
	C839.532959,386.674744 842.103760,401.607391 843.702942,416.554443 
	C844.757568,426.411133 845.881775,436.260468 847.005066,446.109680 
	C848.595703,460.055817 850.330872,473.986572 851.784912,487.946716 
	C853.018127,499.787598 854.160339,511.646606 854.930359,523.524353 
	C855.547485,533.044128 856.288086,542.653687 855.655151,552.135315 
	C854.533936,568.931152 852.577881,585.680542 850.540405,602.396851 
	C850.207581,605.127686 847.666687,607.589417 846.082764,610.518677 
	C840.966125,611.567383 835.913086,612.272827 830.505859,612.584595 
	C832.964172,609.403809 836.710510,607.098083 838.435303,603.750732 
	C848.614624,583.995239 848.643433,562.229675 849.851440,540.786804 
	C850.218994,534.262756 849.347046,527.665833 848.992126,521.105042 
	C848.652039,514.819885 848.445068,508.518799 847.830017,502.259064 
	C846.328003,486.974243 844.771667,471.691711 842.956787,456.441620 
	C841.356995,442.999451 839.760010,429.536835 837.469971,416.202026 
	C834.533386,399.102417 830.623718,382.267273 832.808533,364.586029 
	C834.429565,351.466827 834.056946,338.113953 835.072327,324.903625 
	C836.045471,312.242493 837.663086,299.630920 838.997559,286.997559 
	C838.997620,286.553650 838.997620,286.109741 839.263916,285.146851 
	C840.010254,283.438354 840.490295,282.248840 840.970276,281.059296 
	C840.970276,281.059296 841.037109,280.974548 841.066528,280.928711 
	C841.406494,281.972015 841.717041,283.061157 842.023132,284.852386 
	C842.303894,286.351105 842.588989,287.147736 842.874146,287.944366 
	C842.929565,288.384827 842.984985,288.825256 843.087646,289.595459 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M825.615356,275.235657 
	C826.093079,279.588440 823.091003,281.907043 819.502319,282.893555 
	C809.982483,285.510468 800.269470,287.425995 790.754333,290.058167 
	C783.393005,292.094543 777.474670,289.713196 771.075684,286.255096 
	C760.432861,280.503601 749.277893,275.681641 738.244629,270.675507 
	C723.291931,263.891052 708.340637,257.089355 693.222107,250.688690 
	C677.765564,244.144928 661.795166,238.887222 644.887451,238.401993 
	C637.234253,238.182373 629.522461,240.224487 621.826416,241.150925 
	C619.414612,241.441254 616.967651,241.439072 614.265930,241.283737 
	C614.485107,240.410400 614.975037,239.824097 616.043030,238.966324 
	C618.921021,236.977386 621.931946,235.715729 620.971863,231.908310 
	C624.550598,231.051056 628.458191,228.638977 630.550659,234.284027 
	C630.755920,234.837799 632.629761,234.943024 633.734497,234.963562 
	C638.192566,235.046478 642.652771,235.014511 647.759033,235.297684 
	C648.967163,235.704147 649.528320,235.837906 650.089539,235.971680 
	C669.332520,235.919403 687.418274,241.137100 705.367676,247.515778 
	C725.397827,254.633896 745.643982,261.150299 765.847717,267.771057 
	C773.961487,270.429932 782.083191,273.397186 790.439758,274.883392 
	C797.907349,276.211456 805.701111,275.813416 813.354919,275.942627 
	C817.304871,276.009308 821.263428,275.566071 825.615356,275.235657 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M865.424194,607.903748 
	C865.023315,594.955811 865.140564,582.062500 864.997620,569.172119 
	C864.933472,563.387390 864.447876,557.600403 863.981262,551.828369 
	C862.729919,536.351746 861.374146,520.883606 860.082153,505.410217 
	C859.036987,492.894318 858.098450,480.369080 856.979248,467.859955 
	C855.419556,450.426453 853.714478,433.006012 852.111755,415.576294 
	C851.037659,403.895508 849.910400,392.217590 849.025452,380.521881 
	C847.603455,361.729187 846.208313,342.931549 845.141296,324.116760 
	C844.834290,318.704468 845.693176,313.226013 846.037720,307.322418 
	C847.043091,312.913239 848.156921,318.941986 848.984680,325.009796 
	C850.393982,335.341034 851.655823,345.692963 852.899963,356.045776 
	C853.135193,358.003296 852.999512,360.005371 853.025513,362.892273 
	C853.967590,370.513031 854.919128,377.228363 855.870605,383.943726 
	C855.930481,384.383270 855.990356,384.822845 855.825745,385.779602 
	C856.025452,387.847198 856.449646,389.397583 856.873840,390.947968 
	C856.932922,391.386169 856.992065,391.824371 856.807739,392.799133 
	C856.999756,394.876068 857.435181,396.416473 857.870667,397.956879 
	C857.930908,398.392731 857.991150,398.828552 857.805176,399.806396 
	C857.997437,402.215607 858.436035,404.082855 858.874634,405.950134 
	C858.934387,406.391510 858.994202,406.832916 858.740601,407.670837 
	C858.071167,408.378876 857.383240,408.719055 857.407288,408.997803 
	C858.242737,418.672607 859.239136,428.334045 860.017578,438.013092 
	C861.410034,455.327515 862.533752,472.664551 864.049744,489.967621 
	C864.968506,500.453339 866.381226,510.896759 867.631042,521.352112 
	C868.709595,530.374695 869.916382,539.382080 870.985352,548.405762 
	C872.248291,559.067383 873.825012,569.713501 874.512634,580.414673 
	C875.078674,589.223694 876.731445,598.237305 873.016357,606.860779 
	C870.622986,607.227051 868.229675,607.593323 865.424194,607.903748 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M838.645142,287.056824 
	C837.663086,299.630920 836.045471,312.242493 835.072327,324.903625 
	C834.056946,338.113953 834.429565,351.466827 832.808533,364.586029 
	C830.623718,382.267273 834.533386,399.102417 837.469971,416.202026 
	C839.760010,429.536835 841.356995,442.999451 842.956787,456.441620 
	C844.771667,471.691711 846.328003,486.974243 847.830017,502.259064 
	C848.445068,508.518799 848.652039,514.819885 848.992126,521.105042 
	C849.347046,527.665833 850.218994,534.262756 849.851440,540.786804 
	C848.643433,562.229675 848.614624,583.995239 838.435303,603.750732 
	C836.710510,607.098083 832.964172,609.403809 830.082825,612.526855 
	C826.634827,613.229309 823.255737,613.595764 819.441895,613.909912 
	C819.446045,612.869019 819.611267,611.523010 820.365234,610.945618 
	C830.627136,603.087219 836.084229,592.376343 840.266174,580.374451 
	C845.974670,563.991333 846.476074,547.183716 845.841858,530.306030 
	C845.346558,517.125061 843.406555,503.999725 842.157837,490.844940 
	C841.704468,486.069031 841.253113,481.285736 841.043152,476.495178 
	C840.250793,458.417877 836.366394,440.843201 832.607117,423.239563 
	C830.573303,413.715454 828.037964,404.235992 826.902039,394.598145 
	C825.407776,381.919006 825.028259,369.190918 827.200623,356.370941 
	C829.457031,343.055206 830.201843,329.490540 832.087158,316.102234 
	C833.388855,306.858704 832.260742,297.136444 837.040771,288.516510 
	C837.336853,287.982483 837.869934,287.579834 838.645142,287.056824 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M873.393555,606.906860 
	C876.731445,598.237305 875.078674,589.223694 874.512634,580.414673 
	C873.825012,569.713501 872.248291,559.067383 870.985352,548.405762 
	C869.916382,539.382080 868.709595,530.374695 867.631042,521.352112 
	C866.381226,510.896759 864.968506,500.453339 864.049744,489.967621 
	C862.533752,472.664551 861.410034,455.327515 860.017578,438.013092 
	C859.239136,428.334045 858.242737,418.672607 857.407288,408.997803 
	C857.383240,408.719055 858.071167,408.378876 858.781616,408.002869 
	C860.093445,414.204285 861.290710,420.444672 861.958801,426.741241 
	C863.433472,440.638428 864.701782,454.558594 865.936890,468.479767 
	C866.737671,477.504913 867.129211,486.569427 868.067200,495.578522 
	C869.324524,507.655426 870.793152,519.714783 872.429016,531.746643 
	C874.128662,544.248535 876.208069,556.698364 877.982971,569.190674 
	C879.160706,577.479553 879.827881,585.847717 881.207458,594.099487 
	C882.834717,603.832947 883.061157,603.795105 873.393555,606.906860 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M620.618225,231.970306 
	C621.931946,235.715729 618.921021,236.977386 616.309326,238.846405 
	C615.666870,237.621307 615.336182,236.244644 614.996338,234.514008 
	C616.746338,233.450775 618.505493,232.741531 620.618225,231.970306 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M855.922302,383.511658 
	C854.919128,377.228363 853.967590,370.513031 853.034058,363.334198 
	C854.026123,369.607025 855.000061,376.343323 855.922302,383.511658 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M792.429504,616.908569 
	C794.212952,616.424377 796.411133,615.992004 798.858765,615.870789 
	C797.020264,616.441467 794.932251,616.700928 792.429504,616.908569 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M858.925110,405.552063 
	C858.436035,404.082855 857.997437,402.215607 857.841797,400.134949 
	C858.408386,401.665710 858.691956,403.409851 858.925110,405.552063 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M857.921265,397.574615 
	C857.435181,396.416473 856.999756,394.876068 856.843994,393.129333 
	C857.406372,394.346100 857.689087,395.769226 857.921265,397.574615 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M856.923767,390.566528 
	C856.449646,389.397583 856.025452,387.847198 855.863098,386.110779 
	C856.407837,387.344849 856.690735,388.764984 856.923767,390.566528 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M842.924438,287.602814 
	C842.588989,287.147736 842.303894,286.351105 842.036316,285.214905 
	C842.360840,285.670624 842.667786,286.465942 842.924438,287.602814 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M649.924622,235.718735 
	C649.528320,235.837906 648.967163,235.704147 648.228821,235.338913 
	C648.621033,235.226898 649.190369,235.346344 649.924622,235.718735 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M840.883911,279.733826 
	C840.625000,279.888580 840.393982,279.724213 840.122437,279.290100 
	C840.397583,279.031616 840.674255,279.163086 840.883911,279.733826 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M515.685303,389.913391 
	C515.747620,390.083679 515.591675,390.403229 515.188782,390.839355 
	C515.116943,390.658142 515.291992,390.360382 515.685303,389.913391 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M514.710205,391.038635 
	C514.748901,391.224670 514.543457,391.506073 514.081970,391.869843 
	C514.039307,391.679474 514.252686,391.406738 514.710205,391.038635 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M510.705566,394.035492 
	C510.764069,394.223755 510.583954,394.496918 510.170837,394.863220 
	C510.114197,394.677704 510.290558,394.399048 510.705566,394.035492 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M511.667358,392.896973 
	C511.736176,393.068542 511.588806,393.390778 511.195618,393.830231 
	C511.116882,393.647522 511.284027,393.347565 511.667358,392.896973 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M501.679138,400.916931 
	C501.720856,401.091278 501.536285,401.418701 501.076630,401.842102 
	C501.018646,401.648682 501.235748,401.359375 501.679138,400.916931 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M505.705444,398.038513 
	C505.744904,398.226959 505.539001,398.507385 505.076538,398.867676 
	C505.033325,398.675140 505.246704,398.402771 505.705444,398.038513 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M506.677490,396.906982 
	C506.742157,397.077332 506.590027,397.397949 506.191589,397.835388 
	C506.117096,397.653900 506.288910,397.355560 506.677490,396.906982 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M509.714417,395.038574 
	C509.753479,395.222687 509.549377,395.505005 509.090759,395.873230 
	C509.047882,395.684998 509.259552,395.410889 509.714417,395.038574 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M593.697083,364.009827 
	C592.737976,361.427765 591.976807,359.109680 590.806580,357.020721 
	C587.870117,351.778870 585.196411,346.281433 581.474792,341.642700 
	C580.041260,339.855927 579.062500,338.920654 579.206665,336.500458 
	C579.538757,330.923309 579.261414,325.312561 579.443726,319.722382 
	C579.505737,317.819855 579.551575,315.151398 580.722290,314.181152 
	C586.156738,309.676971 588.108582,302.931244 592.147217,297.647430 
	C596.863647,291.477020 602.643982,286.110138 608.055420,280.483795 
	C612.296265,276.074432 616.507324,271.609222 621.065430,267.542999 
	C623.570984,265.307831 626.646973,262.564911 629.673889,262.282501 
	C637.844482,261.520172 646.329895,260.811646 654.308044,262.231018 
	C662.468323,263.682709 670.412476,267.183289 677.987122,270.767822 
	C682.276184,272.797516 685.515625,276.955536 689.437927,279.872314 
	C694.589233,283.702972 700.001953,287.180542 705.192322,290.960876 
	C707.539673,292.670502 710.103882,294.405731 711.728333,296.719910 
	C715.601074,302.237091 718.956116,308.116425 722.552490,313.829193 
	C726.275940,319.743835 730.669556,325.334930 733.639893,331.603119 
	C738.567139,342.001160 742.099976,353.067688 747.156555,363.394043 
	C750.853149,370.943024 751.659790,378.635254 750.862183,386.546112 
	C750.246643,392.652374 750.050781,399.113708 745.546265,404.222107 
	C744.634277,405.256348 744.496033,406.960846 743.958130,408.339630 
	C743.058044,410.646667 742.481140,413.181183 741.128784,415.189362 
	C738.301270,419.388184 735.204590,423.430664 731.941711,427.304291 
	C728.811218,431.020721 725.031677,434.211121 722.067749,438.042938 
	C716.269470,445.538971 709.938538,452.393524 701.996765,457.668518 
	C701.218384,458.185547 700.333923,459.139160 700.234497,459.977386 
	C699.834473,463.350616 698.236694,465.069397 694.730652,465.112762 
	C693.907959,465.122925 692.434692,465.983948 692.382996,466.555206 
	C691.901489,471.871765 687.427124,474.328705 684.422729,477.741760 
	C682.545532,479.874268 679.867981,481.301636 677.551880,483.048431 
	C674.881592,485.062317 672.213745,487.079468 670.463013,488.401733 
	C666.674622,489.729858 662.840820,489.934692 661.035522,491.953491 
	C657.691528,495.692902 654.700928,495.373138 651.157654,493.126282 
	C647.635437,499.247498 639.087524,499.380188 633.092468,497.974457 
	C630.088440,497.270050 626.535034,498.908600 622.186646,499.670319 
	C620.379517,497.068665 616.914917,494.913544 611.781189,496.848602 
	C610.709045,497.252747 609.034058,496.107666 607.658997,495.614746 
	C607.387146,495.517303 607.266174,495.028839 606.992004,494.903076 
	C604.091797,493.573029 600.345154,492.975830 598.500244,490.776886 
	C596.729553,488.666412 596.925415,484.906097 596.072571,481.032684 
	C599.612793,478.241608 596.712219,473.731140 596.620544,469.519348 
	C596.570435,467.215027 596.579407,464.879333 596.201050,462.618134 
	C595.197021,456.619049 599.907349,450.850372 595.774902,444.280884 
	C594.188232,441.758423 597.404785,436.181152 598.513428,432.017334 
	C598.839661,430.792175 600.106201,429.425842 599.804016,428.492584 
	C598.477539,424.395935 601.387756,420.079285 600.788208,417.346191 
	C599.546814,411.687317 603.692505,405.655151 598.821838,400.191010 
	C596.916382,398.053375 597.599182,393.594360 597.138062,390.187897 
	C596.760315,387.397644 596.454895,384.597656 596.081238,381.806824 
	C595.303101,375.995544 594.501038,370.187500 593.697083,364.009827 
M661.172424,427.437805 
	C663.910889,426.639740 666.677795,424.634552 669.146973,428.219818 
	C669.534668,428.782867 672.403992,428.619049 672.550354,428.217316 
	C674.431091,423.055145 677.964050,424.050293 681.141724,425.616302 
	C682.931946,424.216217 684.167358,422.818359 685.717773,422.117462 
	C690.627075,419.898102 695.719360,418.078125 700.593079,415.789032 
	C701.934692,415.158905 702.871704,413.507660 703.799683,412.187988 
	C706.081604,408.942566 707.525452,404.298004 710.616028,402.600037 
	C716.985352,399.100677 716.184692,392.687622 718.607117,387.630432 
	C718.866394,387.089172 718.846191,386.310974 718.708740,385.698120 
	C716.486877,375.789856 714.601501,365.785400 711.871948,356.017303 
	C708.806274,345.046478 707.677124,332.920807 696.538879,325.946625 
	C695.350525,325.202515 694.740723,323.588379 693.709961,322.525848 
	C692.221985,320.991913 690.719788,319.420502 689.000549,318.172546 
	C685.217590,315.426483 678.969971,314.770203 680.576538,307.643890 
	C680.693970,307.123169 679.722229,306.295288 679.165833,305.694489 
	C675.763611,302.020996 671.752380,298.749878 669.069519,294.614532 
	C666.023193,289.918976 662.384033,287.475647 656.943176,287.890564 
	C656.565979,286.319641 656.633911,284.159149 655.862366,283.799377 
	C651.887085,281.945801 647.813110,279.903503 643.556030,279.139740 
	C635.244934,277.648438 628.779968,281.468750 623.208862,287.484558 
	C619.816101,291.148071 616.674866,294.319122 616.516296,300.050812 
	C616.429138,303.203705 613.614136,306.687042 611.222961,309.277344 
	C607.414612,313.402802 607.045715,315.303040 609.685669,320.653870 
	C610.942749,323.201935 611.103027,326.281891 611.834595,329.101624 
	C612.357239,331.116028 612.443176,333.592163 613.691833,335.009674 
	C619.184265,341.244537 621.376038,348.945557 622.817566,356.628998 
	C624.602966,366.144989 624.992310,375.918793 626.148193,385.561615 
	C626.314636,386.950317 627.317017,388.274811 628.066162,389.550568 
	C629.904419,392.681335 632.774963,395.560120 633.499817,398.914215 
	C634.856018,405.190399 635.070679,411.602692 642.341675,414.489746 
	C643.486450,414.944244 643.951294,416.934479 644.920959,418.048920 
	C646.301636,419.635712 647.617126,421.846375 649.393005,422.397980 
	C652.582275,423.388580 655.174988,424.467682 656.651855,427.728516 
	C656.875854,428.223114 659.159912,427.784637 661.172424,427.437805 
M615.844055,491.290741 
	C615.844055,492.328979 615.844055,493.367218 615.844055,494.405457 
	C616.456116,494.262329 617.068176,494.119232 617.680237,493.976105 
	C617.287292,493.034485 616.894348,492.092896 615.844055,491.290741 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M840.647156,281.084167 
	C840.490295,282.248840 840.010254,283.438354 839.263916,284.813965 
	C839.439758,283.703033 839.881897,282.406067 840.647156,281.084167 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M767.347412,459.401123 
	C769.476257,455.481720 772.207397,452.081818 773.163940,448.240051 
	C775.128723,440.349121 782.542603,438.073029 787.270691,433.082031 
	C787.574951,432.760864 788.265442,432.833527 788.638000,432.544647 
	C790.857910,430.823242 793.074219,427.613556 795.220276,427.669006 
	C797.710266,427.733307 800.212891,430.536743 802.558411,432.347687 
	C809.100769,437.399170 813.956604,443.275848 815.248962,452.062256 
	C816.049255,457.503052 819.753052,462.478821 820.830994,467.925110 
	C822.749695,477.619110 823.595398,487.521851 825.026245,497.317352 
	C825.909058,503.360840 827.537292,509.334534 827.942200,515.398926 
	C828.456909,523.106750 827.755859,530.891052 828.156738,538.612366 
	C828.631897,547.764099 825.148010,555.748535 821.930908,563.918579 
	C817.816223,574.368347 807.228027,580.379456 796.160583,578.179016 
	C790.865356,577.126221 785.632202,575.761108 780.336060,574.713562 
	C773.469543,573.355408 769.817566,567.238892 764.187134,564.037781 
	C758.016418,560.529358 752.153381,556.473877 745.941772,553.045532 
	C741.656677,550.680542 740.062439,547.817932 741.427673,542.939636 
	C742.614502,538.698669 743.226196,534.270569 743.740906,529.883911 
	C743.891113,528.603882 742.837646,527.182556 742.412720,526.033630 
	C751.471619,514.399231 760.253723,502.996124 761.235901,487.328430 
	C761.670044,480.403564 764.458130,473.635437 766.064697,466.769745 
	C766.604736,464.462006 766.795837,462.072632 767.347412,459.401123 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M384.333679,478.325012 
	C404.333160,467.826538 423.990265,457.478882 443.683929,447.201233 
	C446.622742,445.667511 449.815277,444.617706 452.743988,443.067383 
	C456.660339,440.994324 460.386566,438.563354 464.292389,436.468781 
	C465.777649,435.672302 467.534424,435.382294 469.167480,434.861481 
	C469.458496,435.257355 469.749542,435.653259 470.040588,436.049133 
	C466.680817,439.661591 463.321045,443.274048 459.961273,446.886536 
	C460.144287,447.141449 460.327301,447.396393 460.510315,447.651337 
	C462.427124,447.129761 464.343964,446.608215 466.260773,446.086670 
	C466.503906,446.427246 466.747040,446.767822 466.990173,447.108398 
	C465.458588,448.598511 463.743286,449.942871 462.425781,451.602875 
	C454.886200,461.102264 445.368530,468.273773 435.324493,474.823639 
	C423.947693,482.242584 412.446075,489.472961 401.158600,497.024292 
	C397.643341,499.375977 395.050415,503.326935 391.347015,505.139771 
	C371.884827,514.666565 352.050323,523.437073 332.643372,533.070984 
	C316.824005,540.924011 299.938324,545.630676 283.188843,550.569458 
	C272.028442,553.860107 260.270935,555.355896 248.678894,556.881531 
	C238.931229,558.164429 229.005569,559.133423 219.216095,558.755432 
	C210.211212,558.407654 201.293518,556.008667 192.318359,554.638245 
	C177.464218,552.370178 162.573975,550.331116 147.740921,547.935608 
	C140.842758,546.821533 134.043045,545.098022 127.271927,542.729431 
	C132.679398,538.759094 138.580673,540.292419 144.280060,540.242493 
	C147.025787,540.218445 149.775925,540.161804 152.514435,539.977112 
	C156.220886,539.727051 159.937805,539.484558 163.615433,538.983459 
	C173.584015,537.625122 183.574219,536.361023 193.481140,534.631653 
	C204.670593,532.678589 215.763626,530.179138 226.929733,528.083252 
	C231.267700,527.269104 235.587463,524.043396 240.168243,527.622986 
	C240.493439,527.877136 241.939774,527.300964 242.359436,526.735779 
	C246.337265,521.377991 252.804962,522.403381 258.101868,520.462585 
	C258.411987,520.348938 258.931854,520.106934 259.030029,520.216125 
	C264.403809,526.191711 268.756378,519.763733 273.389771,518.806396 
	C286.646271,516.067261 299.936035,513.232849 312.887543,509.350891 
	C324.277954,505.936890 335.425262,501.503723 346.323547,496.710358 
	C359.084808,491.097595 371.460327,484.607880 384.333679,478.325012 
M213.712234,533.051086 
	C213.325668,533.445496 212.939087,533.839844 212.552490,534.234192 
	C213.783554,534.234192 215.014618,534.234192 216.245682,534.234192 
	C216.230698,533.906067 216.215714,533.577942 216.200729,533.249817 
	C215.593155,533.169434 214.985596,533.089111 213.712234,533.051086 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M660.831787,427.607788 
	C659.159912,427.784637 656.875854,428.223114 656.651855,427.728516 
	C655.174988,424.467682 652.582275,423.388580 649.393005,422.397980 
	C647.617126,421.846375 646.301636,419.635712 644.920959,418.048920 
	C643.951294,416.934479 643.486450,414.944244 642.341675,414.489746 
	C635.070679,411.602692 634.856018,405.190399 633.499817,398.914215 
	C632.774963,395.560120 629.904419,392.681335 628.066162,389.550568 
	C627.317017,388.274811 626.314636,386.950317 626.148193,385.561615 
	C624.992310,375.918793 624.602966,366.144989 622.817566,356.628998 
	C621.376038,348.945557 619.184265,341.244537 613.691833,335.009674 
	C612.443176,333.592163 612.357239,331.116028 611.834595,329.101624 
	C611.103027,326.281891 610.942749,323.201935 609.685669,320.653870 
	C607.045715,315.303040 607.414612,313.402802 611.222961,309.277344 
	C613.614136,306.687042 616.429138,303.203705 616.516296,300.050812 
	C616.674866,294.319122 619.816101,291.148071 623.208862,287.484558 
	C628.779968,281.468750 635.244934,277.648438 643.556030,279.139740 
	C647.813110,279.903503 651.887085,281.945801 655.862366,283.799377 
	C656.633911,284.159149 656.565979,286.319641 656.943176,287.890564 
	C662.384033,287.475647 666.023193,289.918976 669.069519,294.614532 
	C671.752380,298.749878 675.763611,302.020996 679.165833,305.694489 
	C679.722229,306.295288 680.693970,307.123169 680.576538,307.643890 
	C678.969971,314.770203 685.217590,315.426483 689.000549,318.172546 
	C690.719788,319.420502 692.221985,320.991913 693.709961,322.525848 
	C694.740723,323.588379 695.350525,325.202515 696.538879,325.946625 
	C707.677124,332.920807 708.806274,345.046478 711.871948,356.017303 
	C714.601501,365.785400 716.486877,375.789856 718.708740,385.698120 
	C718.846191,386.310974 718.866394,387.089172 718.607117,387.630432 
	C716.184692,392.687622 716.985352,399.100677 710.616028,402.600037 
	C707.525452,404.298004 706.081604,408.942566 703.799683,412.187988 
	C702.871704,413.507660 701.934692,415.158905 700.593079,415.789032 
	C695.719360,418.078125 690.627075,419.898102 685.717773,422.117462 
	C684.167358,422.818359 682.931946,424.216217 681.141724,425.616302 
	C677.964050,424.050293 674.431091,423.055145 672.550354,428.217316 
	C672.403992,428.619049 669.534668,428.782867 669.146973,428.219818 
	C666.677795,424.634552 663.910889,426.639740 660.831787,427.607788 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M616.172729,491.221008 
	C616.894348,492.092896 617.287292,493.034485 617.680237,493.976105 
	C617.068176,494.119232 616.456116,494.262329 615.844055,494.405457 
	C615.844055,493.367218 615.844055,492.328979 616.172729,491.221008 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M214.045135,533.029907 
	C214.985596,533.089111 215.593155,533.169434 216.200729,533.249817 
	C216.215714,533.577942 216.230698,533.906067 216.245682,534.234192 
	C215.014618,534.234192 213.783554,534.234192 212.552490,534.234192 
	C212.939087,533.839844 213.325668,533.445496 214.045135,533.029907 
z"/>
</svg>
    </>
  );
};

export default Index;
