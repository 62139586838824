// ** MUI Imports
import Box from '@mui/material/Box';
import BotChat from './componnts/BotChat';
import UserChat from './componnts/UserChat';
import { Send2 } from 'iconsax-react';
import React, { useEffect, useRef, useState } from 'react';
import {
  useGetAiChatQuery,
  useResetChatMutation,
  useSendAiChatMutation,
} from 'store/apis/aichat';
import cn from 'utils/cn';
import { Loading } from 'mdi-material-ui';
import { CircularProgress } from '@mui/material';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// ** Icons Imports

// ** Components
// import Autocomplete from 'layouts/components/Autocomplete'

const AiChat = (props) => {
  const ref = useRef(null);

  const [prompt, setPrompt] = useState('');

  const navigate = useNavigate();

  const chatContainerRef = useRef(null);
  const innerChatContainerRef = useRef(null);
  const loaderRef = useRef(null);

  const [sendPrompt, sendPromptResponse] = useSendAiChatMutation();
  const [resetChat, resetChatResponse] = useResetChatMutation();

  const { data, isLoading, error } = useGetAiChatQuery();

  const {
    data: profileData,
    isLoading: profileDataLoading,
    error: profileError,
  } = useGetMyProfileQuery();

  const handleSend = async () => {
    try {
      if (innerChatContainerRef.current) {
        innerChatContainerRef.current.scrollTo({
          top: innerChatContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }

      const res = await sendPrompt({ prompt }).unwrap();

      setPrompt('');

      console.log(res);
    } catch (error) {
      alert('Error sending message');
      console.log(error);
    }
  };

  const handleReset = async () => {
    try {
      await resetChat().unwrap();
    } catch (error) {
      alert('Error resetting chat');
      console.log(error);
    }
  };

  useEffect(() => {
    if (innerChatContainerRef.current) {
      innerChatContainerRef.current.scrollTo({
        top: innerChatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [sendPromptResponse.isLoading, data]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    const innerChatContainer = innerChatContainerRef.current;

    const handleHeightChange = () => {
      console.log(
        'innerChatContainer, chatContainer',
        innerChatContainer,
        chatContainer,
      );

      if (innerChatContainer && chatContainer) {
        const chatContainerHeight =
          chatContainer.getBoundingClientRect().height;
        innerChatContainer.style.setProperty(
          '--max-height',
          `${chatContainerHeight}px`,
        );
      }
    };

    handleHeightChange();

    window.addEventListener('resize', handleHeightChange);

    return () => {
      window.removeEventListener('resize', handleHeightChange);
    };
  }, []);

  const dataToMap = React.useMemo(() => {
    if (Array.isArray(data?.data)) {
      return data.data;
    }

    return [];
  }, [data]);

  if (error || profileError) {
    return (
      <div className="w-full h-full">
        <Box
          className="w-full bg-white p-5 rounded-3xl h-full grid place-items-center gap-4"
          ref={ref}
        >
          <h2 className="text-lg sm:text-xl lg:text-2xl xl:text-3xl text-center">
            Error fetching data
          </h2>
        </Box>
      </div>
    );
  }

  return (
    <div className="w-full h-full">
      <Box
        className="w-full bg-white p-5 rounded-3xl h-full grid grid-rows-[1fr,5.625rem] gap-4 relative"
        ref={ref}
      >
        <button
          className="absolute top-2 left-2 px-2 py-1 rounded-full cursor-pointer z-50"
          onClick={() => navigate(-1)}
        >
          <img src="/svg/go_back_icon.svg" alt="arrow-left" />
        </button>
        <div
          className="overflow-hidden scrollbar-hidden grid relative pt-4"
          ref={chatContainerRef}
        >
          <div
            className="w-full space-y-2 max-h-[var(--max-height)] overflow-y-auto px-4 scrollbar-hidden grid"
            ref={innerChatContainerRef}
          >
            <div className="flex justify-end flex-col h-full">
              {dataToMap.map((chat, index) => {
                if (chat.role === 'assistant') {
                  return (
                    <BotChat
                      key={index}
                      message={chat.content}
                      time={chat.timestamp}
                      className="!max-w-none"
                    />
                  );
                }
                return (
                  <UserChat
                    key={index}
                    message={chat.content}
                    time={chat.timestamp}
                    avatar={profileData?.data?.profileImage}
                  />
                );
              })}
              {sendPromptResponse.isLoading && (
                <BotChat isTyping ref={loaderRef} />
              )}
              {dataToMap.length === 0 && (
                <BotChat message="Type a message to start the conversation" />
              )}
            </div>
          </div>
          {isLoading || resetChatResponse.isLoading || profileDataLoading ? (
            <div className="absolute inset-0 grid place-items-center bg-white/50">
              <CircularProgress />
            </div>
          ) : null}
        </div>

        <div
          className={cn('flex flex-col justify-end', {
            'pointer-events-none opacity-50':
              sendPromptResponse.isLoading ||
              resetChatResponse.isLoading ||
              isLoading,
          })}
        >
          <div className="chat-toolbar h-[5rem] rounded-full px-2 items-center py-2 grid grid-cols-[auto,1fr,auto] ">
            <button
              className="size-16 rounded-full chat-toolbar"
              onClick={handleReset}
            >
              <img src="/svg/chat-share.svg" alt="" className="size-full " />
            </button>
            <textarea
              name=""
              id=""
              className="border border-[#00000033] focus:outline-none rounded-full w-full h-full py-4 px-6 text-xl font-bold text-[#0F1017] scrollbar-hidden"
              placeholder="Type a message"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              onKeyDown={(e) => {
                // if cntl + shift + enter is pressed
                if (e.key === 'Enter' && e.ctrlKey) {
                  e.preventDefault();
                  setPrompt((prev) => `${prev}\n`);
                  return;
                }
                // if enter is pressed
                if (e.key === 'Enter' && prompt) {
                  e.preventDefault();
                  setPrompt('');
                  handleSend();
                }
              }}
            ></textarea>
            <button
              className="size-16 rounded-full  border border-[#00000033] grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleSend}
              disabled={sendPromptResponse.isLoading || !prompt}
            >
              <Send2 className="text-[#C44297] text-6" variant="Bold" />
            </button>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default AiChat;
