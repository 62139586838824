import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, TextField } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useEffect, useState } from 'react';
import { getDueTodaySchoolAssignments, joinSchoolAssignment } from 'store/Thunk/schoolAssignmentThunk';
import { setTimerDurationSecs } from 'store/Slice/schoolAssignmentSlice';

export default function EnterPin({ setStep }) {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(`/app/student`);
  };
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.authReducer);
  const [pin, setPin] = useState('');


  return (
    <Box
      sx={{
        '& .MuiOutlinedInput-root ': {
          fontSize: '2rem',
        },
        width: '758px',
        bgcolor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: '12px',
        position: 'relative',
        padding: '40px 45px',
        paddingTop: '50px',
        gap: '40px',
      }}
    >
      <Box onClick={handleClose}>
        <Close sx={{ position: 'absolute', right: '10px', top: '10px' }} />
      </Box>
      <h4 style={{
        fontWeight: 600,
        fontSize: '2.5rem',
        color: 'black',
        textAlign: 'center',
      }}>
        Enter Pin Code to Begin!
      </h4>
      <TextField
        sx={{ fontSize: '2rem' }}
        variant="outlined"
        className="!font-bold !text-5xl"
        placeholder="Enter Pin Code Here"
        value={pin}
        onChange={(e) => setPin(e.target.value)}
      />
      <Button
        size="large"
        sx={{ py: '1rem' }}
        className="!py-4"
        variant="contained"
        onClick={() => {
          // Try to join the assignment
          const data = {
            id: window.location.search.split('=')[1],
            pin,
            userId: user?.id,
          };
          console.log(data);
          dispatch(joinSchoolAssignment(data))
            .unwrap()
            .then((response) => {
              const timeDuration = response?.timeDuration * 60;
              const initialDuration = timeDuration - response?.timeUsedInSeconds;
              if (response.timeUsedInSeconds > 0) {
                setStep(4);
              } else {
                setStep(1);
              }
              dispatch(setTimerDurationSecs(initialDuration));
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      >
        Submit
      </Button>
    </Box>
  );
}
