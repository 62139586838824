import styles from "./selectList.module.scss"
import ArrowDown from "./arrow_down.png"
import {useState} from "react";

const SelectList = ({options, optionsSelected, onClickOption, firstIcon, label}) => {
    const [open, setOpen] = useState(false)

    return (
        <div className={styles.selectList} onClick={() => {
            if (!open && options.length === 0) return

            setOpen(!open)
        }}>
            {firstIcon && <img className={styles.firstIcon} src={firstIcon} alt={"icon"}/>}
            <p>{label}</p>
            <img src={ArrowDown} className={styles.lastIcon} alt={"arrow down"}/>

            {open && (
                <>
                    <div className={styles.overlay} onClick={() => setOpen(false)}/>
                    <div className={styles.options}>
                        {options.map((option, index) => {
                            return (
                                <button key={index} className={optionsSelected.includes(option) ? styles.selected : ""} onClick={(event) => {
                                    event.stopPropagation()
                                    if (options.length === 1) setOpen(false)
                                    onClickOption(option)
                                }}>{option}</button>
                            )
                        })}

                    </div>
                </>
            )}

        </div>
    )
}

export default SelectList