import styles from './syle.module.scss';

const Index = ({
  options = [],
  handleOptionSelect,
  selectedCanvasObj,
  setSelectedCanvasObj,
  isDisabled,
}) => {
  return (
    <div className={styles.movementWrapper}>
      {options?.map((data, _index) => (
        <button
          className={styles.materialCard}
          onClick={async () => {
            if (isDisabled) return;
            await handleOptionSelect(data);
            setSelectedCanvasObj({
              ...selectedCanvasObj,
              pattern: data?.metadata?.PATTERN?.name == "Polka Dots" ? "polka_dots" : data?.metadata?.PATTERN?.name?.toLowerCase(),
            });
          }}
          disabled={isDisabled}
        >
          <img src={data?.mediaUrl} alt={data?.label} />
          <h3>
            {data?.label} - ${data?.metadata?.price}
          </h3>
        </button>
      ))}
    </div>
  );
};

export default Index;
