import React, { useEffect, useState } from "react";
import styles from './dressImg.module.scss'
import { useAiBot } from 'context/AibBotContext';

// SVG parts 
import TopDress from './parts/Top'
import BeltDress from './parts/Belt'
import SkirtDress from './parts/Skirt'

// Default parts 
import DefaultFullImg from '../../../../../asset/challenges/dress/default-front.png';
import DefaultTopImg from '../../../../../asset/challenges/dress/dress-elements/Top.png';
import DefaultBeltImg from '../../../../../asset/challenges/dress/dress-elements/Belt.png';
import DefaultBottomImg from '../../../../../asset/challenges/dress/dress-elements/Bottom.png';

// Pattern parts 
import topFloralTexture from '../../../../../asset/challenges/dress/dress-elements/top/pattern/floral/Top.png';
import topPaisleyTexture from '../../../../../asset/challenges/dress/dress-elements/top/pattern/paisley/Top.png';
import topPolkaDotsTexture from '../../../../../asset/challenges/dress/dress-elements/top/pattern/polka_dots/Top.png';
import topStripesTexture from '../../../../../asset/challenges/dress/dress-elements/top/pattern/stripes/Top.png';

import beltFloralTexture from '../../../../../asset/challenges/dress/dress-elements/belt/pattern/floral/Belt.png';
import beltPaisleyTexture from '../../../../../asset/challenges/dress/dress-elements/belt/pattern/paisley/Belt.png';
import beltPolkaDotsTexture from '../../../../../asset/challenges/dress/dress-elements/belt/pattern/polka_dots/Belt.png';
import beltStripesTexture from '../../../../../asset/challenges/dress/dress-elements/belt/pattern/stripes/Belt.png';

import skirtFloralTexture from '../../../../../asset/challenges/dress/dress-elements/skirt/pattern/floral/Bottom.png';
import skirtPaisleyTexture from '../../../../../asset/challenges/dress/dress-elements/skirt/pattern/paisley/Bottom.png';
import skirtPolkaDotsTexture from '../../../../../asset/challenges/dress/dress-elements/skirt/pattern/polka_dots/Bottom.png';
import skirtStripesTexture from '../../../../../asset/challenges/dress/dress-elements/skirt/pattern/stripes/Bottom.png';

const patterns = {
  polka_dots: [topPolkaDotsTexture, beltPolkaDotsTexture, skirtPolkaDotsTexture],
  paisley: [topPaisleyTexture, beltPaisleyTexture, skirtPaisleyTexture],
  floral: [topFloralTexture, beltFloralTexture, skirtFloralTexture],
  stripes: [topStripesTexture, beltStripesTexture, skirtStripesTexture],
};

const DressImg = ({ }) => {
  const { selectedCanvasObj } = useAiBot();
  const [selectedParts, setSelectedParts] = useState({})

  useEffect(() => {
    selectedCanvasObj?.customizedObj && selectedCanvasObj?.customizedObj?.map((item, _index) => {
      if (item?.part?.name?.toLowerCase() === 'top') {
        setSelectedParts(prev => ({
          ...prev,
          top: item?.color?.imageUrl ? item?.color?.imageUrl : item?.pattern?.imageUrl ? item?.pattern?.imageUrl : item?.texture?.imageUrl
        }))
      }
      if (item?.part?.name?.toLowerCase() === 'belt') {
        setSelectedParts(prev => ({
          ...prev,
          belt: item?.color?.imageUrl ? item?.color?.imageUrl : item?.pattern?.imageUrl ? item?.pattern?.imageUrl : item?.texture?.imageUrl
        }))
      }
      if (item?.part?.name?.toLowerCase() === 'bottom') {
        setSelectedParts(prev => ({
          ...prev,
          bottom: item?.color?.imageUrl ? item?.color?.imageUrl : item?.pattern?.imageUrl ? item?.pattern?.imageUrl : item?.texture?.imageUrl
        }))
      }
    })
  }, [selectedCanvasObj?.shouldPlayFinalAnimation])

  const svgs = {
    top: <TopDress selectedCanvasObj={selectedCanvasObj} />,
    belt: <BeltDress selectedCanvasObj={selectedCanvasObj} />,
    bottom: <SkirtDress selectedCanvasObj={selectedCanvasObj} />,
  };

  console.log(selectedCanvasObj, 'selectedCanvasObj')

  return (
    <div>
      <div className={styles.dressImg}>
        {selectedCanvasObj?.imgPos == 'full' ? (
          <>
            {selectedCanvasObj?.shouldPlayFinalAnimation ? (
              <div className={styles.playGrp}>
                <div className={`${styles.playUtilsShapes} ${styles.top}`}>
                  <div style={{ backgroundImage: `url(${selectedParts?.top})`}} />
                </div>
                <div className={`${styles.playUtilsShapes} ${styles.belt}`}>
                  <div style={{ backgroundImage: `url(${selectedParts?.belt})`}} />
                </div>
                <div className={`${styles.playUtilsShapes} ${styles.bottom}`}>
                  <div style={{ backgroundImage: `url(${selectedParts?.bottom})`}} />
                </div>
              </div>
            ) : (
              <img src={DefaultFullImg} alt="" />
            )}
          </>
        ) : (
          <>
            {selectedCanvasObj?.imgPos == 'bottom' ? (
              <>
                {selectedCanvasObj?.pattern ? (
                  <>
                    <img src={patterns?.[selectedCanvasObj?.pattern]?.[2]} alt="" />
                  </>
                ) : (
                  <>
                    {selectedCanvasObj?.imgColor ? (
                      <div className={styles.bottomSvg}>
                        {svgs?.bottom}
                      </div>
                    ) : (
                      <>
                        <img src={DefaultBottomImg} alt="" />
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {selectedCanvasObj?.imgPos == 'belt' ? (
                  <>
                    {selectedCanvasObj?.pattern ? (
                      <>
                        <img src={patterns?.[selectedCanvasObj?.pattern]?.[1]} alt="" />
                      </>
                    ) : (
                      <>
                        {selectedCanvasObj?.imgColor ? (
                          <div className={styles.beltSvg}>
                            {svgs?.belt}
                          </div>
                        ) : (
                          <>
                            <img src={DefaultBeltImg} alt="" />
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {selectedCanvasObj?.pattern ? (
                      <>
                        <img src={patterns?.[selectedCanvasObj?.pattern]?.[0]} alt="" />
                      </>
                    ) : (
                      <>
                        {selectedCanvasObj?.imgColor ? (
                          <div className={styles.topSvg}>
                            {svgs?.top}
                          </div>
                        ) : (
                          <>
                            <img src={DefaultTopImg} alt="" />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DressImg;