import React from 'react';
import { IoSearch } from 'react-icons/io5';

const AcceptedInvitesHeader = (_, ref) => {
  return (
    <div className="flex items-center justify-between" ref={ref}>
      <h1 className="text-black font-medium text-base sm:text-lg md:text-xl lg:text-2xl xl:text-[1.75rem]">
        Accepted Invites
      </h1>
      <button>
        <IoSearch className="text-black text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-[1.75rem]" />
      </button>
    </div>
  );
};

export default React.forwardRef(AcceptedInvitesHeader);
