import React, { useState } from 'react';
import Modal from 'auth/components/modal/modal';
import StudentSelectAvatar from 'auth/completeProfile/student/studentSelectAvatar';
import { Box, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function UploadAvatar({ open, onClose, setStep }) {
  const [modalOpen, setModalOpen] = useState(false);

  const [image, setImage] = useState(null);

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', right: '10px', top: '10px' }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <StudentSelectAvatar
        setStep={setStep}
        setImage={(image) => {
          setModalOpen(false);
          setImage(image);
        }}
      />
    </Dialog>
  );
}
