import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Grid } from '@mui/material';
import { getUserData } from 'store/Thunk/userThunk';
import { useDispatch } from 'react-redux';

function QuizScore(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let {
    title,
    name,
    totalMarks,
    totalGems,
    successPopup,
    failedPopup,
    resetPage,
    showSecondFail,
    answeredCount,
    showScore,
    handleSuccessPopup,
    failed2Popup,
    handleYesBtn,
    remainingQuiz,
    quizStat,
  } = props;

  const percentage = Math.round((answeredCount / totalMarks) * 100);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      textAlign={'center'}
      p={5}
      m={4}
      sx={{ width: '100%', borderRadius: '14px' }}
    >
      <Typography
        variant="h3"
        className="!w-full !text-center"
        sx={{ color: '#000', fontWeight: '700' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          width: 'fit-content',
          alignSelf: 'center',
          borderRadius: '16px',
          backgroundColor: '#F8F8F8',
          m: 2,
          p: 3,
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          sx={{ height: '30ch' }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Box>
                <Typography
                  sx={{ color: '#000', fontSize: '1.85rem', fontWeight: '700' }}
                >
                  You scored
                  <span
                    style={{
                      color: '#C73E9D',
                      marginLeft: '1ch',
                      fontSize: '1.85rem',
                    }}
                  >
                    {quizStat?.TotalScoreForTheQuiz}%
                  </span>{' '}
                  on
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ color: '#000', fontSize: '1.85rem', fontWeight: '700' }}
                >
                  <span
                    style={{
                      fontSize: '1.85rem',
                    }}
                  >
                    "{title}"
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography
                  sx={{ color: '#000', fontWeight: '700', fontSize: '1.85rem' }}
                >
                  You got{' '}
                  {quizStat?.TotalNumberOfCorrectlyAnsweredPerQuiz || ''} out of{' '}
                  {quizStat?.TotalNumberOfCorrectlyAnsweredPerQuiz +
                    quizStat?.TotalNumberOfIncorrectlyAnsweredPerQuiz ||
                    ''}{' '}
                  questions correct
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography
                  sx={{ color: '#000', fontWeight: '700', fontSize: '1.85rem' }}
                >
                  You earned
                  <span
                    style={{
                      color: '#C73E9D',
                      marginLeft: '1ch',
                      fontSize: '1.85rem',
                    }}
                  >
                    {`${quizStat?.TotalTokensEarnedPerQuiz} $G3MS`}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                {!quizStat?.IsReviewQuestionsAvailablePerQuiz ? (
                  <Typography
                    sx={{
                      color: '#000',
                      fontWeight: '700',
                      fontSize: '1.85rem',
                    }}
                  >
                    You have more assignments to complete. Will you like to
                    continue to next quiz?
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: '#000',
                      fontWeight: '700',
                      fontSize: '1.85rem',
                    }}
                  >
                    Let's go back and review this lesson, then you can crush
                    those questions you got wrong?
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', gap: '20px' }}>
            <Button
              sx={{
                textTransform: 'none',
                width: '100%',
                background: '#C73E9D',
                color: '#FFFFFF',
                boxShadow: 3,
                mt: 5,
                '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
              }}
              fullWidth
              onClick={
                !quizStat?.IsReviewQuestionsAvailablePerQuiz
                  ? handleSuccessPopup
                  : handleYesBtn
              }
            >
              {`${
                !quizStat?.IsReviewQuestionsAvailablePerQuiz
                  ? 'Yes I will continue'
                  : 'Yes let’s do it!'
              } `}
            </Button>
            <Button
              sx={{
                textTransform: 'none',
                width: '100%',
                background: '#D9E4E2',
                color: '#000',
                boxShadow: 3,
                mt: 5,
                '&.MuiButtonBase-root:hover': { backgroundColor: '#D9E4E2' },
              }}
              fullWidth
              // onClick={() => resetPage()}
              onClick={() => navigate('/app/student')}
            >
              Next Time
            </Button>
          </Box>

          {/* <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ color: '#000', fontWeight: '700' }}
              >
                Nice Try, You have scored {finalMarks}/{totalMarks * 10}
              </Typography>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </Box>
  );
}

export default QuizScore;
