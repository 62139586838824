import STEModal from 'pages/ShareToEarn/modals/STEModal';
import React from 'react';
import { IoShareSocialOutline } from 'react-icons/io5';

export default function STEFooter() {
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showShare10, setShowShare10] = React.useState(false);

  return (
    <div className="grid md:grid-cols-2 gap-4 ">
      <button
        className="border border-[#C44297] rounded-2xl px-5 py-4 flex justify-between items-center flex-wrap gap-4 text-[#0F161C] text-xs sm:text-sm lg:text-xl"
        onClick={() => setShowSuccess(true)}
      >
        <span>g3ms321564</span>
        <span className="font-medium text-[#C44297]">Copy</span>
      </button>
      <button
        className="border border-[#C44297] bg-[#C44297] rounded-2xl px-5 py-4 flex justify-center items-center flex-wrap gap-4 text-[white] text-xs sm:text-sm lg:text-xl"
        onClick={() => setShowShare10(true)}
      >
        <IoShareSocialOutline />
        <span className="font-medium">Share to Earn G3MS</span>
      </button>

      <STEModal open={showSuccess} handleClose={() => setShowSuccess(false)}>
        <STEModal.Image
          src="/images/share-success.png"
          alt="success"
          className="w-24 h-24"
        />
        <STEModal.Title>
          🎉 Awesome!
          <br />
          Your friend signed up, and you’ve both earned $100 G3MS!
        </STEModal.Title>
        <STEModal.Subtitle>
          Thanks for spreading the word! Your G3MS balance has been updated.
        </STEModal.Subtitle>
        <STEModal.Button onClick={() => setShowSuccess(false)}>
          Check My G3MS Balance
        </STEModal.Button>
      </STEModal>

      <STEModal open={showShare10} handleClose={() => setShowShare10(false)}>
        <STEModal.Image
          src="/images/share-success.png"
          alt="success"
          className="w-24 h-24"
        />
        <STEModal.Title>
          🎉 Congratulations!
          <br />
          You’ve earned $10 G3MS for sharing the app!
        </STEModal.Title>
        <STEModal.Subtitle>
          Keep an eye out—if your friend signs up using your referral code,
          you’ll both receive $100 G3MS!
        </STEModal.Subtitle>
        <STEModal.Button onClick={() => setShowShare10(false)}>
          OK
        </STEModal.Button>
      </STEModal>
    </div>
  );
}
