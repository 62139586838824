import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSelectedTopic, setStudentTopicList, setTopicList } from 'store/Slice/topicSlice';
import API from 'store/api';

export const getTopicList = createAsyncThunk(
  'getTopicList',
  async (_request, { dispatch }) => {
    let url = `/api/v1/topics`;
    // let url = `/api/v1/webapp/templates/student/c217a98c-7a83-46d3-8e6a-2b3726775ac1`;
    try {
      const response = await API.get(url);
      dispatch(setTopicList(response.data.data));
      return response;
    } catch (err) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getStudentTopics = createAsyncThunk(
  'getStudentTopics',
  async (userId, { dispatch }) => {
    let url = `/api/v1/assignment/user-topics/${userId}`;
    try {
      const response = await API.get(url);
      console.log("===============getStudentTopics================")
      console.log(response.data);
      dispatch(setStudentTopicList(response.data.data));
      return response;
    } catch (err) {
      return err;
    }
  },
);

export const getSelectedTopic = createAsyncThunk(
  'getTopicList',
  (_request, { dispatch }) => {
    dispatch(setSelectedTopic(_request?.selected));
  },
);
