import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import sharedStyles from '../payment.module.scss';
import UpgradePlan from './UpgradePlan';
import Math from '../../../components/math.png';
import Language from '../../../components/language.png';
import Science from '../../../components/science.png';
import Social from '../../../components/social.png';
import Web3 from '../../../components/web3.png';
import Fun from '../../../components/fun.png';
import Investing from '../../../components/investing.png';
import Money from '../../../components/money.png';
import Relationship from '../../../components/relationship.png';

export default function StepOne({ setStep, setProgress, setSteps }) {
  const [contents, setContents] = useState([
    { name: 'Web3', selected: true, icon: Web3 },
    { name: 'Fun', selected: true, icon: Fun },
    { name: 'Investing', selected: true, icon: Investing },
    { name: 'Money Management', selected: true, icon: Money },
    { name: 'Building Relationships', selected: true, icon: Relationship },
  ]);
  const handleClick = (index) => {
    setContents((prevContents) => {
      const updatedContents = prevContents.map((content, i) => {
        if (i === index) {
          return { ...content, selected: !content.selected };
        }
        return content;
      });
      return updatedContents;
    });
  };
  const handleNext = (selectedIndex) => {
    setProgress(33);
    setSteps((prevSteps) => {
      return prevSteps.map((step, index) => {
        if (index === selectedIndex) {
          return { ...step, completed: true, current: false };
        } else if (index === selectedIndex + 1) {
          return { ...step, current: true };
        } else {
          return { ...step, current: false };
        }
      });
    });
  };
  console.log('contents', contents);
  return (
    <Stack>
      <Typography className={sharedStyles.headerFreeText} mb={6}>
        Let's collect key settings for G3MS?
      </Typography>
      <UpgradePlan setStep={setStep} />
      <Stack className={sharedStyles.step1action}>
        <Typography
          sx={{ fontWeight: 600, fontSize: '24px', color: '#131515' }}
          mt={6}
          mb={4}
        >
          The FREE plan offers access to state-aligned Math content ONLY:
        </Typography>
        <Box className={sharedStyles.subjectContainer}>
          <Stack
            className={`${sharedStyles.subjectContainer1} ${sharedStyles.subjectContainer1Math}`}
          >
            <img src={Math} alt="" />
            <Typography
              sx={{ fontWeight: 600, fontSize: '20px', color: '#131515' }}
            >
              Math
            </Typography>
          </Stack>
          <Stack className={sharedStyles.subjectContainer1}>
            <img src={Language} alt="" />
            <Typography
              sx={{ fontWeight: 500, fontSize: '20px', color: '#979C9E' }}
            >
              Language Arts
            </Typography>
          </Stack>
          <Stack className={sharedStyles.subjectContainer1}>
            <img src={Science} alt="" />
            <Typography
              sx={{ fontWeight: 500, fontSize: '20px', color: '#979C9E' }}
            >
              Science
            </Typography>
          </Stack>
          <Stack className={sharedStyles.subjectContainer1}>
            <img src={Social} alt="" />
            <Typography
              sx={{ fontWeight: 500, fontSize: '20px', color: '#979C9E' }}
            >
              Social Studies
            </Typography>
          </Stack>
        </Box>
        <Typography
          sx={{ fontWeight: 600, fontSize: '24px', color: '#131515' }}
          mt={8}
          mb={4}
        >
          Develop the whole child with our FREE plan content, deselect to
          remove:
        </Typography>
        <Box className={sharedStyles.subjectContainer}>
          {contents?.map((content, index) => (
            <Stack
              key={index}
              onClick={() => handleClick(index)}
              className={`${sharedStyles.subjectContainer1} ${
                content.selected ? sharedStyles.subjectContainerSelected : ''
              }`}
            >
              <img src={content.icon} alt="" />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '20px',
                  color: '#131515',
                  textAlign: 'center',
                }}
              >
                {content.name}
              </Typography>
            </Stack>
          ))}
        </Box>
        <Box className="flex w-full justify-end">
          <Button
            onClick={() => handleNext(0)}
            variant={'contained'}
            size="large"
            sx={{
              mt: '50px',
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
              color: '#fff !important',
            }}
            className={'primary_bg_btn'}
          >
            Next
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}
