import { CircularProgress } from '@mui/material';
import { Loading } from 'mdi-material-ui';
import { config } from 'process';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyGetMyProfileWithTokenQuery } from 'store/apis/profile';
import { setUserDetails } from 'store/Slice/authSlice';
import { setShowCodeToEarn } from 'store/Slice/uiSlice';
import { useAppDispatch } from 'store/store';
import analytics from 'utils/analytics';

export default function TokenLogin() {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [getProfile, { error, data, isLoading }] =
    useLazyGetMyProfileWithTokenQuery();

  const token = searchParams.get('token');

  const redirectTo = searchParams.get('redirectTo');

  console.log('token', token, redirectTo);

  useEffect(() => {
    const loginWithToken = async () => {
      try {
        const res = await getProfile(token).unwrap();

        const user = res?.data;

        console.log('user', user);

        localStorage.setItem('accessToken', token);
        localStorage.setItem('uuid', user?.id);
        localStorage.setItem('currentUser', user?.type);
        localStorage.setItem('userData', JSON.stringify(user || {}));
        dispatch(setUserDetails(user || {}));

        switch (user?.type) {
          case 'student':
            if (redirectTo === 'codeToEarn') {
              navigate('/app/student/challenge-selection');
              return;
            }
            navigate('/app/student');
            break;
          case 'teacher':
            localStorage.clear();
            window.location.href = `${config.ADMIN_URL}/school/${user.school.id}?token=${token}`;
            break;
          case 'parent':
            localStorage.clear();
            window.location.href = `${config.ADMIN_URL}/super-admin/dashboard?token=${token}`;
            break;
          case 'school_leader':
            localStorage.clear();
            window.location.href = `${config.ADMIN_URL}/school-management/school/${user?.school.id}?token=${token}`;
            break;
          default:
            localStorage.clear();
            window.location.href = `${config.ADMIN_URL}/super-admin/dashboard?token=${token}`;
            break;
        }

        analytics.logEvent('login', {
          user_id: data?.data?.id,
        });
      } catch (error) {
        navigate('/');
      }
    };

    if (token) {
      loginWithToken();
    } else {
      navigate('/');
    }
  }, [token]);

  return (
    <div className="absolute inset-0 grid place-items-center">
      <div className="grid place-items-center gap-4" >
        <CircularProgress color="secondary" />
        <h1 className="text-black text-center mt-4 font-bold text-xl ">
          Loading
        </h1>
      </div>
    </div>
  );
}
