import styles from "./radioButtons.module.scss"
import {useState} from "react";

const RadioButtons = ({options, onChange, value, template}) => {

    const [selected, setSelected] = useState(value)

    return (
        <div className={styles.radioButtons}>
            {options.map((option, index) => {
                return (
                    <div key={index} className={styles.radioButton}>
                        <label>
                            <input type="radio" value={option} checked={option === selected} onChange={(target) => {
                                setSelected(target.target.value)
                                onChange(target.target.value)
                            }}/>

                            <span className={styles.checkmark}/>
                        </label>
                        <p>{template(option)}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default RadioButtons