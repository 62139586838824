import { useEffect, useState } from 'react';
import styles from './selectAssignment.module.scss';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'api/hooks';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getStudentTopics } from 'store/Thunk/topicThunk';
import appStore from '../../../../media/app-store.png';
import googlePlay from '../../../../media/play-store.png';
import { useGetTopicsQuery } from 'store/apis/topics';
import cn from 'utils/cn';

const assesments = [{}];

const SelectAssignmentList = ({ setVideoTopic, data }) => {
  const [topic, setTopic] = useState('');
  const navigate = useNavigate();

  const { data: topics, isLoading: topicsLoading } = useGetTopicsQuery();

  // console.log(topics?.data, 'topics');
  const { user } = useAppSelector((state) => state.authReducer);

  const dispatch = useAppDispatch();

  const query = useQuery();
  const topicId = query.get('topic');

  const { studentTopicList } = useAppSelector((state) => state.topicReducer);

  console.log(studentTopicList, 'studentTopicList');

  useEffect(() => {
    dispatch(getStudentTopics(user?.id));
  }, [dispatch, user?.id]);

  useEffect(() => {
    if (topicId) {
      setTopic(topicId);
    } else {
      setTopic('');
    }
  }, [topicId]);

  const handleTopicNavigation = (item) => {
    const currentPath = '/app/student';
    const willBeRoute = currentPath.includes('leaderboard')
      ? '/app/student/leaderboard/'
      : currentPath;
    navigate(`${willBeRoute}?topic=${item.id}`);
  };

  return (
    <div className={cn(styles.selectAssignmentList)} id="selectAssignmentList">
      {/*  <button
        className={cn(
          'border-2 text-nowrap px-[1.125rem] py-2 rounded-xl text-base font-semibold border-[#C73E9D33] text-[#C73E9D] hover:bg-[#C73E9D33] hover:text-[#C73E9D] hover:border-[#C73E9D33]',
          {
            'text-white bg-[#c73e9d] border-[#c73e9d] hover:text-white hover:bg-[#c73e9d] hover:border-[#c73e9d]':
              topic === '',
          },
        )}
        onClick={() => {
          setVideoTopic('');
          if (topicId === '') {
            navigate(`/app/student`);
          } else {
            navigate(`/app/student?topic=`);
          }
        }}
      >
        Surprise Me!
      </button>
      {data
        ? data
        : studentTopicList?.map((item, index) => {
            return (
              <button
                key={index}
                // className={`${styles.item} ${topic === item.id ? styles.selected : ''
                //   }`}
                className={cn(
                  'border-2 text-nowrap px-[1.125rem] py-2 rounded-xl text-base font-semibold border-[#C73E9D33] text-[#C73E9D] hover:bg-[#C73E9D33] hover:text-[#C73E9D] hover:border-[#C73E9D33]',
                  {
                    'text-white bg-[#c73e9d] border-[#c73e9d] hover:text-white hover:bg-[#c73e9d] hover:border-[#c73e9d]':
                      topic === item.id,
                  },
                )}
                onClick={() => {
                  setVideoTopic(item.id);
                  handleTopicNavigation(item);
                }}
              >
                {item.name}
              </button>
            );
          })} */}
      <a
        href="https://apps.apple.com/us/app/g3ms/id1625313492"
        target="_blank"
        rel="noreferrer"
        className="block"
      >
        <img
          src={appStore}
          alt="app store"
          className="w-24 h-8 object-contain lg:w-32 lg:h-10  2xl:w-40 2xl:h-12"
        />
      </a>

      <a
        className="block"
        href="https://play.google.com/store/apps/details?id=com.g3ms"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={googlePlay}
          alt="app store"
          className="w-24 h-8 object-contain lg:w-32 lg:h-10  2xl:w-40 2xl:h-12"
        />
      </a>

      <a
        className="bg-[#C44297] text-white rounded-lg px-6 py-4 font-bold text-xs xl:text-sm flex items-center gap-2 h-8 lg:h-10 2xl:h-12"
        href="https://codepen.io/pen/"
        target="_blank"
        rel="noreferrer"
      >
        CodePen Editor
      </a>
    </div>
  );
};

export default SelectAssignmentList;
