const Index = ({ selectedCanvasObj }) => {
  return (
    <>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="100%" viewBox="0 0 992 992" enable-background="new 0 0 992 992" >
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M590.121765,382.085938 
	C586.580322,384.038208 583.366272,385.791534 580.151794,387.544067 
	C574.929016,390.391449 572.474609,389.552551 570.795898,383.998657 
	C568.923462,377.804138 567.456848,371.485962 565.544189,365.304932 
	C563.509766,358.730347 561.933777,351.868195 556.746887,346.637726 
	C552.856873,348.968628 550.514893,352.166016 548.407898,355.564117 
	C547.180298,357.543915 546.264954,359.751434 544.843567,361.570923 
	C539.364136,368.584869 536.695618,376.450226 536.093201,385.351898 
	C535.764648,390.207855 532.538452,394.302002 529.586426,398.097198 
	C524.949646,404.058411 521.103455,410.385803 518.365173,417.442871 
	C517.441101,419.824341 516.658875,422.553619 513.603638,424.032440 
	C509.487091,420.226746 508.022339,414.852142 505.570038,410.078918 
	C504.546021,408.085876 504.151947,405.514923 500.898132,405.808868 
	C497.849304,406.084229 497.291168,408.377350 496.621704,410.609558 
	C495.333588,414.904358 493.804657,419.158447 492.932709,423.540283 
	C490.963318,433.436920 482.510559,437.200684 475.587769,442.385132 
	C469.686584,446.804474 467.985596,446.229919 465.039307,439.446014 
	C464.972992,439.293335 464.914429,439.130707 464.816498,438.999390 
	C463.110474,436.712006 463.738861,432.008759 460.260284,432.028107 
	C456.181274,432.050720 456.199402,436.784088 455.005463,439.683960 
	C452.920929,444.746857 451.670715,450.116119 449.277374,455.106354 
	C446.863434,460.139618 443.484161,463.937073 438.306335,466.159027 
	C433.736206,468.120239 429.634583,471.156464 424.518372,472.150421 
	C422.771454,470.759064 422.076080,468.778076 421.565491,466.650696 
	C420.854370,463.687744 419.851318,460.158295 416.637024,459.847412 
	C413.230682,459.517914 409.596527,461.023865 407.696716,464.355804 
	C405.313202,468.536102 402.835449,472.767151 401.244659,477.274933 
	C396.787445,489.905121 386.117493,494.147430 374.828613,497.811127 
	C372.038818,498.716522 371.455627,497.041199 370.651062,495.005554 
	C369.561920,492.249939 370.720551,488.559113 367.457306,486.648102 
	C365.302124,487.371094 364.680237,489.173126 363.900665,490.786438 
	C360.308685,498.219971 353.805023,502.595459 347.061890,506.618073 
	C342.904755,509.098053 342.238220,505.567413 341.719635,502.807007 
	C340.349976,495.515961 339.292023,494.874725 333.493103,499.576904 
	C326.550720,505.206146 320.646637,512.170837 312.104218,515.672791 
	C311.181183,516.051147 310.338867,516.657349 309.394409,516.946777 
	C306.512939,517.829773 303.507141,520.135132 300.819427,516.623230 
	C298.398254,513.459534 298.215454,507.158661 301.097321,504.605804 
	C305.805054,500.435486 310.492828,496.077271 316.270996,493.368988 
	C320.464905,491.403290 322.128326,492.557617 322.574158,497.170441 
	C328.376465,495.069092 333.127808,491.525879 337.500793,487.172882 
	C341.677063,483.015686 346.506073,479.767181 353.035370,481.471710 
	C354.458069,481.843109 355.592346,480.798157 356.518555,479.851227 
	C359.823151,476.472626 364.035431,474.366821 367.812378,471.652893 
	C372.509827,468.277679 377.555511,466.451660 383.268127,468.648254 
	C385.870819,469.649109 387.457306,468.577301 388.701141,466.605408 
	C395.044861,456.548584 405.513580,452.349915 415.589813,447.597748 
	C416.300781,447.262421 417.648315,446.866821 417.891754,447.163147 
	C421.176941,451.162170 424.041687,446.962219 427.027069,446.951691 
	C435.459534,446.921936 436.295959,440.713715 437.876434,434.965607 
	C441.290039,422.550476 451.811951,418.406921 462.113739,414.011414 
	C463.390289,413.466736 464.818207,413.568024 465.685516,414.900818 
	C466.632690,416.356415 465.741943,417.376434 464.656921,418.297363 
	C463.550507,419.236450 462.096588,420.015625 463.057343,422.656494 
	C469.574097,418.922241 478.739532,419.600281 480.148682,408.899353 
	C480.812775,403.856354 484.821808,400.281555 489.128876,397.416351 
	C492.170868,395.392700 494.963898,392.997833 497.971313,390.917938 
	C499.663513,389.747620 501.813446,389.979950 503.627228,389.631012 
	C509.643311,388.473572 513.965820,389.194916 518.033020,394.207947 
	C519.301941,395.771973 521.512146,392.660736 521.486267,390.722168 
	C521.339478,379.713715 526.313843,370.588043 531.568115,361.454529 
	C534.729126,355.959625 534.662292,355.783691 540.290710,358.901306 
	C542.743469,354.590027 545.183167,350.262573 547.665710,345.959778 
	C548.520630,344.478058 548.492859,343.174408 547.324707,341.858398 
	C542.807556,336.769440 543.901794,330.784882 545.846252,325.436554 
	C549.890564,314.312744 555.455078,304.020782 563.865540,295.294373 
	C568.607422,290.374268 573.968140,289.468719 580.115051,289.745392 
	C582.108276,289.835114 582.672241,291.420349 582.882812,292.778351 
	C583.424561,296.271790 585.723816,297.511536 588.684509,298.801208 
	C597.775024,302.761078 607.222046,304.940796 617.064941,305.718597 
	C622.284485,306.131073 623.653259,309.145569 620.747070,313.554047 
	C620.108215,314.523193 619.183411,315.313568 618.603333,316.310211 
	C612.437927,326.903564 609.624268,326.795776 596.436340,323.862579 
	C592.072815,322.892029 587.840454,321.309814 583.575745,319.922150 
	C576.658264,317.671387 572.699829,321.158844 574.004150,328.548645 
	C574.966064,333.998260 576.845276,339.184937 579.714355,343.904938 
	C584.746521,352.183258 588.679810,360.972595 591.594482,370.151367 
	C592.794006,373.928589 595.432861,378.453827 590.121765,382.085938 
M559.162903,320.334595 
	C557.945557,322.616425 555.916077,324.597046 556.538940,327.618500 
	C558.164062,325.557892 559.471436,323.338348 560.606262,321.033783 
	C561.012329,320.209259 560.936340,319.244537 559.162903,320.334595 
z"/>
</svg>
    </>
  );
};

export default Index;
