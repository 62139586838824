import parse from 'html-react-parser';

export const convertedOutput = (input) => {
  return input.replace(/\^(-?\d+)/g, '<sup>$1</sup>');
};
export const computeValue = (expression) => {
  const value = expression?.split('/');
  return (
    <div
      className="fraction"
      style={{ display: 'inline-block', flexDirection: 'column' }}
    >
      <div className="fraction--numerator">
        {parse(convertedOutput(value[0]))}
      </div>
      <div
        className="fraction--bar"
        style={{
          border: '1px solid rgba(76, 78, 100, 0.87)',
          width: 'inherit',
        }}
      ></div>
      <div className="fraction--denominator">
        {parse(convertedOutput(value[1]))}
      </div>
    </div>
  );
};
export function SentenceMapper2(sentence) {
  // const mappedSentence = sentence?.split(':')?.join(':<br/>');
  if (typeof sentence === 'string') {
    const mappedSentence = sentence.replace(/:/g, ' : <br/>');
    console.log('Mapped Sentence:', mappedSentence);
    console.log(mappedSentence);
    return mappedSentence;
  } else {
    return sentence;
  }

  // return <div>{sentence}</div>;
}
export function SentenceMapper(sentence) {
  // const colon = sentence?.split(':').join(':<br/>');

  const mappedSentence = SentenceMapper2(sentence)
    ?.split('')
    .map((character, index) => {
      if (character === '(') {
        return `${character + ' '}`;
      } else if (character === ')') {
        return `${' ' + character}`;
      } else {
        return character;
      }
    });

  console.log(mappedSentence.join('')); // Log the new value in the console

  return mappedSentence.join('');
}
