import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getUserClassRooms } from 'store/Thunk/userThunk';

import {
  getStudentAssignments,
  getStudentAssignmentsInfo,
} from 'store/Thunk/quizThunk';
import Student from './student';
import VerifyEmail from '../VerifyEmail';

const ClassItem = ({ title }) => {
  return (
    <Box sx={{ background: '#F8F8F8', borderRadius: '4px', p: 1 }}>
      <span>{title}</span>
    </Box>
  );
};

const TypoItem = ({ title, detail }) => {
  return (
    <Box>
      <Typography sx={{ color: '#929292' }}>{title}</Typography>
      <Typography sx={{ fontWeight: 400, color: '#131515' }}>
        {detail}
      </Typography>
    </Box>
  );
};

function StudentProfile() {
  const { user: userData } = useAppSelector((state) => state.authReducer);
  const [assignmentsList, setAssignmentsList] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const dispatch = useAppDispatch();
  const [classList, setClassList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userData?.id) {
      dispatch(getUserClassRooms(userData?.id))
        .unwrap()
        .then((res) => {
          setClassList(res);
        });
    }
  }, [userData]);

  const fetchData = async () => {
    try {
      setLoading(true);
      await dispatch(getStudentAssignmentsInfo())
        .unwrap()
        .then((res) => {
          if (Object.values(res).length > 0) {
            const promises = Object.values(res)?.map(async (item) => {
              const { id = '', name: className = '' } = item;
              const assignments = await dispatch(
                getStudentAssignments({
                  request: { classId: id, search: searchValue },
                }),
              ).unwrap();
              return { id, className, assignments };
            });

            Promise.all(promises)
              .then((info) => {
                setAssignmentsList(info);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                console.error(
                  'An error occurred during the asynchronous operations:',
                  error,
                );
              });
          } else {
            setLoading(false);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchValue]);

  const getAssignmentsForSingleClassOfStudent = async ({
    classId,
    pageNo,
    pageCount,
  }) => {
    if (classId) {
      try {
        setAssignmentsList((prevList) => {
          const updatedList = prevList.map((item) =>
            item.id === classId ? { ...item, loading: true } : item,
          );
          return updatedList;
        });

        const assignments = await dispatch(
          getStudentAssignments({
            request: { classId, pageIndex: pageNo, pageCount },
          }),
        ).unwrap();

        setAssignmentsList((prevList) => {
          const updatedList = prevList.map((item) =>
            item.id === classId
              ? { ...item, assignments, loading: false }
              : item,
          );
          return updatedList;
        });
      } catch (error) {
        console.error('Error fetching assignments for class:', error);
      } finally {
        setAssignmentsList((prevList) => {
          const updatedList = prevList.map((item) =>
            item.id === classId ? { ...item, loading: false } : item,
          );
          return updatedList;
        });
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
      <Student />
    </Box>
  );
}

export default StudentProfile;
