import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setJoinedSchoolAssignment,
  setSchoolAssignmentResult,
  setSchoolAssignmentTodayList,
} from 'store/Slice/schoolAssignmentSlice';
import API from 'store/api';
import { NotificationManager } from 'react-notifications';

export const getDueTodaySchoolAssignments = createAsyncThunk(
  'getDueTodaySchoolAssignments',
  async (userId, { dispatch }) => {
    let url = `/api/v1/assignment/due-today/${userId}`;

    try {
      const response = await API.get(url);
      const assignments = response.data.data;
      console.log('==========getDueTodaySchoolAssignments=========')
      console.log(assignments)
      dispatch(setSchoolAssignmentTodayList(assignments));
      return assignments;
    } catch (err) {
      return err;
    }
  },
);

export const joinSchoolAssignment = createAsyncThunk(
  'joinSchoolAssignment',
  async (data, { dispatch, rejectWithValue }) => {
    let url = `/api/v1/assignment/school-assignment/join/${data.id}`;

    try {
      const response = await API.post(url, {
        pinCode: data.pin,
        userId: data.userId,
      });
      const result = response.data.data;
      dispatch(setJoinedSchoolAssignment(result));
      return result;
    } catch (err) {
      NotificationManager.error(err.response.data.message);
      return rejectWithValue(err.response.data);
    }
  },
);

export const joinNonPinSchoolAssignment = createAsyncThunk(
  'joinSchoolAssignment',
  async (data, { dispatch, rejectWithValue }) => {
    let url = `/api/v1/assignment/school-assignment/join/no-pin/${data.id}/${data.userId}`;

    try {
      const response = await API.post(url);
      const result = response.data.data;
      console.log('=========joinNonPinSchoolAssignment==========')
      console.log(result)
      dispatch(setJoinedSchoolAssignment(result));
      return result;
    } catch (err) {
      // NotificationManager.error(err.response.data.message);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSchoolAssignmentResult = createAsyncThunk(
  'getSchoolAssignmentResult',
  async (data, { dispatch, rejectWithValue }) => {
    let url = `/api/v1/assignment/school-assignment/result/${data.id}/${data.userId}`;

    try {
      const response = await API.get(url);
      const result = response.data.data;
      console.log('=========getSchoolAssignmentResult==========')
      console.log(result)
      dispatch(setSchoolAssignmentResult(result));
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const submitSchoolAssignment = createAsyncThunk(
  'submitSchoolAssignment',
  async (data, { dispatch, rejectWithValue }) => {
    let url = `/api/v1/assignment/school-assignment/submit/${data.id}/${data.sessionId}`;

    try {
      const response = await API.post(url, {
        userId: data.userId,
        answers: data.answers,
      });
      const result = response.data.data;
      dispatch(setSchoolAssignmentResult(result));
      return result;
    } catch (err) {
      NotificationManager.error(err.response.data.message);
      return rejectWithValue(err.response.data);
    }
  },
);
