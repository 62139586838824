import { Box, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import EnterPin from './EnterPinCode';
import Waiting from './Waiting';
import Completed from './completed';
import Timeout from './timeout';
import SelectAssignmentList from '../main/elements/assignments/selectAssignmentList';
import ShowAllConent from '../Layout/ShowAllConent';
import { useAppDispatch, useAppSelector } from 'store/store';
import ShowChallengeTask from '../../components/Layout/ShowChallengeTask';
import ShowVideoTask from '../../components/Layout/ShowVideoTask';
import ShowVideo from '../../components/Layout/ShowVideo';
import ShowMath from '../../components/Layout/ShowMath';
import ShowChallenge from '../../components/Layout/ShowChallenge';
import ShowChallengeVideo from '../../components/Layout/ShowChallengeVideo';
import SchoolAssignmentQuiz from '../Layout/schoolAssignmentChallenge';
import config from 'configs/config';
import WebSocketListener from '@core/components/Websocket';
import { useQuery } from 'api/hooks';
import { setJoinedSchoolAssignment, setSchoolAssignmentStep, setTimerDurationSecs } from 'store/Slice/schoolAssignmentSlice';
import { getDueTodaySchoolAssignments, getSchoolAssignmentResult, joinNonPinSchoolAssignment } from 'store/Thunk/schoolAssignmentThunk';

export default function PinCode() {
  const [videoTopic, setVideoTopic] = useState('all');
  const dispatch = useAppDispatch();
  const query = useQuery();
  const assignment = query.get('assignment');

  const { joinedSchoolAssignment, selectedAssignment, step, status } = useAppSelector(
    (state) => state.schoolAssignmentReducer
  );
  const assignmentType = joinedSchoolAssignment?.session?.currentTemplate?.topic[0]?.topic?.name?.toLowerCase();
  const topicId = joinedSchoolAssignment?.session?.currentTemplate?.topic[0]?.topicId;
  const questions = joinedSchoolAssignment?.session?.currentTemplateQuestions;

  const [selectedAnswers, setSelectedAnswers] = useState([]);

  function setStep(step) {
    dispatch(setSchoolAssignmentStep(step));
  }

  useEffect(() => {
    if (questions?.length > 0 && step === 1 && status === 'Start') {
      const initialAnswers = questions.map((question) => ({
        questionId: question.id,
        studentAnswer: '',
      }));
      console.log("initialAnswers", initialAnswers);
      setSelectedAnswers(initialAnswers);
    }
  }, [step, questions, status]);

  useEffect(() => {
    if (topicId) {
      setVideoTopic(topicId);
    }
  }, [topicId]);

  const { user } = useAppSelector((state) => state.authReducer);
  useEffect(() => {
    dispatch(
      getSchoolAssignmentResult({
        id: assignment,
        userId: user?.id,
      }),
    )
      .unwrap()
      .then((response) => {
        dispatch(setSchoolAssignmentStep(3));
      })
      .catch((err) => {
        dispatch(getDueTodaySchoolAssignments(user?.id)).unwrap().then((data) => {
          for (const ass of data) {
            if (ass.id === assignment) {
              if (!ass.pinCode) {
                dispatch(
                  joinNonPinSchoolAssignment({
                    id: ass.id,
                    userId: user?.id,
                  }),
                )
                  .unwrap()
                  .then((response) => {
                    const timeDuration = ass.timeDuration * 60;
                    dispatch(setTimerDurationSecs(timeDuration));
                    dispatch(setSchoolAssignmentStep(4));
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                break;
              } else {
                dispatch(setSchoolAssignmentStep(0));
              }
            }
          }
        }).catch((err) => {
          console.error(err);
        })

      });
  }, [assignment, user?.id, dispatch])


  return (
    <Box height="100%" bgcolor={'#5C5C5C'} borderRadius={'20px'} width={'100%'}>
      <Box
        justifyContent={'center'}
        display={'flex'}
        alignItems={'center'}
        height={'100%'}
        borderRadius={'18px'}
        width={'100%'}
      >
        {step === 0 && <EnterPin setStep={setStep} />}
        {step === 1 && <Waiting status={status} setStep={setStep} />}
        {step === 2 && <Timeout setStep={setStep} />}
        {step === 3 && <Completed setStep={setStep} />}
        {step === 4 && <SchoolAssignmentQuiz setStep={setStep} selectedAnswers={selectedAnswers} setSelectedAnswers={setSelectedAnswers} />}
      </Box>
    </Box>
  );
}
