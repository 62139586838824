import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import EyeBorderIcon from 'icons/eyeBorderIcon';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

import Grid from '@mui/material/Grid';

import { Autocomplete, Box, Pagination, TextField } from '@mui/material';
import bellwork from 'asset/bellwork.svg';
import dailypracticearrow from 'asset/dailypracticearrow.svg';
import diagnostic from 'asset/diagnostic.svg';
import exitticket from 'asset/exitticket.svg';
import unitquiz from 'asset/unitquiz.svg';
import moment from 'moment';
import { status, statusBgColors, statusColors } from 'utils/constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getStudentProgressByClassroom } from 'store/Thunk/userThunk';
const icons = {
  Bellwork: bellwork,
  Exit_Tickets: exitticket,
  Unit_Test: unitquiz,
  Unit_Quiz: unitquiz,
  Diagnostic: diagnostic,
  Daily_Practice: unitquiz,
};

const StudentsList = ({
  classroom,
  students,
  setSelectedAssignment,
  setIsOpenAssignmentView,
}) => {

  const dailyPracticeAssignments = students.dailyPracticeAssignments.flatMap(
    (assignment) => {
      const id = assignment.id;
      const type = "Daily Practice";
      const status = assignment.status;
      const dueDate = assignment.endDate;
      const startDate = assignment.startDate;
      const assignBy = assignment.assignedBy;
      let allAssignments = assignment?.templates?.concat(assignment.videos);
      if (allAssignments?.length > 0) {
        return allAssignments?.map((template) => ({
          id,
          type,
          status,
          dueDate,
          classroom,
          startDate,
          assignBy,
          ...template,
        }));
      }
      return [];
    },
  );

  const schoolAssignments = students.schoolAssignments.flatMap(
    (assignment) => {
      const id = assignment.id;
      const userId = students.id;
      const name = students.name;
      const profileImage = students.profileImage;
      const type = assignment.type;
      const status = assignment.status;
      const dueDate = assignment.dueDate;
      const startDate = assignment.startDate;
      const assignBy = assignment.assignBy;
      const school_assignment_template = assignment.school_assignment_template;
      const school_assignment_quiz_results =
        assignment.school_assignment_quiz_results;

      return {
        id,
        userId,
        name,
        profileImage,
        type,
        status,
        dueDate,
        classroom,
        startDate,
        assignBy,
        school_assignment_template,
        school_assignment_quiz_results,
        ...(school_assignment_template.length > 0 ? school_assignment_template[0] : {}),
      }

      // return assignment.school_assignment_template.map((template) => ({
      //   id,
      //   userId,
      //   name,
      //   profileImage,
      //   type,
      //   status,
      //   dueDate,
      //   startDate,
      //   assignBy,
      //   school_assignment_template,
      //   school_assignment_quiz_results,
      //   ...template,
      // }));
    },
  );

  const handleView = (item) => {
    setSelectedAssignment(item);
    setIsOpenAssignmentView(true);
  };
  return (
    <List component="div" disablePadding>
      <Box sx={{ m: 2, mb: 4 }}>
        <ListItem sx={{ pl: 4 }}>
          {/* grid for listing student properties */}
          <Grid
            container
            spacing={2}
            sx={{
              '& .MuiGrid-item': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <Grid item md={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontSize={'12px'} color={'#000'} fontWeight={600}>
                Assignment Type
              </Typography>
            </Grid>
            <Grid item md={2.9}>
              <Typography fontSize={'12px'} color={'#000'} fontWeight={600}>
                Standard
              </Typography>
            </Grid>
            <Grid item md={2.5}>
              <Typography fontSize={'12px'} color={'#000'} fontWeight={600}>
                Skill
              </Typography>
            </Grid>

            <Grid item md={1.5}>
              <Typography fontSize={'12px'} color={'#000'} fontWeight={600}>
                Start Date
              </Typography>
            </Grid>
            <Grid item md={1.5}>
              <Typography fontSize={'12px'} color={'#000'} fontWeight={600}>
                End Date
              </Typography>
            </Grid>

            <Grid item md={1}>
              <Typography fontSize={'12px'} color={'#000'} fontWeight={600}>
                Status
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography fontSize={'12px'} color={'#000'} fontWeight={600}>
                Actions
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      </Box>
      {schoolAssignments?.filter((assignment) => assignment.template)?.map((template) => (
        <Box sx={{ m: 2, mb: 4 }}>
          <ListItem sx={{ pl: 4 }}>
            {/* grid for listing template properties */}
            <Grid
              container
              spacing={2}
              sx={{
                '& .MuiGrid-item': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <Grid item md={1.5} sx={{ display: 'flex' }}>
                <img src={icons[template?.type || '']} alt="" />
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                >
                  {template?.type}
                </Typography>
              </Grid>
              <Grid item md={2.9} justifyContent={'center'}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                >
                  {template?.template?.subTopic[0]?.subTopic?.name || 'N/A'}
                </Typography>
              </Grid>
              <Grid item md={2.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                >
                  {template?.template?.skill[0]?.skill?.name || 'N/A'}
                </Typography>
              </Grid>

              <Grid item md={1.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                >
                  {moment(template?.startDate).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
              <Grid item md={1.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                >
                  {moment(template?.dueDate).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                  sx={{
                    minWidth: '80px',
                    textAlign: 'center',
                    color:
                      template?.school_assignment_quiz_results[0]
                        ?.colorCode ||
                      statusColors[template?.status] ||
                      '',
                    backgroundColor:
                      `${template?.school_assignment_quiz_results[0]?.colorCode}11` ||
                      statusBgColors[template?.status] ||
                      '',
                    padding: '5px 8px',
                    borderRadius: '16px',
                  }}
                >
                  {template?.school_assignment_quiz_results[0]?.percentage
                    ? `${template?.school_assignment_quiz_results[0]?.percentage}%`
                    : status[template?.status]}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <IconButton
                  onClick={() => handleView(template)}
                  style={{
                    color: '#20A58A',
                    marginRight: '-11px',
                    padding: '8px',
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <EyeBorderIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        </Box>
      ))}
      <Box sx={{ m: 2, mb: 4, p: 2 }}>
        {/* grid for listing template properties */}
        <Grid
          container
          spacing={2}
          bgcolor={'#F9F9F9'}
          borderRadius={'8px'}
          pb={2}
          sx={{
            '& .MuiGrid-item': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          <Grid
            item
            md={3}
            sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
          >
            <img src={dailypracticearrow} alt="" />
            <Typography fontSize={'12px'} fontWeight={600} color={'#131515'}>
              Daily Practice
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography fontSize={'12px'} color={'#000'} fontWeight={600}>
              Total Videos:{' '}
              <span className="font-bold" style={{ color: '#84818a' }}>
                -
              </span>
            </Typography>
          </Grid>
          {/* <Grid item md={1}>
              <Typography fontSize={'12px'}>Topic</Typography>
            </Grid> */}
          <Grid item md={3}>
            <Typography fontSize={'12px'} color={'#000'} fontWeight={600}>
              Total Quiz:{' '}
              <span className="font-bold" style={{ color: '#84818a' }}>
                {dailyPracticeAssignments?.length || 0}
              </span>
            </Typography>
          </Grid>
          <Grid item md={3} justifyContent={'center'}>
            <Typography fontSize={'12px'} color={'#000'} fontWeight={600}>
              Total Challenges:{' '}
              <span className="font-bold" style={{ color: '#84818a' }}>
                -
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {dailyPracticeAssignments?.filter((assignment) => assignment?.template)?.map((template, idx) => (
        <Box sx={{ m: 2, mb: 4 }} key={idx}>
          <ListItem sx={{ pl: 4 }}>
            {/* grid for listing template properties */}
            <Grid
              container
              spacing={2}
              sx={{
                '& .MuiGrid-item': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <Grid item md={1.5} sx={{ display: 'flex' }}>
                <span>
                  <img src={unitquiz} alt="" />
                </span>
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                >
                  {'Daily practice'}
                </Typography>
              </Grid>
              <Grid item md={2.9} justifyContent={'center'}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                >
                  {template?.template
                    ? template?.template?.subTopic[0]?.subTopic?.name
                    : template?.video?.subTopic[0]?.subTopic?.name || 'N/A'}
                </Typography>
              </Grid>
              <Grid item md={2.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                >
                  {template?.template
                    ? template?.template?.skill[0]?.skill?.name
                    : template?.video?.skill[0]?.skill?.name || 'N/A'}
                </Typography>
              </Grid>

              <Grid item md={1.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                >
                  {moment(template?.startDate).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
              <Grid item md={1.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                >
                  {moment(template?.endDate).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'12px'}
                  sx={{
                    minWidth: '80px',
                    textAlign: 'center',
                    color:
                      template?.template?.QuizResult[0]?.colorCode ||
                      statusColors[template?.status] ||
                      '',
                    backgroundColor: template?.template?.QuizResult[0]
                      ?.colorCode
                      ? `${template?.template?.QuizResult[0]?.colorCode}11`
                      : `${statusColors[template?.status]}11` || '',
                    padding: '5px 8px',
                    borderRadius: '16px',
                  }}
                >
                  {
                    template?.template?.QuizResult[0]?.percentage1st
                      ? `${template?.template?.QuizResult[0]?.percentage1st}%`
                      : '' || status[template?.status]
                  }
                </Typography>
              </Grid>
              <Grid item md={1}>
                <IconButton
                  onClick={() => handleView(template)}
                  style={{
                    color: '#20A58A',
                    marginRight: '-11px',
                    padding: '8px',
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <EyeBorderIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        </Box>
      ))}
    </List>
  );
};

export default function AssignmentList({
  classes,
  color,
  search,
  status,
  pageNo,
  setPageNo,
  pageSize,
  setPageSize,
  pageIndex,
  header,
  showPagination,
  entriesPerPage,
  setSelectedAssignment,
  assignmentsList,
  setIsOpenAssignmentView,
  assignmentsListMeta,
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const classDetail = urlParams.get('classDetail');
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(
    classDetail ? 0 : -1,
  );

  const handleClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex(-1); // Collapse the selected div if it's already expanded
    } else {
      setSelectedIndex(index); // Expand the selected div
    }
  };
  const dispatch = useAppDispatch();
  const fetchIdRef = useRef(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const { user } = useAppSelector((state) => state.authReducer);
  const studentId = user?.id;

  useEffect(() => {
    if (classes && classes.length) {
      setPageCount(assignmentsListMeta?.totalPages);
      setTotalCount(assignmentsListMeta?.totalCount);
    }
  }, [assignmentsListMeta, classes]);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setPageSize(pageSize);
        dispatch(
          getStudentProgressByClassroom({
            id: studentId,
            status,
            page: pageIndex,
            limit: pageSize,
          }),
        );
      }
    },
    [studentId, dispatch, setPageNo, status, setPageSize],
  );

  const setEntriesPerPage = (value) => {
    setPageNo(1);
    fetchData({
      pageIndex: 0,
      pageSize: value,
    });
    setPageSize(value);
  };
  const handleChangePage = (
    event,
    value,
  ) => {
    if (fetchData) {
      fetchData({
        pageIndex: value - 1,
        pageSize,
      });
    }
  };
  const renderPagination = () => (
    <Pagination
      count={pageCount || 10}
      page={pageNo + 1 || 1}
      shape="rounded"
      onChange={handleChangePage}
    />
  );

  return (
    <>
      {classes?.map((userClassroom, i) => {
        const dailyAssignmentTemplates = userClassroom.user.dailyPracticeAssignments.flatMap((obj) => {
          return obj.templates;
        })
        return (
          <div
            style={{
              border: open ? '1px solid #DCDCDC' : 'none',
              borderRadius: '10px 10px 0px 0px',
              marginBottom: '16px',
            }}
            key={i}
          >
            <Card
              style={{
                backgroundColor: color,
                borderRadius: '10px 10px 0px 0px',
                boxShadow:
                  '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
              }}
            >
              <ListItemButton
                style={{ padding: '3px 12px' }}
                onClick={() => handleClick(i)}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: '#131515',
                    '& .css-6uzn28-MuiTypography-root': {
                      color: '#131515',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '22px',
                    },
                  }}
                >
                  {header?.map((head, idx) => (
                    <Grid item md={head.width} key={idx}>
                      {head && userClassroom && (
                        <>
                          <Typography
                            color="#84818A"
                            fontSize={'12px'}
                            fontWeight={400}
                            gutterBottom
                          >
                            {head?.label}
                          </Typography>
                          <Tooltip
                            title={
                              head?.value === 'name'
                                ? userClassroom['classroomId']
                                : ''
                            }
                          >
                            <Typography fontWeight={600} color={'#131515'} fontSize={'14px'}>
                              {head?.value === 'totalAssignments'
                                ? dailyAssignmentTemplates.length +
                                userClassroom.user.schoolAssignments.length
                                : head?.value === 'name'
                                  ? userClassroom.classroom['name']
                                  : userClassroom.user[head?.value] ||
                                  (userClassroom.user[head?.value] === 0
                                    ? 0
                                    : '-')}
                            </Typography>
                          </Tooltip>
                        </>
                      )}
                    </Grid>
                  ))}
                  <Grid
                    item
                    md={0.5}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {selectedIndex === i ? <ExpandLess /> : <ExpandMore />}
                  </Grid>
                </Grid>
              </ListItemButton>
            </Card>

            <Collapse in={selectedIndex === i} timeout="auto" unmountOnExit>
              <StudentsList
                setSelectedAssignment={setSelectedAssignment}
                setIsOpenAssignmentView={setIsOpenAssignmentView}
                students={userClassroom.user}
                classroom={userClassroom.classroom}
              />
            </Collapse>
          </div>
        );
      })}
      {true && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={4}
          sx={{ border: '1px solid #E9EBED', borderRadius: '6px', p: 2 }}
        >
          {true && (
            <Box flex="1" display="flex" alignItems="center">
              <Typography variant="body2" className="entries-page-txt">
                Show&nbsp;&nbsp;
              </Typography>
              <Autocomplete
                disableClearable
                value={pageSize?.toString()}
                options={['10', '25', '50', '100']}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography variant="body2" className="entries-page-txt">
                &nbsp;&nbsp;entries of {totalCount}
              </Typography>
            </Box>
          )}
          {renderPagination()}
        </Box>
      )}
    </>
  );
}
