import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Checkbox,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import sharedStyles from '../payment.module.scss';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            '&.MuiLinearProgress-root': {
              height: '15px',
              borderRadius: '80px',
            },
            '& .MuiLinearProgress-bar': {
              borderRadius: '8px',
            },
          }}
        />
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function FreePlan({ setStep, setIsUpgrade }) {
  const [progress, setProgress] = React.useState(3);
  const [steps, setSteps] = useState([
    {
      completed: false,
      current: true,
      lable: "Step 1: Let's collect key settings for G3MS?",
    },
    {
      completed: false,
      current: false,
      lable: 'Step 2: Add a student',
    },
    {
      completed: false,
      current: false,
      lable: 'Step 3: Add another parent',
    },
  ]);
  const handleClick = (selectedIndex) => {
    const updatedSteps = steps.map((step, index) => {
      if (index === selectedIndex) {
        if (selectedIndex === 0) {
          setProgress(3);
        }
        return { ...step, current: true };
      } else {
        return { ...step, current: false };
      }
    });
    setSteps(updatedSteps);
  };

  return (
    <Stack className={sharedStyles.freePlanContainer}>
      <Box className={sharedStyles.headerFreePlan}>
        <Typography className={sharedStyles.headerFreeText}>
          Welcome to G3MS! Please complete your Onboarding Checklist.
        </Typography>
        <Box>
          <IconButton
            onClick={() =>
              setStep((prevStep) => ({
                ...prevStep,
                current: 'pricing',
              }))
            }
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Stack className="w-full p-8 mt-8">
        <Box className="w-full flex flex-col gap-2">
          <LinearProgressWithLabel value={progress} />
          <Box
            className="w-full flex justify-between"
            sx={{
              '& .MuiTypography-root': {
                color: '#C73E9D',
                fontWeight: 600,
                fontSize: '20px',
              },
            }}
          >
            <Typography>0%</Typography>
            <Typography>50%</Typography>
            <Typography>100%</Typography>
          </Box>
        </Box>
        <Box className="flex w-full gap-8 mt-8">
          <Stack className={sharedStyles.containerLeft}>
            <Typography className={sharedStyles.headerFreeText}>
              Steps to Complete
            </Typography>
            <Stack gap={3}>
              {steps.map((step, index) => (
                <Box
                  key={index}
                  onClick={() => handleClick(index)}
                  className={`${sharedStyles.stepsItem} ${
                    step.current || step.completed
                      ? sharedStyles.stepsItemActive
                      : ''
                  }`}
                >
                  <Checkbox
                    onChange={() => handleClick(index)}
                    checked={step.current || step.completed}
                    sx={{
                      height: 'max-content',
                      padding: '0px',
                      '& .MuiSvgIcon-root': { fontSize: 28 },
                    }}
                    size="medium
                  "
                  />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '20px',
                      color:
                        step.current || step.completed ? '#c73e9d' : '#979c9e',
                    }}
                  >
                    {step.lable}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Stack>
          <Stack className={sharedStyles.containerRight}>
            {steps[0].current && (
              <StepOne
                setProgress={setProgress}
                setSteps={setSteps}
                setStep={setStep}
              />
            )}
            {steps[1].current && (
              <StepTwo
                setProgress={setProgress}
                setSteps={setSteps}
                setStep={setStep}
                setIsUpgrade={setIsUpgrade}
              />
            )}
            {steps[2].current && (
              <StepThree
                setProgress={setProgress}
                setSteps={setSteps}
                setStep={setStep}
              />
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
