const Index = ({ selectedCanvasObj }) => {
	return (
		<>
			<svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				viewBox="0 0 720 684">
				<path fill={selectedCanvasObj?.imgColor} d="M439.3,264.9c0,0,16.5-4.8,38.9-9.3c22.4-4.5,62.6-4.8,78.5,7.6c0,0,21.3,14.5,4.1,29.6
	C542.3,309,543.2,323,538,348.1c-5.2,25.1-10,38.9-10.7,52c-0.7,13.1-6.2,54.4-12.4,79.5c-6.2,25.1-18.6,66.4-26.5,75
	c0,0-2.4,8.9-1.7,13.1c0.7,4.1-7.6,9.6-7.6,9.6s-58.5-5.2-95.7-5.5c0,0-5.5-19.3,5.2-58.5c10.7-39.2,11.4-59.9,12-80.5
	c0.7-20.6-4.5-42.7,6.5-67.4c11-24.8,20-46.8,26.2-68.1C439.6,275.9,439.3,270.4,439.3,264.9z"/>
				<path fill={selectedCanvasObj?.imgColor} d="M287.3,264.9c0,0-16.5-4.8-38.9-9.3c-22.4-4.5-62.6-4.8-78.5,7.6c0,0-21.3,14.5-4.1,29.6
	c18.5,16.2,17.5,30.3,22.7,55.4c5.2,25.1,10,38.9,10.7,52s6.2,54.4,12.4,79.5c6.2,25.1,18.6,66.4,26.5,75c0,0,2.4,8.9,1.7,13.1
	c-0.7,4.1,7.6,9.6,7.6,9.6s58.5-5.2,95.7-5.5c0,0,5.5-19.3-5.2-58.5c-10.7-39.2-11.4-59.9-12-80.5s4.5-42.7-6.5-67.4
	c-11-24.8-20-46.8-26.2-68.1C286.9,275.9,287.3,270.4,287.3,264.9z"/>
			</svg>
		</>
	);
};

export default Index;
