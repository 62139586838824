import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Box,
  Typography,
  Divider,
  Card,
  Avatar,
  Stack,
  Button,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/store';
import { Circles } from 'react-loader-spinner';

import style from './student.module.scss';
import SimpleTable from '@core/components/SimpleTable';
import { TypoItem } from './macros';
import {
  getUserData, getStudentDetail,
  getStudentTotals,
  getStudentProgressByClassroom,
  getStudentQR,
} from 'store/Thunk/userThunk';
import { CardInfo } from '@core/components/CardInfo';
import TableHeader from '@core/components/TableHeader';
import Icon1 from './icons/icon-1.svg';
import Icon2 from './icons/icon-2.svg';
import Icon3 from './icons/icon-3.svg';
import Icon4 from './icons/icon-4.svg';
import Icon5 from './icons/icon-5.svg';
import Icon6 from './icons/icon-6.svg';
import Icon7 from './icons/icon-7.svg';
import Icon8 from './icons/icon-8.svg';
import Icon9 from './icons/icon-9.svg';
import Icon10 from './icons/icon-10.svg';
import Icon11 from './icons/icon-11.svg';
import Icon12 from './icons/icon-12.svg';
import StudentProgressHeader from './StudentProgressHeader';
import AssignmentList from './Assignment';
import AssignmentView from './AssignmentView';

export const AdminAssignmentStatus = {
  All: 'All',
  Not_Started: 'Not Started',
  Started: 'Started',
  Completed: 'Completed',
  InProgress: 'InProgress',
}

function Student() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.authReducer);
  const { studentTotals, studentProgressByClassroom, studentQr, studentProgressLoading } = useAppSelector((state) => state.userReducer);

  const studentId = user?.id;

  const [search, setSearch] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tabValue, setTabValue] = useState(AdminAssignmentStatus.All);

  const [isOpenAssignmentView, setIsOpenAssignmentView] =
    React.useState(false);


  const handleCloseView = () => {
    setIsOpenAssignmentView(false);
  };
  const [selectedAssignment, setSelectedAssignment] = React.useState({
    id: '',
  });

  useEffect(() => {
    if (studentId) {
      dispatch(getUserData({ studentId }));
      dispatch(getStudentTotals(studentId));
      dispatch(
        getStudentDetail({
          id: studentId,
        }),
      );
      dispatch(getStudentQR(studentId));
    }
  }, [dispatch, studentId]);

  useEffect(() => {
    if (studentId) {
      dispatch(
        getStudentProgressByClassroom({
          id: studentId,
          status: tabValue,
          page: pageNo,
          limit: pageSize,
          search,
        }),
      );
    }
  }, [dispatch, studentId, pageNo, tabValue, pageSize, search]);

  const color = '#E9F6F3';
  const header = [
    { label: 'Classroom Name', width: 3, value: 'name' },
    { label: 'Total Assignments', width: 1.5, value: 'totalAssignments' },
    { label: 'Completed', width: 1.5, value: 'totalCompletedAssignments' },
    { label: 'Not Started', width: 1.5, value: 'totalNotStartedAssignments' },
    { label: 'Overall Grade', width: 1.5, value: 'totalGems' },
    {
      label: 'Intervention Needed?',
      width: 1.5,
      value: 'interVentionRecommended',
    },
  ];

  const CHECK_POINT = 20;
  const getColor = (val) => val < CHECK_POINT ? '#F41F52' : '#2D9739';

  const data = [
    {
      title: 'Total Teachers',
      icon: Icon2,
      count: studentTotals?.totalTeachers || 0,
      md: 4,
    },
    {
      title: 'Total Parents',
      icon: Icon3,
      count: studentTotals?.totalParents || 0,
      md: 4,
    },
    {
      title: 'Total Classrooms',
      count: studentTotals?.totalClassrooms || 0,
      icon: Icon4,
      md: 4,
    },
    {
      title: 'Bellwork %',
      count: `${studentTotals?.schoolAssignmentBellwork || 0}%`,
      icon: Icon5,
      md: 3,
      color: getColor(studentTotals?.schoolAssignmentBellwork),
    },
    {
      title: 'Exit Ticket %',
      count: `${studentTotals?.schoolAssignmentExitTicket || 0}%`,
      icon: Icon6,
      md: 3,
      color: getColor(studentTotals?.schoolAssignmentExitTicket),
    },
    {
      title: 'Unit Quiz',
      count: `${studentTotals?.schoolAssignmentUnitQuiz || 0}%`,
      icon: Icon8,
      md: 3,
      color: getColor(studentTotals?.schoolAssignmentUnitQuiz),
    },
    {
      title: 'Unit Test %',
      count: `${studentTotals?.schoolAssignmentUnitTest || 0}%`,
      icon: Icon7,
      md: 3,
      color: getColor(studentTotals?.schoolAssignmentUnitTest),
    },
    {
      title: 'Daily Practice %',
      count: `${studentTotals?.dailyPractices || 0}%`,
      icon: Icon9,
      md: 3,
      color: getColor(studentTotals?.dailyPractices),
    },
    {
      title: 'Diagnostics %',
      count: `${studentTotals?.schoolAssignmentDiagnostic || 0}%`,
      icon: Icon10,
      md: 3,
      color: getColor(studentTotals?.schoolAssignmentDiagnostic),
    },
    {
      title: 'Total Videos Watched',
      count: studentTotals?.watchedVideos || 0,
      icon: Icon11,
      md: 3,
    },
    {
      title: 'Total G3MS Earned',
      count: studentTotals?.totalGemsEarned || 0,
      icon: Icon12,
      md: 3,
    },
  ];

  const tabValues = [
    { title: 'All', value: AdminAssignmentStatus.All },
    { title: 'Completed', value: AdminAssignmentStatus.Completed },
    { title: 'Not Started', value: AdminAssignmentStatus.Not_Started },
    { title: 'In Progress', value: AdminAssignmentStatus.InProgress },
  ];

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setPageNo(pageIndex);
      setPageSize(pageSize);
      dispatch(
        getStudentProgressByClassroom({
          id: studentId,
          status: tabValue,
          page: pageIndex,
          limit: pageSize,
          search,
        }),
      );
    },
    [studentId, dispatch, setPageNo, tabValue, setPageSize, search],
  );

  return (
    <Stack sx={{ position: 'relative', width: '100%' }} spacing={2}>
      <Grid sx={{ position: 'relative', width: '100%' }} container spacing={2}>
        <Card
          sx={{
            p: 7,
            gap: '10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box className={style.content_wrapper}>
            {user?.profileImage ? (
              <img
                className={style.profile_img}
                alt={user?.name}
                src={user?.profileImage}
              />
            ) : (
              <Avatar sx={{ width: '80px', height: '80px', alignitems: 'center' }} />
            )}
            <div className={style.profile_detail_wrapper}>
              <Box className={style.profile_detail}>
                <Typography
                  sx={{ color: '#20A58A', fontWeight: 700, fontSize: '16px' }}
                >
                  {user?.name}
                </Typography>
                <Typography sx={{ fontSize: '12px' }}>
                  (ID: {user?.id})
                </Typography>
              </Box>
              <Box className={style.info}>
                <TypoItem title="Email" detail={user?.email} />
                <TypoItem title="Grade" detail={user?.gradeName} />
                <TypoItem title="Parent" detail={user?.parent?.name || user?.parent?.email || '-'} />
                <TypoItem
                  title="School Name"
                  detail={user?.school?.name || '-'}
                />
              </Box>
            </div>
            {studentQr && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', flex: 1, marginLeft: 'auto', maxWidth: '400px' }}>
                <Box
                  border={'1px solid #DCDCDC'}
                  borderRadius={'6px'}
                  width={'100px'}
                  padding={'10px'}
                >
                  <img src={studentQr.qr} alt="qr" width={'100%'} />
                </Box>
                <Typography style={{
                  fontWeight: 700,
                  fontSize: '14px',
                }}>
                  Scan to Log Into Mobile App
                </Typography>
              </Box>
            )}
          </Box>
          <Box>
            <Grid container spacing={2} sx={{ mt: 0, mb: 0 }}>
              {data?.map((info) => (
                <CardInfo
                  item={{
                    title: ` ${info?.title}`,
                    content: `${info?.count || 0}`,
                    icon: `${info?.icon}`,
                    color: `${info?.color}`,
                    sm: info?.sm || 4,
                    md: info?.md || 4,
                  }}
                />
              ))}
            </Grid>
          </Box>
          <TableHeader
            item={{
              title: 'Progress By Classrooms',
              action: true,
              setSearch: setSearch,
              fetchData,
            }}
          />

          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {tabValues?.map((item) => {
                return (
                  <>
                    <Box
                      sx={
                        tabValue === item?.value
                          ? {
                            borderBottom: '4px #C73E9D solid',
                            color: '#000',
                          }
                          : null
                      }
                    >
                      <Button
                        onClick={() => {
                          setTabValue(item?.value);
                        }}
                        sx={{
                          p: '8px 30px',
                          borderRadius: '0px',
                          color:
                            tabValue === item?.value ? '#484848' : '#84818A',
                          fontSize: '12px',
                          fontWeight: tabValue === item?.value ? '600' : '500',
                          lineHeight: '22px',
                          '&:hover': {
                            backgroundColor: 'none',
                          },
                          textTransform: 'none',
                        }}
                      >
                        {item?.title}
                      </Button>
                    </Box>
                  </>
                );
              })}
            </Box>
            <Divider sx={{ mt: 0 }} />
          </Box>

          <StudentProgressHeader />

          {studentProgressLoading ? (
            <Stack alignItems={'center'}>
              <Circles width="30" color="#c73e9d" visible={studentProgressLoading} />
            </Stack>
          ) : (
            studentProgressByClassroom && (
              <>
                <AssignmentList
                  color={color}
                  search={search}
                  status={tabValue}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  assignmentsList={studentProgressByClassroom.items}
                  assignmentsListMeta={studentProgressByClassroom.meta}
                  classes={studentProgressByClassroom.items}
                  setSelectedAssignment={setSelectedAssignment}
                  setIsOpenAssignmentView={setIsOpenAssignmentView}
                  header={header}
                />
                <AssignmentView
                  open={isOpenAssignmentView}
                  onClose={handleCloseView}
                  selectedAssignment={selectedAssignment}
                />
              </>
            )
          )}
        </Card>
      </Grid>
    </Stack>
  );
}

export default Student;
