export default function EyeBorderIcon() {
  return (
    <svg
      width="30"
      height="30.5"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4816 20.1555C10.5316 20.1555 6.63764 14.7755 6.48164 14.5555C6.42134 14.4708 6.38894 14.3694 6.38894 14.2655C6.38894 14.1615 6.42134 14.0602 6.48164 13.9755C6.64564 13.7455 10.5376 8.37549 15.4816 8.37549C20.4256 8.37549 24.3256 13.7555 24.4816 13.9755C24.5419 14.0602 24.5743 14.1615 24.5743 14.2655C24.5743 14.3694 24.5419 14.4708 24.4816 14.5555C24.3256 14.7755 20.4316 20.1555 15.4816 20.1555ZM7.51164 14.2555C8.37764 15.3495 11.6676 19.1555 15.4816 19.1555C19.2956 19.1555 22.5856 15.3555 23.4516 14.2555C22.5856 13.1615 19.2956 9.35549 15.4816 9.35549C11.6676 9.35549 8.37764 13.1615 7.51164 14.2555ZM15.4816 17.4555C14.8487 17.4555 14.23 17.2678 13.7038 16.9162C13.1776 16.5646 12.7674 16.0648 12.5252 15.4801C12.283 14.8954 12.2196 14.2519 12.3431 13.6312C12.4666 13.0105 12.7714 12.4403 13.2189 11.9927C13.6664 11.5452 14.2366 11.2404 14.8573 11.117C15.4781 10.9935 16.1215 11.0569 16.7062 11.2991C17.2909 11.5413 17.7907 11.9514 18.1423 12.4777C18.494 13.0039 18.6816 13.6226 18.6816 14.2555C18.6816 15.1042 18.3445 15.9181 17.7444 16.5182C17.1443 17.1183 16.3303 17.4555 15.4816 17.4555ZM15.4816 12.0555C15.0465 12.0555 14.6212 12.1845 14.2594 12.4263C13.8976 12.668 13.6156 13.0116 13.4491 13.4136C13.2826 13.8156 13.239 14.2579 13.3239 14.6847C13.4088 15.1114 13.6183 15.5034 13.926 15.8111C14.2337 16.1188 14.6257 16.3283 15.0524 16.4132C15.4792 16.4981 15.9215 16.4545 16.3235 16.288C16.7255 16.1215 17.0691 15.8395 17.3109 15.4777C17.5526 15.116 17.6816 14.6906 17.6816 14.2555C17.6816 13.672 17.4499 13.1124 17.0373 12.6999C16.6247 12.2873 16.0651 12.0555 15.4816 12.0555Z"
        fill="#20A58A"
      />
      <rect
        x="0.981636"
        y="0.755371"
        width="29"
        height="29"
        rx="6.5"
        stroke="#20A58A"
      />
    </svg>
  );
}
