import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { setUserDetails } from 'store/Slice/authSlice';


const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorage.clear();
    dispatch(setUserDetails(null));
    navigate('/auth/login');
  }, [navigate, dispatch]);

  return (
    <>
    </>
  );
};

export default Logout;
