import React from 'react';
import sharedStyles from '../payment.module.scss';
import { Box, Button, Stack, Typography } from '@mui/material';

export default function UpgradePlan({ setStep }) {
  const handleUpgrade = () => {
    setStep((prevStep) => ({
      ...prevStep,
      current: 'pricing',
    }));
  };
  return (
    <Box className={sharedStyles.upgradePlan}>
      <Stack gap={2}>
        <Typography
          sx={{ fontWeight: 600, fontSize: '24px', color: '#131515' }}
        >
          Upgrade plan
        </Typography>
        <Typography
          sx={{ fontWeight: 400, fontSize: '18px', color: '#84818A' }}
        >
          With your FREE plan, you can add 1 student.
        </Typography>
      </Stack>
      <Box className="flex items-center">
        <Button
          variant={'contained'}
          onClick={handleUpgrade}
          size="large"
          sx={{
            width: '100%',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '20px',
            borderWidth: '2px',
            color: '#fff !important',
            backgroundColor: '#20A58A',
            '&:hover': { backgroundColor: '#20A58A' },
          }}
        >
          Upgrade Plan
        </Button>
      </Box>
    </Box>
  );
}
