import React, { useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import searchIcon from './search.svg';

import SquadCard from './SquadCard';
import styles from './topSquad.module.scss';
import { useDispatch } from 'react-redux';
import { getLeaderBoardTopResult } from 'store/Thunk/quizThunk';
import { useAppSelector } from 'store/store';
import { useQuery } from 'api/hooks';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const TopSquad = () => {
  const dispatch = useDispatch();
  const [search, setSearchValue] = useState('');
  const { userData } = useAppSelector((state) => state.userReducer);
  const { leaderBoardTopTen } = useAppSelector((state) => state.quizReducer);
  const currentUser = JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();
  const query = useQuery();
  const topicId = query.get('topic');

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (currentUser?.grade[0]?.gradeId) {
      dispatch(
        getLeaderBoardTopResult({
          search: search,
          topicId: topicId !== '' && topicId,
          gradeId: currentUser?.grade[0]?.gradeId,
          school: currentUser?.school?.id,
        }),
      );
    }
  }, [search, topicId]);

  return (
    <div className={styles.leaderboard}>
      <ArrowBack
        style={{ fontSize: '30px' }}
        onClick={() => navigate('/app/student')}
        className={styles.back_btn}
      />
      <p className={styles.title}>
        The more you learn, The more you{' '}
        <span style={{ color: '#C73E9D' }}>Earn $G3MS Tokens!</span>
      </p>
      {leaderBoardTopTen.length === 0 ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70vh',
            flexDirection: 'column',
          }}
        >
          <LeaderboardIcon
            sx={{
              width: 100,
              height: 100,
            }}
          />
          <Typography variant="h5">No Leaderboard Data</Typography>
        </div>
      ) : (
        <main className={styles.mainContainer}>
          <div className={styles.header}>
            <p>Top 10 Score Squad!</p>
            <p>
              School Name:
              {' ' + currentUser?.schoolName}
            </p>
            <p>Grade: {userData?.grade[0]?.grade.name}</p>
          </div>
          <div className={styles.wrapper}>
            {leaderBoardTopTen.map((member, index) => {
              return (
                <SquadCard
                  image={member.student.profileImage}
                  name={member.student.name}
                  place={member.rank}
                  tokens={member.totalScore}
                  grade={
                    member?.student.grade[0] &&
                    member.student.grade.length > 0 &&
                    member?.student.grade[0]?.grade?.name
                  }
                />
              );
            })}
          </div>
        </main>
      )}
      <div className={styles.inputContainer}>
        <span className={styles.header}>
          <p>What is my Ranking?</p>
        </span>
        <div className={styles.searchWrapper}>
          <img src={searchIcon} />
          <input onChange={handleChange} placeholder="Search for my ranking" />
        </div>
      </div>
    </div>
  );
};

export default TopSquad;
