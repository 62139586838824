import {
  SettingsConsumer,
  SettingsProvider,
} from '@core/context/settingsContext';

import { getAnalytics, logEvent } from 'firebase/analytics';

import ThemeComponent from '@core/theme/ThemeComponent';
import VideoDetails from 'app/student/components/Layout/VideoChallenge/DailyLesson';
import ChallengeSelection from 'app/student/components/challengeSelection';
import DailyQuiz from 'app/student/components/Layout/dailyChallenge';
import StudentProfile from 'app/student/components/profile/profile';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import 'react-notifications/lib/notifications.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import StudentAppModule from './app/student/app';
import CompleteProfile from './auth/completeProfile/student/completeProfile';
import Register from './auth/register/register';
import './index.css';
import DressChallenge from 'app/student/components/challenges/dress';
import SneakerChallenge from 'app/student/components/challenges/sneaker';
import UserLayout from './layouts/UserLayout';
// ** React Perfect Scrollbar Style
import TopSquad from 'app/student/components/TopSquad/TopSquad';
import Login from 'auth/login/login_new';
import Logout from 'auth/login/logout';
import Pricing from 'auth/login/components/pricing';
import { useEffect } from 'react';
import WebRedirect from 'auth/redirect/WebRedirect';

import TokenLogin from 'pages/TokenLogin';
import AiChat from 'pages/Aichat/AiChat';
import ShareToEarn from 'pages/ShareToEarn/ShareToEarn';

function App(props) {
  const { Component } = props;
  const setConfig = Component?.setConfig ?? undefined;

  useEffect(() => {
    const analytics = getAnalytics();
    const handlePathChangeAnalytics = () => {
      const path = window.location.pathname;
      logEvent(analytics, 'page_view', { page_path: path });
    };

    handlePathChangeAnalytics();

    window.addEventListener('popstate', handlePathChangeAnalytics);

    return () => {
      window.removeEventListener('popstate', handlePathChangeAnalytics);
    };
  }, []);

  return (
    <BrowserRouter>
      <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                <NotificationContainer />
                <Routes>
                  <Route path="/auth/login" element={<Login />} />
                  <Route path="/auth/logout" element={<Logout />} />
                  <Route path="/auth/register/:type" element={<Register />} />
                  <Route path="/auth/register/" element={<Register />} />
                  <Route path="/auth/pricing/" element={<Pricing />} />
                  <Route path="/auth/token-login" element={<TokenLogin />} />
                  <Route
                    path="/auth/complete_profile/"
                    element={<CompleteProfile />}
                  />
                  <Route
                    path="/app/student/challenge-selection"
                    element={
                      <UserLayout>
                        <ChallengeSelection />
                      </UserLayout>
                    }
                  />
                   <Route
                    path="/app/student/dress-challenge/:id"
                    element={
                        <DressChallenge />
                    }
                  />
                  <Route
                    path="/app/student/sneaker-challenge/:id"
                    element={
                        <SneakerChallenge />
                    }
                  />
                  <Route
                    path="/app/student"
                    element={
                      <UserLayout>
                        <StudentAppModule />
                      </UserLayout>
                    }
                  />
                  <Route
                    path="/app/student/profile"
                    element={
                      <UserLayout>
                        <StudentProfile />
                      </UserLayout>
                    }
                  />
                  <Route
                    path="/app/student/leaderboard"
                    element={
                      <UserLayout>
                        <TopSquad />
                      </UserLayout>
                    }
                  />
                  {/* <Route path="/app/teacher" element={<TeacherAppModule />} /> */}
                  <Route
                    path="/app/student/video"
                    element={
                      <>
                        <VideoDetails />
                      </>
                    }
                  />
                  <Route
                    path="/app/student/daily-quiz"
                    element={
                      <>
                        <DailyQuiz />
                      </>
                    }
                  />
                  <Route path="/redirectWeb" element={<WebRedirect />} />
                  <Route path="/" element={<Login />} />
                  <Route path="*" element={<Login />} />

                  <Route
                    path="/app/student/chat"
                    element={
                      <div className='p-4 h-screen'>
                        <AiChat />
                      </div>
                    }
                  />
                  <Route
                    path="/app/student/share-to-earn"
                    element={
                      <UserLayout>
                        <ShareToEarn />
                      </UserLayout>
                    }
                  />
                </Routes>
              </ThemeComponent>
            );
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </BrowserRouter>
  );
}

export default App;
