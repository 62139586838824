export const Roles = {
  ADMIN: 'super_admin',
  TEACHER: 'teacher',
  PARENT: 'parent',
  SCHOOL_LEADER: 'school_leader',
  TUTOR: 'tutor',
};
export const statusColors = {
  Live: '#C73E9D',
  Started: '#B54708',
  Pause: '#B54708',
  Resume: '#B54708',
  Completed: '#027A48',
  Cancelled: '#B54708',
  Not_Started: '#84818a',
};

export const statusBgColors = {
  Live: '#ffecf9',
  Started: '#FFFAEB',
  Pause: '#FFFAEB',
  Resume: '#FFFAEB',
  Completed: '#ECFDF3',
  Cancelled: '#FFFAEB',
  Not_Started: '#F2F4F7',
};
export const status = {
  Live: 'Live',
  Started: 'Started',
  Pause: 'Pause',
  Resume: 'Resume',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Not_Started: 'Not Started',
};
export const resultVideo = {
  be_right_back: [
    'SdtI00ocGlUH8IN02AFVpXpCZZIBd00uHVandmbWaIkv1E',
    '9G8uqzi5SC020168vdCmZYMyrxPy901WyBxYsm3pQx0247Q',
    'Z9Q8as01OlTShF7NegcFXgRSQxhLAXQSN3U0200lhD3ag8',
    'MtdEWXc1U5mMPRfl01Dd4r3858Arlq00I8qQE00LjCgaN4',
    'kOoO02bGD01jXtUaue9N4LIPWdlSOhzFNDjHAqOV4O0154',
    'DC35nJXJmD8R9IxHz02C9iRtpKb9hnJ9F0071sKEPEVC8',
  ],
  do_it_again: [
    'HpzlyVzk01Sl00nILDa45BLVfpTaj1jm7TINvPcMUflMM',
    'xS01LDwxn6uFG9LkrpS014uurwE01dtNmExYHvDhmsi5Ng',
    'cg3Q8e00IDHrzQaD101AWSn3pRymTQTC2syj005BFJ00ris',
    'vqe9QuC2GkDkZw1lbLA9Uhdnb00LxMki3tSQT6wdDaO4',
    'H7ouuD004mgA014QsATG00KZpYaPSe8T400oM61d01oJPaQs',
    'kGJpsiKaVcYrd1wP900RLPXcpPo469UZo01rp9YmQrhak',
  ],
  do_not_stop: [
    '00ekSRwOXby3km88I5MXWAwyql02Bzvyy5IWkM01zkZzzg',
    'b00qRnN6UnGne3IZTyefEozA9D3TSyffisZKY1SWtUE8',
    'dvWs2sA33z1oKZjXcrHTuMBcIrSyBDmZQHab6MjFPAw',
    'On7700JuEOv01cr7gUy501D01usGTbITpvqbbse700aPCC5w',
    'ZV5l7hZixr2WR1oC01AcnQgcfvuraBZx7LdCR6vz01Ai4',
    '4zeBB1VfpM02xwhYNdASJt00RpLgnkO028m6mdjInkjNQQ',
  ],
  social_break: [
    'DvFjDtYeZXdHwlEMw9jGy1t3PlIFiYdwtqCDfkH2KNg',
    'avJwNbo69NW02dZ6MntPeU00KHChdx68IOhTa6NkI8rbo',
    'm8V9caheXqjaJP5Uq2sF4OuRcOzx5OfpS01WehjSFsqc',
    'idHb00ACzDy5Ug02ZZNaqcomOzGJO6qafo6UrlbkXcRF00',
    'Bf002fkFwQL3Yc900q7UPKf9PTx2EWlRK9wzDW87AAbU00',
    '6YU8jbv02NwJCTvtSmGF2ng00Kuat2Lf3kE02kkDaa02zOc',
  ],
};
