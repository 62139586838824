import { createSlice } from '@reduxjs/toolkit';

const intialState = {
  teacherList: null,
};

const TeacherSlice = createSlice({
  name: 'teacher_slice',
  initialState: intialState,
  reducers: {
    setTeacherData: (state, action) => {
      state.teacherList = action.payload;
          },
  },
});

export const { setTeacherData } = TeacherSlice.actions;

export default TeacherSlice.reducer;
