import React from 'react'

export default function STELeftCenter() {
  return (
    <div className="flex items-center justify-center flex-col gap-8 py-4">
      <img
        src="/svg/sharetoearn.svg"
        className="w-full max-w-[25rem] mb-2"
        alt="sharetoearn"
      />
      <div className="space-y-4 flex flex-col items-center justify-center">
        <h1 className="text-black font-bold text-base sm:text-lg md:text-xl lg:text-2xl xl:text-[1.75rem] text-center">
          Share the G3MS app with your friends and earn rewards! 📲
        </h1>
        <p className="text-black text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-center">
          You’ll earn $10 G3MS for sharing, and you and your friend will both
          receive $100 G3MS when they sign up using your referral code.
        </p>
      </div>
    </div>
  );
}
