import { useQuery } from 'api/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setQuizLoading } from 'store/Slice/quizSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getQuizList } from 'store/Thunk/quizThunk';

export default function useGetQuizTemplate({ videoTopic = '' } = {}) {
  const dispatch = useAppDispatch();
  const { quizList, quizLoading } = useAppSelector(
    (state) => state.quizReducer,
  );

  const query = useQuery();
  const topic = videoTopic || query.get('topic');

  const { user } = useAppSelector((state) => state.authReducer);
  const { userData } = useAppSelector((state) => state.userReducer);

  const [template, setTemplate] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      console.log('::RAN THIS QUIZ AGAIN<============');
      dispatch(setQuizLoading(true));
      dispatch(
        getQuizList({
          topic: videoTopic || topic,
          userId: user?.id,
          gradeId:
            userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        }),
      )
        .unwrap()
        .then((res) => {
          console.log('<============Done loading quiz list<============', res);
          dispatch(setQuizLoading(false));
        })
        .catch((err) => {
          dispatch(setQuizLoading(false));
          console.log(
            '<============Error loading quiz list<============',
            err,
            err.payload,
          );
        })
        .finally(() => {
          dispatch(setQuizLoading(false));
          console.log('<============Finally loading quiz list<============');
        });
    }
    // }, [videoTopic, user?.id, userData?.grade]);
  }, [user?.id, userData?.grade]);

  useEffect(() => {
    setTemplate(quizList);
  }, [quizList, topic]);

  const navigateToQuiz = (videoTopic) => {
    console.warn('template', template);
    if (template?.templateId) {
      navigate(`/app/student/daily-quiz?quiz=${template.templateId}`, {
        state: videoTopic,
      });
    } else {
      alert('No quiz session available for this student.');
    }
  };

  return { template, isLoading: quizLoading, navigateToQuiz };
}
