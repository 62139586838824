import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: null,
  showTop: false,
  studentProgressLoading: false,
  studentProgressByClassroom: null,
  studentQr: null,
  studentDetail: null,
  studentTotals: null,
};

const UserSlice = createSlice({
  name: 'user_slice',
  initialState: initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setShowTop: (state, action) => {
      state.showTop = action.payload;
    },
    setStudentDetail: (state, action) => {
      state.studentDetail = action.payload;
    },
    setStudentProgressLoading: (state, action) => {
      state.studentProgressLoading = action.payload;
    },
    setStudentProgressByClassroom: (state, action) => {
      state.studentProgressByClassroom = action.payload;
    },
    setStudentQr: (state, action) => {
      state.studentQr = action.payload;
    },
    setStudentTotals: (state, action) => {
      state.studentTotals = action.payload;
    },
  },
});

export const {
  setUserData,
  setShowTop,
  setStudentDetail,
  setStudentProgressByClassroom,
  setStudentQr,
  setStudentTotals,
  setStudentProgressLoading,
} = UserSlice.actions;

export default UserSlice.reducer;
