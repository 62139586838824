import { createSlice } from '@reduxjs/toolkit';

const intialState = {
  showCodeToEarn: false,
};

const uiSlice = createSlice({
  name: 'ui_slice',
  initialState: intialState,
  reducers: {
    setShowCodeToEarn: (state, action) => {
      state.showCodeToEarn = action.payload;
    },
  },
});

export const { setShowCodeToEarn } = uiSlice.actions;

export const selectShowCodeToEarn = (state) => state.uiReducer.showCodeToEarn;

const uiReducer = uiSlice.reducer;

export default uiReducer;
