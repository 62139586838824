import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setUserData,
  setStudentTotals,
  setStudentDetail,
  setStudentProgressByClassroom,
  setStudentQr,
  setStudentProgressLoading,
} from 'store/Slice/userSlice';
import API from 'store/api';

export const getUserData = createAsyncThunk(
  'getUserData',
  async (_request, { dispatch, rejectWithValue }) => {
    let url = `/api/v1/students/${_request.studentId}`;
    try {
      const response = await API.get(url);
      dispatch(setUserData(response.data.data));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUserClassRooms = createAsyncThunk(
  'getUserClassRooms',
  async (id, { dispatch }) => {
    let url = `/api/v1/classroom/student/${id}`;
    try {
      const response = await API.get(url);
      return response?.data?.data;
    } catch (err) {
      return err;
    }
  },
);

export const getStudentTotals = createAsyncThunk(
  'getStudentTotals',
  async (id, { dispatch, rejectWithValue }) => {
    let url = `/api/v1/dashboard/student/totals/${id}`;
    try {
      const response = await API.get(url);
      dispatch(setStudentTotals(response?.data?.data));
      return response?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getStudentDetail = createAsyncThunk(
  'getStudentDetail',
  async (_request, { dispatch, rejectWithValue }) => {
    // const page = _request.pageIndex || 0;
    // const size = _request.pageSize || 10;
    const search = _request?.search || '';
    let url = `api/v1/dashboard/general/student/${_request?.id}`;
    try {
      if (search) {
        url += `?query=${search}`;
      }
      const response = await API.get(url);
      dispatch(setStudentDetail(response.data.data));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getStudentProgressByClassroom = createAsyncThunk(
  'getStudentProgressByClassroom',
  async (_request, { dispatch, rejectWithValue }) => {
    let url = `/api/v1/assignment/progress-by-classroom/${_request.id}`;

    const queryParams = {
      status: _request.status,
      page: (_request.page || 1).toString(),
      limit: (_request.limit || 10).toString(),
      search: _request.search || '',
    };

    url += `?${new URLSearchParams(queryParams).toString()}`;

    try {
      dispatch(setStudentProgressLoading(true));
      const response = await API.get(url);
      dispatch(setStudentProgressByClassroom(response?.data?.data));
      dispatch(setStudentProgressLoading(false));
      return response?.data;
    } catch (err) {
      if (err?.code !== 'ERR_CANCELED')
        dispatch(setStudentProgressLoading(false));
      return rejectWithValue(err.response.data);
    }
  },
);

export const getStudentQR = createAsyncThunk(
  'getStudentQR',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/api/v1/students/get-qr/${id}`);
      dispatch(setStudentQr(response?.data?.data));
      return response?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
