import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { correct } from 'asset';

export default function SuccessAnswer(props) {
  const { onClose, isReview } = props;

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={true} onClose={onClose}>
      <DialogContent>
        <Box textAlign={'center'}>
          <img
            src={correct}
            alt="correct"
            style={{ margin: 'auto', marginTop: '12px', marginBottom: '12px' }}
          />
          <Box>
            <Typography variant="h5" sx={{ color: '#000', fontWeight: '700' }}>
              Your answer is correct!!
            </Typography>
          </Box>
          <Button
            sx={{
              textTransform: 'none',
              width: '60%',
              background: '#C73E9D',
              color: '#FFFFFF',
              my: 5,
              '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
            }}
            onClick={onClose}
          >
            Next
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
