import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAvatarImages } from 'store/Slice/registerSlice';
import API from 'store/api';

export const getAvatarImages = createAsyncThunk(
  'getAvatarImages',
  async (_request, { dispatch, rejectWithValue }) => {
    let url = `/api/v1/user/avatar/images`;
    try {
      const response = await API.get(url);
      dispatch(setAvatarImages(response.data.data));
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.data);
    }
  },
);

export const selectAvatarImage = createAsyncThunk(
  'selectAvatarImage',
  async (_request, { rejectWithValue }) => {
    let url = `/api/v1/user/avatar/select`;
    try {
      const response = await API.post(url, {
        url: _request.url,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data);
    }
  },
);

export const uploadProfilePicture = createAsyncThunk(
  'uploadProfilePicture',
  async (data, { rejectWithValue }) => {
    let url = `/api/v1/user/profile/image`;
    try {
      const response = await API.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data);
    }
  },
);

export const addTemporarySchool = createAsyncThunk(
  'addTemporarySchool',
  async (data, { rejectWithValue }) => {
    let url = `/api/v1/user/add-school`;
    try {
      const response = await API.post(url, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data);
    }
  },
);

export const completeProfile = createAsyncThunk(
  'completeProfile',
  async (data, { rejectWithValue }) => {
    let url = `/api/v1/user/profile/complete`;
    try {
      const response = await API.post(url, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
