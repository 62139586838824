// ** MUI Imports
import Box from '@mui/material/Box';
import React, { useEffect, useRef } from 'react';
import STELeftCenter from './components/STELeftCenter';
import STELeftHeader from './components/STELeftHeader';
import STEFooter from './components/STEFooter';
import AcceptedInvitesHeader from './components/AcceptedInvitesHeader';
import Invitation from './components/Invitation';

// ** Icons Imports

// ** Components
// import Autocomplete from 'layouts/components/Autocomplete'

const tenData = Array.from({ length: 20 }, (_, i) => i + 1);

const ShareToEarn = (props) => {
  const ref = useRef(null);

  const chatContainerRef = useRef(null);
  const innerChatContainerRef = useRef(null);
  const invitesHeaderRef = useRef(null);

  const invitationsRef = useRef(null);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    const innerChatContainer = innerChatContainerRef.current;

    const invitations = invitationsRef.current;

    const invitesHeader = invitesHeaderRef.current;

    const handleHeightChange = () => {
      if (innerChatContainer && chatContainer) {
        const chatContainerHeight =
          chatContainer.getBoundingClientRect().height;
        innerChatContainer.style.setProperty(
          '--max-height',
          `${chatContainerHeight}px`,
        );
      }

      if (invitesHeader && invitations && innerChatContainer) {
        const innerChatContainerHeight =
          innerChatContainer.getBoundingClientRect().height;
        const invitesHeaderHeight =
          invitesHeader.getBoundingClientRect().height;
        invitations.style.setProperty(
          '--max-height',
          `calc(${innerChatContainerHeight - invitesHeaderHeight}px)`,
        );
      }
    };

    handleHeightChange();

    window.addEventListener('resize', handleHeightChange);

    return () => {
      window.removeEventListener('resize', handleHeightChange);
    };
  }, []);

  return (
    <div className="w-full h-full">
      <Box
        className="w-full bg-white p-5 rounded-3xl h-full grid gap-4"
        ref={ref}
      >
        <div
          className="overflow-hidden scrollbar-hidden grid relative xl:grid-cols-[2fr,1fr] gap-8"
          ref={chatContainerRef}
        >
          <div
            className="w-full space-y-2 max-h-[var(--max-height)] grid grid-rows-[auto,1fr,auto] overflow-y-auto px-4 scrollbar-hidden border border-[#00000033] rounded-2xl p-4 "
            ref={innerChatContainerRef}
          >
            <STELeftHeader />
            <STELeftCenter />
            <STEFooter />
          </div>
          <div
            className="w-full space-y-4 grid grid-rows-[auto,1fr] max-h-[var(--max-height)] overflow-y-auto px-4 scrollbar-hidden border border-[#00000033] rounded-2xl p-4 "
            ref={innerChatContainerRef}
          >
            <AcceptedInvitesHeader ref={invitesHeaderRef} />
            <div
              className="max-h-[var(--max-height)] overflow-y-auto scrollbar-hidden"
              ref={invitationsRef}
            >
              {tenData.map((_, i) => (
                <Invitation
                  key={i}
                  userName={`User ${i + 1}`}
                  status={i % 2 === 0 ? 'Accepted' : 'Failed'}
                />
              ))}
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ShareToEarn;
