const Index = ({ selectedCanvasObj }) => {
  return (
    <>

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="100%" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
<path fill='transparent' opacity="1.000000" stroke="none" 
	d="
M996.150024,1001.000000 
	C664.129333,1001.000000 332.633453,1001.000000 1.000000,1001.000000 
	C1.000000,834.381287 1.000000,667.762573 1.000000,501.143799 
	C1.000000,334.479218 1.000000,167.814621 1.000000,1.075015 
	C334.279175,1.075015 667.558350,1.075015 1000.918762,1.075015 
	C1000.918762,334.274994 1000.918762,667.550049 1000.918762,1001.000000 
	C999.559631,1001.000000 998.117249,1001.000000 996.150024,1001.000000 
M73.929878,644.021484 
	C73.929878,644.021484 73.865280,644.080200 74.006401,644.774780 
	C75.734238,647.164368 77.462082,649.553894 78.817940,652.706970 
	C76.621201,660.796875 77.295624,663.058594 83.869896,667.265747 
	C96.115494,675.101929 107.851761,683.715454 122.260605,687.758911 
	C134.666718,691.240295 146.734680,695.926819 158.993073,700.018555 
	C158.993073,700.018555 158.919418,700.028076 159.111649,700.461914 
	C159.297363,700.780945 159.569580,700.896973 160.604874,701.164368 
	C167.598419,703.451599 174.468658,706.278076 181.611694,707.911499 
	C195.653320,711.122620 209.922180,713.342102 223.959167,716.571106 
	C244.241272,721.236511 264.712555,723.709473 285.514557,724.078613 
	C296.058929,724.265625 306.579712,725.602051 317.125916,725.904053 
	C335.406891,726.427734 353.699341,726.551208 372.918335,726.976379 
	C395.569183,726.983093 418.221466,727.153259 440.870453,726.950745 
	C463.627594,726.747253 486.381378,726.172546 510.046570,725.870117 
	C517.964355,725.553711 526.389709,726.858398 533.690735,724.572205 
	C547.497742,720.248901 561.104980,716.617004 575.699219,717.807129 
	C577.098083,717.921265 578.608093,716.673523 580.490295,716.000854 
	C580.490295,716.000854 580.911682,716.073059 580.982849,716.952637 
	C581.288208,719.985657 581.593628,723.018677 582.444458,726.720825 
	C586.528381,726.814331 590.616577,727.067810 594.695068,726.958557 
	C599.176270,726.838501 603.648438,726.384521 608.784912,725.749512 
	C611.860413,726.158569 614.944702,726.966125 618.009766,726.898865 
	C625.924988,726.725098 633.833496,725.824585 641.741272,725.865967 
	C648.145264,725.899475 654.536316,727.026367 660.949097,727.376526 
	C663.957458,727.540649 667.003418,727.017334 670.684387,726.904541 
	C671.468872,726.544861 672.253357,726.185181 673.708740,726.245911 
	C675.830017,726.829529 677.955872,727.923340 680.071716,727.904358 
	C687.417419,727.838501 694.759583,727.386230 702.719360,726.973511 
	C703.472595,726.667114 704.225891,726.360779 705.101990,726.888428 
	C705.922485,727.256836 706.742554,727.943054 707.563599,727.944336 
	C715.724426,727.957397 723.885498,727.857300 732.664978,727.690613 
	C733.771423,727.143555 734.877869,726.596497 736.011169,726.900757 
	C737.346497,727.601379 738.697998,728.933105 740.014526,728.899414 
	C748.031250,728.694214 756.040100,728.178406 764.653320,727.670410 
	C765.761475,726.810547 766.869690,725.950745 768.057007,725.879517 
	C769.544250,726.581116 771.061829,727.944824 772.513977,727.878418 
	C783.216309,727.389282 793.921814,726.794250 804.589844,725.828918 
	C808.140076,725.507629 811.583496,724.006592 815.754517,723.007690 
	C816.824402,723.289795 817.894287,723.571899 819.847168,723.973450 
	C825.561523,722.930054 831.275818,721.886658 837.779663,720.958130 
	C838.837708,721.012756 839.895691,721.067383 841.384827,721.700378 
	C845.930664,720.475769 850.476440,719.251160 855.895935,717.963501 
	C863.457153,717.909058 870.483398,715.987366 875.250122,709.896484 
	C876.905334,707.781494 876.501099,704.054688 877.642334,700.753296 
	C879.800537,698.517090 881.958801,696.280945 884.037720,694.005188 
	C884.037720,694.005188 883.993164,694.081848 884.432678,693.894958 
	C884.758301,693.724731 884.894836,693.457886 885.073364,692.626892 
	C885.113220,692.457703 885.153015,692.288513 885.697876,691.783325 
	C885.755127,691.197205 885.812317,690.611084 886.003601,689.233337 
	C885.952393,688.165710 885.901184,687.098083 885.959229,685.270874 
	C885.557617,683.812805 885.156006,682.354736 884.939575,680.136597 
	C885.647217,676.781677 886.354858,673.426819 887.756897,669.557373 
	C887.831055,649.797119 887.922424,630.036865 887.932373,610.276611 
	C887.932922,609.208496 887.250793,608.140015 886.992615,606.449280 
	C886.721191,605.922974 886.449768,605.396729 886.040039,604.133850 
	C879.854980,598.902100 872.330750,598.593628 864.893616,598.954285 
	C849.401123,599.705750 833.930115,600.903564 818.450989,601.928345 
	C813.511841,602.255371 808.566589,602.524353 803.638489,602.976929 
	C789.520508,604.273315 775.423401,605.817932 761.292419,606.943054 
	C749.754944,607.861694 738.185059,608.412109 726.621521,608.954834 
	C710.570435,609.708191 694.507996,610.221069 678.457764,610.990784 
	C667.235229,611.528992 656.023193,612.295288 644.809875,613.013000 
	C629.811768,613.972839 614.819092,615.017456 599.819031,615.945190 
	C596.833008,616.129944 593.806519,615.733154 590.829468,615.978333 
	C575.377625,617.250671 559.941711,618.717346 544.488342,619.969116 
	C534.606873,620.769714 524.710693,621.429993 514.810364,621.947693 
	C499.925598,622.725952 485.036987,623.511353 470.139893,623.951721 
	C451.708221,624.496643 433.266205,624.691162 414.828766,625.043213 
	C414.498444,625.049500 414.169617,625.134705 412.934998,625.105408 
	C400.801178,625.063354 388.667236,624.985596 376.533691,625.023682 
	C374.994476,625.028503 373.457153,625.639954 370.991943,625.987305 
	C345.413910,625.652283 319.828064,625.592896 294.261200,624.865112 
	C281.604828,624.504761 268.958771,623.147522 256.353302,621.813293 
	C243.633652,620.466980 230.964600,618.631714 218.283432,616.935547 
	C205.762878,615.260803 193.236267,613.615662 180.746399,611.731567 
	C166.648788,609.605042 152.591843,607.209778 138.512238,604.962585 
	C126.052307,602.973816 113.623802,600.717224 101.107903,599.175842 
	C93.255325,598.208801 85.267509,598.154846 77.339005,598.130798 
	C75.553886,598.125366 73.763199,599.950500 71.378708,601.387817 
	C71.299423,602.620544 71.220131,603.853271 71.052200,606.007202 
	C71.344597,612.362671 71.636993,618.718079 72.016289,625.778259 
	C72.413773,626.846985 72.811249,627.915771 72.571899,629.153442 
	C72.368668,629.751282 72.165436,630.349060 71.342346,631.449097 
	C71.542389,633.982544 71.742432,636.515930 72.012100,639.749634 
	C72.324654,640.572388 72.637207,641.395142 72.934883,642.654053 
	C72.934883,642.654053 73.120804,643.048828 73.158783,643.551453 
	C73.434486,643.730408 73.710190,643.909424 73.929878,644.021484 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M413.840088,625.183105 
	C414.169617,625.134705 414.498444,625.049500 414.828766,625.043213 
	C433.266205,624.691162 451.708221,624.496643 470.139893,623.951721 
	C485.036987,623.511353 499.925598,622.725952 514.810364,621.947693 
	C524.710693,621.429993 534.606873,620.769714 544.488342,619.969116 
	C559.941711,618.717346 575.377625,617.250671 590.829468,615.978333 
	C593.806519,615.733154 596.833008,616.129944 599.819031,615.945190 
	C614.819092,615.017456 629.811768,613.972839 644.809875,613.013000 
	C656.023193,612.295288 667.235229,611.528992 678.457764,610.990784 
	C694.507996,610.221069 710.570435,609.708191 726.621521,608.954834 
	C738.185059,608.412109 749.754944,607.861694 761.292419,606.943054 
	C775.423401,605.817932 789.520508,604.273315 803.638489,602.976929 
	C808.566589,602.524353 813.511841,602.255371 818.450989,601.928345 
	C833.930115,600.903564 849.401123,599.705750 864.893616,598.954285 
	C872.330750,598.593628 879.854980,598.902100 885.772583,604.657227 
	C882.990234,606.753967 880.475342,608.327332 877.629456,609.914551 
	C876.856812,609.926270 876.415222,609.924072 875.773560,609.679626 
	C874.699829,609.931763 873.826111,610.426086 872.528442,610.933594 
	C869.402405,611.285400 866.700317,611.624023 863.754395,611.710327 
	C815.574402,618.421570 767.309509,621.605957 718.529785,624.025146 
	C714.955505,624.338196 711.836304,624.788818 708.702515,624.944641 
	C687.663940,625.990967 666.625732,627.059937 645.580078,627.946228 
	C633.131165,628.470581 620.667175,628.625854 608.213013,629.040161 
	C600.751831,629.288330 593.289917,629.610901 585.839966,630.083191 
	C566.167664,631.330261 546.503418,632.703247 526.832520,633.973938 
	C515.752869,634.689697 504.670319,635.376221 493.582397,635.945679 
	C472.878662,637.008850 452.174072,638.070496 431.462311,638.957153 
	C412.886353,639.752502 394.302948,640.400696 375.717926,640.948547 
	C359.932312,641.413757 344.140808,641.811462 328.349823,641.910706 
	C319.560333,641.965881 310.768463,641.166992 301.974365,641.077515 
	C286.981293,640.924927 271.985596,641.027954 256.541809,641.022156 
	C252.639252,640.686035 249.178482,640.413574 245.733780,640.005432 
	C233.581268,638.565552 221.386017,637.393066 209.296936,635.534058 
	C192.781219,632.994263 176.254318,630.368347 159.901367,626.966248 
	C135.755234,621.942810 111.692810,616.468811 87.743599,610.581177 
	C81.839317,609.129700 75.453827,607.028137 71.975082,600.932983 
	C73.763199,599.950500 75.553886,598.125366 77.339005,598.130798 
	C85.267509,598.154846 93.255325,598.208801 101.107903,599.175842 
	C113.623802,600.717224 126.052307,602.973816 138.512238,604.962585 
	C152.591843,607.209778 166.648788,609.605042 180.746399,611.731567 
	C193.236267,613.615662 205.762878,615.260803 218.283432,616.935547 
	C230.964600,618.631714 243.633652,620.466980 256.353302,621.813293 
	C268.958771,623.147522 281.604828,624.504761 294.261200,624.865112 
	C319.828064,625.592896 345.413910,625.652283 371.930786,625.993896 
	C384.615234,625.989685 396.361023,626.013733 408.106110,625.933228 
	C410.019073,625.920166 411.928833,625.444824 413.840088,625.183105 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M875.973572,609.921875 
	C876.415222,609.924072 876.856812,609.926270 878.005371,609.945984 
	C880.140686,609.638245 881.569153,609.313049 883.018799,609.287354 
	C883.360046,610.057922 883.680054,610.528931 883.970825,611.329346 
	C882.961975,612.773254 881.982239,613.887878 880.656799,615.035278 
	C877.240173,618.802856 872.355408,622.150635 871.459595,626.349487 
	C869.309814,636.425720 868.708923,646.882690 868.109375,657.222412 
	C867.678284,664.655762 866.903748,671.668518 860.619019,677.042725 
	C857.160339,677.725525 854.082581,678.365173 850.600098,679.003906 
	C845.348877,680.096252 840.490479,681.141357 835.670166,682.339417 
	C835.015930,682.502075 834.552063,683.430664 833.625610,684.004211 
	C832.502441,684.004211 831.753662,684.004578 830.665894,684.004883 
	C829.551270,684.337402 828.775635,684.669861 827.625671,685.002808 
	C826.502686,685.003784 825.754089,685.004333 824.576172,685.003235 
	C821.556458,685.340576 818.964417,685.668091 816.375854,686.021057 
	C811.582825,686.674561 806.791809,687.342896 801.666687,688.005249 
	C800.888916,688.005737 800.444519,688.006165 799.751709,687.740845 
	C791.333496,687.985901 783.163635,688.496582 774.662476,689.006958 
	C773.889404,689.006958 773.447693,689.007141 772.581543,689.003357 
	C768.104797,689.001343 764.052368,689.003357 759.666687,689.005066 
	C758.888916,689.004822 758.444458,689.004822 757.708252,688.707031 
	C752.277649,688.606140 747.138794,688.803040 741.600220,689.000000 
	C740.134277,688.999207 739.068054,688.998413 737.914673,688.607788 
	C734.549561,687.814392 731.271667,687.410889 727.550049,687.006531 
	C724.737549,687.006226 722.368835,687.006775 719.916809,686.620911 
	C718.339905,685.822449 716.869507,685.257446 715.348328,685.025818 
	C709.875305,684.192322 704.476562,683.366394 701.134094,678.097412 
	C697.393738,672.201111 693.538208,666.377747 689.715088,660.534302 
	C689.449036,660.127625 689.095886,659.712219 688.682861,659.477112 
	C678.595032,653.736145 668.523499,647.965088 658.362732,642.355530 
	C656.827393,641.507874 654.845215,641.502075 653.104553,640.988708 
	C641.071899,637.439758 628.864502,640.458191 616.753296,640.211304 
	C607.278381,640.018250 597.780090,641.900940 588.267944,642.376038 
	C576.635315,642.957153 568.282043,651.178284 557.974304,654.854797 
	C552.885742,656.669678 548.637512,660.748657 543.609924,662.846619 
	C534.078369,666.823975 524.341858,670.361206 514.535767,673.614868 
	C505.246338,676.697021 495.915619,680.424500 486.321136,681.636841 
	C472.035797,683.441895 457.502716,683.288208 443.074249,683.950134 
	C442.587799,683.972412 442.095245,683.771423 441.600830,683.722534 
	C435.846680,683.152649 429.883820,681.379333 424.386902,682.321228 
	C416.682037,683.641541 410.426086,680.059753 405.096436,676.794250 
	C398.775879,672.921570 391.883789,670.986389 385.441315,667.800659 
	C382.065735,666.131409 377.797729,666.236877 374.470764,664.506470 
	C369.421631,661.880432 364.851318,658.337769 360.055328,655.218750 
	C358.408844,654.147949 356.793671,652.561951 354.995087,652.276367 
	C348.645660,651.268188 342.239166,650.456848 335.824127,650.062561 
	C326.382141,649.482178 316.915253,649.288452 307.456787,649.003662 
	C303.686066,648.890137 299.896729,649.168640 296.138123,648.925171 
	C288.082855,648.403564 280.042023,647.658508 271.650391,647.036499 
	C270.203674,647.378784 269.101837,647.689392 267.917297,647.616272 
	C264.889740,647.155029 261.944855,647.077515 258.600189,647.000000 
	C257.134186,646.999207 256.068024,646.998352 254.948181,646.652954 
	C253.927673,645.874695 252.960831,645.440979 251.577881,645.006348 
	C249.774719,645.004639 248.387665,645.003784 246.769440,644.729492 
	C245.357193,644.305664 244.176132,644.155273 242.531250,644.004395 
	C230.691193,642.388489 219.301041,640.863892 207.943359,639.127014 
	C199.785583,637.879578 191.668701,636.362732 183.537994,634.940735 
	C172.344925,632.983276 161.156052,631.001892 149.541656,629.026367 
	C147.415756,629.006531 145.713593,628.991089 143.934464,628.606323 
	C136.235977,626.492920 128.614456,624.748901 120.606842,622.960938 
	C108.117851,619.325562 96.014969,615.734192 83.594749,612.113892 
	C82.853661,612.054932 82.429909,612.024902 81.977898,611.703125 
	C81.299507,611.268311 80.649376,611.125183 79.707619,610.949524 
	C78.949921,610.605835 78.483841,610.294617 77.960999,609.637878 
	C76.291389,608.501587 74.678551,607.710754 72.939186,606.676270 
	C72.255379,605.983765 71.698112,605.534912 71.140846,605.085999 
	C71.220131,603.853271 71.299423,602.620544 71.676895,601.160400 
	C75.453827,607.028137 81.839317,609.129700 87.743599,610.581177 
	C111.692810,616.468811 135.755234,621.942810 159.901367,626.966248 
	C176.254318,630.368347 192.781219,632.994263 209.296936,635.534058 
	C221.386017,637.393066 233.581268,638.565552 245.733780,640.005432 
	C249.178482,640.413574 252.639252,640.686035 256.630127,641.403931 
	C260.621094,642.523010 264.046448,643.691223 267.533112,643.918213 
	C280.763794,644.779541 294.013336,645.357239 307.257996,645.995422 
	C311.781342,646.213440 316.312195,646.270020 320.837708,646.448730 
	C333.430969,646.945862 346.019623,647.713745 358.617096,647.899536 
	C367.918732,648.036682 377.229797,647.324829 386.539581,647.178284 
	C397.136047,647.011536 407.737457,647.152893 418.333740,646.979004 
	C432.787506,646.741821 447.239868,646.383667 461.690063,645.978333 
	C469.980621,645.745789 478.268250,645.369629 486.552216,644.957458 
	C491.511841,644.710571 496.463196,644.287048 501.415924,643.912415 
	C518.573364,642.614868 535.728271,641.282349 552.887634,640.010376 
	C558.669922,639.581787 564.465454,639.335205 570.250000,638.934082 
	C588.907288,637.640442 607.544373,635.706116 626.222717,635.156189 
	C648.695435,634.494446 671.204407,634.832275 693.693481,635.096741 
	C700.775696,635.180054 707.849915,636.370361 714.917358,637.148193 
	C720.090027,637.717529 721.241028,643.002625 724.483887,645.479370 
	C730.477417,650.056946 733.146912,656.469910 736.897095,662.354309 
	C739.545776,666.510437 743.337891,670.301331 744.691833,674.831421 
	C746.591736,681.188232 750.968323,683.260193 756.328674,683.858826 
	C763.728699,684.685425 771.234558,685.080811 778.676025,684.895569 
	C789.035461,684.637695 799.418518,684.046143 809.715332,682.908142 
	C821.030884,681.657532 832.263672,679.648987 843.524902,677.921143 
	C847.984192,677.236938 850.330444,674.745728 851.224609,670.112122 
	C853.853149,656.490662 856.706055,642.904724 859.909973,629.408997 
	C860.508667,626.887085 862.982361,624.704407 864.869263,622.616943 
	C868.464478,618.639771 872.260559,614.844177 875.977539,610.710327 
	C875.979126,610.272278 875.976318,610.097107 875.973572,609.921875 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M738.001892,688.997559 
	C739.068054,688.998413 740.134277,688.999207 741.892761,689.296753 
	C747.723389,689.397217 752.861694,689.201050 758.000000,689.004883 
	C758.444458,689.004822 758.888916,689.004822 759.899780,689.276611 
	C761.701660,689.879944 763.183105,690.815186 764.126953,690.430725 
	C767.027039,689.249329 770.735413,693.395203 773.005920,689.007324 
	C773.447693,689.007141 773.889404,689.006958 774.714600,689.342651 
	C775.880737,690.039490 776.672668,690.730042 777.444641,690.708374 
	C782.993286,690.552307 788.550842,690.400818 794.076904,689.918396 
	C796.091553,689.742554 798.027954,688.669983 800.000061,688.006531 
	C800.444519,688.006165 800.888916,688.005737 801.955505,688.298645 
	C809.602234,688.018677 816.630310,687.481506 823.644043,686.797180 
	C824.151306,686.747681 824.554077,685.627319 825.005432,685.004883 
	C825.754089,685.004333 826.502686,685.003784 827.824768,685.257751 
	C829.267090,685.009827 830.135986,684.507324 831.004883,684.004883 
	C831.753662,684.004578 832.502441,684.004211 833.863037,684.262817 
	C836.422302,684.331299 838.517090,684.533325 840.286255,683.868530 
	C843.950806,682.491272 847.442261,680.653381 851.004883,679.004883 
	C854.082581,678.365173 857.160339,677.725525 860.995728,677.037964 
	C866.224365,675.627319 870.649902,674.459839 871.049622,668.414307 
	C871.896362,655.605896 872.845276,642.798096 874.129883,630.028076 
	C874.562378,625.728333 875.171387,621.141968 879.655029,618.459839 
	C880.493469,617.958130 880.572815,616.187500 881.002441,615.002441 
	C881.982239,613.887878 882.961975,612.773254 884.220215,611.157043 
	C884.654602,610.083374 884.810547,609.511230 885.082581,608.686401 
	C885.761292,607.979614 886.323853,607.525635 886.886475,607.071716 
	C887.250793,608.140015 887.932922,609.208496 887.932373,610.276611 
	C887.922424,630.036865 887.831055,649.797119 887.356262,669.563599 
	C886.935913,669.399902 886.916260,669.229858 886.935791,668.586304 
	C886.975037,651.889099 886.975037,635.665527 886.975037,619.441956 
	C886.306885,619.401184 885.638733,619.360413 884.970581,619.319641 
	C884.648865,620.792603 884.090454,622.257874 884.042725,623.739685 
	C883.642761,636.158264 883.611084,648.596985 882.838440,660.990417 
	C882.599792,664.818726 881.528198,669.942261 878.901306,671.998291 
	C873.160950,676.491028 866.145996,679.339355 859.723450,682.985901 
	C857.709900,684.129028 855.926025,685.676514 853.644043,687.085876 
	C840.375305,688.469910 827.500916,689.820496 814.623291,691.139954 
	C805.113647,692.114258 795.619751,693.444519 786.084106,693.921326 
	C762.769165,695.087158 739.434143,695.849976 715.988770,696.433533 
	C710.646790,695.246338 705.450623,693.967285 700.194580,693.634766 
	C683.324219,692.567505 666.438782,691.636414 649.545227,691.071655 
	C637.972778,690.684875 626.375793,691.095642 614.792236,690.951782 
	C609.253174,690.882996 603.717102,690.051514 598.184326,690.099548 
	C588.148315,690.186584 578.117554,690.755371 568.081421,690.975220 
	C549.964233,691.372192 531.843811,691.981140 513.726440,691.927673 
	C474.924805,691.813171 436.121887,691.493164 397.324097,690.935852 
	C377.409515,690.649719 357.496948,689.848572 337.598236,688.952393 
	C321.551483,688.229675 305.490082,687.407898 289.502808,685.908752 
	C273.394440,684.398254 257.361359,682.060791 241.310760,679.966980 
	C229.430695,678.417236 218.883652,674.409058 212.096069,663.617065 
	C209.273239,659.128906 206.286896,654.731445 203.160019,650.449646 
	C199.206589,645.036011 194.897690,640.182739 187.649933,638.890198 
	C178.053802,637.178955 168.509140,635.127014 159.022675,632.882080 
	C155.875977,632.137451 152.977402,630.344421 149.965393,629.030701 
	C161.156052,631.001892 172.344925,632.983276 183.537994,634.940735 
	C191.668701,636.362732 199.785583,637.879578 207.943359,639.127014 
	C219.301041,640.863892 230.691193,642.388489 242.588440,644.345337 
	C244.406509,644.792175 245.703552,644.897583 247.000610,645.002930 
	C248.387665,645.003784 249.774719,645.004639 251.632751,645.350220 
	C253.069763,646.129150 254.035797,646.563354 255.001831,646.997559 
	C256.068024,646.998352 257.134186,646.999207 258.880096,647.291016 
	C262.373199,647.721313 265.186615,647.860657 268.000000,648.000000 
	C269.101837,647.689392 270.203674,647.378784 271.739441,647.427490 
	C273.006012,648.187927 273.805328,648.830811 274.676697,648.950928 
	C280.241241,649.718567 285.883820,650.065186 291.372864,651.179871 
	C294.514404,651.817932 297.990753,652.994751 300.314362,655.057617 
	C306.937775,660.937866 312.888306,667.570435 319.424164,673.557007 
	C322.904572,676.744873 326.704132,680.752930 330.958984,681.701111 
	C342.363739,684.242310 354.049927,685.781555 365.703705,686.866211 
	C376.099365,687.833801 386.600067,687.872253 397.056366,687.924133 
	C404.932190,687.963196 412.810120,687.344727 420.689209,687.082886 
	C423.348999,686.994568 426.018951,687.020325 428.677887,687.137939 
	C433.891388,687.368469 439.103821,687.990356 444.310516,687.928772 
	C454.846802,687.804138 465.376953,687.173218 475.913269,687.040955 
	C490.427643,686.858826 504.950867,687.183289 519.460083,686.848816 
	C533.711792,686.520264 547.946167,685.504150 562.196350,685.037659 
	C577.398376,684.539856 592.614441,684.463196 607.815063,683.935425 
	C614.182251,683.714355 620.523132,682.778015 626.880737,682.221069 
	C629.723633,681.972107 632.612305,681.486206 635.421875,681.743713 
	C649.651123,683.047546 663.849792,684.689148 678.081970,685.955872 
	C687.973938,686.836243 697.896057,687.465637 707.818420,687.873352 
	C711.860229,688.039429 715.938416,687.322510 720.000061,687.007324 
	C722.368835,687.006775 724.737549,687.006226 727.638916,687.395630 
	C731.448242,688.189514 734.725098,688.593567 738.001892,688.997559 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M371.987000,726.842529 
	C353.699341,726.551208 335.406891,726.427734 317.125916,725.904053 
	C306.579712,725.602051 296.058929,724.265625 285.514557,724.078613 
	C264.712555,723.709473 244.241272,721.236511 223.959167,716.571106 
	C209.922180,713.342102 195.653320,711.122620 181.611694,707.911499 
	C174.468658,706.278076 167.598419,703.451599 160.148804,700.800903 
	C159.473907,700.227356 159.216141,700.090942 158.919418,700.028076 
	C158.919418,700.028076 158.993073,700.018555 158.986862,699.668335 
	C156.277740,695.523010 153.525528,691.761353 150.911743,687.905823 
	C150.361725,687.094421 150.319962,685.938599 150.404480,684.972046 
	C152.512421,685.617310 154.259888,686.231934 156.156586,687.125854 
	C156.891602,687.587646 157.477356,687.770142 158.034195,687.971924 
	C158.005280,687.991272 158.012146,687.922058 158.213959,688.217041 
	C166.487564,691.317017 174.447769,694.528687 182.670120,696.784729 
	C188.200012,698.302002 194.100723,698.467712 200.220413,699.221558 
	C203.780487,700.159607 206.895477,701.499451 210.140594,702.006592 
	C232.049789,705.430603 253.892685,709.720947 275.939362,711.704773 
	C300.537750,713.918213 325.329773,713.975037 350.036011,715.006409 
	C351.667450,715.074463 353.286194,715.446655 354.985840,716.072144 
	C357.814758,717.615173 360.779236,718.428833 363.269257,719.996155 
	C366.379303,721.953979 369.098846,724.532166 371.987000,726.842529 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M156.007355,686.846558 
	C154.259888,686.231934 152.512421,685.617310 150.137802,684.716797 
	C143.091705,682.282959 136.672760,680.134949 129.987885,677.732788 
	C121.152687,673.962097 112.583420,670.445618 104.006119,666.960327 
	C103.998100,666.991516 104.062248,666.985596 103.873276,666.749390 
	C103.119484,666.305481 102.554672,666.097656 101.773926,665.620605 
	C96.985275,663.030151 92.298813,660.903687 87.878731,658.321289 
	C84.793541,656.518677 82.073204,654.091614 79.189911,651.943481 
	C77.462082,649.553894 75.734238,647.164368 74.288231,644.357239 
	C75.047821,643.914795 75.525574,643.889893 76.151352,644.138916 
	C76.869797,644.604797 77.440216,644.796814 78.220657,645.247559 
	C81.270180,647.358765 84.109673,649.211365 87.014999,651.402344 
	C92.707031,654.507935 98.333244,657.275024 104.295059,660.047119 
	C105.077477,660.047302 105.524277,660.042542 105.946121,660.065308 
	C105.921158,660.092773 105.909042,660.166138 105.935257,660.525574 
	C108.671219,662.450134 111.266251,664.272339 114.108353,665.540955 
	C144.221161,678.981384 175.625427,688.700684 207.490952,696.818787 
	C238.830688,704.802979 270.860565,709.041504 303.111694,710.839111 
	C327.989807,712.225647 352.937866,712.802002 377.858154,712.901062 
	C445.682678,713.170654 513.509033,712.962463 581.334534,713.039612 
	C589.794922,713.049255 598.252136,713.791870 606.715149,713.928040 
	C630.837097,714.316040 654.961243,714.566223 679.544678,714.893677 
	C691.336426,714.929321 702.668091,714.941833 714.286743,715.257568 
	C719.424438,715.635315 724.275085,715.709778 729.549438,715.811218 
	C744.560547,715.222717 759.151855,714.685181 773.734192,713.967224 
	C785.493958,713.388245 797.326965,713.243103 808.977661,711.742432 
	C827.830078,709.314209 846.636475,706.328491 865.293640,702.708557 
	C870.814331,701.637390 875.767761,697.642334 881.290466,694.858337 
	C882.438904,694.501282 883.277954,694.273071 884.117065,694.044800 
	C881.958801,696.280945 879.800537,698.517090 877.008118,701.018555 
	C869.219727,703.585388 862.065613,705.887024 854.517090,708.178284 
	C849.719177,709.075439 845.315552,709.983093 840.499634,710.917603 
	C838.700256,711.004211 837.313110,711.063965 835.495361,711.085205 
	C829.734009,711.736633 824.403259,712.426514 818.639038,713.096436 
	C816.760437,713.133362 815.315186,713.190308 813.461182,713.198853 
	C811.434631,713.424561 809.826721,713.824463 808.197510,713.952820 
	C794.807922,715.008057 781.414368,716.012695 767.614502,717.010986 
	C766.119507,717.022766 765.032043,717.056213 763.481445,717.054443 
	C754.024780,717.365723 745.031250,717.712158 735.627808,718.037231 
	C734.124329,718.049744 733.030823,718.083679 731.474121,718.078247 
	C722.365662,718.074280 713.720276,718.109680 704.660645,718.103394 
	C703.139526,718.088989 702.032715,718.116211 700.463623,718.103088 
	C691.663269,718.035034 683.325073,718.007324 674.572388,717.964905 
	C672.775085,717.960754 671.392273,717.971313 669.572815,717.939819 
	C666.661133,717.650269 664.188843,717.220703 661.710632,717.183411 
	C634.778198,716.777893 607.844788,716.433838 580.911682,716.073059 
	C580.911682,716.073059 580.490295,716.000854 579.854553,715.984375 
	C563.429321,715.760620 547.639771,715.553406 531.396790,715.278320 
	C495.871368,715.221863 460.799469,715.231873 425.727539,715.251770 
	C424.733521,715.252319 423.740112,715.395264 422.745483,715.410278 
	C408.196442,715.628845 393.647278,715.842346 379.097809,716.029541 
	C378.454590,716.037781 377.808228,715.669617 377.162872,715.668030 
	C369.745575,715.649780 362.328186,715.668457 354.910828,715.677307 
	C353.286194,715.446655 351.667450,715.074463 350.036011,715.006409 
	C325.329773,713.975037 300.537750,713.918213 275.939362,711.704773 
	C253.892685,709.720947 232.049789,705.430603 210.140594,702.006592 
	C206.895477,701.499451 203.780487,700.159607 199.858246,699.108276 
	C191.601913,697.031311 184.084564,695.080994 176.585892,693.061401 
	C170.383163,691.390869 164.202545,689.638306 158.012146,687.922058 
	C158.012146,687.922058 158.005280,687.991272 157.895828,687.692810 
	C157.193375,687.211731 156.600357,687.029114 156.007355,686.846558 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M884.077393,694.025024 
	C883.277954,694.273071 882.438904,694.501282 880.890503,694.855469 
	C875.169556,696.641602 870.271790,699.050720 865.126648,699.833740 
	C841.299988,703.459534 817.465088,707.196289 793.512573,709.772034 
	C777.558350,711.487610 761.404053,711.773071 745.334045,711.876038 
	C724.400635,712.010132 703.461609,711.347229 682.525574,710.994080 
	C664.086060,710.683105 645.647705,710.298645 627.207581,710.035278 
	C625.477539,710.010559 623.738464,710.622498 621.589478,710.949707 
	C619.794739,710.957031 618.414062,710.953552 616.931519,710.559509 
	C614.984497,709.781677 613.140137,709.059570 611.294495,709.056335 
	C571.664490,708.987427 532.033936,709.125427 492.404572,708.961426 
	C448.152802,708.778320 403.897858,708.601746 359.652771,707.893372 
	C337.454620,707.537903 315.251343,706.367676 293.095642,704.887695 
	C277.450043,703.842529 261.814453,702.117004 246.291351,699.882507 
	C216.904678,695.652405 188.256607,688.122314 160.087585,678.834534 
	C145.955124,674.174866 131.995468,668.991211 117.594391,664.034119 
	C116.505127,663.988831 115.779129,663.953918 114.981339,663.578613 
	C112.594505,662.171021 110.279465,661.103638 107.621048,660.035583 
	C106.821457,660.078613 106.365250,660.122375 105.909042,660.166138 
	C105.909042,660.166138 105.921158,660.092773 105.900391,659.701599 
	C104.597519,658.862732 103.315407,658.415039 101.840424,657.719482 
	C100.758469,656.988037 99.869385,656.504395 98.988861,655.685669 
	C98.995125,654.903870 98.992828,654.457214 99.288414,653.983521 
	C100.058220,653.643188 100.530159,653.329834 101.356110,653.061523 
	C104.459831,654.417175 107.209534,655.727722 110.013489,657.356201 
	C110.723824,658.095398 111.379906,658.516663 112.400787,658.952759 
	C113.495354,658.993042 114.225121,659.018494 115.024170,659.382690 
	C117.396965,660.494263 119.700485,661.267212 122.318802,662.065430 
	C123.408485,662.407104 124.183365,662.723572 124.999557,663.328125 
	C125.694870,663.754761 126.348854,663.893311 127.349396,664.038330 
	C128.780350,664.380127 129.864731,664.715393 130.993347,665.337463 
	C131.693405,665.768188 132.349243,665.912048 133.319519,666.078125 
	C134.406143,666.415466 135.178345,666.730652 136.024719,667.399902 
	C145.018890,671.108704 153.778366,675.007019 162.894058,677.699158 
	C179.237839,682.525940 195.696365,687.051636 212.285583,690.941162 
	C243.927200,698.359985 276.203705,701.610352 308.542755,703.874634 
	C327.407806,705.195557 346.352936,705.786255 365.267426,705.914978 
	C442.366638,706.439575 519.468140,706.609253 596.568237,707.025330 
	C616.188416,707.131226 635.807434,707.608948 655.424805,708.020691 
	C664.549988,708.212158 673.671631,708.974670 682.791870,708.924622 
	C713.074890,708.758545 743.374390,708.865784 773.632202,707.832581 
	C793.282654,707.161560 812.889893,704.875610 832.481873,702.958679 
	C847.102905,701.528015 861.637634,699.293213 875.051697,692.793213 
	C878.851257,690.952026 882.261292,688.306885 885.849976,686.030457 
	C885.901184,687.098083 885.952393,688.165710 885.636963,689.661438 
	C885.244446,690.766174 885.218689,691.442749 885.192871,692.119385 
	C885.153015,692.288513 885.113220,692.457703 884.757324,692.964233 
	C884.242737,693.533447 884.093384,693.793518 883.993164,694.081848 
	C883.993164,694.081848 884.037720,694.005188 884.077393,694.025024 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M885.904602,685.650635 
	C882.261292,688.306885 878.851257,690.952026 875.051697,692.793213 
	C861.637634,699.293213 847.102905,701.528015 832.481873,702.958679 
	C812.889893,704.875610 793.282654,707.161560 773.632202,707.832581 
	C743.374390,708.865784 713.074890,708.758545 682.791870,708.924622 
	C673.671631,708.974670 664.549988,708.212158 655.424805,708.020691 
	C635.807434,707.608948 616.188416,707.131226 596.568237,707.025330 
	C519.468140,706.609253 442.366638,706.439575 365.267426,705.914978 
	C346.352936,705.786255 327.407806,705.195557 308.542755,703.874634 
	C276.203705,701.610352 243.927200,698.359985 212.285583,690.941162 
	C195.696365,687.051636 179.237839,682.525940 162.894058,677.699158 
	C153.778366,675.007019 145.018890,671.108704 136.414703,667.387817 
	C139.546158,667.986511 142.322372,669.091858 145.183594,669.894104 
	C169.258286,676.644104 193.173737,684.082764 217.493866,689.795410 
	C234.489075,693.787598 251.983963,695.919617 269.366394,697.920593 
	C286.616516,699.906311 304.002197,700.731201 321.336609,701.948547 
	C323.777466,702.119995 326.273041,701.395630 328.729431,701.481506 
	C341.165253,701.916199 353.595764,702.909729 366.029449,702.931396 
	C442.690155,703.064636 519.351257,702.989014 596.385559,702.985840 
	C597.505920,702.985840 598.252991,702.982910 599.289795,703.282715 
	C600.056152,703.713684 600.529358,703.939819 601.009644,703.956055 
	C621.390991,704.646240 641.768799,705.653320 662.155945,705.908875 
	C689.188721,706.247803 716.231995,706.249756 743.264832,705.917358 
	C763.152771,705.672791 783.039429,704.833984 802.909912,703.903198 
	C810.803101,703.533386 818.684143,702.420898 826.511841,701.252258 
	C836.946411,699.694458 847.342346,697.854065 857.719727,695.943970 
	C866.554443,694.317749 875.005615,691.581970 881.927856,685.531128 
	C882.708130,684.849121 882.562256,683.107544 883.015137,681.633789 
	C883.707703,681.239014 884.231079,681.067871 884.754395,680.896667 
	C885.156006,682.354736 885.557617,683.812805 885.904602,685.650635 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M354.985840,716.072144 
	C362.328186,715.668457 369.745575,715.649780 377.162872,715.668030 
	C377.808228,715.669617 378.454590,716.037781 379.097809,716.029541 
	C393.647278,715.842346 408.196442,715.628845 422.745483,715.410278 
	C423.740112,715.395264 424.733521,715.252319 425.727539,715.251770 
	C460.799469,715.231873 495.871368,715.221863 531.427979,715.648193 
	C531.667908,717.031189 531.186340,717.984375 531.217651,718.920471 
	C531.397278,724.301636 528.506531,725.391113 523.723694,725.150452 
	C518.883850,724.906860 514.000793,725.521667 509.136658,725.760498 
	C486.381378,726.172546 463.627594,726.747253 440.870453,726.950745 
	C418.221466,727.153259 395.569183,726.983093 372.452667,726.909424 
	C369.098846,724.532166 366.379303,721.953979 363.269257,719.996155 
	C360.779236,718.428833 357.814758,717.615173 354.985840,716.072144 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M71.096527,605.546631 
	C71.698112,605.534912 72.255379,605.983765 73.058655,606.995483 
	C74.875687,608.366638 76.446732,609.174988 78.017761,609.983398 
	C78.483841,610.294617 78.949921,610.605835 79.867447,611.205200 
	C80.881310,611.660522 81.443726,611.827698 82.006149,611.994873 
	C82.429909,612.024902 82.853661,612.054932 83.671318,612.440918 
	C91.559303,618.458008 95.446892,626.806274 100.237282,634.484558 
	C103.013985,638.935181 105.075035,643.897949 110.753769,645.851929 
	C125.469818,650.915710 139.926666,656.806824 154.812256,661.286987 
	C178.885864,668.532471 203.106537,675.364502 227.488205,681.481934 
	C241.237167,684.931641 255.358231,687.220276 269.446106,688.913452 
	C286.028534,690.906433 302.742126,691.933044 319.426086,692.915710 
	C332.024841,693.657776 344.666962,693.654175 357.287781,694.048035 
	C358.200836,694.076538 359.097229,694.639648 360.005554,695.185242 
	C360.009705,695.416321 360.031982,695.877075 359.566010,695.947021 
	C350.461792,695.739014 341.806335,695.716736 333.188446,695.136414 
	C304.726746,693.219727 276.148254,692.167053 247.868500,688.707336 
	C227.043594,686.159607 206.615952,680.364563 185.791016,675.728516 
	C184.864410,675.309875 184.158066,675.179138 183.217377,675.133301 
	C182.643295,675.235229 182.320404,675.169495 181.795288,674.732178 
	C180.869507,674.311829 180.162750,674.180481 179.103088,674.117432 
	C176.511429,673.497864 174.272644,672.809937 171.987900,671.757812 
	C171.179031,670.849426 170.490295,670.105286 169.642212,669.790894 
	C153.637299,663.855957 137.428268,658.423889 121.643852,651.954773 
	C104.633278,644.983154 86.695053,639.354004 73.952621,624.751770 
	C73.714195,624.478577 72.622803,624.949707 71.929382,625.073547 
	C71.636993,618.718079 71.344597,612.362671 71.096527,605.546631 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M79.003922,652.325195 
	C82.073204,654.091614 84.793541,656.518677 87.878731,658.321289 
	C92.298813,660.903687 96.985275,663.030151 101.921837,665.903564 
	C102.877876,666.632324 103.470062,666.808960 104.062256,666.985596 
	C104.062248,666.985596 103.998100,666.991516 104.023659,667.314819 
	C111.641739,673.980347 120.920952,676.051575 130.253815,677.987000 
	C136.672760,680.134949 143.091705,682.282959 149.777344,684.686218 
	C150.319962,685.938599 150.361725,687.094421 150.911743,687.905823 
	C153.525528,691.761353 156.277740,695.523010 158.966400,699.699341 
	C146.734680,695.926819 134.666718,691.240295 122.260605,687.758911 
	C107.851761,683.715454 96.115494,675.101929 83.869896,667.265747 
	C77.295624,663.058594 76.621201,660.796875 79.003922,652.325195 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M580.947266,716.512817 
	C607.844788,716.433838 634.778198,716.777893 661.710632,717.183411 
	C664.188843,717.220703 666.661133,717.650269 669.571777,718.385742 
	C670.015625,721.517700 670.023987,724.161682 670.032349,726.805603 
	C667.003418,727.017334 663.957458,727.540649 660.949097,727.376526 
	C654.536316,727.026367 648.145264,725.899475 641.741272,725.865967 
	C633.833496,725.824585 625.924988,726.725098 618.009766,726.898865 
	C614.944702,726.966125 611.860413,726.158569 607.977661,725.888184 
	C598.746582,726.035156 590.322815,726.043396 581.898987,726.051697 
	C581.593628,723.018677 581.288208,719.985657 580.947266,716.512817 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M884.846985,680.516602 
	C884.231079,681.067871 883.707703,681.239014 882.693481,681.694458 
	C880.796265,682.932800 879.390015,683.886902 877.579102,684.890564 
	C874.279724,685.626099 871.418579,686.561951 868.484680,686.956299 
	C852.539368,689.099365 836.628479,691.795105 820.607849,693.017944 
	C789.656067,695.380554 758.659058,697.457703 727.641968,698.554138 
	C705.545532,699.335266 683.384583,698.382385 661.254028,698.087769 
	C637.790527,697.775330 614.328796,697.332153 590.866760,696.920898 
	C589.548035,696.897827 588.233459,696.645630 586.928833,696.253662 
	C586.978027,695.678894 586.991760,695.349670 587.451538,695.003540 
	C599.248047,694.995300 610.574890,694.998779 621.901794,695.011902 
	C633.894775,695.025757 645.891846,694.858643 657.879883,695.110840 
	C677.292053,695.519348 696.698364,696.205933 716.107178,696.775024 
	C739.434143,695.849976 762.769165,695.087158 786.084106,693.921326 
	C795.619751,693.444519 805.113647,692.114258 814.623291,691.139954 
	C827.500916,689.820496 840.375305,688.469910 854.067139,687.085632 
	C861.752930,686.601318 868.186707,685.307800 873.486572,680.220642 
	C877.671631,676.203491 882.406128,672.758789 886.896606,669.059875 
	C886.916260,669.229858 886.935913,669.399902 887.009033,669.820923 
	C886.354858,673.426819 885.647217,676.781677 884.846985,680.516602 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M768.021973,717.032715 
	C781.414368,716.012695 794.807922,715.008057 808.197510,713.952820 
	C809.826721,713.824463 811.434631,713.424561 813.504822,713.615356 
	C814.330078,717.068481 814.702942,720.056702 815.075745,723.044922 
	C811.583496,724.006592 808.140076,725.507629 804.589844,725.828918 
	C793.921814,726.794250 783.216309,727.389282 772.513977,727.878418 
	C771.061829,727.944824 769.544250,726.581116 768.025024,725.035278 
	C768.002747,721.804993 768.012390,719.418823 768.021973,717.032715 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M736.037781,718.058594 
	C745.031250,717.712158 754.024780,717.365723 763.500366,717.499817 
	C764.005432,721.242371 764.028503,724.504333 764.051575,727.766296 
	C756.040100,728.178406 748.031250,728.694214 740.014526,728.899414 
	C738.697998,728.933105 737.346497,727.601379 735.998169,726.029053 
	C736.002686,722.791077 736.020264,720.424866 736.037781,718.058594 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M71.972839,625.425903 
	C72.622803,624.949707 73.714195,624.478577 73.952621,624.751770 
	C86.695053,639.354004 104.633278,644.983154 121.643852,651.954773 
	C137.428268,658.423889 153.637299,663.855957 169.642212,669.790894 
	C170.490295,670.105286 171.179031,670.849426 171.597260,671.715393 
	C165.399689,670.325134 159.450638,668.884827 153.710327,666.854370 
	C126.851311,657.354370 99.417305,649.093323 75.902550,631.594910 
	C74.912910,630.550903 74.060829,629.767700 73.208740,628.984497 
	C72.811249,627.915771 72.413773,626.846985 71.972839,625.425903 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M705.074951,718.145020 
	C713.720276,718.109680 722.365662,718.074280 731.497803,718.515930 
	C732.005310,721.924988 732.025879,724.857056 732.046448,727.789062 
	C723.885498,727.857300 715.724426,727.957397 707.563599,727.944336 
	C706.742554,727.943054 705.922485,727.256836 705.044678,726.027710 
	C705.016602,722.826355 705.045776,720.485657 705.074951,718.145020 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M674.986938,717.979614 
	C683.325073,718.007324 691.663269,718.035034 700.491211,718.530945 
	C701.354980,721.693115 701.729004,724.387024 702.103088,727.080994 
	C694.759583,727.386230 687.417419,727.838501 680.071716,727.904358 
	C677.955872,727.923340 675.830017,726.829529 673.612305,725.761536 
	C674.006226,722.844666 674.496582,720.412170 674.986938,717.979614 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M509.591614,725.815308 
	C514.000793,725.521667 518.883850,724.906860 523.723694,725.150452 
	C528.506531,725.391113 531.397278,724.301636 531.217651,718.920471 
	C531.186340,717.984375 531.667908,717.031189 531.881470,715.716003 
	C547.639771,715.553406 563.429321,715.760620 579.642700,716.012939 
	C578.608093,716.673523 577.098083,717.921265 575.699219,717.807129 
	C561.104980,716.617004 547.497742,720.248901 533.690735,724.572205 
	C526.389709,726.858398 517.964355,725.553711 509.591614,725.815308 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M854.911438,708.188721 
	C862.065613,705.887024 869.219727,703.585388 876.705688,701.173950 
	C876.501099,704.054688 876.905334,707.781494 875.250122,709.896484 
	C870.483398,715.987366 863.457153,717.909058 855.455811,717.542114 
	C854.980896,714.143372 854.946167,711.166016 854.911438,708.188721 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M819.072510,713.116394 
	C824.403259,712.426514 829.734009,711.736633 835.519043,711.510986 
	C836.312317,714.931213 836.651245,717.887268 836.990173,720.843262 
	C831.275818,721.886658 825.561523,722.930054 819.430481,723.468018 
	C819.033386,719.680542 819.052979,716.398499 819.072510,713.116394 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M854.517090,708.178223 
	C854.946167,711.166016 854.980896,714.143372 855.018921,717.573608 
	C850.476440,719.251160 845.930664,720.475769 841.195496,720.947937 
	C840.974792,717.093872 840.943359,713.992310 840.911926,710.890686 
	C845.315552,709.983093 849.719177,709.075439 854.517090,708.178223 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M76.003326,643.864990 
	C75.525574,643.889893 75.047821,643.914795 74.217667,644.009949 
	C73.865280,644.080200 73.929878,644.021484 73.878906,643.830444 
	C73.692368,643.322571 73.456657,643.125732 73.120804,643.048828 
	C73.120804,643.048828 72.934883,642.654053 72.971863,642.075623 
	C72.653381,640.681274 72.297928,639.865295 71.942474,639.049377 
	C71.742432,636.515930 71.542389,633.982544 71.906784,631.413574 
	C73.026230,632.594177 73.581238,633.810425 74.133423,635.346558 
	C75.094444,636.699707 76.058304,637.732971 77.124390,639.089600 
	C78.133354,639.953369 79.040085,640.493835 79.986633,641.373352 
	C81.023659,642.463562 82.020866,643.214722 83.194550,644.235229 
	C84.543236,645.028687 85.715462,645.552856 86.963341,646.412231 
	C89.028954,647.825989 91.018913,648.904419 93.199783,650.244202 
	C94.218834,650.722168 95.046959,650.938843 95.789894,651.387512 
	C95.804939,652.410583 95.905182,653.201599 95.988373,654.011108 
	C95.971321,654.029541 95.936172,654.065491 95.563759,654.050049 
	C92.443954,653.044434 89.696556,652.054199 86.949165,651.063965 
	C84.109673,649.211365 81.270180,647.358765 78.192108,644.958862 
	C77.303452,644.229309 76.653389,644.047180 76.003326,643.864990 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M818.639038,713.096436 
	C819.052979,716.398499 819.033386,719.680542 818.989014,723.408325 
	C817.894287,723.571899 816.824402,723.289795 815.415161,723.026306 
	C814.702942,720.056702 814.330078,717.068481 813.913635,713.663757 
	C815.315186,713.190308 816.760437,713.133362 818.639038,713.096436 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M840.499634,710.917603 
	C840.943359,713.992310 840.974792,717.093872 840.979980,720.658752 
	C839.895691,721.067383 838.837708,721.012756 837.384888,720.900696 
	C836.651245,717.887268 836.312317,714.931213 835.949707,711.549438 
	C837.313110,711.063965 838.700256,711.004211 840.499634,710.917603 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M413.387543,625.144287 
	C411.928833,625.444824 410.019073,625.920166 408.106110,625.933228 
	C396.361023,626.013733 384.615234,625.989685 372.394318,625.985474 
	C373.457153,625.639954 374.994476,625.028503 376.533691,625.023682 
	C388.667236,624.985596 400.801178,625.063354 413.387543,625.144287 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M674.572388,717.964966 
	C674.496582,720.412170 674.006226,722.844666 673.276855,725.551331 
	C672.253357,726.185181 671.468872,726.544861 670.358398,726.855103 
	C670.023987,724.161682 670.015625,721.517700 670.008423,718.427856 
	C671.392273,717.971313 672.775085,717.960754 674.572388,717.964966 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M764.352417,727.718384 
	C764.028503,724.504333 764.005432,721.242371 763.963501,717.535034 
	C765.032043,717.056213 766.119507,717.022766 767.614502,717.010986 
	C768.012390,719.418823 768.002747,721.804993 767.985474,724.640991 
	C766.869690,725.950745 765.761475,726.810547 764.352417,727.718384 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M732.355713,727.739868 
	C732.025879,724.857056 732.005310,721.924988 731.960999,718.555298 
	C733.030823,718.083679 734.124329,718.049744 735.627808,718.037231 
	C736.020264,720.424866 736.002686,722.791077 735.984741,725.603394 
	C734.877869,726.596497 733.771423,727.143555 732.355713,727.739868 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M702.411255,727.027222 
	C701.729004,724.387024 701.354980,721.693115 700.953430,718.571289 
	C702.032715,718.116211 703.139526,718.088989 704.660645,718.103394 
	C705.045776,720.485657 705.016602,722.826355 704.983276,725.610718 
	C704.225891,726.360779 703.472595,726.667114 702.411255,727.027222 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M582.171753,726.386230 
	C590.322815,726.043396 598.746582,726.035156 607.647461,726.051514 
	C603.648438,726.384521 599.176270,726.838501 594.695068,726.958557 
	C590.616577,727.067810 586.528381,726.814331 582.171753,726.386230 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M886.939575,606.760498 
	C886.323853,607.525635 885.761292,607.979614 884.754944,608.698120 
	C883.873291,608.971008 883.435425,608.979431 882.997559,608.987793 
	C881.569153,609.313049 880.140686,609.638245 878.336365,609.932068 
	C880.475342,608.327332 882.990234,606.753967 885.841736,605.025513 
	C886.449768,605.396729 886.721191,605.922974 886.939575,606.760498 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M74.136238,635.026611 
	C73.581238,633.810425 73.026230,632.594177 72.216721,631.162476 
	C72.165436,630.349060 72.368668,629.751282 72.890320,629.068970 
	C74.060829,629.767700 74.912910,630.550903 75.889587,631.956543 
	C75.388199,633.394836 74.762215,634.210693 74.136238,635.026611 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M885.445374,691.951355 
	C885.218689,691.442749 885.244446,690.766174 885.569885,690.057251 
	C885.812317,690.611084 885.755127,691.197205 885.445374,691.951355 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M71.977280,639.399536 
	C72.297928,639.865295 72.653381,640.681274 72.979301,641.857544 
	C72.637207,641.395142 72.324654,640.572388 71.977280,639.399536 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M884.212891,693.988403 
	C884.093384,693.793518 884.242737,693.533447 884.641846,693.197998 
	C884.894836,693.457886 884.758301,693.724731 884.212891,693.988403 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M159.015533,700.244995 
	C159.216141,700.090942 159.473907,700.227356 159.810486,700.623657 
	C159.569580,700.896973 159.297363,700.780945 159.015533,700.244995 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M73.139793,643.300171 
	C73.456657,643.125732 73.692368,643.322571 73.906914,643.863892 
	C73.710190,643.909424 73.434486,643.730408 73.139793,643.300171 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M875.973145,610.973145 
	C872.260559,614.844177 868.464478,618.639771 864.869263,622.616943 
	C862.982361,624.704407 860.508667,626.887085 859.909973,629.408997 
	C856.706055,642.904724 853.853149,656.490662 851.224609,670.112122 
	C850.330444,674.745728 847.984192,677.236938 843.524902,677.921143 
	C832.263672,679.648987 821.030884,681.657532 809.715332,682.908142 
	C799.418518,684.046143 789.035461,684.637695 778.676025,684.895569 
	C771.234558,685.080811 763.728699,684.685425 756.328674,683.858826 
	C750.968323,683.260193 746.591736,681.188232 744.691833,674.831421 
	C743.337891,670.301331 739.545776,666.510437 736.897095,662.354309 
	C733.146912,656.469910 730.477417,650.056946 724.483887,645.479370 
	C721.241028,643.002625 720.090027,637.717529 714.917358,637.148193 
	C707.849915,636.370361 700.775696,635.180054 693.693481,635.096741 
	C671.204407,634.832275 648.695435,634.494446 626.222717,635.156189 
	C607.544373,635.706116 588.907288,637.640442 570.250000,638.934082 
	C564.465454,639.335205 558.669922,639.581787 552.887634,640.010376 
	C535.728271,641.282349 518.573364,642.614868 501.415924,643.912415 
	C496.463196,644.287048 491.511841,644.710571 486.552216,644.957458 
	C478.268250,645.369629 469.980621,645.745789 461.690063,645.978333 
	C447.239868,646.383667 432.787506,646.741821 418.333740,646.979004 
	C407.737457,647.152893 397.136047,647.011536 386.539581,647.178284 
	C377.229797,647.324829 367.918732,648.036682 358.617096,647.899536 
	C346.019623,647.713745 333.430969,646.945862 320.837708,646.448730 
	C316.312195,646.270020 311.781342,646.213440 307.257996,645.995422 
	C294.013336,645.357239 280.763794,644.779541 267.533112,643.918213 
	C264.046448,643.691223 260.621094,642.523010 257.079285,641.405273 
	C271.985596,641.027954 286.981293,640.924927 301.974365,641.077515 
	C310.768463,641.166992 319.560333,641.965881 328.349823,641.910706 
	C344.140808,641.811462 359.932312,641.413757 375.717926,640.948547 
	C394.302948,640.400696 412.886353,639.752502 431.462311,638.957153 
	C452.174072,638.070496 472.878662,637.008850 493.582397,635.945679 
	C504.670319,635.376221 515.752869,634.689697 526.832520,633.973938 
	C546.503418,632.703247 566.167664,631.330261 585.839966,630.083191 
	C593.289917,629.610901 600.751831,629.288330 608.213013,629.040161 
	C620.667175,628.625854 633.131165,628.470581 645.580078,627.946228 
	C666.625732,627.059937 687.663940,625.990967 708.702515,624.944641 
	C711.836304,624.788818 714.955505,624.338196 718.613403,624.395264 
	C719.965881,625.201782 720.758972,625.950623 721.611206,626.025513 
	C737.215576,627.395874 752.823730,629.689331 768.437866,629.740479 
	C788.392578,629.805969 808.481201,632.128052 828.341370,627.124878 
	C841.115356,623.906860 853.524231,620.351562 863.998169,611.962646 
	C866.700317,611.624023 869.402405,611.285400 872.906738,610.957886 
	C874.463684,610.970398 875.218445,610.971741 875.973145,610.973145 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M863.754395,611.710388 
	C853.524231,620.351562 841.115356,623.906860 828.341370,627.124878 
	C808.481201,632.128052 788.392578,629.805969 768.437866,629.740479 
	C752.823730,629.689331 737.215576,627.395874 721.611206,626.025513 
	C720.758972,625.950623 719.965881,625.201782 719.061768,624.396484 
	C767.309509,621.605957 815.574402,618.421570 863.754395,611.710388 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M875.977539,610.710327 
	C875.218445,610.971741 874.463684,610.970398 873.330688,610.944702 
	C873.826111,610.426086 874.699829,609.931763 875.773560,609.679626 
	C875.976318,610.097107 875.979126,610.272278 875.977539,610.710327 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M719.916809,686.620911 
	C715.938416,687.322510 711.860229,688.039429 707.818420,687.873352 
	C697.896057,687.465637 687.973938,686.836243 678.081970,685.955872 
	C663.849792,684.689148 649.651123,683.047546 635.421875,681.743713 
	C632.612305,681.486206 629.723633,681.972107 626.880737,682.221069 
	C620.523132,682.778015 614.182251,683.714355 607.815063,683.935425 
	C592.614441,684.463196 577.398376,684.539856 562.196350,685.037659 
	C547.946167,685.504150 533.711792,686.520264 519.460083,686.848816 
	C504.950867,687.183289 490.427643,686.858826 475.913269,687.040955 
	C465.376953,687.173218 454.846802,687.804138 444.310516,687.928772 
	C439.103821,687.990356 433.891388,687.368469 428.677887,687.137939 
	C426.018951,687.020325 423.348999,686.994568 420.689209,687.082886 
	C412.810120,687.344727 404.932190,687.963196 397.056366,687.924133 
	C386.600067,687.872253 376.099365,687.833801 365.703705,686.866211 
	C354.049927,685.781555 342.363739,684.242310 330.958984,681.701111 
	C326.704132,680.752930 322.904572,676.744873 319.424164,673.557007 
	C312.888306,667.570435 306.937775,660.937866 300.314362,655.057617 
	C297.990753,652.994751 294.514404,651.817932 291.372864,651.179871 
	C285.883820,650.065186 280.241241,649.718567 274.676697,648.950928 
	C273.805328,648.830811 273.006012,648.187927 272.084351,647.395874 
	C280.042023,647.658508 288.082855,648.403564 296.138123,648.925171 
	C299.896729,649.168640 303.686066,648.890137 307.456787,649.003662 
	C316.915253,649.288452 326.382141,649.482178 335.824127,650.062561 
	C342.239166,650.456848 348.645660,651.268188 354.995087,652.276367 
	C356.793671,652.561951 358.408844,654.147949 360.055328,655.218750 
	C364.851318,658.337769 369.421631,661.880432 374.470764,664.506470 
	C377.797729,666.236877 382.065735,666.131409 385.441315,667.800659 
	C391.883789,670.986389 398.775879,672.921570 405.096436,676.794250 
	C410.426086,680.059753 416.682037,683.641541 424.386902,682.321228 
	C429.883820,681.379333 435.846680,683.152649 441.600830,683.722534 
	C442.095245,683.771423 442.587799,683.972412 443.074249,683.950134 
	C457.502716,683.288208 472.035797,683.441895 486.321136,681.636841 
	C495.915619,680.424500 505.246338,676.697021 514.535767,673.614868 
	C524.341858,670.361206 534.078369,666.823975 543.609924,662.846619 
	C548.637512,660.748657 552.885742,656.669678 557.974304,654.854797 
	C568.282043,651.178284 576.635315,642.957153 588.267944,642.376038 
	C597.780090,641.900940 607.278381,640.018250 616.753296,640.211304 
	C628.864502,640.458191 641.071899,637.439758 653.104553,640.988708 
	C654.845215,641.502075 656.827393,641.507874 658.362732,642.355530 
	C668.523499,647.965088 678.595032,653.736145 688.682861,659.477112 
	C689.095886,659.712219 689.449036,660.127625 689.715088,660.534302 
	C693.538208,666.377747 697.393738,672.201111 701.134094,678.097412 
	C704.476562,683.366394 709.875305,684.192322 715.348328,685.025818 
	C716.869507,685.257446 718.339905,685.822449 719.916809,686.620911 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M715.988770,696.433533 
	C696.698364,696.205933 677.292053,695.519348 657.879883,695.110840 
	C645.891846,694.858643 633.894775,695.025757 621.901794,695.011902 
	C610.574890,694.998779 599.248047,694.995300 586.984985,694.999268 
	C579.363098,695.004456 572.677307,694.997986 565.523804,694.980469 
	C548.707397,694.976257 532.358765,694.983032 515.540833,694.990479 
	C463.381470,694.978760 411.691437,694.966431 360.001373,694.954163 
	C359.097229,694.639648 358.200836,694.076538 357.287781,694.048035 
	C344.666962,693.654175 332.024841,693.657776 319.426086,692.915710 
	C302.742126,691.933044 286.028534,690.906433 269.446106,688.913452 
	C255.358231,687.220276 241.237167,684.931641 227.488205,681.481934 
	C203.106537,675.364502 178.885864,668.532471 154.812256,661.286987 
	C139.926666,656.806824 125.469818,650.915710 110.753769,645.851929 
	C105.075035,643.897949 103.013985,638.935181 100.237282,634.484558 
	C95.446892,626.806274 91.559303,618.458008 83.988655,612.469849 
	C96.014969,615.734192 108.117851,619.325562 120.670868,623.322876 
	C128.751144,625.477722 136.381287,627.226624 144.011444,628.975586 
	C145.713593,628.991089 147.415756,629.006531 149.541656,629.026367 
	C152.977402,630.344421 155.875977,632.137451 159.022675,632.882080 
	C168.509140,635.127014 178.053802,637.178955 187.649933,638.890198 
	C194.897690,640.182739 199.206589,645.036011 203.160019,650.449646 
	C206.286896,654.731445 209.273239,659.128906 212.096069,663.617065 
	C218.883652,674.409058 229.430695,678.417236 241.310760,679.966980 
	C257.361359,682.060791 273.394440,684.398254 289.502808,685.908752 
	C305.490082,687.407898 321.551483,688.229675 337.598236,688.952393 
	C357.496948,689.848572 377.409515,690.649719 397.324097,690.935852 
	C436.121887,691.493164 474.924805,691.813171 513.726440,691.927673 
	C531.843811,691.981140 549.964233,691.372192 568.081421,690.975220 
	C578.117554,690.755371 588.148315,690.186584 598.184326,690.099548 
	C603.717102,690.051514 609.253174,690.882996 614.792236,690.951782 
	C626.375793,691.095642 637.972778,690.684875 649.545227,691.071655 
	C666.438782,691.636414 683.324219,692.567505 700.194580,693.634766 
	C705.450623,693.967285 710.646790,695.246338 715.988770,696.433533 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M880.656738,615.035278 
	C880.572815,616.187500 880.493469,617.958130 879.655029,618.459839 
	C875.171387,621.141968 874.562378,625.728333 874.129883,630.028076 
	C872.845276,642.798096 871.896362,655.605896 871.049622,668.414307 
	C870.649902,674.459839 866.224365,675.627319 861.376709,676.994873 
	C866.903748,671.668518 867.678284,664.655762 868.109375,657.222412 
	C868.708923,646.882690 869.309814,636.425720 871.459595,626.349487 
	C872.355408,622.150635 877.240173,618.802856 880.656738,615.035278 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M143.934479,628.606201 
	C136.381287,627.226624 128.751144,625.477722 121.056976,623.366821 
	C128.614456,624.748901 136.235977,626.492920 143.934479,628.606201 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M799.751709,687.740845 
	C798.027954,688.669983 796.091553,689.742554 794.076904,689.918396 
	C788.550842,690.400818 782.993286,690.552307 777.444641,690.708374 
	C776.672668,690.730042 775.880737,690.039490 775.045898,689.342957 
	C783.163635,688.496582 791.333496,687.985901 799.751709,687.740845 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M824.576172,685.003235 
	C824.554077,685.627319 824.151306,686.747681 823.644043,686.797180 
	C816.630310,687.481506 809.602234,688.018677 802.288818,688.298523 
	C806.791809,687.342896 811.582825,686.674561 816.375854,686.021057 
	C818.964417,685.668091 821.556458,685.340576 824.576172,685.003235 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M850.600098,679.003906 
	C847.442261,680.653381 843.950806,682.491272 840.286255,683.868530 
	C838.517090,684.533325 836.422302,684.331299 834.237488,684.263184 
	C834.552063,683.430664 835.015930,682.502075 835.670166,682.339417 
	C840.490479,681.141357 845.348877,680.096252 850.600098,679.003906 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M757.708252,688.707031 
	C752.861694,689.201050 747.723389,689.397217 742.292542,689.296692 
	C747.138794,688.803040 752.277649,688.606140 757.708252,688.707031 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M772.581543,689.003357 
	C770.735413,693.395203 767.027039,689.249329 764.126953,690.430725 
	C763.183105,690.815186 761.701660,689.879944 760.233032,689.276855 
	C764.052368,689.003357 768.104797,689.001343 772.581543,689.003357 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M737.914673,688.607788 
	C734.725098,688.593567 731.448242,688.189514 728.082642,687.396362 
	C731.271667,687.410889 734.549561,687.814392 737.914673,688.607788 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M267.917297,647.616272 
	C265.186615,647.860657 262.373199,647.721313 259.279907,647.290955 
	C261.944855,647.077515 264.889740,647.155029 267.917297,647.616272 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M77.960999,609.637878 
	C76.446732,609.174988 74.875687,608.366638 73.185181,607.239136 
	C74.678551,607.710754 76.291389,608.501587 77.960999,609.637878 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M246.769440,644.729492 
	C245.703552,644.897583 244.406509,644.792175 243.052261,644.345825 
	C244.176132,644.155273 245.357193,644.305664 246.769440,644.729492 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M254.948181,646.652954 
	C254.035797,646.563354 253.069763,646.129150 252.048859,645.351135 
	C252.960831,645.440979 253.927673,645.874695 254.948181,646.652954 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M830.665894,684.004883 
	C830.135986,684.507324 829.267090,685.009827 828.199097,685.257324 
	C828.775635,684.669861 829.551270,684.337402 830.665894,684.004883 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M883.018799,609.287354 
	C883.435425,608.979431 883.873291,608.971008 884.638794,608.950928 
	C884.810547,609.511230 884.654602,610.083374 884.249390,610.827759 
	C883.680054,610.528931 883.360046,610.057922 883.018799,609.287354 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M81.977890,611.703125 
	C81.443726,611.827698 80.881310,611.660522 80.159058,611.237671 
	C80.649376,611.125183 81.299507,611.268311 81.977890,611.703125 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M886.935791,668.586304 
	C882.406128,672.758789 877.671631,676.203491 873.486572,680.220642 
	C868.186707,685.307800 861.752930,686.601318 854.460083,687.038757 
	C855.926025,685.676514 857.709900,684.129028 859.723450,682.985901 
	C866.145996,679.339355 873.160950,676.491028 878.901306,671.998291 
	C881.528198,669.942261 882.599792,664.818726 882.838440,660.990417 
	C883.611084,648.596985 883.642761,636.158264 884.042725,623.739685 
	C884.090454,622.257874 884.648865,620.792603 884.970581,619.319641 
	C885.638733,619.360413 886.306885,619.401184 886.975037,619.441956 
	C886.975037,635.665527 886.975037,651.889099 886.935791,668.586304 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M158.213959,688.217041 
	C164.202545,689.638306 170.383163,691.390869 176.585892,693.061401 
	C184.084564,695.080994 191.601913,697.031311 199.473358,699.124878 
	C194.100723,698.467712 188.200012,698.302002 182.670120,696.784729 
	C174.447769,694.528687 166.487564,691.317017 158.213959,688.217041 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M156.156586,687.125854 
	C156.600357,687.029114 157.193375,687.211731 157.924744,687.673462 
	C157.477356,687.770142 156.891602,687.587646 156.156586,687.125854 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M115.053131,663.918945 
	C115.779129,663.953918 116.505127,663.988831 117.664391,664.379761 
	C122.276550,666.611389 126.377434,668.690002 130.646790,670.329956 
	C161.336304,682.118042 192.704300,691.483521 224.914215,698.325073 
	C252.366592,704.156006 280.164795,706.615112 307.994507,708.834595 
	C326.337463,710.297485 344.794037,710.854248 363.201843,710.896667 
	C447.811951,711.091492 532.422852,710.962708 617.033447,710.950073 
	C618.414062,710.953552 619.794739,710.957031 621.872925,711.260620 
	C635.103577,712.034851 647.635010,712.559570 660.170471,712.957458 
	C665.138367,713.115295 670.121094,712.860962 675.084167,713.082214 
	C676.441467,713.142700 677.752380,714.245850 679.084534,714.870544 
	C654.961243,714.566223 630.837097,714.316040 606.715149,713.928040 
	C598.252136,713.791870 589.794922,713.049255 581.334534,713.039612 
	C513.509033,712.962463 445.682678,713.170654 377.858154,712.901062 
	C352.937866,712.802002 327.989807,712.225647 303.111694,710.839111 
	C270.860565,709.041504 238.830688,704.802979 207.490952,696.818787 
	C175.625427,688.700684 144.221161,678.981384 114.108353,665.540955 
	C111.266251,664.272339 108.671219,662.450134 105.935257,660.525574 
	C106.365250,660.122375 106.821457,660.078613 107.686790,660.378784 
	C110.414986,661.788086 112.734062,662.853516 115.053131,663.918945 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M679.544678,714.893677 
	C677.752380,714.245850 676.441467,713.142700 675.084167,713.082214 
	C670.121094,712.860962 665.138367,713.115295 660.170471,712.957458 
	C647.635010,712.559570 635.103577,712.034851 622.286987,711.249878 
	C623.738464,710.622498 625.477539,710.010559 627.207581,710.035278 
	C645.647705,710.298645 664.086060,710.683105 682.525574,710.994080 
	C703.461609,711.347229 724.400635,712.010132 745.334045,711.876038 
	C761.404053,711.773071 777.558350,711.487610 793.512573,709.772034 
	C817.465088,707.196289 841.299988,703.459534 865.126648,699.833740 
	C870.271790,699.050720 875.169556,696.641602 880.581177,694.984253 
	C875.767761,697.642334 870.814331,701.637390 865.293640,702.708557 
	C846.636475,706.328491 827.830078,709.314209 808.977661,711.742432 
	C797.326965,713.243103 785.493958,713.388245 773.734192,713.967224 
	C759.151855,714.685181 744.560547,715.222717 729.405579,715.446289 
	C723.891907,715.021118 718.945801,714.987732 713.999695,714.954346 
	C702.668091,714.941833 691.336426,714.929321 679.544678,714.893677 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M129.987885,677.732788 
	C120.920952,676.051575 111.641739,673.980347 104.031677,667.283691 
	C112.583420,670.445618 121.152687,673.962097 129.987885,677.732788 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M87.014992,651.402344 
	C89.696556,652.054199 92.443954,653.044434 95.638748,654.384277 
	C97.050873,655.162842 98.015594,655.591797 98.980309,656.020752 
	C99.869385,656.504395 100.758469,656.988037 101.982018,657.972412 
	C102.864136,658.996216 103.411789,659.519165 103.959442,660.042114 
	C98.333244,657.275024 92.707031,654.507935 87.014992,651.402344 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M714.286743,715.257568 
	C718.945801,714.987732 723.891907,715.021118 728.981812,715.419434 
	C724.275085,715.709778 719.424438,715.635315 714.286743,715.257568 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M104.295059,660.047119 
	C103.411789,659.519165 102.864136,658.996216 102.174881,658.220276 
	C103.315407,658.415039 104.597519,658.862732 105.925354,659.674072 
	C105.524277,660.042542 105.077477,660.047302 104.295059,660.047119 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M76.151352,644.138916 
	C76.653389,644.047180 77.303452,644.229309 77.982079,644.700195 
	C77.440216,644.796814 76.869797,644.604797 76.151352,644.138916 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M103.873276,666.749390 
	C103.470062,666.808960 102.877876,666.632324 102.137772,666.172791 
	C102.554672,666.097656 103.119484,666.305481 103.873276,666.749390 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M86.887680,646.076965 
	C85.715462,645.552856 84.543236,645.028687 83.170090,643.919312 
	C82.646942,642.555176 82.324722,641.776306 82.012863,640.986938 
	C82.023232,640.976562 82.045860,640.953369 82.415939,640.966797 
	C84.483360,641.930725 86.175148,642.891357 87.878952,643.830139 
	C105.367661,653.465942 123.902611,660.720764 142.780319,666.966064 
	C174.250702,677.377258 205.995834,686.924561 239.030518,691.135925 
	C258.365204,693.600769 277.709930,696.095398 297.115173,697.862061 
	C311.466156,699.168457 325.916412,699.514709 340.332764,699.939636 
	C358.531982,700.476135 376.740997,700.892578 394.946991,700.946716 
	C441.347961,701.084778 487.749634,700.992065 534.151001,700.992981 
	C553.142090,700.993347 572.133545,700.948608 591.123657,701.085266 
	C592.757629,701.096985 594.382874,702.321655 596.012207,702.982910 
	C519.351257,702.989014 442.690155,703.064636 366.029449,702.931396 
	C353.595764,702.909729 341.165253,701.916199 328.729431,701.481506 
	C326.273041,701.395630 323.777466,702.119995 321.336609,701.948547 
	C304.002197,700.731201 286.616516,699.906311 269.366394,697.920593 
	C251.983963,695.919617 234.489075,693.787598 217.493866,689.795410 
	C193.173737,684.082764 169.258286,676.644104 145.183594,669.894104 
	C142.322372,669.091858 139.546158,667.986511 136.340546,667.033813 
	C135.178345,666.730652 134.406143,666.415466 133.155365,665.817078 
	C132.100891,665.372742 131.525009,665.211670 130.949127,665.050598 
	C129.864731,664.715393 128.780350,664.380127 127.184067,663.782227 
	C126.100868,663.359741 125.529556,663.199890 124.958244,663.040100 
	C124.183365,662.723572 123.408485,662.407104 122.120628,661.776855 
	C119.390076,660.656738 117.172493,659.850342 114.954903,659.043945 
	C114.225121,659.018494 113.495354,658.993042 112.347832,658.636597 
	C111.273132,657.883118 110.616173,657.460754 109.959229,657.038330 
	C107.209534,655.727722 104.459831,654.417175 101.157822,652.792114 
	C99.742203,652.314148 98.878883,652.150635 97.827080,651.744080 
	C97.050751,651.385864 96.462921,651.270691 95.875092,651.155518 
	C95.046959,650.938843 94.218834,650.722168 93.152206,649.901245 
	C90.905037,648.223694 88.896362,647.150330 86.887680,646.076965 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M616.931519,710.559509 
	C532.422852,710.962708 447.811951,711.091492 363.201843,710.896667 
	C344.794037,710.854248 326.337463,710.297485 307.994507,708.834595 
	C280.164795,706.615112 252.366592,704.156006 224.914215,698.325073 
	C192.704300,691.483521 161.336304,682.118042 130.646790,670.329956 
	C126.377434,668.690002 122.276550,666.611389 118.027664,664.390137 
	C131.995468,668.991211 145.955124,674.174866 160.087585,678.834534 
	C188.256607,688.122314 216.904678,695.652405 246.291351,699.882507 
	C261.814453,702.117004 277.450043,703.842529 293.095642,704.887695 
	C315.251343,706.367676 337.454620,707.537903 359.652771,707.893372 
	C403.897858,708.601746 448.152802,708.778320 492.404572,708.961426 
	C532.033936,709.125427 571.664490,708.987427 611.294495,709.056335 
	C613.140137,709.059570 614.984497,709.781677 616.931519,710.559509 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M114.981339,663.578613 
	C112.734062,662.853516 110.414986,661.788086 108.030167,660.379517 
	C110.279465,661.103638 112.594505,662.171021 114.981339,663.578613 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M115.024170,659.382629 
	C117.172493,659.850342 119.390076,660.656738 121.805832,661.751648 
	C119.700485,661.267212 117.396965,660.494263 115.024170,659.382629 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M98.988861,655.685669 
	C98.015594,655.591797 97.050873,655.162842 96.011169,654.399658 
	C95.936172,654.065491 95.971321,654.029541 96.361664,654.013245 
	C97.498634,654.001160 98.245255,654.005432 98.995651,654.005310 
	C98.999413,654.000977 98.990540,654.010498 98.990540,654.010498 
	C98.992828,654.457214 98.995125,654.903870 98.988861,655.685669 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M98.015564,651.987183 
	C98.878883,652.150635 99.742203,652.314148 100.803802,652.747070 
	C100.530159,653.329834 100.058220,653.643188 99.288414,653.983521 
	C98.990540,654.010498 98.999413,654.000977 98.893921,653.742554 
	C98.530807,652.985107 98.273186,652.486145 98.015564,651.987183 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M110.013489,657.356262 
	C110.616173,657.460754 111.273132,657.883118 111.983032,658.621704 
	C111.379906,658.516663 110.723824,658.095398 110.013489,657.356262 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M124.999557,663.328125 
	C125.529556,663.199890 126.100868,663.359741 126.837509,663.775696 
	C126.348854,663.893311 125.694870,663.754761 124.999557,663.328125 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M130.993347,665.337463 
	C131.525009,665.211670 132.100891,665.372742 132.840927,665.794922 
	C132.349243,665.912048 131.693405,665.768188 130.993347,665.337463 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M596.385559,702.985840 
	C594.382874,702.321655 592.757629,701.096985 591.123657,701.085266 
	C572.133545,700.948608 553.142090,700.993347 534.151001,700.992981 
	C487.749634,700.992065 441.347961,701.084778 394.946991,700.946716 
	C376.740997,700.892578 358.531982,700.476135 340.332764,699.939636 
	C325.916412,699.514709 311.466156,699.168457 297.115173,697.862061 
	C277.709930,696.095398 258.365204,693.600769 239.030518,691.135925 
	C205.995834,686.924561 174.250702,677.377258 142.780319,666.966064 
	C123.902611,660.720764 105.367661,653.465942 87.878952,643.830139 
	C86.175148,642.891357 84.483360,641.930725 82.365143,640.620483 
	C80.679077,638.525940 79.922050,635.117615 77.022156,638.766174 
	C76.058304,637.732971 75.094444,636.699707 74.133408,635.346558 
	C74.762215,634.210693 75.388199,633.394836 76.027145,632.217285 
	C99.417305,649.093323 126.851311,657.354370 153.710327,666.854370 
	C159.450638,668.884827 165.399689,670.325134 171.643219,672.079712 
	C174.272644,672.809937 176.511429,673.497864 179.360443,674.366455 
	C180.651886,674.705200 181.333115,674.863159 182.014328,675.021179 
	C182.320404,675.169495 182.643295,675.235229 183.471985,675.385620 
	C184.644409,675.707458 185.327850,675.862000 186.011292,676.016479 
	C206.615952,680.364563 227.043594,686.159607 247.868500,688.707336 
	C276.148254,692.167053 304.726746,693.219727 333.188446,695.136414 
	C341.806335,695.716736 350.461792,695.739014 360.030151,695.994751 
	C412.635773,695.966309 464.311279,695.960022 516.452148,696.024902 
	C529.192322,696.133179 541.467041,696.182251 553.741821,696.200317 
	C557.829651,696.206360 561.917603,696.133240 566.456787,696.130981 
	C573.577637,696.277100 580.247314,696.388611 586.916931,696.500122 
	C588.233459,696.645630 589.548035,696.897827 590.866760,696.920898 
	C614.328796,697.332153 637.790527,697.775330 661.254028,698.087769 
	C683.384583,698.382385 705.545532,699.335266 727.641968,698.554138 
	C758.659058,697.457703 789.656067,695.380554 820.607849,693.017944 
	C836.628479,691.795105 852.539368,689.099365 868.484680,686.956299 
	C871.418579,686.561951 874.279724,685.626099 877.535889,685.259033 
	C876.731506,686.392456 875.675842,687.588013 874.382690,687.961670 
	C863.630188,691.068909 852.988037,694.922363 842.013123,696.805725 
	C827.216553,699.344727 812.187988,700.838989 797.196167,701.854004 
	C779.153381,703.075562 761.047424,703.415955 742.963257,703.951111 
	C734.724121,704.194946 726.469482,704.110962 718.225769,703.961121 
	C706.093506,703.740723 693.966125,703.234070 681.833679,703.041687 
	C676.620850,702.958984 671.391785,703.638977 666.185181,703.480408 
	C655.913086,703.167603 645.656372,702.342285 635.384155,702.049194 
	C626.587280,701.798096 617.776306,701.905762 608.974365,702.037842 
	C605.645630,702.087769 602.324585,702.650818 599.000000,702.979980 
	C598.252991,702.982910 597.505920,702.985840 596.385559,702.985840 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M599.289795,703.282715 
	C602.324585,702.650818 605.645630,702.087769 608.974365,702.037842 
	C617.776306,701.905762 626.587280,701.798096 635.384155,702.049194 
	C645.656372,702.342285 655.913086,703.167603 666.185181,703.480408 
	C671.391785,703.638977 676.620850,702.958984 681.833679,703.041687 
	C693.966125,703.234070 706.093506,703.740723 718.225769,703.961121 
	C726.469482,704.110962 734.724121,704.194946 742.963257,703.951111 
	C761.047424,703.415955 779.153381,703.075562 797.196167,701.854004 
	C812.187988,700.838989 827.216553,699.344727 842.013123,696.805725 
	C852.988037,694.922363 863.630188,691.068909 874.382690,687.961670 
	C875.675842,687.588013 876.731506,686.392456 877.940491,685.209412 
	C879.390015,683.886902 880.796265,682.932800 882.524231,681.918030 
	C882.562256,683.107544 882.708130,684.849121 881.927856,685.531128 
	C875.005615,691.581970 866.554443,694.317749 857.719727,695.943970 
	C847.342346,697.854065 836.946411,699.694458 826.511841,701.252258 
	C818.684143,702.420898 810.803101,703.533386 802.909912,703.903198 
	C783.039429,704.833984 763.152771,705.672791 743.264832,705.917358 
	C716.231995,706.249756 689.188721,706.247803 662.155945,705.908875 
	C641.768799,705.653320 621.390991,704.646240 601.009644,703.956055 
	C600.529358,703.939819 600.056152,703.713684 599.289795,703.282715 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M515.986755,695.953674 
	C464.311279,695.960022 412.635773,695.966309 360.496155,695.924866 
	C360.031982,695.877075 360.009705,695.416321 360.005554,695.185242 
	C411.691437,694.966431 463.381470,694.978760 515.538696,695.231567 
	C516.005798,695.472107 515.986755,695.953674 515.986755,695.953674 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M185.791016,675.728577 
	C185.327850,675.862000 184.644409,675.707458 183.706360,675.300659 
	C184.158066,675.179138 184.864410,675.309875 185.791016,675.728577 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M181.795288,674.732178 
	C181.333115,674.863159 180.651886,674.705200 179.713318,674.298157 
	C180.162750,674.180481 180.869507,674.311829 181.795288,674.732178 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M586.928833,696.253662 
	C580.247314,696.388611 573.577637,696.277100 566.451660,695.854675 
	C565.994019,695.359680 565.992798,695.175598 565.991516,694.991455 
	C572.677307,694.997986 579.363098,695.004456 586.515442,695.015137 
	C586.991760,695.349670 586.978027,695.678894 586.928833,696.253662 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M77.124390,639.089600 
	C79.922050,635.117615 80.679077,638.525940 81.995071,640.607056 
	C82.045860,640.953369 82.023232,640.976562 81.670410,640.985962 
	C80.860672,641.008301 80.403740,641.021240 79.946815,641.034241 
	C79.040085,640.493835 78.133354,639.953369 77.124390,639.089600 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M86.963333,646.412231 
	C88.896362,647.150330 90.905037,648.223694 92.961288,649.640015 
	C91.018913,648.904419 89.028954,647.825989 86.963333,646.412231 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M97.827072,651.744141 
	C98.273186,652.486145 98.530807,652.985107 98.890152,653.746826 
	C98.245255,654.005432 97.498634,654.001160 96.378716,653.994812 
	C95.905182,653.201599 95.804939,652.410583 95.789894,651.387512 
	C96.462921,651.270691 97.050751,651.385864 97.827072,651.744141 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M79.986633,641.373352 
	C80.403740,641.021240 80.860672,641.008301 81.660049,640.996338 
	C82.324722,641.776306 82.646942,642.555176 82.993622,643.650024 
	C82.020866,643.214722 81.023659,642.463562 79.986633,641.373352 
z"/>
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M565.523804,694.980469 
	C565.992798,695.175598 565.994019,695.359680 566.000366,695.820068 
	C561.917603,696.133240 557.829651,696.206360 553.741821,696.200317 
	C541.467041,696.182251 529.192322,696.133179 516.452148,696.024902 
	C515.986755,695.953674 516.005798,695.472107 516.007935,695.230957 
	C532.358765,694.983032 548.707397,694.976257 565.523804,694.980469 
z"/>
</svg>
    </>
  );
};

export default Index;
