import styles from './register.module.scss';
import sharedStyles from '../sharedStyle.module.scss';
import image from '../handy_image.png';
import { useParams, useNavigate } from 'react-router-dom';
import TeacherIcon from './icons/teacher.png';
import ParentIcon from './icons/parent.png';
import StudentIcon from './icons/student.png';
import SchoolLeaderIcon from './icons/school_leader.png';
import FurtherIcon from './icons/button.png';
import SelectType, { options } from '../components/selectType';
import { useEffect, useState } from 'react';
import EmailIcon from '../components/input/email.png';
import TextField from '../components/input/textField';
import PasswordIcon from '../components/input/password.png';
import AppleIcon from '../components/Apple.png';
import GoogleIcon from '../components/Google.png';
import ClassLinkIcon from '../components/Classlink.png';
import CleverIcon from '../components/Clever.png';
import {
  appleProvider,
  auth,
  classLinkProvider,
  cleverProvider,
  googleProvider,
} from '../../api/firebase';
import axios from '../../api/axios';
import { Dna } from 'react-loader-spinner';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { useAppSelector } from 'store/store';
import analytics from 'utils/analytics';

function extractFieldName(errorMessage) {
  const match = errorMessage.match(
    /Unique constraint failed on the fields: \(`([^`]+)`\)/,
  );
  if (match && match[1]) {
    const fieldName = match[1];
    return `${fieldName} is already exist`;
  }
  return errorMessage;
}

const SelectTypePage = () => {
  const options = [
    {
      name: 'teacher',
      background: 'linear-gradient(286.17deg, #246BFD 0%, #5089FD 100%)',
      icon: TeacherIcon,
    },
    {
      name: 'parent',
      background: 'linear-gradient(286.17deg, #FB9400 0%, #FFAB38 100%)',
      icon: ParentIcon,
    },
    {
      name: 'student',
      background:
        'linear-gradient(286.17deg, #20A58A 0%, #71E3BB 100%, #71E3BB 100%)',
      icon: StudentIcon,
    },
    {
      name: 'school leader',
      id: 'school_leader',
      background: '#C73E9D',
      icon: SchoolLeaderIcon,
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      navigate('/auth/complete_profile?type=' + currentUser);
    }
  }, []);

  return (
    <div className={sharedStyles.centerContainer}>
      <img src={image} className={sharedStyles.handyImage} alt={'register'} />
      <div
        className={`${styles.selecetType} ${sharedStyles.whiteContainerBox}`}
      >
        <h1 className={styles.title}>
          What type of account would you like to create?
        </h1>

        {options.map((option, index) => {
          return (
            <div key={index} className={styles.option}>
              <div
                className={styles.iconContainer}
                style={{ background: option.background }}
              >
                <img src={option.icon} alt={'icon'} />
              </div>
              <h2>I'm a {option.name}</h2>
              <button
                className={styles.furtherButton}
                onClick={() => {
                  navigate(`/auth/register/${option.id || option.name}`);
                }}
              >
                <img src={FurtherIcon} alt={'further icon'} />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const RegisterPage = ({ typeParam }) => {
  const navigate = useNavigate();
  const [type, setType] = useState(typeParam);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      navigate('/auth/complete_profile?type=' + currentUser);
      return;
    } else if (user) {
      navigate('app/student');
    }

    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  }, []);

  function checkSamePassword() {
    if (password === verifyPassword) {
      return true;
    }

    NotificationManager.error('Passwords do not match', 'Error', 3000);
    return false;
  }

  function onRegisterPasswordEmail() {
    if (!checkSamePassword()) return;

    setLoading(true);
    auth
      .createUserWithEmailAndPassword(email, password)
      .then(onSignUp)
      .catch(onError);
  }

  function signUpWithGoogle() {
    signInWithProvider(googleProvider);
  }

  function signInWithApple() {
    signInWithProvider(appleProvider);
  }

  function classLink() {
    signInWithProvider(classLinkProvider);
  }

  function clever() {
    signInWithProvider(cleverProvider);
  }

  function signInWithProvider(provider) {
    // if (!checkSamePassword()) return;
    setLoading(true);
    auth.signInWithPopup(provider).then(onSignUp).catch(onError);
  }

  function signInAnonymously() {
    setLoading(true);
    auth.signInAnonymously().then(onSignUp).catch(onError);
  }

  function onError(error) {
    setLoading(false);
    NotificationManager.error(error.message, 'Error', 3000);
    analytics.logEvent('sign_up_error', { error: error.message });
  }

  function onSignUp(userCredential) {
    const user = userCredential.user;

    createBackendUser(user.email, user.uid);
  }

  function createBackendUser(email, uid) {
    axios
      .post('/user', {
        email: email,
        firebaseId: uid,
        type: type.toLowerCase(),
      })
      .then((response) => {
        setLoading(false);

        if (response.status == 201) {
          NotificationManager.success(email, 'Success');
          localStorage.setItem('uuid', response.data.data.id);
          localStorage.setItem('currentUser', type.toLowerCase());
          localStorage.setItem('currentUserData', response.data.data);
          analytics.logEvent('sign_up', { user_id: response.data.data.id });
          navigate(
            `/auth/complete_profile?type=${type.toLowerCase()}&email=${
              response.data.data.email
            }`,
          );
        }
      })
      .catch((error) => {
        console.log(error, 'e');
        setLoading(false);
        const errMsg = extractFieldName(error?.response?.data?.message || '');
        NotificationManager.error(errMsg, 'Error', 3000);
        analytics.logEvent('sign_up_error', { error: errMsg });
      });
  }

  /**
   *                 {type == "Student" && (
   *                     <button className={sharedStyles.loginButton} onClick={() => signInAnonymously()}
   *                             style={{backgroundColor: "#C73E9D", color: "#F5F5F5"}}>
   *                         <CodeIcon/>
   *                         I have a code
   *                     </button>
   *                 )}
   */

  return (
    <div className={sharedStyles.centerContainer}>
      <div className={`${styles.register} ${sharedStyles.whiteContainerBox}`}>
        <h1 className={styles.title}>Sign Up</h1>
        <h2 className={styles.text}>
          Give your students the tutoring they need to close learing gaps
        </h2>
        <SelectType setCurrentState={setType} defaultType={typeParam} />

        {
          <>
            <TextField
              type={'text'}
              placeholder={'Enter your email'}
              onChange={setEmail}
              icon={EmailIcon}
            />
            <div className={styles.passwords}>
              <TextField
                type={'password'}
                placeholder={'Passwords'}
                onChange={setPassword}
                icon={PasswordIcon}
              />
              <TextField
                type={'password'}
                placeholder={'Confirm Password'}
                onChange={setVerifyPassword}
                icon={PasswordIcon}
              />
            </div>
            <button
              className={sharedStyles.loginButton}
              onClick={onRegisterPasswordEmail}
              style={{ backgroundColor: '#C73E9D' }}
            >
              Sign up
            </button>

            <div className={sharedStyles.orField}>
              <div className={sharedStyles.line}></div>
              <p>OR</p>
              <div className={sharedStyles.line}></div>
            </div>
          </>
        }

        <button
          className={sharedStyles.loginButton}
          style={{ backgroundColor: '#131515' }}
          onClick={signInWithApple}
        >
          <img src={AppleIcon} alt={'apple logo icon'} />
          Sign up with Apple ID
        </button>
        <button
          className={sharedStyles.loginButton}
          onClick={signUpWithGoogle}
          style={{ backgroundColor: '#20A58A', color: '#484848' }}
        >
          <img src={GoogleIcon} alt={'google logo icon'} />
          {/* Sign up with Google */}
        </button>

        {/* {type != 'Parent' && ( */}
        <>
          <button
            className={sharedStyles.loginButton}
            style={{
              backgroundColor: 'rgba(15,106,220,0.49)',
              color: '#131515',
            }}
            onClick={classLink}
          >
            <img src={ClassLinkIcon} alt={'class link logo icon'} />
            Sign up with Class Link
          </button>

          <button
            className={sharedStyles.loginButton}
            style={{ backgroundColor: '#5b20be', color: 'white' }}
            onClick={clever}
          >
            <img src={CleverIcon} alt={'clever logo icon'} />
            Sign up with Clever
          </button>
        </>
        {/* )} */}

        {loading && (
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        )}
      </div>
    </div>
  );
};

const Register = () => {
  const navigate = useNavigate();
  const params = useParams();
  var regex = new RegExp(options.join('|'), 'i');
  var isAvailable = regex.test(params.type);
  const canRegister = params.type && isAvailable;

  const { user } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    if (user) {
      navigate('/app/student');
    }
  }, [user]);

  return (
    <>
      {canRegister && <RegisterPage typeParam={params.type} />}
      {!canRegister && <SelectTypePage />}
    </>
  );
};

export default Register;
