import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { IncorrectIcon } from 'asset';
import sharedStyles from '../../../sharedStyle.module.scss';
import hazard from '../../../components/hazard.png';
import { useAppDispatch } from 'store/store';
import { addTemporarySchool } from 'store/Thunk/registerThunk';
import { NotificationManager } from 'react-notifications';
import CloseIcon from '@mui/icons-material/Close';

export default function AddStudent({
  open,
  onClose,
  onUpgrade,
  formData,
  setFormData,
  setSchoolCode,
}) {
  const dispatch = useAppDispatch();
  const createSchool = () => {
    onUpgrade();
    // dispatch(
    //   addTemporarySchool({
    //     name: formData?.schoolName,
    //     address: formData?.schoolAddress,
    //     type: formData?.schoolType?.name,
    //   }),
    // )
    //   .unwrap()
    //   .then((data) => {
    //     setFormData({ ...formData, schoolCode: data.data.id });
    //     NotificationManager.success('School added successfully', 'Success');
    //     setSchoolCode(true);
    //     onClose();
    //   })
    //   .catch((err) => {
    //     NotificationManager.error('Error while adding school', 'Error');
    //     console.log(err);
    //   });
  };

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', right: '10px', top: '10px' }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle
        mt={6}
        sx={{ fontSize: '1.7vw !important', color: '#000' }}
        fontWeight={600}
        textAlign={'center'}
        mb={6}
      >
        You have added [Student First Name & Last Name]
      </DialogTitle>
      <DialogContent>
        <Stack
          gap={3}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            '& .MuiTypography-root': {
              fontWeight: 500,
              fontSize: '20px',
              color: '#C73E9D',
            },
            '& span': { color: '#979C9E' },
          }}
        >
          <Typography>
            Would you like to <strong>UPGRADE</strong> and Add Another Student?{' '}
          </Typography>
        </Stack>
        <Box
          mt={8}
          display={'flex'}
          alignItems={'center'}
          gap={8}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Button
            variant={'outlined'}
            size="medium"
            className={'primary_bg_btn'}
            onClick={onClose}
            sx={{
              flex: 1,
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
          >
            No
          </Button>
          <Button
            size="medium"
            className={'primary_bg_btn'}
            sx={{
              flex: 1,
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
            variant="contained"
            onClick={createSchool}
          >
            Add Another Student
          </Button>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
