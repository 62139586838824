import React from "react";
import TodaysChallenege from "../main/elements/assignments/TodaysChallenge";
import VideoPlayer from "../main/elements/assignments/VideoPlayer";
import styles from "../../app.module.scss";

function ShowAllConent() {
  return (
    <>
      <div
        className={`${styles.whiteContainerChallengeTask}`}
        style={{ margin: "0 35px 0 0" }}
      >
        <div className={`${styles.stats}`}>
          <TodaysChallenege />
        </div>
      </div>
      <div className={`${styles.main} ${styles.whiteContainer}`}>
        <VideoPlayer />
      </div>
    </>
  );
}

export default ShowAllConent;
