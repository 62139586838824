import React from 'react';

import sharedStyles from '../../../sharedStyle.module.scss';

import Logo from '../../../components/logo.png';

import Arrow from '../../../components/Arrow-Left.png';
import HomeLogin from '../../../components/StudentsSecond.png';
import OTPInput, { ResendOTP } from 'otp-input-react';

import { Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useVerifyPhoneOtpMutation } from 'store/apis/otp';
import { NotificationManager } from 'react-notifications';

export default function OtpValidation({
  setStep,
  type,
  phoneNumber,
  verificationSid,
}) {
  const [otp, setOtp] = React.useState('');

  const [submitOtp, submitOtpRes] = useVerifyPhoneOtpMutation();

  const handleVerifyPhoneOtp = async () => {
    try {
      await submitOtp({
        verificationSid,
        otp: otp,
      }).unwrap();

      setStep((prevStep) => ({
        ...prevStep,
        current: 'signup',
        phoneNumber,
      }));
    } catch (error) {
      console.log(error);
      NotificationManager.error(error?.data?.message || 'Something went wrong');
    }
  };

  return (
    <Box className={`${sharedStyles.container}`}>
      <Stack className={`${sharedStyles.studentsContainer}`}>
        <Box className={sharedStyles.arrow}>
          <img
            src={Arrow}
            alt="img-discription"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setStep((prevStep) => ({
                ...prevStep,
                current: 'phoneValidation',
              }));
            }}
          />
        </Box>
        <Box className={sharedStyles.imageLeft}>
          <img src={HomeLogin} alt="img-discription" />
        </Box>
      </Stack>
      <Stack className={`${sharedStyles.loginContainer}`}>
        <Box className={sharedStyles.logo}>
          <img src={Logo} alt={'logo'} />
        </Box>

        <Stack className="w-10/12 space-y-5">
          <Typography
            className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
            style={{ color: '#131515' }}
          >
            Welcome to G3MS
          </Typography>
          <Typography className="!font-normal !text-2xl mt-4">
            Enter the OTP sent to your{' '}
            <span style={{ color: '#C73E9D' }}>{type}</span>
          </Typography>
        </Stack>
        <Box
          sx={{
            width: '60%',
            display: 'flex',
            gap: '20px',
            '& .MuiOutlinedInput-input': {
              paddingTop: '25px',
              fontSize: '18px',
              fontWeight: 500,
            },
          }}
          className="flex flex-col items-center gap-4 py-4"
        >
          <OTPInput
            value={otp}
            onChange={setOtp}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
            inputClassName="border-2 border-gray-300 rounded-md !w-12 !h-12 lg:!w-14 lg:!h-14 text-center focus:outline-none focus:border-primary"
          />

          {/* <ResendOTP /> */}
        </Box>
        <Button
          variant={'contained'}
          size="large"
          sx={{
            width: '60%',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '20px',
            borderWidth: '2px',
          }}
          className={'primary_bg_btn'}
          onClick={handleVerifyPhoneOtp}
          disabled={otp.length !== 6 || submitOtpRes.isLoading}
        >
          Next
        </Button>
      </Stack>
    </Box>
  );
}
