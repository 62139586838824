import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Grid } from '@mui/material';
import { correct } from 'asset';
export default function ReviewDone({ quizStat }) {
  const navigate = useNavigate();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      textAlign={'center'}
      p={5}
      m={4}
      sx={{ width: '100%', borderRadius: '14px' }}
    >
      <Box className="flex w-full justify-center items-center">
        <img
          style={{ width: '30%', objectFit: 'cover' }}
          src={correct}
          alt=""
        />
      </Box>
      <Typography
        sx={{
          color: '#000',
          fontWeight: '700',
          fontSize: '1.85rem',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <span
          style={{
            color: '#C73E9D',
            marginLeft: '1ch',
            fontSize: '1.85rem',
            textAlign: 'center',
          }}
        >
          All questions have been reviewed.
        </span>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          width: 'fit-content',
          alignSelf: 'center',
          borderRadius: '16px',
          backgroundColor: '#F8F8F8',
          m: 2,
          p: 3,
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Box>
                <Typography
                  sx={{ color: '#000', fontWeight: '700', fontSize: '1.85rem' }}
                >
                  You have earned
                  <span
                    style={{
                      color: '#C73E9D',
                      fontSize: '1.85rem',
                    }}
                  >
                    {` ${
                      quizStat?.TotalNumberOfIncorrectlyAnsweredPerQuiz || '-'
                    } $G3MS `}
                  </span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <Button
                sx={{
                  textTransform: 'none',
                  width: '100%',
                  background: '#C73E9D',
                  color: '#FFFFFF',
                  boxShadow: 3,
                  mt: 5,
                  '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
                }}
                fullWidth
                size="large"
                onClick={() => navigate('/app/student')}
              >
                Continue
              </Button>
            </Box>
          }
          {/* <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{ color: '#000', fontWeight: '700' }}
          >
            Nice Try, You have scored {finalMarks}/{totalMarks * 10}
          </Typography>
        </Grid>
      </Grid> */}
        </Box>
      </Box>
    </Box>
  );
}
