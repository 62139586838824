import React, { useEffect, useState } from "react";
import styles from './sneakerImg.module.scss'
import { useAiBot } from 'context/AibBotContext';

// SVG parts 
import PlaySneaker from './parts/PLAY'
import TopSneaker from './parts/Top'
import LacesSneaker from './parts/Laces'
import SoleSneaker from './parts/Sole'
import BottomSneaker from './parts/Bottom'
import EyeletsSneaker from './parts/Eyelets'

// Default parts 
import DefaultFullImg from '../../../../../asset/challenges/sneaker/default-left.png';
import DefaultTopImg from '../../../../../asset/challenges/sneaker/sneaker-elements/Top.png';
import DefaultSoleImg from '../../../../../asset/challenges/sneaker/sneaker-elements/Tongue.png';
import DefaultLacesImg from '../../../../../asset/challenges/sneaker/sneaker-elements/Laces.png';
import DefaultEyeletsImg from '../../../../../asset/challenges/sneaker/sneaker-elements/Eyelits.png';
import DefaultBottomImg from '../../../../../asset/challenges/sneaker/sneaker-elements/Bottom.png';

// PLAY parts ::
// floral 
import FloralPlayTopImg from '../../../../../asset/challenges/sneaker/play/floral/Top.png';
import FloralPlayLacesImg from '../../../../../asset/challenges/sneaker/play/floral/Laces.png';
import FloralPlayEyeletsImg from '../../../../../asset/challenges/sneaker/play/floral/Eyelits.png';
import FloralPlaySoleImg from '../../../../../asset/challenges/sneaker/play/floral/Tongue.png';
import FloralPlayBottomImg from '../../../../../asset/challenges/sneaker/play/floral/Bottom.png';

// Pattern parts 
import topFloralTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/top/pattern/floral/Top.png';
import topPaisleyTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/top/pattern/paisley/Top.png';
import topPolkaDotsTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/top/pattern/polka_dots/Top.png';
import topStripesTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/top/pattern/stripes/Top.png';

import lacesFloralTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/laces/pattern/floral/Laces.png';
import lacesPaisleyTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/laces/pattern/paisley/Laces.png';
import lacesPolkaDotsTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/laces/pattern/polka_dots/Laces.png';
import lacesStripesTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/laces/pattern/stripes/Laces.png';

import soleFloralTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/sole/pattern/floral/Tongue.png';
import solePaisleyTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/sole/pattern/paisley/Tongue.png';
import solePolkaDotsTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/sole/pattern/polka_dots/Tongue.png';
import soleStripesTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/sole/pattern/stripes/Tongue.png';

import bottomFloralTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/bottom/pattern/floral/Bottom.png';
import bottomPaisleyTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/bottom/pattern/paisley/Bottom.png';
import bottomPolkaDotsTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/bottom/pattern/polka_dots/Bottom.png';
import bottomStripesTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/bottom/pattern/stripes/Bottom.png';

import eyeletsFloralTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/eyelets/pattern/floral/Eyelits.png';
import eyeletsPaisleyTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/eyelets/pattern/paisley/Eyelits.png';
import eyeletsPolkaDotsTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/eyelets/pattern/polka_dots/Eyelits.png';
import eyeletsStripesTexture from '../../../../../asset/challenges/sneaker/sneaker-elements/eyelets/pattern/stripes/Eyelits.png';

const patterns = {
  polka_dots: [solePolkaDotsTexture, lacesPolkaDotsTexture, topPolkaDotsTexture, bottomPolkaDotsTexture, eyeletsPolkaDotsTexture],
  paisley: [solePaisleyTexture, lacesPaisleyTexture, topPaisleyTexture, bottomPaisleyTexture, eyeletsPaisleyTexture],
  floral: [soleFloralTexture, lacesFloralTexture, topFloralTexture, bottomFloralTexture, eyeletsFloralTexture],
  stripes: [soleStripesTexture, lacesStripesTexture, topStripesTexture, bottomStripesTexture, eyeletsStripesTexture],
};

const Index = ({ }) => {
  const { selectedCanvasObj } = useAiBot();
  const [selectedParts, setSelectedParts] = useState({})

  useEffect(() => {
    selectedCanvasObj?.customizedObj && selectedCanvasObj?.customizedObj?.map((item, _index) => {
      if (item?.part?.name?.toLowerCase() === 'top') {
        setSelectedParts(prev => ({
          ...prev,
          top: item?.color?.imageUrl ? item?.color?.imageUrl : item?.pattern?.imageUrl ? item?.pattern?.imageUrl : item?.texture?.imageUrl
        }))
      }
      if (item?.part?.name?.toLowerCase() === 'laces') {
        setSelectedParts(prev => ({
          ...prev,
          laces: item?.color?.imageUrl ? item?.color?.imageUrl : item?.pattern?.imageUrl ? item?.pattern?.imageUrl : item?.texture?.imageUrl
        }))
      }
      if (item?.part?.name?.toLowerCase() === 'sole') {
        setSelectedParts(prev => ({
          ...prev,
          sole: item?.color?.imageUrl ? item?.color?.imageUrl : item?.pattern?.imageUrl ? item?.pattern?.imageUrl : item?.texture?.imageUrl
        }))
      }
      if (item?.part?.name?.toLowerCase() === 'eyelets') {
        setSelectedParts(prev => ({
          ...prev,
          eyelets: item?.color?.imageUrl ? item?.color?.imageUrl : item?.pattern?.imageUrl ? item?.pattern?.imageUrl : item?.texture?.imageUrl
        }))
      }
      if (item?.part?.name?.toLowerCase() === 'bottom') {
        setSelectedParts(prev => ({
          ...prev,
          bottom: item?.color?.imageUrl ? item?.color?.imageUrl : item?.pattern?.imageUrl ? item?.pattern?.imageUrl : item?.texture?.imageUrl
        }))
      }
    })
  }, [selectedCanvasObj?.shouldPlayFinalAnimation])

  const svgs = {
    top: <TopSneaker selectedCanvasObj={selectedCanvasObj} />,
    laces: <LacesSneaker selectedCanvasObj={selectedCanvasObj} />,
    sole: <SoleSneaker selectedCanvasObj={selectedCanvasObj} />,
    bottom: <BottomSneaker selectedCanvasObj={selectedCanvasObj} />,
    eyelets: <EyeletsSneaker selectedCanvasObj={selectedCanvasObj} />,
  };

  console.log(selectedCanvasObj, 'selectedCanvasObj')

  return (
    <div>
      <div className={styles.sneakerImg}>
        {selectedCanvasObj?.imgPos == 'full' ? (
          <>
            {selectedCanvasObj?.shouldPlayFinalAnimation ? (
              <div className={styles.playGrp}>
                <div className={`${styles.playUtilsShapes} ${styles.bottom}`}>
                  <div style={{ backgroundImage: `url(${selectedParts?.bottom})` }} />
                </div>
                <div className={`${styles.playUtilsShapes} ${styles.sole}`}>
                  <div style={{ backgroundImage: `url(${selectedParts?.sole})` }} />
                </div>
                <div className={`${styles.playUtilsShapes} ${styles.top}`}>
                  <div style={{ backgroundImage: `url(${selectedParts?.top})` }} />
                </div>
                <div className={`${styles.playUtilsShapes} ${styles.eyelets}`}>
                  <div style={{ backgroundImage: `url(${selectedParts?.eyelets})` }} />
                </div>
                <div className={`${styles.playUtilsShapes} ${styles.laces}`}>
                  <div style={{ backgroundImage: `url(${selectedParts?.laces})` }} />
                </div>
              </div>
            ) : (
              <img src={DefaultFullImg} alt="" />
            )}
          </>
        ) : (
          <>
            {selectedCanvasObj?.imgPos == 'sole' ? (
              <>
                {selectedCanvasObj?.pattern ? (
                  <>
                    <img src={patterns?.[selectedCanvasObj?.pattern]?.[0]} alt="" />
                  </>
                ) : (
                  <>
                    {selectedCanvasObj?.imgColor ? (
                      <>
                        {svgs?.sole}
                      </>
                    ) : (
                      <>
                        <img src={DefaultSoleImg} alt="" />
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {selectedCanvasObj?.imgPos == 'laces' ? (
                  <>
                    {selectedCanvasObj?.pattern ? (
                      <>
                        <img src={patterns?.[selectedCanvasObj?.pattern]?.[1]} alt="" />
                      </>
                    ) : (
                      <>
                        {selectedCanvasObj?.imgColor ? (
                          <>
                            {svgs?.laces}
                          </>
                        ) : (
                          <>
                            <img src={DefaultLacesImg} alt="" />
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {selectedCanvasObj?.imgPos == 'top' ? (
                      <>
                        {selectedCanvasObj?.pattern ? (
                          <>
                            <img src={patterns?.[selectedCanvasObj?.pattern]?.[2]} alt="" />
                          </>
                        ) : (
                          <>
                            {selectedCanvasObj?.imgColor ? (
                              <>
                                {svgs?.top}
                              </>
                            ) : (
                              <>
                                <img src={DefaultTopImg} alt="" />
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {selectedCanvasObj?.imgPos == 'bottom' ? (
                          <>
                            {selectedCanvasObj?.pattern ? (
                              <>
                                <img src={patterns?.[selectedCanvasObj?.pattern]?.[3]} alt="" />
                              </>
                            ) : (
                              <>
                                {selectedCanvasObj?.imgColor ? (
                                  <>
                                    {svgs?.bottom}
                                  </>
                                ) : (
                                  <>
                                    <img src={DefaultBottomImg} alt="" />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {selectedCanvasObj?.imgPos == 'eyelets' ? (
                              <>
                                {selectedCanvasObj?.pattern ? (
                                  <>
                                    <img src={patterns?.[selectedCanvasObj?.pattern]?.[4]} alt="" />
                                  </>
                                ) : (
                                  <>
                                    {selectedCanvasObj?.imgColor ? (
                                      <>
                                        {svgs?.eyelets}
                                      </>
                                    ) : (
                                      <>
                                        <img src={DefaultEyeletsImg} alt="" />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {selectedCanvasObj?.pattern ? (
                                  <>
                                    <img src={patterns?.[selectedCanvasObj?.pattern]?.[0]} alt="" />
                                  </>
                                ) : (
                                  <>
                                    {selectedCanvasObj?.imgColor ? (
                                      <>
                                        {svgs?.sole}
                                      </>
                                    ) : (
                                      <>
                                        <img src={DefaultSoleImg} alt="" />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Index;