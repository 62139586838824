import { createSlice } from '@reduxjs/toolkit';

const intialState = {
  videoListing: [],
  videoActivity: [],
  currentVideo: null,
  videoLoading: false,
  followingLoading: false,
  reactionLoading: false,
};

const VideoSlice = createSlice({
  name: 'video_slice',
  initialState: intialState,
  reducers: {
    setVideoActivityList: (state, action) => {
      state.videoActivity = action.payload;
    },
    updateVideoActivityList: (state, action) => {
      state.videoActivity = [action.payload];
    },
    setCurrentVideo: (state, action) => {
      let obj = action.payload;
      state.currentVideo = obj;
    },
    removeVideoActivityList: (state, action) => {
      state.videoActivity = [];
    },
    moveToNextSkills: (state, action) => {
      state.videoListing = state.videoListing.filter(
        (item) => item.id !== state.currentVideo.id,
      );
      state.currentVideo = state.videoListing[0];
      state.videoActivity = [];
    },
    removeifAvaliable: (state, action) => {
      state.videoListing = state.videoListing.filter(
        (item) => item.id !== state.currentVideo.id,
      );
    },
    setVideoList: (state, action) => {
      state.videoListing = action.payload;
    },
    setVideoLoading: (state, action) => {
      state.videoLoading = action.payload;
    },
    setFollowingLoading: (state, action) => {
      state.followingLoading = action.payload;
    },
    setReactionLoading: (state, action) => {
      state.reactionLoading = action.payload;
    },
  },
});

export const {
  setVideoList,
  setVideoLoading,
  setFollowingLoading,
  setReactionLoading,
  setVideoActivityList,
  updateVideoActivityList,
  removeVideoActivityList,
  setCurrentVideo,
  moveToNextSkills,
  removeifAvaliable,
} = VideoSlice.actions;

export default VideoSlice.reducer;
