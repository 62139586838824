const Index = ({ selectedCanvasObj }) => {
  return (
    <>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="100%" viewBox="0 0 992 992" enable-background="new 0 0 992 992" >
<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none" 
	d="
M774.096558,620.345947 
	C756.399109,621.551392 739.158569,622.374084 721.889771,622.606140 
	C719.772095,622.634583 717.667358,623.231384 715.545898,623.470093 
	C711.654602,623.908020 710.156128,622.276794 710.512939,618.261658 
	C711.806519,603.704529 714.274170,589.401367 718.404907,575.354553 
	C722.865234,560.186829 728.591980,545.504028 734.577332,530.902832 
	C734.932617,530.036072 735.711365,529.183716 734.947876,527.782715 
	C724.236755,530.823853 713.414429,533.742859 702.688416,536.980774 
	C689.201965,541.052002 675.703308,545.118164 662.376038,549.672363 
	C646.357788,555.146362 630.194153,560.041931 613.694336,563.811035 
	C595.099731,568.058838 576.299805,571.160522 557.191223,570.183167 
	C545.284668,569.574158 533.295837,567.743103 523.381714,560.198792 
	C518.537781,556.512756 515.610535,551.191528 514.664001,545.059875 
	C510.565765,518.511047 521.410034,497.021301 537.302185,477.013580 
	C542.140747,470.921936 548.261719,466.398926 553.646851,461.007538 
	C555.196594,459.455902 557.214722,458.199036 559.492920,459.036926 
	C561.930725,459.933563 561.298462,462.344879 561.314758,464.259094 
	C561.374146,471.257477 561.621277,478.270020 561.287842,485.252869 
	C560.477417,502.224945 571.813599,513.003479 588.378174,515.762207 
	C610.205139,519.397339 631.236572,515.621521 652.208435,510.493591 
	C673.004395,505.408691 693.726562,500.021698 714.491150,494.807617 
	C716.100098,494.403625 717.843018,494.417480 718.816345,492.079041 
	C710.601501,490.177368 702.551453,488.011414 694.571411,485.585236 
	C674.052368,479.346771 654.140686,471.595459 634.492432,462.997986 
	C622.268982,457.649323 610.396912,451.666931 598.958740,444.940125 
	C587.036743,437.928711 576.190430,429.353821 567.726257,418.132446 
	C564.995239,414.511902 564.269836,411.155090 567.601501,407.482544 
	C573.303894,401.196838 578.932190,394.855865 585.760986,389.718079 
	C589.208557,387.124298 592.291382,387.256378 595.646362,390.077972 
	C611.140259,403.108673 628.261353,413.681976 646.434448,422.468018 
	C661.990417,429.988770 677.926819,436.685181 694.574646,441.499176 
	C695.916199,441.887115 697.455933,442.844849 698.798096,441.448730 
	C700.258301,439.929871 699.295044,438.427216 698.756775,436.833679 
	C692.097839,417.121796 677.784851,404.588745 660.011292,395.211578 
	C638.010986,383.604462 614.648132,375.397339 591.185730,367.426849 
	C582.417786,364.448303 581.533508,362.053650 586.132874,354.107147 
	C587.799744,351.227264 589.156799,348.170013 590.742920,345.241180 
	C603.994507,320.770355 619.956116,298.091248 636.468079,275.758148 
	C639.137329,272.147827 641.527588,271.697937 645.710632,273.029633 
	C671.050903,281.096954 696.443054,289.001343 721.892822,296.728821 
	C745.751892,303.973297 770.533447,307.590851 794.065369,315.784241 
	C807.690979,320.528381 821.169922,325.767242 834.231262,332.074188 
	C840.007507,334.863403 846.060303,336.806305 851.647461,340.349396 
	C858.492676,344.690155 861.303894,350.586273 862.197144,357.943878 
	C864.331909,375.527313 866.298889,393.131042 868.370605,410.722229 
	C869.338440,418.940460 870.398926,427.147766 871.400879,435.362000 
	C871.614929,437.116669 872.207031,438.883148 873.928772,439.351807 
	C877.971619,440.452209 878.132690,443.680145 878.291321,446.808411 
	C878.485229,450.631378 878.103699,454.489868 878.399414,458.299744 
	C878.817444,463.685120 877.442810,468.125244 873.049744,471.516571 
	C871.110535,473.013550 870.089417,475.052246 870.348450,477.630585 
	C871.605774,490.147125 873.041260,502.650604 874.007385,515.190063 
	C874.784302,525.273987 876.160217,535.276611 877.223389,545.319580 
	C878.758057,559.816284 881.299194,574.136719 883.826355,588.469421 
	C884.459106,592.058289 885.046936,595.657043 885.780396,599.225586 
	C887.064392,605.472900 885.743835,607.857544 879.438477,609.182922 
	C865.850769,612.038940 852.158630,614.145508 838.313538,615.510254 
	C822.035583,617.114807 805.793213,618.856750 789.434082,619.606812 
	C784.497559,619.833130 779.533997,620.241699 774.096558,620.345947 
M826.775269,576.662842 
	C829.766968,581.027466 834.147583,583.841736 838.328857,586.836853 
	C842.153687,589.576660 846.441101,590.457336 851.141663,589.482727 
	C865.373169,586.531860 873.121033,571.468689 867.010925,558.083801 
	C862.634827,548.497253 855.156555,541.317627 847.455872,534.439148 
	C842.547424,530.054749 841.771362,527.170532 844.899719,521.393616 
	C846.722778,518.026917 848.762634,514.778198 850.618225,511.428436 
	C853.479675,506.263062 855.612122,500.895416 854.984009,494.793060 
	C854.513184,490.218719 851.761719,488.627136 847.793762,491.035736 
	C836.855530,497.675262 824.852051,501.615692 812.695801,505.175964 
	C794.173340,510.600739 775.610840,515.888977 757.097046,521.343018 
	C751.214600,523.075928 750.908569,525.131226 755.045166,529.571960 
	C759.736206,534.607788 765.479980,538.352905 770.654724,542.787109 
	C775.020386,546.528015 778.560364,551.648071 784.613220,552.989868 
	C792.872864,554.820740 801.134033,556.941956 809.512207,557.833801 
	C814.888611,558.406189 818.137634,560.703857 820.512207,565.049805 
	C822.506409,568.699585 824.420471,572.393127 826.775269,576.662842 
M742.219055,490.114044 
	C741.111267,492.297638 742.986389,493.105103 744.180298,494.079620 
	C746.903931,496.302734 748.307556,499.193329 749.048645,502.547699 
	C751.062500,511.662781 753.144714,513.135986 762.568665,511.737610 
	C783.964966,508.562897 805.229431,504.801178 825.603943,497.170959 
	C835.118103,493.607910 844.458984,490.109833 851.076965,481.513794 
	C856.280334,474.755066 857.329102,470.257782 852.832520,463.464355 
	C851.263977,461.094696 850.304138,458.908386 852.062866,456.497589 
	C829.901794,462.030365 807.717529,464.146729 785.255188,464.422791 
	C771.302368,464.594238 757.995911,460.535248 744.309204,459.046844 
	C737.281189,458.282501 735.654541,459.796692 736.699402,466.803345 
	C737.500427,472.174316 740.208069,476.813141 742.196167,481.736877 
	C743.202209,484.228333 745.158325,486.834991 742.219055,490.114044 
M833.645996,446.068878 
	C837.363892,442.136322 837.960144,437.428864 836.330933,432.582520 
	C833.642822,424.585754 832.433533,416.471741 832.964172,408.052429 
	C833.133850,405.360992 832.342712,402.794128 830.946655,400.432831 
	C828.198242,395.784210 826.569214,395.347809 821.608826,397.684418 
	C817.686096,399.532227 814.672668,402.192963 812.922485,406.222351 
	C811.400513,409.726318 808.790833,411.705780 804.882996,412.001404 
	C801.407532,412.264252 798.810913,414.272278 796.124207,416.362640 
	C785.250183,424.823242 776.082825,435.318695 764.392395,442.808014 
	C761.640869,444.570740 758.081665,446.546326 758.848572,450.364471 
	C759.653015,454.369904 763.879150,454.684357 767.180420,455.692078 
	C767.495605,455.788300 767.850708,455.757141 768.162842,455.859711 
	C784.178406,461.124237 800.374268,459.055634 816.544495,456.955383 
	C823.568420,456.043121 829.698181,453.398285 833.645996,446.068878 
z"/>
</svg>
    </>
  );
};

export default Index;
