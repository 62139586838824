import { useAiBot } from 'context/AibBotContext';
import { useState, useEffect } from 'react';
import ResetIcon from '../../../../../asset/challenges/editOptions/reset.svg';
import UndoIcon from '../../../../../asset/challenges/editOptions/undo.svg';
import BgContainer from '../../../../../asset/challenges/preview-desktop-bg.png';
import DressImg from './DressImg';
import styles from './dressPlay.module.scss';
import './movements.css'

const DressPlay = ({ }) => {
  const [triggerAnim, setTriggerAnim] = useState(true);

  const { selectedCanvasObj, onReset } = useAiBot();

  useEffect(() => {
    if(selectedCanvasObj?.shouldPlayFinalAnimation){
      setTriggerAnim(false)
    }
  }, [selectedCanvasObj?.shouldPlayFinalAnimation])

  return (
    <div className={styles.playContainer}>

      <img src={selectedCanvasObj?.mediaUrl} />
      <div className={styles.editingBar}>
        <div className={styles.bar} />
        <div
          onClick={() => setTriggerAnim(!triggerAnim)}
          className={styles.editingUndo}
        >
          <img src={UndoIcon} alt="" />
          {triggerAnim ? 'Play' : 'Stop'}
        </div>
        <div onClick={() => onReset()} className={styles.editingReset}>
          <img src={ResetIcon} alt="" />
          Reset
        </div>
      </div>
      <div className={styles.bgContainer}>
        <img className={styles.bgImg} src={BgContainer} alt="" />
        <div className={styles.dressContainer}>
          <div
            className={`${styles.dress}`}
          >
            <div className={`${styles.dressImg} ${selectedCanvasObj?.imgMaterial ? `movement--${selectedCanvasObj?.imgMaterial}` : 'movement--smooth'} ${triggerAnim && styles.dressImgStop}`}>
              <DressImg chatbotSelectionObj={selectedCanvasObj} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DressPlay;
