import { useEffect, useState } from 'react';
import { useAiBot } from 'context/AibBotContext';
import ResetIcon from '../../../../../asset/challenges/editOptions/reset.svg';
import UndoIcon from '../../../../../asset/challenges/editOptions/undo.svg';
import BgContainer from '../../../../../asset/challenges/preview-desktop-bg.png';
import SneakerImg from './SneakerImg';
import styles from "./sneakerPlay.module.scss";
import './movements.css'

const Index = ({ }) => {
  const [triggerAnim, setTriggerAnim] = useState(true)

  const { selectedCanvasObj, onReset } = useAiBot();

  useEffect(() => {
    if(selectedCanvasObj?.shouldPlayFinalAnimation){
      setTriggerAnim(false)
    }
  }, [selectedCanvasObj?.shouldPlayFinalAnimation])

  return (
    <div className={styles.playContainer}>
      <div className={styles.editingBar}>
        <div className={styles.bar} />
        <div
          onClick={() => setTriggerAnim(!triggerAnim)}
          className={styles.editingUndo}
        >
          <img src={UndoIcon} alt="" />
          {triggerAnim ? 'Play' : 'Stop'}
        </div>
        <div onClick={() => onReset()} className={styles.editingReset}>
          <img src={ResetIcon} alt="" />
          Reset
        </div>
      </div>
      <div className={styles.bgContainer}> 
        <img className={styles.bgImg} src={BgContainer} alt="" />
        <div className={styles.dressContainer}>
          <div className={`${styles.dress}`}>
            <div className={`${styles.dressImg} ${selectedCanvasObj?.imgMaterial ? `movement--${selectedCanvasObj?.imgMaterial}` : 'movement--smooth'} ${triggerAnim && styles.dressImgStop}`}>
              <SneakerImg chatbotSelectionObj={selectedCanvasObj} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
