import React from 'react';
import dayjs from 'dayjs';
import cn from 'utils/cn';

const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

export default function UserChat({
  message = 'Hi, how can I help you today?',
  time = dayjs().fromNow(),
  user = 'You',
  avatar = '/images/demo-avatar.png',
  isTyping = false,
  className = '',
}) {
  return (
    <div className={cn('w-full flex items-center justify-start py-4')}>
      <div
        className={cn(
          'flex gap-4 max-w-[70%] xl:max-w-[60%] w-full',
          className,
        )}
      >
        <div className="size-10 rounded-full shadow-xl shadow-[#00000040] relative">
          <img
            className="h-full w-full rounded-full object-cover "
            src={avatar}
            alt="avatar"
          />
          <div className="bg-[#12B76A] size-[0.625rem] rounded-full ring-1 ring-white absolute right-0 bottom-0"></div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center space-x-5">
            <p className="text-base text-[#344054] font-medium">{user}</p>
            {!isTyping && (
              <p className="md:text-xs text-[#475467]">
                {dayjs(time).fromNow()}
              </p>
            )}
          </div>
          <div className="bg-[#C44297] p-4 rounded-e-lg rounded-b-lg">
            {isTyping ? (
              <span className="loader block mx-auto"></span>
            ) : (
              <article
                className=" text-[#FFFFFF] text-2xl prose lg:prose-xl xl:prose-2xl"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
