import React from 'react';
import style from './index.scss';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'store/store';
import App from './App';
import './index.css';

import 'react-phone-number-input/style.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
