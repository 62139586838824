import { useNavigate } from 'react-router-dom'
import styles from './challengesHeader.module.scss'

import DiamondIcon from "../../../../../asset/challenges/header/diamond_icon.svg";
import GoBackIcon from "../../../../../asset/challenges/header/go_back_icon.svg";
import { useGetMyProfileQuery } from 'store/apis/profile';

const Index = () => {
    const navigate = useNavigate();

    const {data} = useGetMyProfileQuery();

    console.log('profileData', data);
    
    return (
      <>
        <div className={styles.headerWrapper}>
          <span
            onClick={() => navigate('/app/student/challenge-selection')}
            className={styles.goBackBtn}
          >
            <img src={GoBackIcon} alt="Go Back Icon" />
          </span>

          <div className={styles.titleWrapper}>
            <h1>The Rizz Royale</h1>
            <span>Step into the Future of Fashion!</span>
          </div>
          <div className={styles.badge}>
            <img src={DiamondIcon} alt="Diamond Icon" />
            <span>$ {data?.data?.gemsTokens ?? 0}</span>
          </div>
        </div>
      </>
    );
}

export default Index;