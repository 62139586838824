import moment from 'moment';
/**
 * Check for URL queries as well for matching
 * Current URL & Item Path
 *
 * @param item
 * @param activeItem
 */
export const handleURLQueries = (router, path) => {
  if (Object.keys(router.query).length && path) {
    const arr = Object.keys(router.query);

    return (
      router.asPath.includes(path) &&
      router.asPath.includes(router.query[arr[0]]) &&
      path !== '/'
    );
  }

  return false;
};

/**
 * Check if the given item has the given url
 * in one of its children
 *
 * @param item
 * @param currentURL
 */
export const hasActiveChild = (item, currentURL) => {
  const { children } = item;
  if (!children) {
    return false;
  }
  for (const child of children) {
    if (child.children) {
      if (hasActiveChild(child, currentURL)) {
        return true;
      }
    }
    const childPath = child.path;

    // Check if the child has a link and is active
    if (
      child &&
      childPath &&
      currentURL &&
      (childPath === currentURL ||
        (currentURL.includes(childPath) && childPath !== '/'))
    ) {
      return true;
    }
  }

  return false;
};

/**
 * Check if this is a children
 * of the given item
 *
 * @param children
 * @param openGroup
 * @param currentActiveGroup
 */
export const removeChildren = (children, openGroup, currentActiveGroup) => {
  children.forEach((child) => {
    if (!currentActiveGroup.includes(child.title)) {
      const index = openGroup.indexOf(child.title);
      if (index > -1) openGroup.splice(index, 1);

      // @ts-ignore
      if (child.children)
        removeChildren(child.children, openGroup, currentActiveGroup);
    }
  });
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLocalDateTime = (date, format = 'MMMM D, YYYY, HH:mm') => {
  if (!date) {
    return null;
  }
  const d = new Date(date);
  return moment(d).format(format);
};

const _LessonTypes = {
  video: 'Video',
  template: 'Quiz',
  dailyChallenges: 'Daily Challenge',
};

export const getFirstAvailableContent = (content, key) => {
  const availableContent =
    ['video', 'template', 'dailyChallenges'].find((type) => content[type]) ||
    {};

  const newKey = availableContent === 'dailyChallenges' ? 'topic' : key;

  if (
    content[availableContent] &&
    content[availableContent][newKey] &&
    content[availableContent][newKey][0]
  ) {
    return {
      text: content[availableContent]?.[newKey][0]?.[newKey]?.name,
      title: _LessonTypes[availableContent],
      type: availableContent,
    };
  } else {
    return {
      text: '',
      title: _LessonTypes[availableContent],
      type: availableContent,
    };
  }
};

export const accordionColors = {
  video: {
    background: '#BBF3FB',
  },
  template: {
    background: '#FFBFEB7A',
  },
  dailyChallenges: {
    background: '#A0F2CB',
  },
};

export const assignmentStatusType = {
  todo: 'Not Started',
  progress: 'In Progress',
  completed: 'Completed',
};
