import styles from "./selectOption.module.scss"
import Icon from "./arrow_down.png"
import {useEffect, useRef} from "react";

const SelectOption = ({options, setSelected, firstIcon, label, width}) => {
    const labelRef = useRef(null)
    const selectRef = useRef(null)

    useEffect(() => {
        if (width) {
            labelRef.current.style.width = "100%"
            selectRef.current.style.width = "100%"
        }

    }, []);


    return (
        <label className={styles.container} ref={labelRef}>
            {firstIcon && <img src={firstIcon} alt={"icon"}/>}
            <p>{label}</p>
            <select ref={selectRef} className={styles.select} onChange={(current) => {
                setSelected(current.target.value)
            }}>
                {options.map((option, index) => <option key={index} value={option}>{option}</option>)}
            </select>

        </label>
    )
}

export default SelectOption