import { apiSlice } from '..';

export const assignmentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssignments: builder.query({
      query: (id) => {
        return {
          url: 'assignment/due-today/' + id,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useLazyGetAssignmentsQuery, useGetAssignmentsQuery } =
  assignmentsApiSlice;
