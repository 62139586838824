import styles from "./selectType.module.scss"
import {useEffect, useState} from "react";

export const options = ["Teacher", "Student", "Parent", "School_leader"]

const SelectButton = ({selected, name, onClick}) => {
    return (<button className={`${styles.button} ${selected ? styles.selected : ""}`} onClick={onClick}>{name}</button>)
}

const SelectType = ({setCurrentState, defaultType}) => {

    const [selected, setSelected] = useState(0)

    useEffect(() => {
        if (defaultType)
            setSelected(options.map(option => option.toLowerCase()).indexOf(defaultType))

    }, [])

    useEffect(() => {
        if (selected == -1)
            setSelected(0)
        setCurrentState(options[selected])
    }, [selected]);


    return (<div className={styles.selectType}>
        {options.map((option, index) =>
            <SelectButton
                key={index}
                name={option}
                selected={index === selected}
                onClick={() => {
                    setSelected(index)
                }}/>
        )}
    </div>)
}

export default SelectType