import { createAsyncThunk } from '@reduxjs/toolkit';
import { setTeacherData } from 'store/Slice/teacherSlice';
import API from 'store/api';

export const getTeacherData = createAsyncThunk(
  'getTeacherData',
  async (_request, { dispatch }) => {
    let url = `/api/v1/tutors/classroom/student/${_request.studentId}`;
    try {
      const response = await API.get(url);
      dispatch(setTeacherData(response.data.data));
      return response;
    } catch (err) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
