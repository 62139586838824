// ** MUI Imports
import { styled, useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';

// ** Util Import
import { hexToRGBA } from '@core/utils/hex-to-rgba';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  transition: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  overflow: 'hidden',
  marginBottom: '15px',
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: '100%',
  padding: '0 !important',
  borderRadius: '18px',
  minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
  transition:
    'padding .25s ease-in-out, box-shadow .25s ease-in-out, backdrop-filter .25s ease-in-out',
}));

const LayoutAppBar = (props) => {
  // ** Props
  const { settings, verticalAppBarContent: userVerticalAppBarContent } = props;

  // ** Hooks
  const theme = useTheme();
  const scrollTrigger = useScrollTrigger({
    threshold: 0,
    disableHysteresis: true,
  });

  // ** Vars
  const { skin, appBar, appBarBlur, contentWidth } = settings;

  const appBarFixedStyles = () => {
    return {
      px: `${theme.spacing(6)} !important`,
      ...(appBarBlur && { backdropFilter: 'blur(8px)' }),
      boxShadow: theme.shadows[skin === 'bordered' ? 0 : 3],
      backgroundColor: hexToRGBA(
        theme.palette.background.paper,
        appBarBlur ? 0.9 : 1,
      ),
      ...(skin === 'bordered' && {
        border: `1px solid ${theme.palette.divider}`,
        borderTopWidth: 0,
      }),
    };
  };
  if (appBar === 'hidden') {
    return null;
  }

  return (
    <AppBar
      elevation={0}
      color="default"
      className="layout-navbar !px-0"
      position={appBar === 'fixed' ? 'sticky' : 'static'}

    >
      <Toolbar
        className="navbar-content-container !rounded-none !p-0 !bg-transparent"
        sx={{
          ...(appBar === 'fixed' && { ...appBarFixedStyles() }),
          boxShadow: '0px',
          padding: '0 !important',

        }}
      >
        {(userVerticalAppBarContent && userVerticalAppBarContent(props)) ||
          null}
      </Toolbar>
    </AppBar>
  );
};

export default LayoutAppBar;
