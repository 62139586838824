import { Typography } from '@mui/material';
import React from 'react';
import styles from './topSquad.module.scss';

const SquadCard = ({ place, image, name, tokens, grade }) => {
  return (
    <section className={styles.card}>
      <section style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" color="#000" fontWeight={900}>
          {place}.
        </Typography>
        <div style={{ display: 'flex', marginLeft: 20, alignItems: 'center' }}>
          <img
            src={image}
            width={60}
            height={60}
            style={{ padding: 5, borderRadius: '50%' }}
          />
          <div>
            <p>{name}</p>
            <p>Student, {grade}</p>
          </div>
        </div>
      </section>
      <section style={{ display: 'flex', alignItems: 'center' }}>
        <p className={styles.gemsText}>{tokens} $G3MS</p>
      </section>
    </section>
  );
};

export default SquadCard;
