import styles from './syle.module.scss';

import techoImg from '../../../../../../../asset/challenges/materials/close-up-perforated-fabric.png';
import fabricImg from '../../../../../../../asset/challenges/materials/close-up-velvet-texture.png';
import velvetImg from '../../../../../../../asset/challenges/materials/metalic-fabric-texture1.png';
import fiverImg from '../../../../../../../asset/challenges/materials/white-knitted-fabric-background1.png';
import cn from 'utils/cn';

const Index = ({
  options = [],
  endPointOptions,
  handleOptionSelect,
  selectedCanvasObj,
  setSelectedCanvasObj,
  isDisabled,
}) => {
  console.log(options, 'options in materials');

  const hasOptionsWithLengthFourteen = options?.find(
    (item) => item?.label?.length >= 14,
  );
  
  const convertMaterial = (imgMaterial) => {
    return imgMaterial
        .toLowerCase()      
        .replace(/\(.*?\)/g, '')       
        .trim().toLowerCase();                
  };

  return (
    <div className={styles.materialsWrapper}>
      {options?.map((data, _index) => (
        <button
          className={cn(
            'bg-[#20A58A] h-[3rem] px-4  rounded-xl text-sm sm:text-base md:text-lg text-white font-bold w-full even:bg-[#FF6B6B] [box-shadow:_0px_4px_12px_0px_#FFFFFF66_inset] even:[box-shadow:_0px_4px_12px_0px_#FFFFFF66_inset]',
            {
              'h-[4.5rem]': hasOptionsWithLengthFourteen,
              '!bg-[#C44297]': options?.length > 2,
            },
          )}
          onClick={async () => {
            if (isDisabled) return;

            await handleOptionSelect(data);
            setSelectedCanvasObj({
              ...selectedCanvasObj,
               imgMaterial: convertMaterial(data?.metadata?.TEXTURE?.name),
            });
          }}
          disabled={isDisabled}
        >
          <h3>
            {data?.label} - ${data?.metadata?.price}
          </h3>
        </button>
      ))}
    </div>
  );
};

export default Index;
