import { useEffect, useState } from 'react';
import { Dna } from 'react-loader-spinner';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import ArrowDownIcon from '../../components/input/arrow_down.png';
import AutoComplete from '../../components/input/autocomplete';
import RadioButtons from '../../components/input/radioButtons';
import SelectList from '../../components/input/selectList';
import SelectOption from '../../components/input/selectOption';
import SelectOrAdd from '../../components/input/selectOrAdd';
import TextField from '../../components/input/textField';
import sharedStyles from '../../sharedStyle.module.scss';
import { ReactComponent as UploadFileIcon } from '../upload_file.svg';
import CityIcon from './city.png';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import PersonIcon from './person.png';
import SchoolIcon from './school.png';
import TeachIcon from './teach.png';
import config from 'configs/config';
import styles from './teacher.module.scss';
import analytics from 'utils/analytics';

const Teacher = ({ user, uuid, existingEmail }) => {
  const [gender, setGender] = useState('Mr');
  const [school, setSchool] = useState('');
  const [schoolLeader, setSchoolLeader] = useState('');
  const [schoolLeaderEmail, setSchoolLeaderEmail] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [teachMethod, setTeachMethod] = useState('teach');
  const [image, setImage] = useState('');
  const [imageObject, setImageObject] = useState(null);
  const [email, setEmail] = useState('');

  const navigate = useNavigate();
  console.log('teacher component called');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (existingEmail) {
      setEmail(existingEmail);
    }
  }, [existingEmail]);

  function onSignUp() {
    if (!firstName || !imageObject || !gender || !school || !lastName) {
      NotificationManager.error('Please fill all the fields', 'Error');
      return;
    }

    // check if email is valid
    if (!/\S+@\S+\.\S+/.test(email)) {
      NotificationManager.error('Please enter a valid email', 'Error');
      return;
    }

    const schoolId = schoolData.find((v) => v.name === school).id;
    const gradeIds = rawGrades
      .filter((grade) => gradesToRemove.includes(grade.name))
      .map((grade) => grade.id);
    const topicIds = rawTopics
      .filter((topic) => topicsToRemove.includes(topic.name))
      .map((topic) => topic.id);

    const data = new FormData();
    data.append('name', firstName + ' ' + lastName);
    data.append('schoolId', schoolId);

    if (teachMethod == 'teach')
      gradeIds.forEach((gradeId) => data.append('gradeIds[]', gradeId));

    if (teachMethod == 'tutor')
      topicIds.forEach((topicId) => data.append('topicIds[]', topicId));

    data.append('file', imageObject);

    setLoading(true);
    axios
      .put(`/tutors/${uuid}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        setLoading(false);
        localStorage.removeItem('currentUser');
        const token = response.data.token;
        window.location.href = `${config.ADMIN_URL}/school/${schoolId}?token=${token}`;
        analytics.logEvent('complete_profile', {
          user_id: response?.data?.user?.id,
        });
      })
      .catch((error) => {
        setLoading(false);
        NotificationManager.error('Something went wrong', 'Error');
        console.log('error:', error);
        analytics.logEvent('complete_profile_error', { user_id: uuid });
      });
  }

  const [schoolData, setSchoolData] = useState(null);
  const [schoolLeaders, setSchoolLeaders] = useState([]);

  useEffect(() => {
    axios
      .get('/school-leader')
      .then((response) => {
        setSchoolLeaders(
          response.data.data.items.map((leader) => {
            return {
              name: leader.name,
              email: leader.email,
            };
          }),
        );

        analytics.logEvent('get_school_leaders');
      })
      .catch((err) => {
        analytics.logEvent('get_school_leaders_error');
        console.log(err);
      });

    axios
      .get('/grades')
      .then((response) => {
        const grades = response.data.data;
        setRawGrades(grades);
        setGradesToSelect(grades.map((topic) => topic.name));
        analytics.logEvent('get_grades');
      })
      .catch((err) => {
        console.log(err);
        analytics.logEvent('get_grades_error');
      });

    axios
      .get('/topics')
      .then((response) => {
        console.log(response.data.data);
        const topics = response.data.data;
        setRawTopics(topics);
        setTopicsToSelect(topics.map((topic) => topic.name));
        analytics.logEvent('get_topics');
      })
      .catch((err) => {
        console.log(err);
        analytics.logEvent('get_topics_error', { error: err });
      });
  }, []);

  const [state, setState] = useState('');
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    axios
      .get('/state')
      .then((response) => {
        setStateData(response.data.data);
        analytics.logEvent('get_states');
      })
      .catch((err) => {
        console.log(err);
        analytics.logEvent('get_states_error', { error: err });
      });
  }, []);

  useEffect(() => {
    if (state) {
      axios
        .get(`/state/${state}/school`)
        .then((response) => {
          setSchoolData(response.data.data);
          analytics.logEvent('get_schools', { state: state });
        })
        .catch((error) => {
          console.log(error);
          analytics.logEvent('get_schools_error', {
            state: state,
            error: error,
          });
        });
    }
  }, [state]);

  const [rawGrades, setRawGrades] = useState([]);
  const [gradesToSelect, setGradesToSelect] = useState([]);
  const [gradesToRemove, setGradesToRemove] = useState([]);

  const [rawTopics, setRawTopics] = useState([]);
  const [topicsToSelect, setTopicsToSelect] = useState([]);
  const [topicsToRemove, setTopicsToRemove] = useState([]);

  return (
    <div className={sharedStyles.centerContainer}>
      <div
        className={`${styles.completeProfile} ${sharedStyles.whiteContainerBox}`}
      >
        <h1 className={styles.title}>Please complete your profile.</h1>
        <h1 className={styles.title}>
          Don't worry, your data will remain private and only you can see it.
        </h1>

        <label className={sharedStyles.uploadImage}>
          {image ? <img src={image} alt={'profile'} /> : <UploadFileIcon />}

          <input
            type={'file'}
            name={'upload_image'}
            onChange={(target) => {
              setImage(URL.createObjectURL(target.target.files[0]));
              setImageObject(target.target.files[0]);
            }}
          />
        </label>

        <div className={styles.row}>
          <SelectOption options={['Mr', 'Mrs']} setSelected={setGender} />
          <TextField
            type={'text'}
            placeholder={'First Name'}
            icon={PersonIcon}
            onChange={setFirstName}
          />
          <TextField
            type={'text'}
            placeholder={'Last Name'}
            icon={PersonIcon}
            onChange={setLastName}
          />
        </div>

        <TextField
          type={'text'}
          value={email}
          placeholder={'Enter your email'}
          icon={SchoolIcon}
          onChange={setEmail}
        />

        <div className={styles.row}>
          <AutoComplete
            placeholder={'Select State'}
            icon={SchoolIcon}
            onChange={setState}
            value={state}
            options={stateData}
          />
          <AutoComplete
            placeholder={'School'}
            icon={SchoolIcon}
            onChange={setSchool}
            value={school}
            options={(schoolData || []).map((school) => school.name)}
          />
        </div>

        <RadioButtons
          value={'teach'}
          options={['teach', 'tutor']}
          onChange={setTeachMethod}
          template={(option) => `I ${option}`}
        />

        {teachMethod === 'teach' && (
          <>
            <SelectList
              options={gradesToSelect}
              optionsSelected={gradesToRemove}
              label={'Select Grade'}
              onClickOption={(option) => {
                setGradesToRemove([...gradesToRemove, option]);
              }}
              firstIcon={TeachIcon}
            />

            <div className={`${styles.row} ${styles.wrap}`}>
              {gradesToRemove.map((topic, index) => {
                return (
                  <div key={index} className={styles.topic}>
                    <p>{topic}</p>
                    <button
                      onClick={() => {
                        setGradesToRemove(
                          gradesToRemove.filter((v) => v !== topic),
                        );
                      }}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {teachMethod === 'tutor' && (
          <>
            <SelectList
              options={topicsToSelect}
              optionsSelected={topicsToRemove}
              label={'Select Topics'}
              onClickOption={(option) => {
                setTopicsToRemove([...topicsToRemove, option]);
              }}
              firstIcon={TeachIcon}
            />

            <div className={`${styles.row} ${styles.wrap}`}>
              {topicsToRemove.map((topic, index) => {
                return (
                  <div key={index} className={styles.topic}>
                    <p>{topic}</p>
                    <button
                      onClick={() => {
                        setTopicsToRemove(
                          topicsToRemove.filter((v) => v !== topic),
                        );
                      }}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                );
              })}
            </div>
          </>
        )}

        <button
          className={sharedStyles.loginButton}
          onClick={onSignUp}
          style={{ backgroundColor: '#C73E9D', color: '#F5F5F5' }}
        >
          Sign Up
        </button>

        {loading && (
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        )}
      </div>
    </div>
  );
};

export default Teacher;
