import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

export function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export function useSelectQueryElement(queryType, componentList) {
  const [component, setComponent] = useState(null)

  useMemo(() => {
    let findElement = componentList.find((element) => element.id === queryType)

    if (!findElement) findElement = componentList[0]

    setComponent(findElement.component)
  }, [queryType])

  return component
}
