import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { IncorrectIcon } from 'asset';
import sharedStyles from '../../../sharedStyle.module.scss';
import hazard from '../../../components/hazard.png';
import { useAppDispatch } from 'store/store';
import { addTemporarySchool } from 'store/Thunk/registerThunk';
import { NotificationManager } from 'react-notifications';

export default function ConfirmSchool({ open, onClose, formData, setFormData, setSchoolCode }) {
  const dispatch = useAppDispatch();
  const createSchool = () => {
    dispatch(addTemporarySchool({
      name: formData?.schoolName,
      address: formData?.schoolAddress,
      type: formData?.schoolType?.name,
    }))
      .unwrap()
      .then((data) => {
        setFormData({ ...formData, schoolCode: data.data.id });
        NotificationManager.success("School added successfully", "Success");
        setSchoolCode(true);
        onClose();
      })
      .catch((err) => {
        NotificationManager.error("Error while adding school", "Error");
        console.log(err)
      });
  }

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <Box className="justify-center flex w-full mt-10 mb-4">
        <img src={hazard} alt={'logo'} />
      </Box>
      <DialogTitle
        sx={{ fontSize: '1.7vw !important', color: '#000' }}
        fontWeight={600}
        textAlign={'center'}
        mb={6}
      >
        Do you want to be added this school?
      </DialogTitle>
      <DialogContent>
        <Stack
          gap={3}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            '& .MuiTypography-root': {
              fontWeight: 400,
              fontSize: '16px',
              color: '#C73E9D',
            },
            '& span': { color: '#979C9E' },
          }}
        >
          <Box className="flex">
            <Typography>
              School Name: <span>{formData?.schoolName}</span>
            </Typography>
          </Box>
          <Box className="flex">
            <Typography>
              School Address: <span>{formData?.schoolAddress}</span>
            </Typography>
          </Box>
          <Box className="flex">
            <Typography>
              School Type: <span> {formData?.schoolType?.name}</span>
            </Typography>
          </Box>
        </Stack>
        <Box
          mt={8}
          display={'flex'}
          alignItems={'center'}
          gap={8}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Button
            variant={'outlined'}
            size="large"
            className={'primary_bg_btn'}
            onClick={onClose}
            sx={{
              flex: 1,
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
          >
            No
          </Button>
          <Button
            size="large"
            className={'primary_bg_btn'}
            sx={{
              flex: 1,
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
            variant="contained"
            onClick={createSchool}
          >
            Yes
          </Button>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
