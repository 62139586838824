import { useCallback, useState } from 'react';
import { SentenceMapper, computeValue } from 'utils/processSentence';

const useProcessSentence = () => {
  const [processedSentence, setProcessedSentence] = useState([]);

  const processSentence = useCallback((question) => {
    let words = question && SentenceMapper(question)?.split(' ');
    let processedWords =
      question &&
      words?.map((word, index) => {
        if (word.match(/\d+\/\d+/)) {
          const computedValue = computeValue(word);
          return (
            <span
              key={index}
              style={{
                marginLeft: '5px',
                marginRight: '5px',
                color: 'rgba(76, 78, 100, 0.87)',
              }}
            >
              {computedValue}
            </span>
          );
        }
        return word;
      });
    setProcessedSentence(processedWords);
  }, []);

  return {
    processedSentence,
    processSentence,
  };
};

export default useProcessSentence;
