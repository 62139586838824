import Skeleton from './skeleton';
import ChallengeCard from './card';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import styles from './challengeSelection.module.scss';

import GoBackIcon from '../../../../asset/challenge_selection/go_back_icon.svg';
import DiamondIcon from '../../../../asset/challenge_selection/diamond_icon.svg';
import CodeCustomKicksImg from '../../../../asset/challenge_selection/code_custom_kicks.jpg';
import DesignStatementDressImg from '../../../../asset/challenge_selection/design_statement_dress.jpg';
import { useGetMyProfileQuery } from 'store/apis/profile';

const Index = () => {
  const navigate = useNavigate();
  const [challengeData, setChallengeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
   const { data } = useGetMyProfileQuery();



  console.log(challengeData, 'challengeDatachallengeData')

  useEffect(() => {
    fetch('https://dev.g3ms.co/api/v1/challenges')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Fetched data:', data);
        if (data.successCode === 200) {
          console.log(data, 'data in abc')
          setChallengeData(data?.data);
        } else {
          throw new Error('Unexpected API response');
        }
      })
      .catch((error) => {
        console.error('Error fetching challenge data:', error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  console.log(challengeData, 'data in success 1')

  const challenges = [{
    title: 'Glam Code: Design Your Statement Dress',
    tooltipContent: 'Create a stunning dress for the Rizz Royale Dance Party. Use AI commands to stay within budget while making sure your design turns heads on the dance floor.',
    imgSrc: DesignStatementDressImg,
    url: '/app/student/dress-challenge'
  },
  {
    title: 'Kickstart: Code Your Custom Kicks',
    tooltipContent: 'Design the ultimate sneaker for the Rizz Royale Dance Party. Mix style with functionality as you use AI commands to stay on budget and make your sneakers stand out.',
    imgSrc: CodeCustomKicksImg,
    url: '/app/student/sneaker-challenge'
  }]

  return (
    <>
      <div className={styles.challengesSelectorWrapper}>
        <div className={styles.headerWrapper}>
          <span
            className={styles.goBackBtn}
            onClick={() => navigate('/app/student')}
          >
            <img src={GoBackIcon} alt="Go Back Icon" />
          </span>

          <div className={styles.titleWrapper}>
            <h1>The Rizz Royale</h1>
            <span>Step into the Future of Fashion!</span>
          </div>
          <div className={styles.badge}>
            <img src={DiamondIcon} alt="Diamond Icon" />
            <span>$ {data?.data?.gemsTokens ?? 0}</span>
          </div>
        </div>

        <div className={styles.descriptionWrapper}>
          <h2>Choose Your Rizz Royale Challenge: </h2>
          <p>
            Step into the future of design and music. With AI commands, you’ll
            create your own sneakers, dresses, and mix music. In this
            earn-to-code challenge, you’ll design for the Rizz Royale Dance
            Party—within budget, on beat, and in style. Ready to code your
            future?
          </p>
        </div>

        <div className={styles.challengesWrapper}>
          {loading ? (
            <>
              {[...Array(2)]?.map((item, _index) => (
                <Skeleton key={_index} />
              ))}
            </>
          ) : (
            <>
              {challengeData?.data?.map((challenge, _index) => (
                <ChallengeCard
                  key={_index}
                  Index={_index}
                  data={challenge}
                  defaultChallenges={challenges}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Index;  