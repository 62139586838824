import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  quizList: [],
  leaderBoardList: [],
  leaderBoardTopTen: [],
  quizLoading: false,
  doneQuiz: false,
  startQuiz: false,
  questionDuration: 0,
  timeGiven: 60,
  pauseTimer: false,
  hideTimer: false,
};

const QuizSlice = createSlice({
  name: 'quiz_slice',
  initialState: initialState,
  reducers: {
    setQuizList: (state, action) => {
      state.quizList = action.payload;
    },
    setLeaderBoardList: (state, action) => {
      state.leaderBoardList = action.payload;
    },
    setLeaderBoardTopTen: (state, action) => {
      console.log('action', action.payload);
      state.leaderBoardTopTen = action.payload;
    },
    setQuizLoading: (state, action) => {
      state.quizLoading = action.payload;
    },
    setDoneQuiz: (state, action) => {
      state.doneQuiz = action.payload;
    },
    setStartQuiz: (state, action) => {
      state.startQuiz = action.payload;
    },
    setQuestionDuration: (state, action) => {
      state.questionDuration = action.payload;
    },
    setTimeGiven: (state, action) => {
      state.timeGiven = action.payload;
    },
    setPauseTimer: (state, action) => {
      state.pauseTimer = action.payload;
    },
    setHideTimer: (state, action) => {
      state.hideTimer = action.payload;
    },
  },
});

export const {
  setQuizList,
  setLeaderBoardList,
  setQuizLoading,
  setLeaderBoardTopTen,
  setPauseTimer,
  setDoneQuiz,
  setStartQuiz,
  setQuestionDuration,
  setHideTimer,
  setTimeGiven,
} = QuizSlice.actions;

export default QuizSlice.reducer;
