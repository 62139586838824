import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import sharedStyles from '../payment.module.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Promo from './Promo';
import Upgrade from './Upgrade';
export default function Pricing({ setStep, isUpgrade, setIsUpgrade }) {
  const [pricingPlan, setPricingPlan] = useState('monthly');
  const [subscription, setSupscription] = useState('1');
  const [numberOfStudents, setNumberOfStudents] = useState(0);
  const [openPromo, setOpenPromo] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const closePromo = () => {
    setOpenPromo(false);
  };
  const closeUpgrade = () => {
    setOpenUpgrade(false);
  };
  const decreaseStudentCount = () => {
    setNumberOfStudents((s) => s - 1);
  };
  const increaseStudentCount = () => {
    setNumberOfStudents((s) => s + 1);
    numberOfStudents > 0 && setOpenUpgrade(true);
  };
  const handleNumberOfStudents = (e) => {
    setNumberOfStudents(e.target.value);
  };
  const handleUpgrade = (type) => {
    setStep((prevStep) => ({
      ...prevStep,
      current: type === 'free' ? 'freePlan' : 'payment',
    }));
  };
  return (
    <Stack className={sharedStyles.pricingContainer}>
      <Typography className={sharedStyles.pricingHeader}>
        Hey James! Let’s select the best G3MS plan for you.
      </Typography>
      <Stack className="!flex-row gap-3 justify-center items-center w-full">
        <Typography className="!text-3xl !font-semibold !text-gray-500">
          How many students will be using G3MS?
        </Typography>
        <Box className={sharedStyles.pCounter}>
          <Box onClick={decreaseStudentCount} sx={{ cursor: 'pointer' }}>
            -
          </Box>
          <TextField
            className={sharedStyles.studentsCounter}
            id="my-textfield"
            variant="outlined"
            onChange={handleNumberOfStudents}
            value={numberOfStudents || 0}
            style={{
              fontSize: '18px',
              fontWeight: 500,
              width: '40px',
              padding: '0px',
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove the border
                outline: 'none', // Remove the outline
              },
              '& .MuiOutlinedInput-input': {
                padding: '0px',
                textAlign: 'center',
              },
            }}
          />
          <Box sx={{ cursor: 'pointer' }} onClick={increaseStudentCount}>
            +
          </Box>
        </Box>
      </Stack>
      <Typography className={sharedStyles.pricingHeader}>
        Pricing Plan
      </Typography>
      <Stack className={sharedStyles.pricingPlan}>
        <Paper
          className={`${pricingPlan === 'annual' && sharedStyles.activeBtn} ${
            sharedStyles.pricingBtn
          }`}
          elevation={pricingPlan === 'annual' ? 2 : 0}
          onClick={() => {
            setPricingPlan('annual');
          }}
        >
          Annual Billing
        </Paper>
        <Paper
          className={`${pricingPlan === 'monthly' && sharedStyles.activeBtn} ${
            sharedStyles.pricingBtn
          }`}
          elevation={pricingPlan === 'monthly' ? 2 : 0}
          onClick={() => {
            setPricingPlan('monthly');
          }}
        >
          Monthly Billing
        </Paper>
      </Stack>
      <Stack className={sharedStyles.subscription}>
        {isUpgrade ? (
          <Paper
            className={`${
              subscription === '1' && sharedStyles.activeSubscritpion
            } ${sharedStyles.subscriptionBtn}`}
            elevation={8}
            onClick={() => {
              setPricingPlan('annual');
            }}
            sx={{ borderColor: '#D1D8DD !important' }}
          >
            <Typography
              sx={{ color: '#D1D8DD' }}
              fontWeight={600}
              fontSize={'18px'}
              mb={4}
            >
              Forever FREE{' '}
            </Typography>
            <Typography
              sx={{ color: '#D1D8DD' }}
              fontWeight={600}
              fontSize={'60px'}
            >
              FREE
            </Typography>
            <Button
              variant={'contained'}
              size="large"
              sx={{
                width: '100%',
                mt: '32px',
                textTransform: 'capitalize',
                fontWeight: 500,
                fontSize: '20px',
                borderWidth: '2px',
                color: '#fff !important',
              }}
              className={'primary_bg_btn'}
              disabled
            >
              Get Started
            </Button>
            <Divider orientation="horizontal" sx={{ margin: '32px 0px' }} />
            <Typography
              sx={{ color: '#000' }}
              mb={3}
              fontWeight={600}
              fontSize={'16px'}
            >
              FEATURES
            </Typography>
            <Stack gap={4}>
              <Box className="flex gap-2 items-center">
                <CheckCircleOutlineIcon
                  sx={{ color: '#D1D8DD', fontSize: '30px' }}
                />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  Limit to 1 children in one household
                </Typography>
              </Box>
              <Box className="flex gap-2 items-center">
                <CheckCircleOutlineIcon
                  sx={{ color: '#D1D8DD', fontSize: '30px' }}
                />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  No Daily Challenges
                </Typography>
              </Box>
              <Box className="flex gap-2 items-center">
                <CheckCircleOutlineIcon
                  sx={{ color: '#D1D8DD', fontSize: '30px' }}
                />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  Limited freebie rewards
                </Typography>
              </Box>
              <Box className="flex gap-2 items-center">
                <CheckCircleOutlineIcon
                  sx={{ color: '#D1D8DD', fontSize: '30px' }}
                />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  Access to Daily Practice assignments
                </Typography>
              </Box>
              <Box className="flex gap-2 items-start">
                <HighlightOffIcon sx={{ color: '#D1D8DD', fontSize: '30px' }} />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  No camera on App
                </Typography>
              </Box>
              <Box className="flex gap-2 items-start">
                <HighlightOffIcon sx={{ color: '#D1D8DD', fontSize: '30px' }} />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  Access to Admin panel, create content is NOT available
                </Typography>
              </Box>
            </Stack>
          </Paper>
        ) : (
          <Paper
            className={`${
              subscription === '1' && sharedStyles.activeSubscritpion
            } ${sharedStyles.subscriptionBtn}`}
            elevation={8}
            onClick={() => {
              setPricingPlan('annual');
            }}
          >
            <Typography
              sx={{ color: '#C73E9D' }}
              fontWeight={600}
              fontSize={'18px'}
              mb={4}
            >
              Forever FREE{' '}
            </Typography>
            <Typography
              sx={{ color: '#000' }}
              fontWeight={600}
              fontSize={'60px'}
            >
              FREE
            </Typography>
            <Button
              variant={'contained'}
              size="large"
              sx={{
                width: '100%',
                mt: '32px',
                textTransform: 'capitalize',
                fontWeight: 500,
                fontSize: '20px',
                borderWidth: '2px',
              }}
              className={'primary_bg_btn'}
              onClick={() => handleUpgrade('free')}
            >
              Get Started
            </Button>
            <Divider orientation="horizontal" sx={{ margin: '32px 0px' }} />
            <Typography
              sx={{ color: '#000' }}
              mb={3}
              fontWeight={600}
              fontSize={'16px'}
            >
              FEATURES
            </Typography>
            <Stack gap={4}>
              <Box className="flex gap-2 items-center">
                <CheckCircleOutlineIcon
                  sx={{ color: '#20A58A', fontSize: '30px' }}
                />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  Limit to 1 children in one household
                </Typography>
              </Box>
              <Box className="flex gap-2 items-center">
                <CheckCircleOutlineIcon
                  sx={{ color: '#20A58A', fontSize: '30px' }}
                />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  No Daily Challenges
                </Typography>
              </Box>
              <Box className="flex gap-2 items-center">
                <CheckCircleOutlineIcon
                  sx={{ color: '#20A58A', fontSize: '30px' }}
                />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  Limited freebie rewards
                </Typography>
              </Box>
              <Box className="flex gap-2 items-center">
                <CheckCircleOutlineIcon
                  sx={{ color: '#20A58A', fontSize: '30px' }}
                />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  Access to Daily Practice assignments
                </Typography>
              </Box>
              <Box className="flex gap-2 items-start">
                <HighlightOffIcon sx={{ color: '#F41F52', fontSize: '30px' }} />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  No camera on App
                </Typography>
              </Box>
              <Box className="flex gap-2 items-start">
                <HighlightOffIcon sx={{ color: '#F41F52', fontSize: '30px' }} />
                <Typography
                  sx={{ color: '#475467' }}
                  fontWeight={400}
                  fontSize={'16px'}
                >
                  Access to Admin panel, create content is NOT available
                </Typography>
              </Box>
            </Stack>
          </Paper>
        )}
        <Paper
          className={`${
            subscription === '2' && sharedStyles.activeSubscritpion
          } ${sharedStyles.subscriptionBtn}`}
          elevation={8}
          onClick={() => {
            setPricingPlan('annual');
          }}
        >
          <Typography
            sx={{ color: '#C73E9D' }}
            fontWeight={600}
            fontSize={'18px'}
            mb={4}
          >
            G3MS +Plus
          </Typography>
          <Typography sx={{ color: '#000' }} fontWeight={600} fontSize={'60px'}>
            $6.99
            <sub
              style={{ fontSize: '14px', fontWeight: '400', color: '#84818A' }}
            >
              Per Month for 1st user
            </sub>
          </Typography>
          <Typography
            sx={{ color: '#20A58A' }}
            fontWeight={600}
            fontSize={'16px'}
            mt={4}
            mb={2}
          >
            Additional User
          </Typography>
          <Typography sx={{ color: '#000' }} fontWeight={600} fontSize={'40px'}>
            $4.00
            <sub
              style={{ fontSize: '16px', fontWeight: '400', color: '#84818A' }}
            >
              Per Month for 1st user
            </sub>
          </Typography>
          <Button
            variant={'contained'}
            size="large"
            sx={{
              width: '100%',
              mt: '32px',
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
            className={'primary_bg_btn'}
            onClick={() => handleUpgrade('plus')}
          >
            Get Started
          </Button>
          <Divider orientation="horizontal" sx={{ margin: '32px 0px' }} />
          <Typography
            sx={{ color: '#000' }}
            mb={3}
            fontWeight={600}
            fontSize={'16px'}
          >
            FEATURES
          </Typography>
          <Stack gap={4}>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                More users
              </Typography>
            </Box>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                More classrooms
              </Typography>
            </Box>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                Camera on mobile
              </Typography>
            </Box>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                Daily Challenges
              </Typography>
            </Box>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                Full rewards store
              </Typography>
            </Box>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                Pay per user
              </Typography>
            </Box>
          </Stack>
        </Paper>

        <Paper
          className={`${
            subscription === '2' && sharedStyles.activeSubscritpion
          } ${sharedStyles.subscriptionBtn}`}
          elevation={8}
          onClick={() => {
            setPricingPlan('annual');
          }}
        >
          <Typography
            sx={{ color: '#C73E9D' }}
            fontWeight={600}
            fontSize={'18px'}
            mb={4}
          >
            G3MS for Life! Pay once and NEVER pay again!
          </Typography>
          <Typography sx={{ color: '#000' }} fontWeight={600} fontSize={'60px'}>
            $129
            <sub
              style={{ fontSize: '14px', fontWeight: '400', color: '#84818A' }}
            >
              Per User
            </sub>
          </Typography>
          <Typography
            sx={{ color: '#20A58A' }}
            fontWeight={600}
            fontSize={'16px'}
            mt={4}
            mb={2}
          >
            Additional User
          </Typography>
          <Typography sx={{ color: '#000' }} fontWeight={600} fontSize={'40px'}>
            $89.99
            <sub
              style={{ fontSize: '16px', fontWeight: '400', color: '#84818A' }}
            >
              Per Month
            </sub>
          </Typography>
          <Button
            variant={'contained'}
            size="large"
            sx={{
              width: '100%',
              mt: '32px',
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
            className={'primary_bg_btn'}
            onClick={() => handleUpgrade('life')}
          >
            Get Started
          </Button>
          <Divider orientation="horizontal" sx={{ margin: '32px 0px' }} />
          <Typography
            sx={{ color: '#000' }}
            mb={3}
            fontWeight={600}
            fontSize={'16px'}
          >
            FEATURES
          </Typography>
          <Stack gap={4}>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                More users
              </Typography>
            </Box>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                More classrooms
              </Typography>
            </Box>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                Camera on mobile
              </Typography>
            </Box>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                Daily Challenges
              </Typography>
            </Box>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                Full rewards store
              </Typography>
            </Box>
            <Box className="flex gap-2 items-center">
              <CheckCircleOutlineIcon
                sx={{ color: '#20A58A', fontSize: '30px' }}
              />
              <Typography
                sx={{ color: '#475467' }}
                fontWeight={400}
                fontSize={'16px'}
              >
                Pay per user
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Stack>
      <Typography
        className={sharedStyles.footertext}
        sx={{ color: '#979C9E', fontSize: '32px', fontWeight: 700 }}
        mt={6}
      >
        Cancel your subscription at any time.
      </Typography>
      <Typography
        className={sharedStyles.footertext2}
        sx={{
          color: '#C73E9D',
          fontSize: '48px',
          fontWeight: 800,
          '&:hover': {
            textDecoration: 'underline', // Add underline on hover
          },
        }}
        onClick={() => {
          setOpenPromo(true);
        }}
      >
        Got a Promo or Referral Code?
      </Typography>
      <Promo open={openPromo} onClose={closePromo} />
      <Upgrade
        open={openUpgrade}
        setIsUpgrade={setIsUpgrade}
        onClose={closeUpgrade}
      />
    </Stack>
  );
}
