import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FaCaretUp } from 'react-icons/fa6';

export default function STELeftHeader() {
  const navigate = useNavigate();
  return (
    <div className="flex gap-4 items-center justify-between flex-wrap">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center space-x-2 p-2"
      >
        <img src="/svg/back-arrow.svg" alt="arrow-left" />
      </button>

      <h1 className="text-[#090909] text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-[2rem] font-bold">
        Share to Earn
      </h1>

      <div className="flex items-end gap-4">
        <div className="px-3 py-[0.375rem] min-w-16 flex items-center justify-center gap-2 bg-[#C44297] rounded-full font-bold text-xs text-white">
          <FaCaretUp />
          <span>4.5%</span>
        </div>

        <div className="space-y-1 ">
          <p className="text-black font-medium text-xs  md:text-sm xl:text-base">
            Total Earning
          </p>
          <h2 className="text-black font-bold text-base md:text-lg lg:text-xl xl:text-2xl !leading-0 !mb-0 !pb-0">
            $340.00
          </h2>
        </div>
      </div>
    </div>
  );
}
