import { apiSlice } from '..';
import { profileApi } from '../profile';

export const challengeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChallenges: builder.query({
      query: () => {
        return {
          url: 'challenges',
          method: 'GET',
        };
      },
    }),
    getInitialResponse: builder.query({
      query: (id) => {
        return {
          url: `challenges/${id}/stages/6ba53a9f-420e-4dc8-af53-145af89caf33/responses`,
          method: 'GET',
        };
      },
    }),
    getHelp: builder.mutation({
      query: ({ sessionId, ...body }) => {
        return {
          url: `challenges/ai-help/sessions/${sessionId}`,
          method: 'POST',
          body,
        };
      },
    }),
    resetChallenge: builder.mutation({
      query: (challengeId) => {
        return {
          url: `challenges/${challengeId}/reset`,
          method: 'POST',
        };
      },
    }),
    fetchChallengeData: builder.mutation({
      query: ({ challengeId }) => ({
        url: `challenges/${challengeId}/start`,
        method: 'POST',
      }),
    }),
    fetchNextResponse: builder.mutation({
      query: ({ challengeId, sessionId, responseId, optionId }) => ({
        url: `challenges/${challengeId}/sessions/${sessionId}/responses/${responseId}/record-option`,
        method: 'POST',
        body: {
          optionId: optionId,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;

          const tokens = res.data.data.user.gemsTokens;

          const updateUser = profileApi.util.updateQueryData(
            'getMyProfile',
            undefined,
            (draft) => {
              draft.data.gemsTokens = tokens;
              console.log('draft', JSON.parse(JSON.stringify(draft)));
            },
          );

          dispatch(updateUser);
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useLazyGetChallengesQuery,
  useGetChallengesQuery,
  useGetInitialResponseQuery,
  useLazyFetchUserProfileQuery,
  useGetHelpMutation,
  useResetChallengeMutation,
  useFetchNextResponseMutation,
  useFetchChallengeDataMutation,
} = challengeApiSlice;
