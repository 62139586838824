import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';

export const CardInfo = ({ item }) => {
  return (
    <Grid item md={item?.md || 4} sm={item?.sm || 4} xs={12}>
      <Card
        sx={{
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          padding: '8px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
            padding: '0 !important',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginRight: 8,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '24px',
                color: '#667085',
              }}
            >
              {item?.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: '100%',
                textAlign: 'left',
                color: `${item?.color || '#333843'}`,
                fontSize: '20px',
                lineHeight: '32px',
                fontWeight: '500',
                mt: 3,
              }}
            >
              {item?.content}
            </Typography>
          </Box>
          <img
            src={item?.icon}
            alt="line indication"
            width={'61px'}
            height={'56px'}
            style={{
              borderRadius: '8px',
            }}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};
