import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPlayable: false,
  selectedCard: 'Chat',
  sessionId: null,
  responseId: null,
  challengeId: null,
  messages: [],
  selectedCanvasObj: {
    imgColor: null,
    imgMaterial: null,
    imgMovement: null,
    imgPos: 'full',
    playAnimation: 'glossy',
    pattern: null,
  },
  canvasData: [],
};

const DressBotSlice = createSlice({
  name: 'dressBot',
  initialState: initialState,
  reducers: {
    handlePlayable: (state, action) => {
      state.isPlayable = action.payload;
    },
    handleSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },
    handleSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    handleResponseId: (state, action) => {
      state.responseId = action.payload;
    },
    handleChallengeId: (state, action) => {
      state.challengeId = action.payload;
    },
    handleMessages: (state, action) => {
      // check if there is already a message with the same id
      // if there is, do not add it to the state
      const ids = state.messages?.find((msg) => msg.id === action.payload.id);

      if (ids) {
        return;
      }

      state.messages = [...state.messages, action.payload];
    },
    handleSelectedCanvasObj: (state, action) => {
      state.canvasData = [...state.canvasData, action.payload];
      state.selectedCanvasObj = {
        ...state.selectedCanvasObj,
        ...action.payload,
      };
    },
    resetChat: (state) => {
      state.messages = [];
      state.selectedCanvasObj = initialState.selectedCanvasObj;
    },
    resetDressBot: (state) => {
      state.isPlayable = false;
      state.selectedCard = 'Chat';
      state.sessionId = null;
      state.responseId = null;
      state.challengeId = null;
      state.messages = [];
      state.selectedCanvasObj = initialState.selectedCanvasObj;
    },
    removeMessage: (state, action) => {
      state.messages = state.messages.filter(
        (msg) => msg.id !== action.payload,
      );
    },
  },
});

export const {
  handlePlayable,
  handleSelectedCard,
  handleSessionId,
  handleResponseId,
  handleChallengeId,
  handleMessages,
  handleSelectedCanvasObj,
  resetChat,
  resetDressBot,
  removeMessage,
} = DressBotSlice.actions;

export const selectPlayable = (state) => state.dressBot.isPlayable;
export const selectSelectedCard = (state) => state.dressBot.selectedCard;
export const selectSessionId = (state) => state.dressBot.sessionId;
export const selectResponseId = (state) => state.dressBot.responseId;
export const selectChallengeId = (state) => state.dressBot.challengeId;
export const selectMessages = (state) => state.dressBot.messages;
export const selectSelectedCanvasObj = (state) =>
  state.dressBot.selectedCanvasObj;

export const selectCanvasData = (state) => state.sneakerBot.canvasData;

const dressBotReducer = DressBotSlice.reducer;

export default dressBotReducer;
