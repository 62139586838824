import cn from 'utils/cn';
import styles from './syle.module.scss';

const Index = ({
  options = [],
  handleOptionSelect,
  selectedCanvasObj,
  setSelectedCanvasObj,
  isDisabled,
}) => {
  return (
    <div className={styles.colorsWrapper}>
      {options?.map((data, _index) => (
        <button
          className={styles.materialCard}
          onClick={async () => {
            if (isDisabled) return;
            await handleOptionSelect(data);
            setSelectedCanvasObj({
              ...selectedCanvasObj,
              imgColor: data?.metadata?.COLOR?.colorCode,
            });
          }}
          disabled={isDisabled}
        >
          <div
            style={{ backgroundImage: `url(${data?.mediaUrl})` }}
            className={cn(styles.colorBg, 'h-full')}
          >
            <h4>${data?.metadata?.price}</h4>
            <span />
          </div>
          <h3>{data?.label}</h3>
        </button>
      ))}
    </div>
  );
};

export default Index;
