import React from "react";

export default function MyAssignment() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 15V12.5" stroke="#666666" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.39172 2.34955L2.61672 6.97455C1.96672 7.49121 1.55006 8.58288 1.69172 9.39955L2.80006 16.0329C3.00006 17.2162 4.13339 18.1745 5.33339 18.1745H14.6667C15.8584 18.1745 17.0001 17.2079 17.2001 16.0329L18.3084 9.39955C18.4417 8.58288 18.0251 7.49121 17.3834 6.97455L11.6084 2.35788C10.7167 1.64122 9.27506 1.64122 8.39172 2.34955Z" stroke="#666666" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}
