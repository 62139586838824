import React from "react";

const AiBotContext = React.createContext({
  challengeId: null,
  selectedCanvasObj: null,
  setSelectedCanvasObj: () => {},
  messages: [],
  onSend: () => {},
  onOptionSelect: () => {},
  loading: false,
  isToolbarDisabled: false,
  isTyping: false,
  onReset: () => {},
  onSendPrompt: (prompt) => {},
  canvasData: [],
});

const AiBotProvider = ({
  children,
  challengeId,
  selectedCanvasObj,
  setSelectedCanvasObj,
  messages,
  onSend,
  onOptionSelect,
  loading,
  isToolbarDisabled,
  isTyping,
  onReset,
  onSendPrompt,
  canvasData,
  ...rest
}) => {
  return (
    <AiBotContext.Provider
      value={{
        challengeId,
        selectedCanvasObj,
        setSelectedCanvasObj,
        messages,
        onSend,
        onOptionSelect,
        loading,
        isToolbarDisabled,
        isTyping,
        onReset,
        onSendPrompt,
        canvasData,
        ...rest
      }}
    >
      {children}
    </AiBotContext.Provider>
  );
};


const useAiBot = () => {
  const context = React.useContext(AiBotContext);
  if (context === undefined) {
    throw new Error('useAiBot must be used within a AiBotProvider');
  }
  return context;
}

export { AiBotProvider, useAiBot };