import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { IncorrectIcon } from 'asset';

export default function TemplateQuizDialog(props) {
  let { open, onClose } = props;
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Box textAlign={'center'}>
          <img
            src={IncorrectIcon}
            alt="incorrect"
            style={{
              marginTop: '12px',
              marginBottom: '12px',
              transform: 'scale(0.7)',
            }}
          />

          <Typography
            variant="h4"
            sx={{ fontWeight: '700', my: 2, color: '#000' }}
          >
            Looks like we do not have a quiz for you to take on this
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontWeight: '700', my: 0, color: '#000' }}
          >
            skill!
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontWeight: '700', my: 10, color: '#000' }}
          >
            Move onto the next assignment.
          </Typography>
          <Box
            mt={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button
              sx={{
                mr: 2,
                minWidth: '120px',
                '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
              }}
              variant="contained"
              onClick={onClose}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
