import styles from "./autocomplete.module.scss"
import {useState} from "react";

const AutoComplete = ({placeholder, icon, onChange, value, options}) => {
    const [filteredOptions, setFilteredOptions] = useState(options)
    const [displayOptions, setDisplayOptions] = useState(false)
    const [isCorrect, setIsCorrect] = useState(false)
    const [inputValue, setInputValue] = useState(value)

    function onType(value) {
        setFilteredOptions(options.filter(v => v.toLowerCase().indexOf(value.toLowerCase()) > -1))

        console.log("value", value)
        // set value to inputRef value
        setInputValue(value)

        // check if the value is exactly the same as one of the options
        if (options.includes(value)) {
            setFilteredOptions([])
            setIsCorrect(true)
            onChange(value)
        } else {
            setIsCorrect(false)
            onChange(null)
        }

        if (value === "" || value === null) {
            console.log("update")
            setIsCorrect(false)
            setFilteredOptions(options)
            setDisplayOptions(true)
        }
    }

    function onKeyDown(event) {
        if (event.key === "Enter" && displayOptions && filteredOptions.length > 0) {
            setDisplayOptions(false)
            setInputValue(filteredOptions[0])
            onChange(filteredOptions[0])
            setIsCorrect(true)
        }
    }

    return (
        <div className={styles.autocomplete}>
            <img src={icon} alt={"icon"}/>
            <input onClick={() => {
                if (filteredOptions.length === 0) {
                    setDisplayOptions(true)
                    setFilteredOptions(options)
                }
            }} value={inputValue} onKeyDown={onKeyDown} type={"text"}
                   className={isCorrect ? styles.correct : styles.wrong}
                   placeholder={placeholder}
                   onChange={(target) => {
                       onType(target.target.value)
                   }}/>

            {displayOptions && (
                <>
                    <div className={styles.options}>
                        {filteredOptions?.map((option, index) => {
                            return (
                                <div onClick={() => onType(option)} key={index} className={styles.option}>
                                    <p>{option}</p>
                                </div>
                            )
                        })}
                    </div>
                </>
            )}

        </div>
    )
}

export default AutoComplete
