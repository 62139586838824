import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  date: { day: '', month: '', year: '' },
  email: '',
  phone: '',
  password: '',
  parentEmail: '',
  parentPhone: '',
  username: '',
  avatarImages: [],
};

const RegisterSlice = createSlice({
  name: 'auth_slice',
  initialState: initialState,
  reducers: {
    setBirthDate: (state, action) => {
      state.date[action.payload.key] = action.payload.value;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setParentEmail: (state, action) => {
      state.parentEmail = action.payload;
    },
    setParentPhone: (state, action) => {
      state.parentPhone = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setAvatarImages: (state, action) => {
      state.avatarImages = action.payload;
    },
  },
});

export const {
  setBirthDate,
  setEmail,
  setPhone,
  setPassword,
  setParentEmail,
  setParentPhone,
  setUsername,
  setAvatarImages,
} = RegisterSlice.actions;

export default RegisterSlice.reducer;
