import { createSlice } from '@reduxjs/toolkit';

const intialState = {
  topicList: [],
  selectedTopic: '',
  studentTopicList: [],
};
const TopicSlice = createSlice({
  name: 'topic_slice',
  initialState: intialState,
  reducers: {
    setTopicList: (state, action) => {
      console.log(action.payload);
      state.topicList = action.payload;
    },
    setSelectedTopic: (state, action) => {
      state.selectedTopic = action.payload;
    },
    setStudentTopicList: (state, action) => {
      state.studentTopicList = action.payload;
    },
  },
});

export const { setTopicList, setSelectedTopic, setStudentTopicList } = TopicSlice.actions;

export default TopicSlice.reducer;
