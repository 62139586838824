import { analytics as analyticsUtils } from '../api/firebase';

class analytics {
  static logEvent = (
    name,
    params
  ) => {
    if (name.includes('error')) {
      analyticsUtils.logEvent('exception', {
        description: name,
        fatal: true,
      });
      return;
    }
    analyticsUtils.logEvent(name, params);
  };
  static setUser = (userId) => {
    analyticsUtils.setUserId(userId || '');
  };
  static setScreenName = (screenName) => {
    analyticsUtils.logEvent('page_view', { screen_name: screenName });
  };
  static loginUser = (userId) => {
    this.setUser(userId);
    this.logEvent('login', { method: 'email' });
  };
  static logoutUser = () => {
    this.logEvent('logout', {});
  };
  static apiError = (error) => {
    this.logEvent('exception', { error });
  };
}

export default analytics;
