import styles from './challengeSelection.module.scss';
import GetInfoIcon from '../../../../asset/challenge_selection/get_info_icon.svg';
import DesignStatementDressImg from '../../../../asset/challenge_selection/design_statement_dress.jpg';

const Index = ({ }) => {

  return (
    <>
      <div className={`${styles.card}`}>
        <div className={styles.cardHeader}>
          <div className={styles.skeletonContainer}>
            <h3>Glam Code: Design Your Statement Dress</h3>
          </div>
          <span className={styles.tooltip}>
            <div className={styles.skeletonContainer}>
              <img src={GetInfoIcon} alt="Get Info Icon" />
            </div>
          </span>
        </div>
        <div className={styles.skeletonContainer}>
          <div className={styles.cardImgWrapper}>
            <img src={DesignStatementDressImg} alt='' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Index;