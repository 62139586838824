import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationManager } from 'react-notifications';
import { setReactionLoading } from 'store/Slice/videoSlice';
// import { setVideoList } from 'store/Slice/videoSlice';
import API from 'store/api';

export const addReactionVideo = createAsyncThunk(
  'addReactionVideo',
  async (_request, { dispatch }) => {
    let url = `/api/v1/webapp/video/${_request?.id}/reactions`;
    try {
      const response = await API.post(url, _request?.data);
      //   dispatch(setVideoList(response.data.data));
      NotificationManager.success(response.data.message, 'Success');
      return response;
    } catch (err) {
      dispatch(setReactionLoading(false));
      NotificationManager.error(err.response.data.message, 'Error', 3000);
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
