// ** Icon imports
import MyAssignment from 'icons/myAssignment';
import DailyLesson from 'icons/dailyLesson';
import DailyQuiz from 'icons/dailyQuiz';

const navigation = () => {
  return [
    {
      title: 'My Assignments',
      icon: MyAssignment,
      path: '/app/student',
    },
    {
      title: 'Daily Lesson',
      icon: DailyLesson,
      path: '/app/student/video',
    },
    {
      title: 'Daily Quiz',
      icon: DailyQuiz,
      path: '/app/student/daily-quiz',
    },
  ];
};

export default navigation;
