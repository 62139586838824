// ** Icon imports
import MyAssignment from "icons/myAssignment";
import DailyLesson from "icons/dailyLesson";
import DailyQuiz from "icons/dailyQuiz";

const navigation = () => {
  return [
    {
      title: 'My Assignments',
      icon: MyAssignment,
    },
    {
      title: 'Daily Lesson',
      icon: DailyLesson,
    },
    {
      title: 'Daily Quiz',
      icon: DailyQuiz,
    },
  ]
}

export default navigation
