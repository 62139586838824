import React, { useCallback, useEffect, useRef, useState } from 'react';

const WebSocketListener = ({ url, onMessage }) => {
  const ws = useRef(null);

  const connectWebSocket = useCallback(() => {
    console.log('Connecting to WebSocket:', url);
    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.current.onclose = () => {
      console.log('WebSocket disconnected');
      setTimeout(() => {
        connectWebSocket();
      }, 100);
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('WebSocket message received:', message);
      onMessage(message);
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      console.log("Closing the websocket");
    };
  }, [onMessage, url]);

  useEffect(() => {
    if (!ws.current || ws.current.readyState === WebSocket.CLOSED) {
      console.log('Connecting to websocket...')
      setTimeout(() => {
        connectWebSocket();
      }, 100);
    }

    return () => {
      if (ws.current) {
        ws.current.close();
        console.log("Killed websocket connection");
        ws.current = null;
      }
    };
  }, [connectWebSocket]);

  return null;
};

export default WebSocketListener;