import PlayButton from '@mui/icons-material/PlayCircleFilledRounded';
import TextsmsIcon from '@mui/icons-material/Textsms';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { follower_profile } from 'asset';
import { BsFillHandThumbsUpFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import '../../../components/main/elements/assignments/VideoPlayer/VideoPlayer.scss';
import SecondIcon from '../../../media/Frame20452Third.png';
import firstIcon from '../../../media/GroupFirst.png';
import ThirdIcon from '../../../media/GroupFourth.png';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useState } from 'react';
import { getAllVideoList } from 'store/Thunk/videoThunk';
import { useEffect } from 'react';
import VideoSideBar from './VideoSideBar';
import { setVideoLoading } from 'store/Slice/videoSlice';
import EmptyVideo from 'app/student/components/emptyVideo';

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 18,
  height: 18,
  borderRadius: '50%',
  position: 'absolute',
  fontSize: 18,
  right: -3,
  fontWeight: 900,
  backgroundColor: theme.palette.primary.main,
  textAlign: 'center',
  color: theme.palette.common.white,
}));

function DailyLesson({ videoTopic }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const { videoListing, videoLoading } = useAppSelector(
    (state) => state.videoReducer,
  );
  const { user } = useAppSelector((state) => state.authReducer);
  const { userData } = useAppSelector((state) => state.userReducer);

  const [playCount, setPlayCount] = useState(0);
  const [reactionVideo, setReactionVideo] = useState(null);
  const [reactionCount, setReactionCount] = useState(0);

  useEffect(() => {
    dispatch(setVideoLoading(true));
    dispatch(
      getAllVideoList({
        pageIndex: playCount,
        topic: videoTopic,
        userId: user?.id,
        gradeId: userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
      }),
    ).then((res) => dispatch(setVideoLoading(false)));
  }, [playCount, videoTopic]);

  const fetchData = () => {
    dispatch(setVideoLoading(true));
    dispatch(
      getAllVideoList({
        pageIndex: playCount,
        topic: videoTopic,
        userId: user?.id,
        gradeId: userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
      }),
    ).then((res) => dispatch(setVideoLoading(false)));
  };

  useEffect(() => {
    if (videoListing?.length > 0) {
      setReactionVideo(videoListing[0]?.id);
      setReactionCount(videoListing[0].Reaction.length);
    }
  }, [videoListing]);

  return (
    <Box height={'100%'} position={'relative'}>
      {videoLoading ? (
        <Box
          height={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          height={'89%'}
          sx={{
            position: 'relative',
          }}
        >
          {videoListing && videoListing.length > 0 ? (
            <>
              <Grid item xs={12} sm={8} md={8} lg={8} height={'90%'}
                style={{
                  position: 'relative',
                }}
                sx={{
                  position: 'relative',
                }}>
                <Box
                  height={'95%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  position={'relative'}
                >
                  <Box sx={{ position: 'absolute' }}>
                    <IconButton
                      onClick={() =>
                        navigate('/app/student/video', { state: videoTopic })
                      }
                    >
                      <PlayButton
                        sx={{
                          width: '60px',
                          height: '60px',
                          color: theme.palette.primary.main,
                        }}
                      />
                    </IconButton>
                  </Box>
                  <img
                    style={{ borderRadius: '12px' }}
                    width={'100%'}
                    height={'100%'}
                    src={`https://image.mux.com/${videoListing[0]?.playbackId}/animated.gif`}
                    alt={videoListing[0]?.title}
                    loading="lazy"
                  />

                  {/*  <MuxPlayer
              poster=''
              playbackId={'DS00Spx1CV902MCtPj5WknGlR102V5HFkDe'}
              title={'title'}
              streamType="on-demand"
              onPlay={() => navigate('/app/student/video')}
              style={{ aspectRatio: 10 / 9, height: '600px' }}
            /> */}
                </Box>
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    {videoListing[0]?.title}
                  </Typography>
                </Box>
              </Grid>
              <Box>
                <VideoSideBar video={videoListing[0]} fetchData={fetchData} />
              </Box>
              {/* <Box
          component={Paper}
          sx={{
            padding: '40px 4px',
            borderRadius: '10px !important',
            height: '300px',
            width: '80px',
            borderRadius: '48px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          className="videoResponseButtons"
        >
          <Box>
            <Badge
              overlap="circular"
              sx={{ cursor: 'pointer' }}
              badgeContent={<BadgeContentSpan>+</BadgeContentSpan>}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Avatar
                alt="John Doe"
                sx={{ width: 50, height: 50 }}
                src={follower_profile}
              />
            </Badge>
          </Box>
          <div>
            <div className="dropdown">
              <button className="videoResponseButton" style={{ width: '100%' }}>
                <BsFillHandThumbsUpFill size={'28px'} color="#fff" />
              </button>
              <div className="dropdown-content">
                <button>
                  <img src={firstIcon} alt="Like" />
                </button>
                <button>
                  <img src={SecondIcon} alt="Like" />
                </button>
                <button>
                  <img src={ThirdIcon} alt="Like" />
                </button>
              </div>
            </div>
          </div>
          <button className="videoResponseButton">
            <TextsmsIcon size={'28px'} color="#fff" sx={{ color: 'white' }} />
          </button>
        </Box> */}
            </>
          ) : (
            <EmptyVideo />
          )}
        </Grid>
      )}
    </Box>
  );
}

export default DailyLesson;
