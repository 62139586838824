import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import sharedStyles from '../payment.module.scss';
import UpgradePlan from './UpgradePlan';
import Students from '../../../components/students-add.png';
import AddParent from '../dialog/AddParent';
import UploadPicture from '../dialog/UploadPicture';

export default function StepThree({ setProgress, setSteps, setStep }) {
  const [openUPload, setOpenUpload] = useState(false);
  const [addStudent, setAddStudent] = useState(false);
  const [confirmAdd, setConfirmAdd] = useState(false);
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleNext = (selectedIndex) => {
    setConfirmAdd(true);
  };
  const onUpgrade = () => {
    setStep((prevStep) => ({
      ...prevStep,
      current: 'pricing',
    }));
  };
  const onAdd = ({ selectedIndex = 1 }) => {
    setProgress(66);
    setSteps((prevSteps) => {
      return prevSteps.map((step, index) => {
        if (index === selectedIndex) {
          return { ...step, completed: true, current: false };
        } else if (index === selectedIndex + 1) {
          return { ...step, current: true };
        } else {
          return { ...step, current: false };
        }
      });
    });
    setConfirmAdd(false);
  };
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const handleChangeInput = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <Stack>
      <Typography className={sharedStyles.headerFreeText} mb={6}>
        Let’s collect key settings for G3MS?
      </Typography>

      <UpgradePlan setStep={setStep} />

      <AddParent open={confirmAdd} onClose={onAdd} onUpgrade={onUpgrade} />
      <UploadPicture open={openUPload} onClose={handleCloseUpload} />

      {addStudent ? (
        <Paper
          className={sharedStyles.inputcontainer}
          elevation={4}
          sx={{ borderColor: '#D1D8DD !important' }}
        >
          <Box className="flex justify-between w-full mb-6">
            <Typography
              sx={{ fontWeight: 500, fontSize: '16px', color: '#131515' }}
              mt={6}
              mb={3}
            >
              Add another Parent
            </Typography>
            <Box>
              <img src="" alt="" />
              <Button
                onClick={() => setOpenUpload(true)}
                variant={'outlined'}
                size="medium"
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 500,
                  fontSize: '16px',
                  borderStyle: 'dashed',
                  borderWidth: '2px',
                }}
                className={sharedStyles.green_bg_btn}
              >
                Upload Profile Image
              </Button>
            </Box>
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>
                Parent First Name
              </InputLabel>
              <TextField
                required
                fullWidth
                id="cardNumber"
                value={formData?.cardNumber || ''}
                onChange={handleChangeInput}
                placeholder="Parent First Name"
                name="cardNumber"
                error={formError && formError?.cardNumber}
                helperText={
                  formError && formError?.cardNumber ? formError.cardNumber : ''
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>
                Parent Last Name
              </InputLabel>
              <TextField
                required
                fullWidth
                id="cardName"
                value={formData?.cardName || ''}
                onChange={handleChangeInput}
                placeholder="Parent Last Name"
                name="cardName"
                error={formError && formError?.cardName}
                helperText={
                  formError && formError?.cardName ? formError.cardName : ''
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>
                Parent Email
              </InputLabel>
              <TextField
                required
                fullWidth
                id="expiryDate"
                value={formData?.expiryDate || ''}
                onChange={handleChangeInput}
                placeholder="Parent Email (Optional)"
                name="expiryDate"
                error={formError && formError?.expiryDate}
                helperText={
                  formError && formError?.expiryDate ? formError.expiryDate : ''
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>
                Parent Phone
              </InputLabel>
              <TextField
                required
                fullWidth
                id="cvc"
                value={formData?.expiryDate || ''}
                onChange={handleChangeInput}
                placeholder="999999999"
                name="cvc"
                error={formError && formError?.cvc}
                helperText={formError && formError?.cvc ? formError.cvc : ''}
              />
            </Grid>

            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>State</InputLabel>
              <Autocomplete
                multiple
                id="asynchronous-demo"
                open={openCountry}
                onOpen={() => setOpenCountry(true)}
                onClose={() => setOpenCountry(false)}
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                getOptionLabel={(option) => option?.title || ''}
                options={[]}
                loading={loadingCountry}
                value={formData?.country ? formData?.country : [] || [] || {}}
                name="country"
                onChange={handleChangeInput}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select State"
                    error={formError && formError?.country}
                    helperText={
                      formError && formError?.country ? formError.country : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCountry ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>City</InputLabel>
              <Autocomplete
                multiple
                id="asynchronous-demo"
                open={openCountry}
                onOpen={() => setOpenCountry(true)}
                onClose={() => setOpenCountry(false)}
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                getOptionLabel={(option) => option?.title || ''}
                options={[]}
                loading={loadingCountry}
                value={formData?.country ? formData?.country : [] || [] || {}}
                name="country"
                onChange={handleChangeInput}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select City"
                    error={formError && formError?.country}
                    helperText={
                      formError && formError?.country ? formError.country : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCountry ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>Zip Code</InputLabel>
              <TextField
                required
                fullWidth
                id="cvc"
                value={formData?.expiryDate || ''}
                onChange={handleChangeInput}
                placeholder="1234"
                name="cvc"
                error={formError && formError?.cvc}
                helperText={formError && formError?.cvc ? formError.cvc : ''}
              />
            </Grid>
          </Grid>
          <Box className="flex w-full justify-end">
            <Button
              onClick={() => handleNext(1)}
              variant={'contained'}
              size="large"
              sx={{
                mt: '32px',
                textTransform: 'capitalize',
                fontWeight: 500,
                fontSize: '20px',
                borderWidth: '2px',
                color: '#fff !important',
                '&:hover': {
                  backgroundColor: '#c73e9d',
                },
              }}
              className={'primary_bg_btn'}
            >
              Add Parent
            </Button>
          </Box>
        </Paper>
      ) : (
        <Stack className={sharedStyles.step1action}>
          <Box>
            <img src={Students} alt="" />
          </Box>
          <Typography
            sx={{ fontWeight: 600, fontSize: '24px', color: '#131515' }}
            mt={6}
            mb={3}
          >
            Add Another Parent
          </Typography>
          <Typography
            sx={{ fontWeight: 400, fontSize: '18px', color: '#131515' }}
          >
            Invite another parent, grandparent, or caregiver to join G3MS!
            Together, we can support our students' learning and make a bigger
            impact both at home and in the classroom.
          </Typography>
          <Button
            onClick={() => setAddStudent(true)}
            variant={'outlined'}
            size="large"
            sx={{
              width: '100%',
              marginTop: '70px',
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
            className={'primary_bg_btn'}
          >
            Add Parent
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
