import { Box } from '@mui/material';
import useGetQuizTemplate from 'hooks/useGetQuizTemplate';
import PlayIcon from 'icons/PlayIcon';
import { MessageText1 } from 'iconsax-react';
import { Loading } from 'mdi-material-ui';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetMyProfileQuery } from 'store/apis/profile';
import FractionItem from './components/FractionItem';

function ShowAllContent({ videoTopic, setVideoTopic }) {
  const navigate = useNavigate();

  const { data, isLoading: myProfileLoading, error } = useGetMyProfileQuery();

  const { isLoading, navigateToQuiz, template } = useGetQuizTemplate();

  const challenges = React.useMemo(
    () => [
      {
        title: 'Quiz to Earn',
        icon: '/svg/methods/quiz-to-earn.svg',
        navigateTo: `/app/student/daily-quiz?quiz=${template.templateId}`,
        background: '#7979F726',
      },
      {
        title: 'Code to Earn',
        icon: '/svg/methods/code-to-earn.svg',
        navigateTo: '/app/student/challenge-selection',
        background: '#66666626',
      },
    ],
    [videoTopic, template],
  );

  return (
    <Box
      container
      sx={{
        marginLeft: '0px !important',
      }}
      className="!ml-0 "
      bgcolor={'#fff'}
      borderRadius={'30px'}
      alignItems={'start'}
    >
      <div className="grid">
        <div className="px-5 pt-5 w-full space-y-5">
          <h1 className="font-medium text-base xl:text-lg 2xl:text-xl text-[#C73E9D]">
            <span className="text-[#20A58A]">Welcome</span>{' '}
            {data?.data?.name ?? data?.data?.username}!
          </h1>

          <div className="border border-[#00000033] rounded-2xl p-4 grid grid-cols-[1fr,auto]">
            <div className="space-y-4 flex flex-col justify-between items-start">
              <h1 className="font-medium text-sm lg:text-base xl:text-lg text-[#2C3E50] ">
                Need help with a topic? Let our AI Tutor guide you to the
                solution without giving away the answer.
              </h1>
              <button
                className="bg-[#C44297] text-white rounded-xl px-6 py-3 font-bold text-xs xl:text-sm flex items-center gap-2"
                onClick={() => navigate('/app/student/chat')}
              >
                <MessageText1
                  className="text-white size-4 lg:size-5 "
                  variant="Bold"
                />
                <span>AI tutor</span>
              </button>
            </div>
            <img
              src="/svg/bot.svg"
              alt="bot"
              className="w-10 md:w-12 lg:w-14 xl:w-16 h-full object-contain ml-auto"
            />
          </div>

          <div className="border border-[#00000033] rounded-2xl px-4 py-4 space-y-2 md:space-y-4">
            <h1 className="font-medium text-[#2C3E50] text-sm md:text-base lg:text-lg xl:text-xl">
              Daily Practice
            </h1>

            <div className="grid gap-4 grid-cols-2">
              <FractionItem
                onClick={() => navigate('/app/student/video')}
                image="/images/share-video.png"
                title="Learn what matters from video lessons and earn G3MS."
                btnTitle="Start Learning"
              />

              <FractionItem
                onClick={() => navigateToQuiz(videoTopic)}
                image="/images/start-quize.png"
                title="Let’s start today’s daily practice quiz and earn G3MS."
                btnTitle="Test your Knowledge"
              />
            </div>
          </div>

          <div className="border border-[#00000033] rounded-2xl px-4 py-4 space-y-3 md:space-y-5">
            <div className="xl:flex items-center gap-4">
              <h1 className="font-medium text-[#2C3E50] text-base md:text-lg xl:text-xl">
                Earn G3MS
              </h1>
              <p className="text-[#2C3E50] text-xs md:text-sm xl:text-base">
                Complete any challenge below and earn more $G3MS!
              </p>
            </div>

            <div className="grid gap-4 grid-cols-2">
              {challenges.map((challenge, index) => (
                <button
                  key={index}
                  className="px-5 py-6 md:py- lg:py-6  xl:py-6 rounded-2xl grid place-items-center gap-2"
                  style={{ background: challenge.background }}
                  onClick={() => navigate(challenge.navigateTo)}
                >
                  <img
                    src={challenge.icon}
                    alt=""
                    className="w-full h-full max-w-[2.5rem] 2xl:max-w-[2.75rem] object-contain mx-auto"
                  />
                  <h1 className="font-medium text-[#252843] text-sm xl:text-base 2xl:text-lg text-center mx-auto">
                    {challenge.title}
                  </h1>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Loading open={isLoading || myProfileLoading} />
    </Box>
  );
}

export default ShowAllContent;
