import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField
} from '@mui/material';
import Logo from '../../../components/logo.png';
import sharedStyles from '../payment.module.scss';

import { useAppDispatch } from 'store/store';

export default function Promo({ open, onClose, setStep }) {
  const dispatch = useAppDispatch();

  function onNext() {
    // check if email is valid regex
  }
  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          padding: '32px 0px',
          width: 'max-content',
          position: 'relative',
        },
      }}
    >
      <Box className={sharedStyles.closeIcon} onClick={onClose}>
        <CloseIcon />
      </Box>
      <Box className="w-full justify-center flex">
        <img src={Logo} style={{ height: '70px' }} alt={'logo'} />
      </Box>
      <DialogTitle
        fontSize={'48px'}
        sx={{ fontSize: '24px !important', color: '#C73E9D' }}
        fontWeight={600}
        textAlign={'center'}
      >
        Promo or Referral Code
      </DialogTitle>
      <DialogContent>
        <Box
          textAlign={'center'}
          mt={2}
          fullWidth
          sx={{
            '& .MuiOutlinedInput-input': {
              fontSize: '18px',
              fontWeight: 500,
              minWidth: '284px',
            },
          }}
        >
          <FormControl variant="outlined">
            <TextField
              id="my-textfield"
              variant="outlined"
              style={{
                fontSize: '18px',
                fontWeight: 500,
              }}
            />
          </FormControl>

          <Box
            mt={6}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button
              fullWidth
              size="large"
              sx={{
                minWidth: '120px',
                '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
              }}
              variant="contained"
              onClick={onNext}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
