import { Modal } from '@mui/material';
import { Close } from 'mdi-material-ui';
import React from 'react';
import cn from 'utils/cn';

const ModalImage = ({ src, className, alt, ...rest }) => {
  return (
    <img
      src={src}
      className={cn('min-w-40 min-h-40 object-contain', className)}
      alt={alt}
      {...rest}
    />
  );
};

const ModalTitle = ({ children }) => {
  return (
    <h2 className="text-lg md:text-xl lg:text-2xl xl:text-[1.75rem] font-bold text-[#051532] text-center !mt-0 xl:!leading-[120%]">
      {children}
    </h2>
  );
};

const ModalSubtitle = ({ children }) => {
  return (
    <p className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#000000] text-center !mt-0">
      {children}
    </p>
  );
};

const ModalButton = ({ children, onClick, ...rest }) => {
  return (
    <button
      onClick={onClick}
      className="bg-[#C44297] text-white text-xs sm:text-sm lg:text-xl rounded-2xl px-5 py-4 font-medium w-full mt-4"
      {...rest}
    >
      {children}
    </button>
  );
};

export default function STEModal({ open, handleClose, children, className }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="flex items-center justify-center h-full"
        onPointerDown={handleClose}
      >
        <div
          className={cn(
            'bg-white rounded-2xl px-6 py-6 !gap-y-4 md:!gap-y-6 xl:!gap-y-8 grid place-items-center max-w-3xl relative',
            className,
          )}
          onClick={(e) => e.stopPropagation()}
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
        >
          {children}

          <button onClick={handleClose} className="absolute top-4 right-4">
            <Close />
          </button>
        </div>
      </div>
    </Modal>
  );
}

STEModal.Image = ModalImage;
STEModal.Title = ModalTitle;
STEModal.Subtitle = ModalSubtitle;
STEModal.Button = ModalButton;
