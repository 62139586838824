import React, { useState } from 'react';
import { MdContentCopy } from 'react-icons/md';

const CodeBlock = ({ language, value }) => {
  const [copied, setCopied] = useState(false);


  const handleCopy = () => {
    navigator.clipboard
      .writeText(value?.props?.children)
      .then(() => setCopied(true))
      .catch(() => alert('Failed to copy'));
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <pre
      style={{
        padding: 0,
        overflow: 'hidden',
        backgroundColor: '#232628',
        marginTop: '0.5rem',
      }}
    >
      <div className="px-8 py-4 flex justify-between items-center">
        <span
          style={{
            color: '#fff',
          }}
          className='text-sm font-medium'
        >
          {language || 'code'}
        </span>
        <button
          onClick={handleCopy}
          style={{
            color: '#fff',
          }}
          className="flex items-center gap-1 text-xs md:text-sm"
        >
          {copied ? (
            'Copied!'
          ) : (
            <>
              <MdContentCopy /> Copy
            </>
          )}
        </button>
      </div>
      <pre
        style={{
          margin: 0,
          backgroundColor: '#000000',
        }}
      >
        {value}
      </pre>
    </pre>
  );
};

export default CodeBlock;
