import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Logo from '../../../components/logo.png';
import sharedStyles from '../payment.module.scss';


export default function Upgrade({ open, onClose, setIsUpgrade }) {
  function handleUpgrade() {
    setIsUpgrade(true);
    onClose();
  }
  function onNext() {}
  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          padding: '32px 0px',
          maxWidth: '820px',
          position: 'relative',
        },
      }}
    >
      <Box className={sharedStyles.closeIcon} onClick={onClose}>
        <CloseIcon />
      </Box>
      <Box className="w-full justify-center flex">
        <img src={Logo} style={{ height: '70px' }} alt={'logo'} />
      </Box>
      <DialogTitle
        mt={6}
        fontSize={'48px'}
        sx={{ fontSize: '24px !important', color: '#131515' }}
        fontWeight={600}
        textAlign={'center'}
      >
        The FREE plan is limited to 1 student.
      </DialogTitle>
      <DialogTitle
        mt={6}
        fontSize={'48px'}
        sx={{ fontSize: '24px !important', color: '#131515' }}
        fontWeight={600}
        textAlign={'center'}
      >
        Upgrade to unlock our complete content library, high engagement features
        like camera access on the mobile app for self-directed learning, and
        premium rewards including gift cards and tickets to NBA/WNBA/NFL/MBL/NSL
        games & concerts, from brands students love!{' '}
      </DialogTitle>
      <DialogContent>
        <Box
          textAlign={'center'}
          mt={2}
          fullWidth
          sx={{
            '& .MuiOutlinedInput-input': {
              fontSize: '18px',
              fontWeight: 500,
              minWidth: '284px',
            },
          }}
        >
          <Box
            mt={6}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button
              variant={'contained'}
              size="large"
              sx={{
                width: '50%',
                mt: '32px',
                textTransform: 'capitalize',
                fontWeight: 500,
                fontSize: '20px',
                borderWidth: '2px',
                '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
              }}
              className={'primary_bg_btn'}
              onClick={handleUpgrade}
            >
              Continue To Upgrade
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
