const Index = ({ selectedCanvasObj }) => {
	return (
		<>
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				width="100%" viewBox="0 0 992 992" enable-background="new 0 0 992 992" >
				<path fill={selectedCanvasObj?.imgColor} opacity="1.000000" stroke="none"
					d="
M403.690857,470.701935 
	C410.329254,466.814453 416.701752,463.279755 423.463013,460.366852 
	C433.853485,455.890350 441.984589,448.090057 450.380219,440.776215 
	C459.949402,432.440002 470.219421,425.137115 481.277771,418.911926 
	C494.072510,411.709259 505.754333,402.928436 516.927734,393.448761 
	C521.996460,389.148376 527.889771,386.835266 534.435730,387.729492 
	C543.869568,389.018250 549.955933,384.556732 554.816101,377.299072 
	C557.131531,373.841431 559.457031,370.389984 561.826172,366.969055 
	C563.215088,364.963531 563.993225,362.781311 563.554016,360.373810 
	C560.168884,341.818146 565.230408,324.409912 572.621704,308.030304 
	C580.819458,289.863678 592.456482,273.432037 602.695435,256.306519 
	C607.627441,248.057236 616.198730,246.025192 624.723450,247.118454 
	C636.530334,248.632645 648.214539,251.394012 659.500183,255.453812 
	C665.218811,257.510956 665.909790,259.886932 662.086304,264.511719 
	C654.045166,274.238037 645.690247,283.722961 638.019531,293.732574 
	C629.571533,304.756409 622.826355,316.763672 619.941772,330.619385 
	C619.111389,334.608215 619.020813,338.587646 619.673218,342.504974 
	C620.969421,350.288666 620.071899,358.029327 619.932800,365.808441 
	C619.662781,380.916565 610.966736,393.105103 606.243225,406.648560 
	C602.272339,418.033844 593.772461,425.262756 582.306152,429.012177 
	C565.131958,434.628082 547.693115,439.147919 529.738953,441.391266 
	C513.331787,443.441315 499.959778,451.285736 487.063599,461.277466 
	C472.123932,472.852448 458.727203,486.655853 441.406036,494.968689 
	C433.901215,498.570435 425.888855,500.589355 417.529938,501.437225 
	C411.834045,502.015045 408.781799,499.488312 407.029510,494.521179 
	C406.664001,493.485016 406.724396,492.123199 404.822906,491.835236 
	C403.143677,494.601837 402.238617,497.615784 402.327545,501.022766 
	C402.833221,520.398682 407.988434,538.524597 415.718933,556.247803 
	C425.452332,578.562866 439.528809,598.123779 452.854797,618.210876 
	C455.595490,622.342163 458.574554,626.374939 460.417664,631.073181 
	C461.759155,634.492737 460.604675,636.207703 457.075989,636.349731 
	C451.297577,636.582458 445.610107,637.713257 439.757324,637.417542 
	C424.317139,636.637085 408.880463,638.018127 393.485535,638.685791 
	C374.439301,639.511719 355.399414,639.999146 336.353424,640.606628 
	C328.732727,640.849731 321.083832,641.543823 313.463928,641.264221 
	C291.783997,640.468445 270.107849,639.966614 248.480560,637.597839 
	C229.578491,635.527527 210.652145,633.585083 191.784393,631.252930 
	C164.346054,627.861511 137.020752,623.616760 110.134079,616.993408 
	C99.493896,614.372253 88.929909,611.530090 79.359138,605.862549 
	C72.802895,601.980164 72.308174,601.271179 73.739853,594.102051 
	C76.119865,582.184021 79.035294,570.414368 84.997375,559.690796 
	C88.889496,552.690430 93.262939,546.104553 102.967422,547.683594 
	C103.916679,547.838074 105.485954,547.917542 105.675652,548.433289 
	C107.840004,554.318542 113.454292,553.610413 117.816185,554.458496 
	C137.843613,558.352356 158.065582,560.032104 178.509064,560.690674 
	C193.913315,561.186890 209.374908,561.589172 224.767319,562.714294 
	C238.087799,563.688049 250.351181,567.714417 261.035400,576.250000 
	C267.107635,581.101013 273.626221,585.301270 281.842072,586.244995 
	C288.051544,586.958252 296.397766,583.873413 299.697510,578.595581 
	C301.882233,575.101135 300.812164,565.027954 297.707733,561.144836 
	C293.349976,555.693970 288.982574,550.245300 284.444489,544.945374 
	C278.887207,538.455200 276.046478,531.165344 276.604889,522.517090 
	C276.782318,519.768799 277.723358,518.399170 280.312836,517.446167 
	C292.328461,513.024170 304.530823,509.124146 316.551666,504.757629 
	C334.506836,498.235504 352.233521,491.042542 369.840027,483.619415 
	C379.054138,479.734650 388.642914,476.993958 397.883789,473.259857 
	C399.729584,472.514008 401.536163,471.671204 403.690857,470.701935 
M627.527283,280.989532 
	C629.482300,278.881287 630.574951,276.255615 631.757080,273.710480 
	C633.382812,270.210297 630.794922,265.367950 626.964050,264.503693 
	C617.437683,262.354370 605.023438,269.516479 601.849670,278.935852 
	C600.612793,282.606812 601.707825,285.049805 605.367065,286.360107 
	C613.612488,289.312469 620.515015,286.309509 627.527283,280.989532 
M583.229797,305.711090 
	C580.619202,308.699036 577.819031,311.545410 576.223083,315.279572 
	C574.363098,319.631622 575.744385,322.496613 580.204712,323.496521 
	C588.918030,325.449860 601.034485,319.163605 604.666199,311.023651 
	C606.144836,307.709259 605.799988,304.834412 603.433777,302.294769 
	C601.143372,299.836548 598.375488,298.819824 594.881531,299.859222 
	C590.852661,301.057739 587.149780,302.771423 583.229797,305.711090 
M577.206604,395.985016 
	C578.501709,395.707581 579.859741,395.593750 581.081177,395.124634 
	C586.617249,392.998322 590.438171,386.143433 588.930481,381.214813 
	C587.260986,375.757385 583.221863,373.989380 575.981812,375.546783 
	C570.976379,376.623535 564.597656,383.118011 564.672852,387.060944 
	C564.740662,390.621918 570.286438,395.004059 575.992554,396.004242 
	C576.156067,396.032928 576.321899,396.048767 577.206604,395.985016 
M504.340332,415.415070 
	C504.404633,419.498566 507.366730,420.290741 510.457825,420.346497 
	C515.853271,420.443909 522.899170,412.639099 522.590149,407.222748 
	C522.312805,402.360870 518.913757,400.561768 514.864319,403.229675 
	C510.405090,406.167480 506.781616,409.906219 504.340332,415.415070 
M384.718994,493.327148 
	C385.645416,492.391785 386.660339,491.526642 387.473724,490.501617 
	C388.309540,489.448273 388.995575,488.210968 387.872925,486.915863 
	C386.936157,485.835297 385.671234,485.275238 384.307617,485.805908 
	C379.862915,487.535461 374.973389,488.211182 371.129791,491.389709 
	C369.876373,492.426270 368.047363,493.117004 368.826324,495.233978 
	C369.505280,497.079224 371.294922,497.501587 372.934662,497.445923 
	C376.989624,497.308289 380.660095,495.810669 384.718994,493.327148 
M432.090424,465.050018 
	C426.970001,466.570404 422.384247,468.763794 420.012695,474.093597 
	C425.345123,476.561340 433.626953,473.822266 435.802368,469.117371 
	C437.200317,466.093872 436.072296,464.682129 432.090424,465.050018 
M469.148560,436.796265 
	C467.023468,438.574493 462.758972,439.604034 464.769684,443.295898 
	C466.627045,446.706238 470.178040,444.325348 472.772125,443.485931 
	C476.195496,442.378174 480.638641,440.412872 478.839844,436.215820 
	C477.081909,432.114075 473.163483,435.117401 470.216492,436.256958 
	C470.062927,436.316376 469.915375,436.391388 469.148560,436.796265 
M336.078735,504.490448 
	C334.519653,505.587769 331.817444,506.306213 332.674866,508.683960 
	C333.543213,511.092194 336.305603,510.934235 338.316101,510.254395 
	C341.753357,509.092072 345.184052,507.775452 348.122070,505.501495 
	C349.427490,504.491089 350.269989,503.320160 349.425537,501.682648 
	C348.540314,499.966125 347.026855,500.178375 345.538788,500.746735 
	C342.602783,501.868134 339.682312,503.030273 336.078735,504.490448 
M304.991699,515.572754 
	C300.901459,517.083557 296.454956,517.993469 293.605530,521.963745 
	C295.200836,523.936584 297.037537,523.710815 298.826172,523.395264 
	C302.289307,522.784241 305.536652,521.522278 308.582367,519.783142 
	C310.548767,518.660339 313.521545,517.586670 312.360260,514.833801 
	C311.170929,512.014465 308.965637,515.123779 307.171906,515.003296 
	C306.696198,514.971313 306.202362,515.209656 304.991699,515.572754 
z"/>
			</svg>
		</>
	);
};

export default Index;
