import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { timeout } from 'asset';
import { useAppSelector } from 'store/store';
export default function Timeout({ setStep }) {
  const { schoolAssignmentResult } = useAppSelector((state) => state.schoolAssignmentReducer);

  const totalQuestions = schoolAssignmentResult?.answerCorrect?.length;
  const correctAnswers = schoolAssignmentResult?.answerCorrect?.filter((answer) => answer.student_answer === answer.correct_answer).length;

  return (
    <Box
      sx={{
        '& .MuiOutlinedInput-root ': {
          fontSize: '2rem',
        },
        width: '60%',
        bgcolor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        position: 'relative',
        p: 12,
        gap: 6,
      }}
    >
      <img src={timeout} alt="" className="w-24 h-24" />
      <Typography className="!font-bold !text-5xl !text-black text-center">
        Time Has Run Out!
      </Typography>
      <Typography className="!font-normal !text-2xl !text-gray-400 text-center">
        You received{' '}
        <span className="!font-bold" style={{ color: '#DF3023' }}>
          {schoolAssignmentResult?.percentage}%
        </span>{' '}
        ({correctAnswers} out of {totalQuestions} questions correct)
      </Typography>
      <Typography className="!font-normal !text-2xl !text-gray-400 text-center">
        Please wait for your teacher.
      </Typography>
      <Typography
        className="!font-bold !text-2xl text-center"
        sx={{ color: '#C73E9D' }}
      >
        $G3MS Earned: {0}
      </Typography>

      <Button
        fullWidth
        size="large"
        sx={{ py: '1rem' }}
        className="!py-4"
        variant="contained"
        onClick={() => setStep(0)}
      >
        Ok
      </Button>
    </Box>
  );
}
