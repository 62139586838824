import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/analytics';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import { OAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDUohCSEHMyoP_ecjKQW9H8OwxP7kP2IWk',
  authDomain: 'gems-2022.firebaseapp.com',
  databaseURL: 'https://gems-2022-default-rtdb.firebaseio.com',
  projectId: 'gems-2022',
  storageBucket: 'gems-2022.appspot.com',
  messagingSenderId: '960405792185',
  appId: '1:960405792185:web:a90171238b4cdc776cc268',
  measurementId: 'G-YLPCNBMT1F',
};

const devFirebaseConfig = {
  apiKey: "AIzaSyCzR0-ikOGy8G9rOoP8GFCckpNdz_D7PmQ",
  authDomain: "g3ms-dev.firebaseapp.com",
  projectId: "g3ms-dev",
  storageBucket: "g3ms-dev.appspot.com",
  messagingSenderId: "881122938032",
  appId: "1:881122938032:web:bc8bbc07f1216fdcb27f75",
  measurementId: "G-EVYV2Y4JQD"
}

// Initialize Firebase
const app = firebase.initializeApp(devFirebaseConfig);

// init collections
const db = firebase.firestore();
export const usersCollection = db.collection('users');

// init auth
export const auth = firebase.auth();

export const googleProvider = new GoogleAuthProvider();
export const appleProvider = new OAuthProvider('apple.com');
export const classLinkProvider = new OAuthProvider('oidc.classlink');

export const cleverProvider = new OAuthProvider('oidc.clever');
googleProvider.setCustomParameters({ prompt: 'select_account' });
classLinkProvider.setCustomParameters({
  // Target specific email with login hint.
  prompt: 'login',
});


export const setAnalyticsUserId = setUserId;

export const analytics = firebase.analytics();

export const signInWithId = signInWithCredential;


