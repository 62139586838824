import { useEffect, useState } from 'react';
import { Dna } from 'react-loader-spinner';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import ArrowDownIcon from '../../components/input/arrow_down.png';
import AutoComplete from '../../components/input/autocomplete';
import SelectOption from '../../components/input/selectOption';
import SelectOrAdd from '../../components/input/selectOrAdd';
import TextField from '../../components/input/textField';
import sharedStyles from '../../sharedStyle.module.scss';
import CityIcon from '../student/city.png';
import PersonIcon from '../student/person.png';
import SchoolIcon from '../student/school.png';
import { ReactComponent as UploadFileIcon } from '../upload_file.svg';
import styles from './parent.module.scss';
import analytics from 'utils/analytics';

const Parent = ({ user, uuid, existingEmail }) => {
  const [topics, setTopics] = useState(['Topic1', 'Topic2', 'Topic3']);
  const [gender, setGender] = useState('Mr');
  const [school, setSchool] = useState('');
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [teachMethod, setTeachMethod] = useState('option1');
  const [image, setImage] = useState('');
  const [imageObject, setImageObject] = useState(null);

  const [state, setState] = useState('');
  const [stateData, setStateData] = useState([]);

  const [schoolData, setSchoolData] = useState([]);
  const [schoolLeaders, setSchoolLeaders] = useState([]);
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (existingEmail) {
      setEmail(existingEmail);
    }
  }, [existingEmail]);

  function onSignUp() {
    if (!firstName || !imageObject || !gender || !school) {
      NotificationManager.error('Please fill all the fields', 'Error');
      return;
    }

    // check if email is valid regex
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      NotificationManager.error('Please enter a valid email', 'Error');
      return;
    }

    const schoolId = schoolData.find((v) => v.name === school).id;
    console.log(schoolId);

    const formData = new FormData();
    formData.append('name', firstName + ' ' + lastName);
    formData.append('schoolId', schoolId);
    formData.append('file', imageObject);

    setLoading(true);
    axios
      .patch(`/parents/${uuid}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        setLoading(false);

        localStorage.removeItem('currentUser');
        const token = response.data.token;
        window.location.href = `https://dev-admin-panel.g3ms.co/super-admin/dashboard?token=${token}`;
        analytics.logEvent('complete_profile', {
          user_id: response?.data?.user?.id,
        });
      })
      .catch((error) => {
        setLoading(false);
        NotificationManager.error('Something went wrong', 'Error');
        console.log('error:', error);
        analytics.logEvent('complete_profile_error', { user_id: uuid, error });
      });
  }

  useEffect(() => {
    axios.get('/school-leader').then((response) => {
      setSchoolLeaders(
        response.data.data.items.map((leader) => {
          return {
            name: leader.name,
            email: leader.email,
          };
        }),
      );
      analytics.logEvent('get_school_leaders');
    }).catch((err) => {
      analytics.logEvent('get_school_leaders_error');
      console.log(err);
    });

    axios.get('/state').then((response) => {
      setStateData(response.data.data);
      analytics.logEvent('get_states');
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    if (state) {
      axios
        .get(`/state/${state}/school`)
        .then((response) => {
          setSchoolData(response.data.data);
          analytics.logEvent('get_schools', { state: state });
        })
        .catch((error) => {
          console.log(error);
          analytics.logEvent('get_schools_error', { state: state });
        });
    }
  }, [state]);

  return (
    <div className={sharedStyles.centerContainer}>
      <div
        className={`${styles.completeProfile} ${sharedStyles.whiteContainerBox}`}
      >
        <h1 className={styles.title}>Please complete your profile.</h1>
        <h1 className={styles.title}>
          Don't worry, your data will remain private and only you can see it.
        </h1>

        <label className={sharedStyles.uploadImage}>
          {image ? <img src={image} alt={'profile'} /> : <UploadFileIcon />}

          <input
            type={'file'}
            name={'upload_image'}
            onChange={(target) => {
              setImage(URL.createObjectURL(target.target.files[0]));
              setImageObject(target.target.files[0]);
            }}
          />
        </label>

        <div className={styles.row}>
          <SelectOption options={['Mr', 'Mrs']} setSelected={setGender} />
          <TextField
            type={'text'}
            placeholder={'First Name'}
            icon={PersonIcon}
            onChange={setFirstName}
          />
          <TextField
            type={'text'}
            placeholder={'Last Name'}
            icon={PersonIcon}
            onChange={setLastName}
          />
        </div>

        <div className={styles.row}>
          <AutoComplete
            placeholder={'Select State'}
            icon={SchoolIcon}
            onChange={setState}
            value={state}
            options={stateData}
          />
          <AutoComplete
            placeholder={'School'}
            icon={SchoolIcon}
            onChange={setSchool}
            value={school}
            options={(schoolData || []).map((school) => school.name)}
          />
        </div>

        <TextField
          type={'text'}
          value={email}
          placeholder={'Enter your email'}
          icon={SchoolIcon}
          onChange={setEmail}
        />

        <button
          className={sharedStyles.loginButton}
          onClick={onSignUp}
          style={{ backgroundColor: '#C73E9D', color: '#F5F5F5' }}
        >
          Sign Up
        </button>

        {loading && (
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        )}
      </div>
    </div>
  );
};

export default Parent;
