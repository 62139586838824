import cn from 'utils/cn';

const YesNoResBtn = ({
  title,
  options = [],
  handleOptionSelect,
  selectedCanvasObj,
  setSelectedCanvasObj,
  isDisabled,
  type,
}) => {
  const regex = /Perfect, now is the time to choose which part of /;
  const needMoreHelpRegex = /Need More Help?/i;
  const isNeedMoreHelp = options?.find((item) =>
    needMoreHelpRegex.test(item?.label),
  );

  const hasOptionsWithLengthFourteen = options?.find(
    (item) => item?.label?.length >= 14,
  );

  const isOptionsWithYesNo = options?.find(
    (item) =>
      item?.label?.toLowerCase() === 'yes' ||
      item?.label?.toLowerCase() === 'no',
  );

  console.log('isOptionsWithYesNo', isOptionsWithYesNo);

  return (
    <div
      className={cn('grid gap-4 grid-cols-2', {
        'grid-cols-2':
          options?.length === 4 &&
          !isNeedMoreHelp &&
          !hasOptionsWithLengthFourteen,
        'grid-cols-1': isNeedMoreHelp || hasOptionsWithLengthFourteen || options?.length === 1,
        'grid-cols-3': 
          options?.length === 3 &&
          !isNeedMoreHelp &&
          !hasOptionsWithLengthFourteen,
      })}
    >
      {options?.map((item, _index) => (
        <button
          className={cn(
            'bg-[#20A58A] h-[3rem] px-4  rounded-xl text-sm sm:text-base md:text-lg text-white font-bold w-full even:bg-[#FF6B6B] [box-shadow:_0px_4px_12px_0px_#FFFFFF66_inset] even:[box-shadow:_0px_4px_12px_0px_#FFFFFF66_inset]',
            {
              'h-[4.5rem]': hasOptionsWithLengthFourteen,
              '!bg-[#C44297]':
                options?.length > 2 ||
                item?.optionType === 'PART_SELECTION' ||
                item?.optionType === 'PART',
            },
          )}
          // className={`${styles.optionButton} ${
          //   (item?.toLowerCase() == "no" || item?.toLowerCase() == "not ready")
          //     ? styles.optionButtonNo
          //     : styles.optionButtonYes
          // }`}
          onClick={async () => {
            if (isDisabled) return;
            await handleOptionSelect(item);
            console.log('item', item);
            if (
              item.optionType === 'PART' ||
              item?.optionType === 'PART_SELECTION'
            ) {
              setSelectedCanvasObj({
                ...selectedCanvasObj,
                imgPos: item?.label?.toLowerCase(),
                pattern: null,
                imgMaterial: null,
                imgColor: null,
              });
            }
          }}
          disabled={isDisabled}
          key={_index}
        >
          {item?.label}
        </button>
      ))}
    </div>
  );
};

export default YesNoResBtn;
