export function secondsToMinutes(seconds) {
  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;

  if (minutes === 0) {
    return parseFloat(remainingSeconds.toFixed(0)) + ' sec';
  } else {
    return minutes + ' min ' + parseFloat(remainingSeconds.toFixed(0)) + ' sec';
  }
}

export function getFontSize(text) {
  const config = {
    20: 40,
    10: 50,
  }
  for (const key in config) {
    if (text.length < key) {
      console.log(text.length, key)
      return config[key];
    }
  }
  return 30;
}
