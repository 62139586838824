import { useNavigate } from 'react-router-dom';
import styles from './challengeSelection.module.scss';

import GetInfoIcon from '../../../../asset/challenge_selection/get_info_icon.svg';

const Index = ({ Index, data, defaultChallenges }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.card} onClick={() => navigate(`${defaultChallenges?.[Index]?.url}/${data?.id}`)}>
        <div className={styles.cardHeader}>
          <h3>{data?.title}</h3>
          <span className={styles.tooltip} data-tooltip={data?.description}>
            <img src={GetInfoIcon} alt="Get Info Icon" />
          </span>
        </div>
        <div className={styles.cardImgWrapper}>
          <img src={data?.mediaUrl} alt={data?.title} />
        </div>
      </div>
    </>
  )
}

export default Index;