const Belt = ({ selectedCanvasObj }) => {
	return (
		<>
			<svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				viewBox="0 0 720 308">
				<path fill={selectedCanvasObj?.imgColor} d="M562.8,172.3c0,0-5.8-19-10.1-31.8s-5.3-23.5-15.7-24.2c-10.4-0.7-58.5-5.1-90.4-5.1s-133-0.5-133-0.5s-50.8,2.7-65.3,3.4
	c-14.5,0.7-53.4,1.2-60.7,1.7c-7.3,0.5-8.5,3.4-11.8,11.6c-3.4,8.2-7,18.6-10.6,27.8c-3.6,9.2-2.4,14.5,0.2,16.7
	c0,0,23.9,2.2,31.4,1c7.5-1.2,5.6-5.1,27.8-5.6c22.2-0.5,104.7-1,125.7-0.7c21,0.2,69.4,0,88.3,0.2c18.9,0.2,64.3-0.7,75.4,2.7
	C525.1,172.9,548.3,174.4,562.8,172.3z"/>
			</svg>

		</>
	);
};

export default Belt;
