import React from 'react';
import { Button } from '@mui/material';

const SharedButton = ({
  variant,
  name,
  disabled,
  className,
  ...props
}) => {
  return (
    <Button
      variant={variant}
      disabled={disabled}
      className={className}
      {...props}
    >
      {name}
    </Button>
  );
};

export default SharedButton;
