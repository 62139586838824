import PlayIcon from 'icons/PlayIcon';
import React from 'react';

export default function FractionItem({ onClick, image, title, btnTitle }) {
  return (
    <div className="px-4 py-3 md:py-4 grid sm:grid-cols-[auto,1fr] bg-fractionBg rounded-3xl items-center gap-4 xl:gap-8">
      <img
        src={image}
        alt=""
        className="w-full h-full max-w-[10rem] lg:max-w-[12.25rem] lg:h-[6rem] object-contain mx-auto"
      />
      <div className="space-y-3">
        <h1 className="font-medium text-[#2C3E50] !text-xs xl:text-sm 2xl:!text-base">
          {title}
        </h1>

        <button
          className="bg-primary-100 text-white rounded-2xl px-4 py-3 lg:px-5 lg:py-4 font-medium !text-xs xl:!text-sm 2xl:!text-base flex items-center gap-4 justify-center
w-full"
          onClick={onClick}
        >
          <PlayIcon className="text-white size-5 lg:size-6 " />
          <span>{btnTitle}</span>
        </button>
      </div>
    </div>
  );
}
