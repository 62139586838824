const Skirt = ({ selectedCanvasObj }) => {
	return (
		<>
			<svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				viewBox="0 0 720 698">
				<path fill={selectedCanvasObj?.imgColor} d="M566.7,40.5c0,0-45.7,0.4-61,2.9c-15.2,2.5-71.3,2.1-87.3,2.5s-16.5-0.8-23.5,0.8s-71.7,0.8-84.9,0.4
	c-13.2-0.4-21.8-4.9-36.7-5.4c-14.8-0.4-27.5,0.5-27.5,0.5l-2.5,3.6v12.9c0,0-20.6,56.3-22.5,87.1c-1.9,30.8-6,53-9.3,70.3
	c-3.3,17.3-3.3,33.5-6,47.8s-3.3,23.6,0.3,37.6c3.6,14,9.1,50.8,12.6,62.6c3.6,11.8,17.8,63.2,22.4,99.8c4.5,36.7,9.5,63,10.8,76.8
	c1.4,13.7,2.2,16.2,3.3,18.9c1.1,2.7,22.2,21.7,41.5,28.6s46.3,8.2,51.5,9.2c5.1,1,6.6,1,7.8-3.7c1.2-4.7,11.9-34.8,15.2-44.1
	c2.4-6.9,7.3-20.3,9.6-26.8c0.6-1.6,2.7-1.9,3.7-0.6c4.9,6.2,16.5,20.8,25.8,32.2c9.5,11.6,30.8,36.8,39.6,47.3
	c2.6,3,6.3,4.7,10.3,4.7c9.4-0.2,27.7-0.6,41.5-1.3c19.6-1,29-9.9,41.8-24.3c12.8-14.4,13-25.3,13-28.4c0-3.1,7.4-25.5,7.6-44.3
	c0.2-18.7,2.7-31.7,5.8-45.3c3.1-13.6,10.9-41.2,12.6-60.1s4.3-60.1,5.1-66.5c0.8-6.4,7.8-34.6,8.2-49.6c0,0,7.6-15,7.6-23.5
	s-2.5-25.7-3.7-36s-2.9-30.3-3.3-40.8c-0.4-10.5-1.6-55-7.8-77S569,45.1,566.7,40.5z"/>
			</svg>

		</>
	);
};

export default Skirt;
