import React, { useState } from 'react';
import sharedStyles from '../../../sharedStyle.module.scss';
import { Box } from '@mui/material';
import Logo from '../../../components/logo.png';
import Modal from 'auth/components/modal/modal';
import StudentSelectAvatar from 'auth/completeProfile/student/studentSelectAvatar';

export default function ProfileAvatar({ setStep }) {
  const [modalOpen, setModalOpen] = useState(false);

  const [image, setImage] = useState(null);

  return (
    <>
      <Box className={sharedStyles.profileAvatarContainer}>
        <Box className={sharedStyles.avatarLogo}>
          <img src={Logo} alt={'logo'} />
        </Box>
        <Modal open={true} setOpen={setModalOpen}>
          <StudentSelectAvatar
            setStep={setStep}
            setImage={(image) => {
              setModalOpen(false);
              setImage(image);
            }}
          />
        </Modal>
      </Box>
    </>
  );
}
