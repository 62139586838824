import React from "react";

export default function DailyQuiz() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2920_18327)">
      <path d="M-0.000699626 9.29774C0.0440765 9.01373 0.0739272 8.72973 0.118703 8.46067C0.178405 8.13182 0.238106 7.78802 0.312733 7.47412C0.432136 7.01074 0.879897 6.75663 1.34258 6.86127C1.79035 6.9659 2.07393 7.41433 1.95452 7.87771C1.76049 8.71478 1.67094 9.55185 1.70079 10.4188C1.71572 10.9121 1.40229 11.2858 0.924674 11.3456C0.506763 11.4054 0.148554 11.1214 0.0142257 10.658C0.0142257 10.643 -0.000699626 10.6281 -0.015625 10.6131C-0.015625 10.1797 -0.015625 9.74617 -0.015625 9.32763L-0.000699626 9.29774Z" fill="#666666"/>
      <path d="M10.1502 20.0001C10.1502 20.0001 10.0755 19.9702 10.0457 19.9553C9.62778 19.8506 9.35913 19.4769 9.38898 19.0733C9.43375 18.6399 9.74719 18.2961 10.18 18.2811C11.0457 18.2363 11.8964 18.0868 12.7173 17.8177C13.2099 17.6533 13.6875 17.9074 13.8218 18.3708C13.9561 18.8491 13.7024 19.2826 13.2099 19.447C12.3442 19.731 11.4636 19.9254 10.5382 19.9553C10.4785 19.9553 10.4188 19.9852 10.3442 19.9852H10.1502V20.0001Z" fill="#666666"/>
      <path d="M14.6403 5.96407C14.6403 5.96407 14.5358 6.18828 14.4313 6.29292C13.5358 7.21967 12.6254 8.16138 11.7149 9.07319C11.6104 9.17782 11.5806 9.28245 11.5955 9.43193C11.7448 10.5381 11.894 11.6292 12.0433 12.7354C12.1627 13.6322 12.297 14.5291 12.4015 15.426C12.4463 15.7996 12.2075 16.1135 11.8343 16.1733C11.4761 16.2331 11.1477 16.0089 11.0731 15.6502C10.8045 14.4992 10.5358 13.3482 10.2522 12.1973C10.2373 12.1225 10.1925 12.0029 10.1328 11.988C10.0134 11.9431 9.89402 11.9581 9.84924 12.1375C9.59551 13.2436 9.32685 14.3497 9.0582 15.4558C9.04327 15.5455 9.02835 15.6352 8.9985 15.7249C8.87909 16.0687 8.53581 16.263 8.1776 16.1733C7.84924 16.0836 7.64029 15.7548 7.68506 15.411C7.89402 13.8863 8.10297 12.3766 8.297 10.852C8.3567 10.3587 8.41641 9.86541 8.50596 9.38709C8.52088 9.26751 8.49103 9.19277 8.40148 9.11803C7.49103 8.19127 6.59551 7.26452 5.69999 6.32281C5.61044 6.23313 5.53581 6.12849 5.49103 6.02386C5.41641 5.79964 5.50596 5.56048 5.69999 5.4409C5.89402 5.32132 6.14775 5.35121 6.32685 5.51564C6.61044 5.7847 6.87909 6.0687 7.14775 6.35271C7.46118 6.66661 7.77461 6.99546 8.10297 7.30936C8.16267 7.36915 8.28208 7.41399 8.37163 7.41399C9.47611 7.41399 10.5806 7.41399 11.6851 7.41399C11.7746 7.41399 11.894 7.36915 11.9686 7.29441C12.5507 6.7264 13.103 6.15839 13.6701 5.57543C13.8194 5.42595 13.9686 5.33627 14.1925 5.38111C14.4463 5.42595 14.6104 5.62027 14.5955 5.96407H14.6403Z" fill="#666666"/>
      <path d="M20.0013 10.0748C19.9864 11.0614 19.8222 12.018 19.5386 12.9597C19.3894 13.468 18.9565 13.7221 18.4789 13.6025C18.0162 13.4829 17.7476 13.0046 17.8968 12.5113C18.1953 11.5248 18.3147 10.5233 18.27 9.50682C18.27 9.3125 18.2252 9.11818 18.255 8.92386C18.3147 8.47543 18.7028 8.16153 19.1207 8.19142C19.5685 8.20637 19.9117 8.55016 19.9565 9.02849C19.9864 9.38723 20.0013 9.74598 20.0162 10.1047L20.0013 10.0748Z" fill="#666666"/>
      <path d="M4.96933 2.9599C4.96933 3.25885 4.82008 3.46812 4.62605 3.67739C4.22306 4.08097 3.835 4.48456 3.46187 4.9031C3.26784 5.12731 3.11859 5.39637 2.92456 5.62058C2.56635 6.05407 1.92456 6.03912 1.5962 5.59069C1.38724 5.30668 1.37232 4.93299 1.56635 4.61909C2.11859 3.75212 2.79023 2.98979 3.56635 2.33209C3.84993 2.09293 4.16336 2.04809 4.49172 2.19756C4.80515 2.34704 4.96933 2.60115 4.96933 2.9599Z" fill="#666666"/>
      <path d="M1.53593 12.377C1.80459 12.377 2.05832 12.5713 2.2225 12.9001C2.49116 13.4382 2.75981 13.9614 3.04339 14.4995C3.1628 14.7237 3.3419 14.9181 3.49116 15.1423C3.78966 15.5758 3.71504 16.084 3.32698 16.368C2.92399 16.652 2.43145 16.5474 2.11802 16.1288C1.52101 15.3515 1.04339 14.4995 0.685185 13.5877C0.446379 12.9749 0.819514 12.377 1.55086 12.377H1.53593Z" fill="#666666"/>
      <path d="M16.9712 3.0791C17.1055 3.0791 17.3294 3.18374 17.4936 3.37806C18.1652 4.14039 18.7175 4.99241 19.1205 5.93411C19.3145 6.36759 19.1503 6.84592 18.7324 7.05519C18.3294 7.26445 17.8667 7.12992 17.613 6.71139C17.2846 6.17327 16.9861 5.62021 16.6578 5.08209C16.5085 4.84293 16.3145 4.64861 16.1503 4.42439C15.9414 4.14039 15.9264 3.84143 16.0757 3.54248C16.2249 3.24353 16.4936 3.0791 16.9563 3.0791H16.9712Z" fill="#666666"/>
      <path d="M14.6406 17.3539C14.6406 17.0101 14.775 16.8008 14.9839 16.6214C15.4018 16.2477 15.8197 15.874 16.2078 15.4704C16.4167 15.2612 16.5958 14.9921 16.7899 14.753C17.0287 14.4689 17.3272 14.3793 17.6854 14.4689C18.0287 14.5586 18.2227 14.8127 18.2973 15.1565C18.3421 15.3957 18.2675 15.605 18.1332 15.7993C17.5361 16.6214 16.8347 17.3389 16.0287 17.9667C15.7451 18.1909 15.4317 18.2208 15.1182 18.0564C14.8197 17.9069 14.6555 17.6379 14.6555 17.3539H14.6406Z" fill="#666666"/>
      <path d="M10.1031 0.851636C10.1031 1.30007 9.80455 1.65881 9.35679 1.70365C8.49112 1.79334 7.6553 2.00261 6.8344 2.30156C6.37171 2.48093 5.8941 2.24177 5.74485 1.80829C5.58067 1.34491 5.80455 0.896479 6.28216 0.702159C7.20754 0.313519 8.17768 0.104251 9.19261 -0.000382342C9.68515 -0.0452254 10.118 0.343414 10.118 0.851636H10.1031Z" fill="#666666"/>
      <path d="M14.6853 2.98961C14.551 2.94477 14.4167 2.94477 14.2973 2.87003C13.5062 2.4216 12.6704 2.09275 11.8047 1.85359C11.4465 1.7639 11.2227 1.39021 11.2227 1.03147C11.2227 0.657774 11.4913 0.328925 11.8495 0.239239C11.9839 0.209344 12.1331 0.194396 12.2674 0.239239C13.2674 0.478402 14.2077 0.852094 15.0883 1.37526C15.4465 1.58453 15.5958 1.95822 15.4913 2.34686C15.3868 2.70561 15.0585 2.95972 14.6704 2.98961H14.6853Z" fill="#666666"/>
      <path d="M8.28181 18.7889C8.28181 19.3719 7.74449 19.7605 7.1624 19.5961C6.19225 19.3121 5.2818 18.8786 4.44598 18.3256C4.043 18.0565 3.93852 17.5632 4.17733 17.1596C4.41613 16.7561 4.90867 16.6514 5.34151 16.8756C5.87882 17.1596 6.43106 17.4586 6.9833 17.7277C7.23703 17.8472 7.50569 17.907 7.77434 18.0117C8.11763 18.1462 8.31166 18.4451 8.31166 18.8039L8.28181 18.7889Z" fill="#666666"/>
      <path d="M11.3295 5.8296C11.3295 6.4873 10.8071 7.02542 10.1504 7.02542C9.47872 7.02542 8.94141 6.50225 8.94141 5.8296C8.94141 5.17191 9.47872 4.63379 10.1354 4.63379C10.7922 4.63379 11.3295 5.15696 11.3295 5.81466V5.8296Z" fill="#666666"/>
      </g>
      <defs>
      <clipPath id="clip0_2920_18327">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}
