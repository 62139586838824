import { apiSlice } from '..';

export const aiChatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAiChat: builder.query({
      query: () => {
        return {
          url: 'chatbot/session/log',
          method: 'GET',
        };
      },
      providesTags: ['AiChat'],
      transformResponse: (response) => {
        if (Array.isArray(response.data)) {
          return response;
        }

        return {
          ...response,
          data: [],
        };
      },
    }),
    sendAiChat: builder.mutation({
      query: (body) => {
        return {
          url: 'chatbot/session/message',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const userContent = {
          role: 'user',
          content: body.prompt,
          timestamp: new Date().toISOString(),
        };

        const action = aiChatApiSlice.util.updateQueryData(
          'getAiChat',
          undefined,
          (draft) => {
            if (!draft.data) return;
            draft.data = [...draft.data, userContent];
          },
        );

        const dispatcher = dispatch(action);

        try {
          const response = await queryFulfilled;

          dispatch(
            aiChatApiSlice.util.updateQueryData(
              'getAiChat',
              undefined,
              (draft) => {
                if (!draft.data) return;
                draft.data = response.data?.data?.conversationLog;
              },
            ),
          );
        } catch (error) {
          console.log(error);
          dispatcher.undo();
        }
      },
    }),
    resetChat: builder.mutation({
      query: () => {
        return {
          url: 'chatbot/session/reset',
          method: 'PATCH',
        };
      },
      invalidatesTags: ['AiChat'],
    }),
  }),
});

export const {
  useGetAiChatQuery,
  useLazyGetAiChatQuery,
  useSendAiChatMutation,
  useResetChatMutation,
} = aiChatApiSlice;
