import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  schoolAssignmentTodayList: [],
  joinedSchoolAssignment: {},
  selectedAssignment: {},
  schoolAssignmentResult: null,
  timerDurationSecs: 0,
  quizLoading: false,
  step: null,
  status: 'Start',
};

const SchoolAssignmentSlice = createSlice({
  name: 'school_assignment_slice',
  initialState: initialState,
  reducers: {
    setSchoolAssignmentTodayList: (state, action) => {
      state.schoolAssignmentTodayList = action.payload;
    },
    setJoinedSchoolAssignment: (state, action) => {
      state.joinedSchoolAssignment = action.payload;
    },
    setSelectedAssignment: (state, action) => {
      state.selectedAssignment = action.payload;
    },
    setSchoolAssignmentLoading: (state, action) => {
      state.quizLoading = action.payload;
    },
    setSchoolAssignmentStep: (state, action) => {
      state.step = action.payload;
    },
    setSchoolAssignmentStatus: (state, action) => {
      state.status = action.payload;
    },
    setSchoolAssignmentResult: (state, action) => {
      state.schoolAssignmentResult = action.payload;
    },
    setTimerDurationSecs: (state, action) => {
      state.timerDurationSecs = action.payload;
    },
  },
});

export const {
  setSchoolAssignmentTodayList,
  setJoinedSchoolAssignment,
  setSchoolAssignmentLoading,
  setSelectedAssignment,
  setSchoolAssignmentStep,
  setSchoolAssignmentStatus,
  setSchoolAssignmentResult,
  setTimerDurationSecs,
} = SchoolAssignmentSlice.actions;

export default SchoolAssignmentSlice.reducer;
