import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Chatbot from '../chatbot';
import Header from '../header';
import styles from './sneakerChallenge.module.scss';
import SneakerPlay from './SneakerPlay';

import ChatIcon from '../../../../../asset/challenges/mblNavigation/chat.svg';
import PlayIcon from '../../../../../asset/challenges/mblNavigation/play.svg';

import { nanoid } from '@reduxjs/toolkit';
import { AiBotProvider } from 'context/AibBotContext';
import { useParams } from 'react-router-dom';

import {
  useFetchChallengeDataMutation,
  useFetchNextResponseMutation,
  useGetChallengesQuery,
  useGetHelpMutation,
  useResetChallengeMutation,
} from 'store/apis/challenges';
import {
  handleChallengeId,
  handleMessages,
  handlePlayable,
  handleResponseId,
  handleSelectedCanvasObj,
  handleSelectedCard,
  handleSessionId,
  removeMessage,
  resetChat,
  resetSneakerBot,
  selectCanvasData,
  selectChallengeId,
  selectMessages,
  selectPlayable,
  selectResponseId,
  selectSelectedCanvasObj,
  selectSelectedCard,
  selectSessionId,
} from 'store/Slice/sneakerBot';

import { useGetMyProfileQuery } from 'store/apis/profile';
import { resetDressBot } from 'store/Slice/dressBotSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import cn from 'utils/cn';

const mblNavigations = [
  {
    name: 'Play',
    icon: PlayIcon,
  },
  {
    name: 'Chat',
    icon: ChatIcon,
  },
];

const INDEX_VAL = 0;

const Index = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const canvasData = useAppSelector(selectCanvasData);

  const isPlayable = useAppSelector(selectPlayable);
  const setIsPlayable = (value) => dispatch(handlePlayable(value));

  const selectedCard = useAppSelector(selectSelectedCard);
  const setSelectedCard = (value) => dispatch(handleSelectedCard(value));

  const sessionId = useAppSelector(selectSessionId);
  const setSessionId = (value) => dispatch(handleSessionId(value));

  const responseId = useAppSelector(selectResponseId);
  const setResponseId = (value) => dispatch(handleResponseId(value));

  const challengeId = useAppSelector(selectChallengeId);
  const setChallengeId = (value) => dispatch(handleChallengeId(value));

  const messages = useAppSelector(selectMessages);

  const setMessages = (value) => dispatch(handleMessages(value));

  const selectedCanvasObj = useAppSelector(selectSelectedCanvasObj);
  const setSelectedCanvasObj = (value) =>
    dispatch(handleSelectedCanvasObj(value));

  const { data, isLoading, error } = useGetChallengesQuery();

  useEffect(() => {
    if (data) {
      const challengeData = data.data.data[INDEX_VAL];
      console.log('challenge data:', data.data.data);
      setChallengeId(challengeData?.id);
    }
  }, [data]);

  const [fetchNextResponse, fetchNextResponseData] =
    useFetchNextResponseMutation();
  console.log(
    fetchNextResponseData?.data?.data?.customizations,
    'fetchNextResponseData?.data?.data?.customizations',
  );

  const params = useParams();

  const [fetchData, fetchDataResponse] = useFetchChallengeDataMutation();

  const [getHepRequest, getHelpResponse] = useGetHelpMutation();

  const { data: userProfile, isLoading: profileLoading } =
    useGetMyProfileQuery();

  const userName = userProfile?.data?.name;
  const profileImage = userProfile?.data?.profileImage;

  const handleInitialMessage = React.useCallback(
    async ({ predefinedResponse, session }) => {
      if (!predefinedResponse || !session) {
        return;
      }

      const initialMessage = {
        id: predefinedResponse?.challengeId || nanoid(),
        sender: 'bot',
        message: predefinedResponse?.responseText || '',
        time: new Date().toISOString(),
        options:
          predefinedResponse?.predefinedResponseOption?.map(
            (option) => option.label,
          ) || [],
        predefinedResponseOption:
          predefinedResponse?.predefinedResponseOption || [],
        optionType: predefinedResponse?.responseType,
      };

      setMessages(initialMessage);
      setChallengeId(predefinedResponse?.challengeId);
      setSessionId(session?.id);
      setResponseId(predefinedResponse?.id);
    },
    [],
  );

  useEffect(() => {
    let clear = params?.id;

    console.log(clear, 'clear');

    const fetchChallengeData = async () => {
      if (sessionId) {
        return;
      }

      try {
        const response = await fetchData({
          challengeId: params?.id,
        }).unwrap();

        const predefinedResponse = response?.data?.data?.predefinedResponse;
        const session = response?.data?.data?.session;

        handleInitialMessage({ predefinedResponse, session });
      } catch (error) {
        console.error('Error fetching challenge data:', error);
      }
    };

    if (clear) {
      fetchChallengeData();
      clear = null;
    }

    return () => {
      clear = null;
    };
  }, [params?.id, sessionId]);

  const handleSend = async (message) => {
    if (message.length === 0) return;

    const userMessage = {
      id: nanoid(),
      sender: 'user',
      message: message,
      time: new Date().toISOString(),
      profileImage: profileImage,
      userName: userName,
    };

    setMessages(userMessage);

    try {
      const res = await getHepRequest({
        predefinedResponseId: responseId,
        studentPrompt: message,
        sessionId: sessionId,
      }).unwrap();
      console.log('response', res?.data?.data?.statusCode);

      const messageBody = {
        id: nanoid(),
        sender: 'bot',
        message: res?.data?.data?.aiHelpResponse,
        time: new Date().toISOString(),
        options: res?.data?.data?.responseOptions?.map(
          (option) => option.label,
        ),
        predefinedResponseOption: res?.data?.data?.responseOptions?.map(
          (option) => ({
            ...option,
            type: 'HELP',
          }),
        ),
        optionType: 'HELP',
      };
      setMessages(messageBody);
      console.warn('response', res);
    } catch (error) {
      alert('Error sending message');
      dispatch(removeMessage(userMessage.id));
      console.error('Error sending message:', error);
    }
  };

  const [
    lastOptionWithPredefinedResponse,
    setLastOptionWithPredefinedResponse,
  ] = React.useState(messages[0]);

  const handleOptionSelect = async (option) => {
    console.log('Option Selected:', option);
    if (option.type === 'HELP') {
      console.warn('HELP', option);
      if (option.key === 'no') {
        setMessages({
          ...lastOptionWithPredefinedResponse,
          id: nanoid(),
        });
      } else {
        const lastMessage = messages[messages.length - 2];
        let message = lastMessage?.message;

        handleSend(message);
      }
      return;
    }

    const nextResponseId = option?.nextPredefinedResponseId;

    if (option?.optionType === 'PLAY') {
      setSelectedCanvasObj({
        ...selectedCanvasObj,
        imgPos: 'full',
        pattern: null,
        imgMaterial: null,
        imgColor: null,
        shouldPlayFinalAnimation: true,
        customizedObj: fetchNextResponseData?.data?.data?.customizations,
      });
      setSelectedCard('Play');
      return;
    }

    if (option?.optionType === 'HELP') {
      await handleSend('Need More Help');
      return;
    }
    if (option?.optionType === 'CHALLENGE_SELECTION') {
      dispatch(resetDressBot());
      dispatch(resetSneakerBot());
      navigate('/app/student/challenge-selection');
      return;
    }

    const userMessage = {
      id: nanoid(),
      sender: 'user',
      message: `${option.label}`,
      time: new Date().toISOString(),
      profileImage: profileImage,
      userName: userName,
    };

    setMessages(userMessage);

    if (nextResponseId) {
      try {
        const response = await fetchNextResponse({
          challengeId: challengeId,
          sessionId: sessionId,
          responseId: responseId,
          optionId: option.id,
        }).unwrap();

        // Check if the selected option is a wrong answer
        if (!option.isCorrect) {
          console.error('User selected a wrong answer:', option);
          console.table(response);
        }

        console.log('Response:', response);

        if (response.successCode === 201) {
          const predefinedResponse = response?.data?.predefinedResponse;
          const nextMessage = {
            id: response.data?.challengeId || nanoid(),
            sender: 'bot',
            message: predefinedResponse?.responseText || '',
            time: new Date().toISOString(),
            options:
              predefinedResponse?.predefinedResponseOption?.map(
                (option) => option.label,
              ) || [],
            predefinedResponseOption:
              predefinedResponse?.predefinedResponseOption || [],
            optionType: predefinedResponse?.responseType,
          };

          setMessages(nextMessage);
          console.log('nextMessage:', nextMessage);
          if (nextMessage.optionType !== 'INCORRECT') {
            setLastOptionWithPredefinedResponse(nextMessage);
          }

          console.log('setResponseId:', predefinedResponse);
          setResponseId(predefinedResponse?.id);

          if (predefinedResponse?.predefinedResponseOption?.length === 0) {
            await handleNextResponse(predefinedResponse?.challengeId);
          }
        } else {
          throw new Error('Unexpected API response');
        }
      } catch (error) {
        console.error('Error fetching next response:', error);
      }
    }
  };

  const handleNextResponse = async (currentResponseId) => {
    console.log('Fetching next response for response ID:', currentResponseId);
    try {
      const response = await fetchNextResponse({
        challengeId: challengeId,
        sessionId: sessionId,
        responseId: currentResponseId,
        optionId: '',
      }).unwrap();

      if (response.successCode === 201) {
        const predefinedResponse = response?.data?.predefinedResponse;
        const nextMessage = {
          id: predefinedResponse?.challengeId || nanoid(),
          sender: 'bot',
          message: predefinedResponse?.responseText || '',
          time: new Date().toISOString(),
          options:
            predefinedResponse?.predefinedResponseOption.map(
              (option) => option.label,
            ) || [],
          predefinedResponseOption:
            predefinedResponse?.predefinedResponseOption || [],
          optionType: predefinedResponse?.responseType,
        };

        setMessages(nextMessage);

        if (predefinedResponse?.predefinedResponseOption?.length === 0) {
          await handleNextResponse(predefinedResponse?.challengeId);
        }

        console.log('setResponseId2:', predefinedResponse);

        setResponseId(predefinedResponse?.id);
      } else {
        throw new Error('Unexpected API response');
      }
    } catch (error) {
      console.error('Error fetching next response:', error);
    }
  };

  const [handleResetChallenge] = useResetChallengeMutation();

  const resetChallenge = async () => {
    try {
      const response = await handleResetChallenge(challengeId).unwrap();
      dispatch(resetChat());

      const predefinedResponse = response?.data?.data?.predefinedResponse;
      const session = response?.data?.data?.session;
      handleInitialMessage({ predefinedResponse, session });
    } catch (error) {
      console.error('Error resetting challenge:', error);
    }
  };

  const hasError = error || fetchDataResponse.error || fetchNextResponse.error;

  console.log('selectedCanvasObj:', selectedCanvasObj);

  if (hasError) {
    return (
      <div className={cn(styles.chatSection, 'w-full grid rounded-xl')}>
        <div className="flex items-center justify-center w-full h-full">
          <p>Error fetching data. Please try again later.</p>
        </div>
      </div>
    );
  }

  return (
    <AiBotProvider
      challengeId={challengeId}
      selectedCanvasObj={selectedCanvasObj}
      setSelectedCanvasObj={setSelectedCanvasObj}
      messages={messages}
      onSend={handleSend}
      onOptionSelect={handleOptionSelect}
      loading={
        isLoading ||
        profileLoading ||
        fetchDataResponse.isLoading ||
        fetchNextResponseData.isLoading
      }
      isToolbarDisabled={
        fetchNextResponseData.isLoading ||
        isLoading ||
        profileLoading ||
        getHelpResponse.isLoading
      }
      isTyping={fetchNextResponseData.isLoading || getHelpResponse.isLoading}
      onReset={resetChallenge}
      canvasData={canvasData}
    >
      <div className={cn(styles.challengeLayout, 'w-full')}>
        <Header />

        {/* desktop layout  */}
        <div
          className={`${styles.dressChallengeWrapper} ${styles.dressChallengeWrapperDesktop} !w-full`}
        >
          <div className={cn(styles.chatbotWrapper, 'w-full')}>
            <Chatbot />
          </div>
          <div className={styles.challengePreviewWrapper}>
            <SneakerPlay
              setIsPlayable={setIsPlayable}
              selectedCanvasObj={selectedCanvasObj}
            />
          </div>
        </div>

        {/* mobile layout  */}
        <div
          className={`${styles.dressChallengeWrapper} ${styles.dressChallengeWrapperMbl} w-full`}
        >
          {selectedCard === 'Chat' ? (
            <div className={cn(styles.chatbotWrapper, 'w-full')}>
              <Chatbot />
            </div>
          ) : (
            <div className={styles.challengePreviewWrapper}>
              <SneakerPlay
                setIsPlayable={setIsPlayable}
                selectedCanvasObj={selectedCanvasObj}
              />
            </div>
          )}
        </div>

        {/* mobile navigation  */}
        <div className={styles.mblNavigation}>
          <div className={styles.navigationBtnsWrapper}>
            {mblNavigations?.map((item, _index) => (
              <div
                onClick={() => {
                  setSelectedCard(item?.name);
                  item?.name === 'Home' &&
                    navigate('/app/student/challenge-selection');
                }}
                className={`${styles.navigationBtn} ${
                  item?.name === selectedCard && styles.navigationBtnSelected
                }`}
              >
                <img src={item?.icon} alt="" />
                <span>{item?.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AiBotProvider>
  );
};

export default Index;
