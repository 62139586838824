import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import CircularProgressWithLabel from '@core/components/CircularWithValueLabel';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import MuxPlayer from '@mux/mux-player-react';
import { useNavigate } from 'react-router-dom';
import './dailyQuiz.scss';
import EmptyQuiz from 'app/student/components/emptyQuiz';
import { Circles } from 'react-loader-spinner';
import OptionsGrid from '../../AnswerOption/AnswerOption';
import {
  getDueTodaySchoolAssignments,
  submitSchoolAssignment,
} from 'store/Thunk/schoolAssignmentThunk';
import { setTimerDurationSecs } from 'store/Slice/schoolAssignmentSlice';
import AudioIcon from '../../../../../asset/person@2x.png';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import { getFontSize } from 'utils';
import { convertedOutput } from 'utils/processSentence';

const resultVideo = {
  be_right_back: [
    'SdtI00ocGlUH8IN02AFVpXpCZZIBd00uHVandmbWaIkv1E',
    '9G8uqzi5SC020168vdCmZYMyrxPy901WyBxYsm3pQx0247Q',
    'Z9Q8as01OlTShF7NegcFXgRSQxhLAXQSN3U0200lhD3ag8',
    'MtdEWXc1U5mMPRfl01Dd4r3858Arlq00I8qQE00LjCgaN4',
    'kOoO02bGD01jXtUaue9N4LIPWdlSOhzFNDjHAqOV4O0154',
    'DC35nJXJmD8R9IxHz02C9iRtpKb9hnJ9F0071sKEPEVC8',
  ],
  do_it_again: [
    'HpzlyVzk01Sl00nILDa45BLVfpTaj1jm7TINvPcMUflMM',
    'xS01LDwxn6uFG9LkrpS014uurwE01dtNmExYHvDhmsi5Ng',
    'cg3Q8e00IDHrzQaD101AWSn3pRymTQTC2syj005BFJ00ris',
    'vqe9QuC2GkDkZw1lbLA9Uhdnb00LxMki3tSQT6wdDaO4',
    'H7ouuD004mgA014QsATG00KZpYaPSe8T400oM61d01oJPaQs',
    'kGJpsiKaVcYrd1wP900RLPXcpPo469UZo01rp9YmQrhak',
  ],
  do_not_stop: [
    '00ekSRwOXby3km88I5MXWAwyql02Bzvyy5IWkM01zkZzzg',
    'b00qRnN6UnGne3IZTyefEozA9D3TSyffisZKY1SWtUE8',
    'dvWs2sA33z1oKZjXcrHTuMBcIrSyBDmZQHab6MjFPAw',
    'On7700JuEOv01cr7gUy501D01usGTbITpvqbbse700aPCC5w',
    'ZV5l7hZixr2WR1oC01AcnQgcfvuraBZx7LdCR6vz01Ai4',
    '4zeBB1VfpM02xwhYNdASJt00RpLgnkO028m6mdjInkjNQQ',
  ],
  social_break: [
    'DvFjDtYeZXdHwlEMw9jGy1t3PlIFiYdwtqCDfkH2KNg',
    'avJwNbo69NW02dZ6MntPeU00KHChdx68IOhTa6NkI8rbo',
    'm8V9caheXqjaJP5Uq2sF4OuRcOzx5OfpS01WehjSFsqc',
    'idHb00ACzDy5Ug02ZZNaqcomOzGJO6qafo6UrlbkXcRF00',
    'Bf002fkFwQL3Yc900q7UPKf9PTx2EWlRK9wzDW87AAbU00',
    '6YU8jbv02NwJCTvtSmGF2ng00Kuat2Lf3kE02kkDaa02zOc',
  ],
};

function SchoolAssignmentQuiz({
  setStep,
  selectedAnswers,
  setSelectedAnswers,
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.authReducer);
  const { userData } = useAppSelector((state) => state.userReducer);
  const { joinedSchoolAssignment, timerDurationSecs } = useAppSelector(
    (state) => state.schoolAssignmentReducer,
  );
  const template = joinedSchoolAssignment?.session?.currentTemplate;
  const questions = joinedSchoolAssignment?.session?.currentTemplateQuestions;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestionData, setCurrentQuestionData] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [timer, setTimer] = useState(60); // Timer
  const [showError, setShowError] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [finalMarks, setFinalMarks] = useState({ marks: 0, complete: false });
  const [percentage, setPercentage] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);
  const [perQuestion, setPerQuestion] = useState(0);
  const [suffledVideo, setSuffledVideo] = useState({
    do_not_stop: '',
    do_it_again: '',
  });

  useEffect(() => {
    if (finalMarks?.complete) {
      setPercentage(
        Math.ceil((finalMarks?.marks / (questions?.length * 10)) * 100),
      );
    }
  }, [finalMarks?.complete, finalMarks?.marks, questions]);

  const timeDuration = (joinedSchoolAssignment?.timeDuration ?? 0) * 60;

  useEffect(() => {
    if (timerDurationSecs) {
      setTimer(Math.floor(timerDurationSecs));
    }
  }, [timerDurationSecs]);

  useEffect(() => {
    if (questions?.length) {
      setCurrentQuestionData(questions[currentQuestionIndex] || {});
    }
  }, [questions, currentQuestionIndex]);

  useEffect(() => {
    setSuffledVideo({
      do_not_stop: resultVideo?.do_not_stop?.sort(() => Math.random() - 0.5)[0],
      do_it_again: resultVideo?.do_it_again?.sort(() => Math.random() - 0.5)[0],
      be_right_back: resultVideo?.be_right_back?.sort(
        () => Math.random() - 0.5,
      )[0],
    });
  }, []);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 1000);
      return;
    }
  }, [showError]);

  const getG3MSPercentage = () => {
    let percentage = 0;
    if (userData && Object.keys(userData).length) {
      const g3msScore = totalMarks + (userData?.gemsTokens || 0);
      const totalG3MS = userData?.totalGems || 0;

      percentage = (g3msScore * 100) / totalG3MS;
    }
    return isNaN(percentage) ? 0 : percentage;
  };

  const [isSpeaking, setIsSpeaking] = useState(false);

  const handleAnswerSelect = (optionKey) => {
    setSelectedAnswer((prevAnswer) =>
      prevAnswer === optionKey ? '' : optionKey,
    );
    setSelectedAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentQuestionIndex] = {
        questionId: currentQuestionData?.id,
        studentAnswer: optionKey,
      };
      return updatedAnswers;
    });
  };

  useEffect(() => {
    setIsSpeaking(false);
    const answer = selectedAnswers[currentQuestionIndex];
    setSelectedAnswer(answer?.studentAnswer);
  }, [selectedAnswers, currentQuestionIndex]);

  const assId = joinedSchoolAssignment?.id;
  const sessionId = joinedSchoolAssignment?.session?.id;
  const userId = user?.id;

  const submitAnswers = useCallback(() => {
    return dispatch(
      submitSchoolAssignment({
        id: assId,
        sessionId,
        userId,
        answers: selectedAnswers,
      }),
    )
      .unwrap()
      .then((response) => {
        dispatch(getDueTodaySchoolAssignments(user?.id));
        return response;
      });
  }, [assId, sessionId, userId, selectedAnswers, dispatch]);
  const [fontSize, setFontSize] = useState(16); // Initial font size

  const handleNextQuestion = useCallback(() => {
    setFontSize(null);
    setIsSpeaking(false);
    if (currentQuestionIndex === questions.length - 1) {
      console.log('SUBMITTING ASSIGNMENT');
      submitAnswers()
        .then((response) => {
          setStep(3);
        })
        .catch((error) => {
          console.log('error', error);
        });
      return;
    }
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  }, [currentQuestionIndex, questions]);

  const handlePreviousQuestion = useCallback(() => {
    setIsSpeaking(false);
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        // setTimer((prevTimer) => prevTimer - 1);
        dispatch(setTimerDurationSecs(timer - 1));
      }, 1000);
    } else {
      // Timeout
      submitAnswers()
        .then((response) => {
          setStep(2);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
    return () => clearInterval(interval);
  }, [
    timer,
    selectedAnswer,
    submitAnswers,
    setStep,
    currentQuestionData.correctAnswer,
  ]);

  const handleVideo = () => {
    if (percentage >= 75) {
      return suffledVideo?.do_not_stop;
    }
  };

  const handleOnEnded = () => {
    if (percentage >= 75) {
      setSuccessPopup(true);
    }
  };

  const handleSpeakClick = () => {
    setIsSpeaking(true);
  };
  const [speechSynthesisObj, setSpeechSynthesisObj] = useState(null);

  useEffect(() => {
    const question = currentQuestionData?.question;
    const optionA = currentQuestionData?.optionA;
    const optionB = currentQuestionData?.optionB;
    const optionC = currentQuestionData?.optionC;
    const optionD = currentQuestionData?.optionD;

    const options = [
      { label: 'A', value: optionA },
      { label: 'B', value: optionB },
      { label: 'C', value: optionC },
      { label: 'D', value: optionD },
    ];

    const nonEmptyOptions = options.filter(
      (option) =>
        option.value !== null &&
        option.value !== undefined &&
        option.value !== '',
    );

    const messageContent = nonEmptyOptions
      .map((option) => {
        return `, ${option.label}, ${option.value}`;
      })
      .join('');

    const message = new SpeechSynthesisUtterance(
      `${question}${messageContent}`.replaceAll('\\', ''),
    );

    const synthesis = window.speechSynthesis;

    setSpeechSynthesisObj(message);

    if (isSpeaking) {
      synthesis.speak(message);
    }

    // Set isSpeaking to false when done speaking
    message.onend = () => {
      setIsSpeaking(false);
    };

    return () => {
      synthesis.cancel(); // Make sure to cancel the speech when the component unmounts
    };
  }, [isSpeaking, currentQuestionData]);

  function removeSequenceNumber(text) {
    const arrs = text.split(':');
    return arrs[arrs.length - 1];
  }

  return (
    <Box height={'100%'} width={'100%'}>
      {!joinedSchoolAssignment ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Circles
            height="80"
            width="80"
            color="#20A58A"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <Box m={0} width={'100%'} height={'100%'}>
          <Card sx={{ padding: 10, height: '100%' }}>
            {joinedSchoolAssignment ? (
              <>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    border: '6px solid rgba(76, 191, 167, 1)',
                    borderRadius: '20px',
                    background: '#F9F9F9',
                  }}
                  container
                >
                  {/* {!finalMarks?.complete ? ( */}
                  <div
                    style={{ display: 'flex', flex: 3 }}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Card
                      sx={{
                        width: '90%',
                        flex: 1,
                        display: 'flex',
                        background: '#F8F8F8',
                        boxShadow: 'none',
                      }}
                    >
                      {!finalMarks?.complete ? (
                        <CardContent
                          sx={{
                            display: 'flex',
                            flex: '1',
                            flexDirection: 'column',
                          }}
                        >
                          <Box align="center" sx={{ m: 2 }}>
                            {template?.title ? (
                              <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'left'}
                                alignItems={'center'}
                              >
                                <Box>
                                  <IconButton
                                    aria-label="Read"
                                    onClick={handleSpeakClick}
                                    size="large"
                                  >
                                    <img
                                      src={AudioIcon}
                                      alt="speaker"
                                      style={{ width: '40px', height: '40px' }}
                                    />
                                  </IconButton>
                                </Box>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    fontSize: '32px',
                                    fontWeight: 700,
                                    color: '#131515',
                                    marginBottom: '0px',
                                  }}
                                  gutterBottom
                                >
                                  {removeSequenceNumber(template?.title)}
                                </Typography>
                              </Box>
                            ) : (
                              <Skeleton
                                variant="rounded"
                                width={220}
                                height={30}
                                sx={{ mb: 3 }}
                              />
                            )}
                          </Box>
                          {showError && (
                            <Alert
                              severity="error"
                              onClose={() => setShowError(false)}
                            >
                              Select Answer!
                            </Alert>
                          )}
                          {Object.entries(currentQuestionData)?.length ? (
                            <div
                              style={{
                                padding: '0 2rem',
                                background: template?.colorCode
                                  ? `${
                                      template?.colorCode.startsWith('#')
                                        ? ''
                                        : '#'
                                    }${template?.colorCode}`
                                  : '#FFFFFF',
                                borderRadius: ['20px'],
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  margin: '0px',
                                  padding: '0px',
                                  height: '100%',
                                  width: '100%',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: `${getFontSize(
                                      currentQuestionData?.question,
                                    )}px`,
                                    fontWeight: 600,
                                    textAlign: 'center',
                                    p: 1,
                                  }}
                                  variant="subtitle2"
                                >
                                  {currentQuestionData?.question &&
                                    typeof currentQuestionData?.question ===
                                      'string' && (
                                      <Latex>
                                        {currentQuestionData?.question}
                                      </Latex>
                                    )}
                                </Typography>
                                {currentQuestionData?.questionMedia && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',

                                      height: 'auto',
                                      width: '100%',
                                    }}
                                  >
                                    {currentQuestionData?.type === 'image' ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          height: 'auto',
                                          width: '100%',
                                        }}
                                      >
                                        <img
                                          src={
                                            currentQuestionData?.questionMedia
                                          }
                                          alt={`question-media`}
                                          style={{
                                            maxWidth: '100%',
                                            maxHeight: '65vh',
                                            width: '100%',
                                            // margin: '10px',
                                            objectFit: 'contain',
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <video
                                          controls
                                          width="500px"
                                          height="400px"
                                          // style={{ aspectRatio: 16 / 9, height: '200px' }}
                                        >
                                          <source
                                            src={
                                              currentQuestionData?.questionMedia
                                            }
                                            type={`video/${currentQuestionData?.questionMedia
                                              .split('.')
                                              .pop()
                                              .toLowerCase()}`}
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <Skeleton
                              variant="rounded"
                              width={380}
                              height={130}
                            />
                          )}
                        </CardContent>
                      ) : (
                        <>Completed</>
                      )}
                    </Card>
                  </div>

                  <div style={{ display: 'flex', flex: 2, overflow: 'hidden' }}>
                    {finalMarks?.complete ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          border: '10px solid #C73E9D',
                          borderRadius: '13px',
                        }}
                      >
                        <MuxPlayer
                          playbackId={handleVideo()}
                          // title={'title'}
                          // streamType="live"
                          className="dancing_video_player"
                          style={{
                            // aspectRatio: 16 / 9,
                            width: '100%',
                            height: '560px',
                            backgroundColor: 'red',
                          }}
                          autoplay={true}
                          onEnded={handleOnEnded}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '1.5rem 2rem',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: '18px',
                            color: '#666666',
                            textAlign: 'right',
                            marginBottom: '2rem',
                            height: '5%',
                          }}
                        >
                          Question {currentQuestionIndex + 1} out of{' '}
                          {questions?.length}
                        </Typography>
                        <Card
                          sx={{
                            width: '100%',
                            // height: 'fit-content',
                            border: '3px solid rgba(76, 191, 167, 1)',
                            display: 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 4,
                            mt: 3,
                            background: '#F8F8F8',
                            boxShadow: '0px 4px 10px 0px #00000026',
                          }}
                        >
                          <Grid container>
                            <Grid item md={6}>
                              <Grid
                                container
                                sx={{
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: '18px',
                                      color: '#000',
                                    }}
                                  >
                                    Per Question
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontWeight: 800,
                                      fontSize: '20px',
                                      color: '#C73E9D',
                                    }}
                                  >
                                    {perQuestion} $G3MS
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={6}>
                              <Grid
                                container
                                sx={{
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: '18px',
                                      color: '#000',
                                    }}
                                  >
                                    Total Earning
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontWeight: 800,
                                      fontSize: '20px',
                                      color: '#C73E9D',
                                    }}
                                  >
                                    {joinedSchoolAssignment &&
                                      totalMarks +
                                        (userData?.gemsTokens || 0)}{' '}
                                    $G3MS
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                        <Typography
                          sx={{
                            height: '15%',
                            overflowY: 'scroll',
                            fontWeight: 700,
                            fontSize: '22px',
                            color: '#666666',
                            textAlign: 'left',
                          }}
                        >
                          {currentQuestionData?.message}
                        </Typography>
                        <OptionsGrid
                          fontSize={fontSize}
                          setFontSize={setFontSize}
                          selectedAnswer={selectedAnswer}
                          handleAnswerSelect={handleAnswerSelect}
                          currentQuestionData={currentQuestionData}
                          convertedOutput={convertedOutput}
                        />

                        <Box
                          sx={{
                            height: '10%',
                            m: 2,
                            p: 1,
                            display: 'flex',
                            direction: 'coulmn',
                            justifyContent: 'space-evenly',
                            gap: '20px',
                          }}
                        >
                          <Button
                            sx={{
                              textTransform: 'none',
                              background: '#FFFFFF',
                              color: '#C73E9D',
                              border: '1px solid #C73E9D',
                              '&.MuiButtonBase-root:hover': {
                                backgroundColor: '#C73E9D',
                                color: '#FFFFFF',
                              },
                            }}
                            fullWidth
                            onClick={handlePreviousQuestion}
                            disabled={currentQuestionIndex === 0}
                          >
                            Back
                          </Button>
                          <Button
                            sx={{
                              textTransform: 'none',
                              background: '#C73E9D',
                              color: '#FFFFFF',
                              '&.MuiButtonBase-root:hover': {
                                backgroundColor: '#C73E9D',
                              },
                            }}
                            fullWidth
                            onClick={handleNextQuestion}
                          >
                            {currentQuestionIndex === questions.length - 1
                              ? 'Submit'
                              : 'Next'}
                          </Button>
                        </Box>
                        {/* <Grid
                          container
                          height={'20%'}
                          overflowY={'scroll'}
                          direction="column"
                          spacing={1}
                        >
                          <Grid item>
                            <Box
                              sx={{
                                m: 2,
                                p: 1,
                                display: 'flex',
                                direction: 'coulmn',
                                justifyContent: 'space-evenly',
                              }}
                            >
                              <Box>
                                <CircularProgressWithLabel
                                  timer={timer}
                                  size="5rem"
                                  progressColor="#4CBFA7"
                                  remainProgressColor="#E4F2EE"
                                  value={timer}
                                  maxDuration={timeDuration}
                                />
                                <Typography
                                  sx={{ fontWeight: 700, textAlign: 'center' }}
                                  variant="subtitle1"
                                >
                                  Time
                                </Typography>
                              </Box>
                              <Box>
                                <CircularProgressWithLabel
                                  size="5rem"
                                  progressColor="#C73E9D"
                                  remainProgressColor="#FAEAF5"
                                  value={getG3MSPercentage()}
                                  label={
                                    joinedSchoolAssignment &&
                                    totalMarks + (userData?.gemsTokens || 0)
                                  }
                                />
                                <Typography
                                  sx={{ fontWeight: 700, textAlign: 'center' }}
                                  variant="subtitle1"
                                >
                                  $G3MS
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid> */}
                      </Box>
                    )}
                  </div>
                </div>
                {finalMarks?.complete && (
                  <Box
                    sx={{
                      border: '2px solid #20A58A',
                      backgroundColor: '#F8F8F8',
                      padding: '40px',
                      borderRadius: '12px',
                      mt: 6,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={4}>
                        <Grid
                          contaniner
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '24px',
                            color: '#000',
                          }}
                        >
                          <Grid item>
                            <Typography
                              sx={{
                                fontWeight: 800,
                                fontSize: '1.65rem',
                                color: '#000',
                              }}
                            >
                              You Now Have A Total Of
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                fontWeight: 800,
                                fontSize: '20px',
                                color: '#000',
                              }}
                            >
                              <span
                                style={{
                                  color: '#C73E9D',
                                  fontSize: '28px',
                                  fontWeight: '700',
                                }}
                              >
                                {joinedSchoolAssignment &&
                                  totalMarks + (userData?.gemsTokens || 0)}
                                $G3MS
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid contaniner></Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid
                          contaniner
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Grid item>
                            <Button
                              sx={{
                                textTransform: 'none',
                                width: '100%',
                                borderRadius: '8px',
                                padding: '0.4375rem 1.375rem',
                                fontWeight: 700,
                                fontSize: '20px',
                                background: '#C73E9D',
                                color: '#FFFFFF',
                                lineHeight: 1.715,
                                boxShadow: 3,
                                mt: 5,
                                '&.MuiButtonBase-root:hover': {
                                  backgroundColor: '#C73E9D',
                                },
                              }}
                              fullWidth
                              onClick={() => {
                                navigate('/app/student/leaderboard/?topic=');
                              }}
                            >
                              Top 10 Score Squad
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </>
            ) : (
              <EmptyQuiz width={30} />
              // <Quiz />
            )}
          </Card>
        </Box>
      )}
    </Box>
  );
}

export default SchoolAssignmentQuiz;
