import { NotificationManager } from 'react-notifications';
import API from 'store/api';
import analytics from 'utils/analytics';

export const getAllVideoListReq = async (_request) => {
  const size = _request?.pageSize || 1;
  const userId = _request?.userId || 1;
  const gradeId = _request?.gradeId || '';
  const topic = _request?.topic;
  let url = `api/v1/assignment/video/${userId}?limit=${size}&userId=${userId}`;
  try {
    if (topic) {
      url += `&topic=${topic}`;
    }
    if (gradeId) {
      url += `&grade=${gradeId}`;
    }
    const response = await API.get(url);
    analytics.logEvent('get_all_video_list', { userId: userId });
    return response.data.data;
  } catch (err) {
    // NotificationManager.error(err.response.data.message, 'Error', 3000);
    analytics.logEvent('get_all_video_list_error', { error: err });
    throw err;
  }
};

export const autoAssignVideo = async (query) => {
  const { userId, skillId, classroomId, assignmentId } = query;
  const params = {
    skillId,
    classroomId,
    assignmentId,
  };
  let url = `api/v1/assignment/student/${userId}/request/video?`;
  let queries = [];
  for (const key in params) {
    if (params[key]) {
      queries.push(`${key}=${query[key]}`);
    }
  }
  url += queries.join('&');
  try {
    const response = await API.get(encodeURI(url));
    console.log('=========autoAssignVideo===========', response);
    analytics.logEvent('auto_assign_video', { userId });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
