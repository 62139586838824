import {
  Card,
  Collapse,
  Grid,
  ListItemButton,
  Typography
} from '@mui/material';
export default function CardWrapper({ title, children }) {
  return (
    <div
      style={{
        border: '1px solid #DCDCDC',
        borderRadius: '20px',
        marginTop: '16px',
      }}
    >
      <Card
        style={{
          backgroundColor: '#E9F6F3',
          borderRadius: '10px 10px 0px 0px',
          boxShadow:
            '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
        }}
      >
        <ListItemButton style={{ padding: '3px 12px', pointerEvents: 'none' }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#131515',
              '& .css-6uzn28-MuiTypography-root': {
                color: '#131515',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
              },
            }}
          >
            <Grid item className="min-h-14 flex items-center">
              <Typography fontWeight={600} fontSize={'16px'} color={'#131515'}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </ListItemButton>
      </Card>
      <Collapse sx={{ padding: '5px' }} in={true} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
}
