const config = {
  // BASE_URL: `${process.env.REACT_APP_BACKEND_API_URL}`,
  BASE_URL: 'https://dev.g3ms.co/',
  ADMIN_URL: 'https://dev-admin-panel.g3ms.co',
  WS: 'wss://dev.g3ms.co/',

  // BASE_URL: 'http://localhost:8080/',
  // ADMIN_URL: 'http://localhost:3001',
  // WS: 'ws://127.0.0.1:8080',
};

export default config;
