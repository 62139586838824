import React, { useCallback, useEffect, useState } from 'react';
import sharedStyles from '../../../sharedStyle.module.scss';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import QrBorder from '../../../components/QrBorder.png';
import { QrReader } from 'react-qr-reader';
import axios from 'api/axios';
import { NotificationManager } from 'react-notifications';
import { auth } from 'api/firebase';
import config from 'configs/config';
import { setUserDetails } from 'store/Slice/authSlice';
import { useAppDispatch } from 'store/store';
import Arrow from '../../../components/Arrow-Left.png';
import analytics from 'utils/analytics';

export default function ScanQR({ setStep }) {
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!data) return;
    setLoading(true);
    setError('');
    setSuccess('');
    axios
      .post('/auth/login/qrcode', {
        qrCode: data,
      })
      .then((response) => {
        if (response.status === 201) {
          setSuccess('Login Successfully');
          // NotificationManager.success("Login Successfully", 'Success', 3000);

          // Construct access token
          auth
            .signInWithCustomToken(response.data.data.customToken)
            .then(async (userCredential) => {
              const accessToken = await userCredential.user.getIdToken();
              const refreshToken = await userCredential.user.refreshToken;
              localStorage.setItem('accessToken', accessToken);
              localStorage.setItem('refreshToken', refreshToken);
              localStorage.setItem('uuid', response.data.data.user.id);
              localStorage.setItem('currentUser', response.data.data.user.type);
              localStorage.setItem(
                'userData',
                JSON.stringify(response.data.data.user),
              );
              dispatch(setUserDetails(response.data.data.user));
              analytics.logEvent('scan_login', {
                user_id: response.data.data.user.id,
              });
            })
            .catch((error) => {
              console.error(error);
              analytics.logEvent('scan_login_error', { error: error.message });
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError(error.response.data.message[0]);
        analytics.logEvent('scan_login_error', { error: error.message });
      });
  }, [dispatch, data]);

  return (
    <Box className={sharedStyles.qrContainer}>
      <Box className={sharedStyles.arrowFloat}>
        <img
          src={Arrow}
          alt="img-discription"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setStep((prevStep) => ({
              ...prevStep,
              current: 'home',
            }));
          }}
        />
      </Box>
      <Box className={sharedStyles.avatar}>
        {/* <img src={QRCode} className={sharedStyles.qrCode} alt={'logo'} /> */}
        <video id="qr-video" className={sharedStyles.qrCode}></video>
        <img src={QrBorder} alt={'logo'} />
      </Box>
      <QrReader
        onResult={(result) => {
          if (!!result) {
            setData(result.text);
          }
        }}
        style={{ width: '100%' }}
        videoId="qr-video"
        videoStyle={{ width: '90%', height: '100%' }}
        constraints={{
          facingMode: 'user',
          aspectRatio: { ideal: 1 },
        }}
      />
      <Typography
        style={{
          color: error ? 'red' : 'green',
          fontSize: '16px',
          display: error ? 'block' : 'none',
        }}
      >
        {error || success}
      </Typography>
      <Stack sx={{ '& .MuiTypography-root': { textAlign: 'center' } }}>
        <Typography
          className="!font-semibold"
          style={{ color: '#131515', fontSize: '32px' }}
        >
          Scan QR code to sign in
        </Typography>
        <CircularProgress
          style={{
            color: '#131515',
            margin: 'auto',
            visibility: loading ? 'visible' : 'hidden',
          }}
        />
      </Stack>
    </Box>
  );
}
