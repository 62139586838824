import axios from 'axios';
import config from 'configs/config';

const BASE_URL = `${config.BASE_URL}api/v1`;
// const BASE_URL = 'http://localhost:5000/api/v1';

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
