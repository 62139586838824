import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './Slice/authSlice';
import quizReducer from './Slice/quizSlice';
import registerReducer from './Slice/registerSlice';
import schoolAssignmentReducer from './Slice/schoolAssignmentSlice';
import teacherReducer from './Slice/teacherSlice';
import topicReducer from './Slice/topicSlice';
import userReducer from './Slice/userSlice';
import videoReducer from './Slice/videoSlice';

import { useDispatch, useSelector } from 'react-redux';
import { apiSlice } from './apis';
import uiReducer from './Slice/uiSlice';
import dressBotReducer from './Slice/dressBotSlice';
import sneakerBotReducer from './Slice/sneakerBot';

const combinedReducer = combineReducers({
  quizReducer,
  topicReducer,
  videoReducer,
  authReducer,
  registerReducer,
  userReducer,
  teacherReducer,
  schoolAssignmentReducer,
  uiReducer: uiReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  dressBot: dressBotReducer,
  sneakerBot: sneakerBotReducer,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
export default store;
