import React, { useState } from 'react';

import sharedStyles from '../../../sharedStyle.module.scss';

import Logo from '../../../components/logo.png';

import Arrow from '../../../components/Arrow-Left.png';
import HomeLogin from '../../../components/StudentsSecond.png';

import { Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import ReactFlagsSelect from 'react-flags-select';

import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input/input';
import { useSendPhoneOtpMutation } from 'store/apis/otp';
import { NotificationManager } from 'react-notifications';

export default function PhoneNumberValidation({ setStep }) {
  const [selected, setSelected] = useState('US');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [sendPhoneOtp, sendPhoneOtpRes] = useSendPhoneOtpMutation();

  const handleSendPhoneOtp = async () => {
    try {
      const phonNum = parsePhoneNumber(phoneNumber);

      const res = await sendPhoneOtp({
        countryCode: `+${phonNum.countryCallingCode}`,
        phoneNumber: phonNum.nationalNumber,
      }).unwrap();

     /*  setStep((prevStep) => ({
        ...prevStep,
        current: 'phoneOtp',
        phoneNumber: phoneNumber,
        verificationSid: res.data.verificationSid,
      })); */
      setStep((prevStep) => ({
        ...prevStep,
        current: 'signup',
        phoneNumber,
      }));
    } catch (error) {
      NotificationManager.error(error?.data?.message || 'Something went wrong');
    }
  };

  return (
    <Box className={`${sharedStyles.container}`}>
      <Stack className={`${sharedStyles.studentsContainer}`}>
        <Box className={sharedStyles.arrow}>
          <img
            src={Arrow}
            alt="img-discription"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setStep((prevStep) => ({
                ...prevStep,
                current: 'birth',
              }));
            }}
          />
        </Box>
        <Box className={sharedStyles.imageLeft}>
          <img src={HomeLogin} alt="img-discription" />
        </Box>
      </Stack>
      <Stack className={`${sharedStyles.loginContainer}`}>
        <Box className={sharedStyles.logo}>
          <img src={Logo} alt={'logo'} />
        </Box>

        <Stack className="w-10/12 space-y-5 justify-center items-center">
          <Typography
            className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
            style={{ color: '#131515' }}
          >
            Welcome to G3MS
          </Typography>
          <Typography className="!font-normal !text-2xl mt-4 max-w-[30ch] mx-auto">
            We will send you a verification code on your phone to confirm it's
            you.
          </Typography>
        </Stack>
        <Box
          sx={{
            width: '60%',
            display: 'flex',
            gap: '20px',
            '& .MuiOutlinedInput-input': {
              paddingTop: '25px',
              fontSize: '18px',
              fontWeight: 500,
            },
          }}
          className="flex flex-col !gap-4"
        >
          <ReactFlagsSelect
            selected={selected}
            onSelect={(code) => setSelected(code)}
            searchable
            searchPlaceholder="Search for a country"
            className="[&>button]:h-16 lg:[&>button]:h-14"
          />

          <PhoneInput
            country={selected}
            value={phoneNumber || ''}
            onChange={setPhoneNumber}
            placeholder="Enter phone number"
            className="w-full h-16 lg:h-14 p-4 border border-gray-300 rounded-lg focus:outline-none"
          />
        </Box>
        <Button
          variant={'contained'}
          size="large"
          sx={{
            width: '60%',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '20px',
            borderWidth: '2px',
          }}
          className={'primary_bg_btn'}
          onClick={handleSendPhoneOtp}
          disabled={
            !phoneNumber ||
            !isValidPhoneNumber(phoneNumber) ||
            sendPhoneOtpRes.isLoading
          }
        >
          Next
        </Button>
      </Stack>
    </Box>
  );
}
