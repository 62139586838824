import React, { useState } from 'react';
import sharedStyles from '../payment.module.scss';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Arrow from '../../../components/Arrow-Left.png';
import Paypal from '../../../components/paypal.png';
import Visa from '../../../components/visa.png';
import CardWrapper from './CardWrapper';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';

const options = [
  {
    value: 'option1',
    label: '',
    image: Paypal,
  },
  {
    value: 'option2',
    label: 'Credit Card',
    image: Visa,
  },
];

export default function Payment({ setStep }) {
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const handleChangeInput = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  return (
    <Stack className={sharedStyles.paymentContainer}>
      <Box className={sharedStyles.arrowFloat}>
        <img
          src={Arrow}
          alt="img-discription"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setStep((prevStep) => ({
              ...prevStep,
              current: 'pricing',
            }));
          }}
        />
      </Box>
      <Typography className={sharedStyles.paymentHeader}>
        Payment Method
      </Typography>
      <Typography className={sharedStyles.paymentSubHeader}>
        Lorem ipsum dolor sit amet consectetur. Lacus elit nullam non elit quis
        consectetur viverra faucibus purus. Integer diam nisl urna viverra.
        Vulputate iaculis tellus viverra nibh mauris sagittis egestas in
        faucibus. Ullamcorper ipsum aliquam risus odio ultricies.
      </Typography>
      <Stack className="sm:!flex-row !flex-col" gap={6}>
        <Stack flex={5} className="flex-3 flex-col sm:flex-row" gap={6}>
          <Stack>
            <CardWrapper title="Payment Details">
              <Stack p={6}>
                <RadioGroup
                  sx={{ mb: 6 }}
                  value={selectedValue}
                  row
                  onChange={handleChange}
                >
                  {options.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={
                        <Box className="flex items-center gap-2">
                          <Typography
                            className="!text-xs !font-medium"
                            sx={{ color: '#131515' }}
                          >
                            {option.label}
                          </Typography>
                          <img src={option.image} alt={option.label} />
                        </Box>
                      }
                    />
                  ))}
                </RadioGroup>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ color: '#484848', pb: 2 }}>
                      Card Number
                    </InputLabel>
                    <TextField
                      required
                      fullWidth
                      id="cardNumber"
                      value={formData?.cardNumber || ''}
                      onChange={handleChangeInput}
                      placeholder="Enter card number"
                      name="cardNumber"
                      error={formError && formError?.cardNumber}
                      helperText={
                        formError && formError?.cardNumber
                          ? formError.cardNumber
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ color: '#484848', pb: 2 }}>
                      Card Name
                    </InputLabel>
                    <TextField
                      required
                      fullWidth
                      id="cardName"
                      value={formData?.cardName || ''}
                      onChange={handleChangeInput}
                      placeholder="Enter card name"
                      name="cardName"
                      error={formError && formError?.cardName}
                      helperText={
                        formError && formError?.cardName
                          ? formError.cardName
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ color: '#484848', pb: 2 }}>
                      Expiry Date
                    </InputLabel>
                    <TextField
                      required
                      fullWidth
                      id="expiryDate"
                      value={formData?.expiryDate || ''}
                      onChange={handleChangeInput}
                      placeholder="MM/YY"
                      name="expiryDate"
                      error={formError && formError?.expiryDate}
                      helperText={
                        formError && formError?.expiryDate
                          ? formError.expiryDate
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ color: '#484848', pb: 2 }}>
                      MM/YY
                    </InputLabel>
                    <TextField
                      required
                      fullWidth
                      id="cvc"
                      value={formData?.expiryDate || ''}
                      onChange={handleChangeInput}
                      placeholder="Enter CVC number"
                      name="cvc"
                      error={formError && formError?.cvc}
                      helperText={
                        formError && formError?.cvc ? formError.cvc : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
            </CardWrapper>
          </Stack>
          <Stack>
            <CardWrapper title="Billing Details">
              <Stack p={6}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#484848', pb: 2 }}>
                      Email Address
                    </InputLabel>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      value={formData?.email || ''}
                      onChange={handleChangeInput}
                      placeholder="Enter email address"
                      name="email"
                      error={formError && formError?.email}
                      helperText={
                        formError && formError?.email ? formError.email : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ color: '#484848', pb: 2 }}>
                      Country
                    </InputLabel>
                    <Autocomplete
                      multiple
                      id="asynchronous-demo"
                      open={openCountry}
                      onOpen={() => setOpenCountry(true)}
                      onClose={() => setOpenCountry(false)}
                      isOptionEqualToValue={(option, value) =>
                        option.title === value.title
                      }
                      getOptionLabel={(option) => option?.title || ''}
                      options={[]}
                      loading={loadingCountry}
                      value={
                        formData?.country ? formData?.country : [] || [] || {}
                      }
                      name="country"
                      onChange={handleChangeInput}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Country"
                          error={formError && formError?.country}
                          helperText={
                            formError && formError?.country
                              ? formError.country
                              : ''
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingCountry ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ color: '#484848', pb: 2 }}>
                      Zip code
                    </InputLabel>
                    <TextField
                      required
                      fullWidth
                      id="expiryDate"
                      value={formData?.zipCode || ''}
                      onChange={handleChangeInput}
                      placeholder="Enter zip code"
                      name="expiryDate"
                      error={formError && formError?.zipCode}
                      helperText={
                        formError && formError?.zipCode ? formError.zipCode : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
            </CardWrapper>
          </Stack>
        </Stack>
        <Stack flex={3}>
          <Stack>
            <CardWrapper title="Subscription summary">
              <Stack p={6} gap={2}>
                <Box className="flex justify-between w-full">
                  <Stack>
                    <Typography
                      fontWeight={600}
                      fontSize={'14px'}
                      color={'#131515'}
                    >
                      G3MS +
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={'14px'}
                      color={'#979C9E'}
                    >
                      Life time free use
                    </Typography>
                  </Stack>
                  <Box>
                    <Typography
                      fontWeight={600}
                      fontSize={'24px'}
                      color={'#131515'}
                    >
                      6.99
                    </Typography>
                  </Box>
                </Box>
                <Divider orientation="horizontal" sx={{ my: 3 }} />
                <Box className="flex justify-between w-full">
                  <Typography
                    fontWeight={400}
                    fontSize={'16px'}
                    color={'#979C9E'}
                  >
                    Payment
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={'16px'}
                    color={'#979C9E'}
                  >
                    $0
                  </Typography>
                </Box>
                <Box className="flex justify-between w-full">
                  <Typography
                    fontWeight={400}
                    fontSize={'16px'}
                    color={'#979C9E'}
                  >
                    Time Duration
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={'16px'}
                    color={'#979C9E'}
                  >
                    Life time
                  </Typography>
                </Box>
                <Box className="flex justify-between w-full">
                  <Typography
                    fontWeight={600}
                    fontSize={'16px'}
                    color={'#131515'}
                  >
                    Total Amount
                  </Typography>
                  <Typography
                    fontWeight={600}
                    fontSize={'16px'}
                    color={'#131515'}
                  >
                    $6.99
                  </Typography>
                </Box>
                <Button
                  variant={'contained'}
                  size="large"
                  sx={{
                    width: '100%',
                    mt: '32px',
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    fontSize: '20px',
                    borderWidth: '2px',
                    color: '#fff !important',
                  }}
                  className={'primary_bg_btn'}
                >
                  Confirm
                </Button>
              </Stack>
            </CardWrapper>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
