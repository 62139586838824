import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { correct } from 'asset';
import { coinHand } from 'asset';

export default function ReviewSuccess(props) {
  const { onClose } = props;

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={true} onClose={onClose}>
      <DialogContent>
        <Box textAlign={'center'}>
          <img
            src={correct}
            alt="correct"
            style={{ margin: 'auto', marginTop: '12px', marginBottom: '12px' }}
          />
          <img
            src={coinHand}
            alt="correct"
            style={{ margin: 'auto', marginTop: '12px', marginBottom: '12px' }}
          />
          <Box>
            <Typography
              sx={{ color: '#000', fontWeight: '700', fontSize: '16px' }}
            >
              $1 G3MS earned for reviewing your wrong answrss!
            </Typography>
            <Typography
              sx={{ color: '#000', fontWeight: '400', fontSize: '16px' }}
            >
              Understanding your mistakes helps you learn and improve. Keep it
              up!
            </Typography>
          </Box>
          <Button
            sx={{
              textTransform: 'none',
              width: '60%',
              background: '#C73E9D',
              color: '#FFFFFF',
              my: 5,
              '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
            }}
            onClick={onClose}
          >
            Ok
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
