import TextsmsIcon from '@mui/icons-material/Textsms';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';

import blankAvatar from '../../../../../media/avatar-blankImage.png';
import { follower_profile } from 'asset';
import { styled } from '@mui/material/styles';

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 18,
  height: 18,
  borderRadius: '50%',
  position: 'absolute',
  fontSize: 18,
  right: -3,
  fontWeight: 900,
  backgroundColor: theme.palette.primary.main,
  textAlign: 'center',
  color: theme.palette.common.white,
}));

function VideoActions() {
  const [isCopy, setIsCopy] = React.useState(false);
  const videoData = {
    Url: 'http://www.g3ms.com/@evoldass/videos/723345',
    like: '10.1k',
    mesg: '2.4k',
    users: '1.8k',
  };

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(videoData?.Url)
      .then(() => {
        setIsCopy(true);
      })
      .catch((error) => {
        console.error('Failed to copy text:', error);
      });
  };

  return (
    <Box my={2}>
      {/*  <Box
        direction="row"
        justifyContent="center"
        alignItems="center"
        display={'flex'}
      >
        <Box>
          <IconButton
            aria-label="Example"
            size="large"
            sx={VideoActionsCss.IconButton}
          >
            <ThumbUpAltIcon />
          </IconButton>
          {videoData?.like}
        </Box>
        <Box>
          <IconButton
            aria-label="Example"
            size="large"
            sx={VideoActionsCss.IconButton}
          >
            <TextsmsIcon />
          </IconButton>
          {videoData?.like}
        </Box>
        <Box>
          <Badge
            overlap="circular"
            sx={{ mx: 2, cursor: 'pointer' }}
            badgeContent={<BadgeContentSpan>+</BadgeContentSpan>}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Avatar
              alt="John Doe"
              sx={{ width: 50, height: 50 }}
              src={follower_profile}
            />
          </Badge>

          {videoData?.users}
        </Box>
      </Box> */}
      {/* </Box> */}
      {/* <Box
        direction="row"
        justifyContent="center"
        alignItems="center"
        display={'flex'}
      >
        <Box
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            my: 2,
            display: 'flex',
            width: '90%',
            borderRadius: '10px !important',
            backgroundColor: '#fff',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: '500',
              color: '#808080',
              ml: 1,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '80%',
            }}
          >
            {videoData?.Url}
          </Typography>
          <Button
            sx={{ color: '#20A58A', textTransform: 'capitalize' }}
            onClick={handleCopyClick}
          >
            {isCopy ? 'Copied !!' : 'Copy link'}
          </Button>
        </Box>
      </Box> */}
    </Box>
  );
}

export default VideoActions;

const VideoActionsCss = {
  IconButton: {
    background: '#20A58A',
    m: 2,
    color: 'white',
    '&:hover': {
      background: '#20A58A',
    },
  },
};
