import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { store } from '../store';

import { auth } from 'api/firebase';
import config from 'configs/config';
import { redirect } from 'react-router-dom';
import { logoutUser } from 'store/Slice/authSlice';
import analytics from 'utils/analytics';

const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL
    ? `${config.BASE_URL}api/v1/`
    : 'https://dev.g3ms.co/api/v1/',
  prepareHeaders: (headers, api) => {
    const token = localStorage.getItem('accessToken');
    // console.warn(token);

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const handleLogout = () => {
  localStorage.removeItem('userData');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('uuid');
  localStorage.removeItem('header');
  store.dispatch(apiSlice.util.resetApiState());
  analytics.logEvent('logout', { user_id: localStorage.getItem('uuid') });
  analytics.logoutUser();
  redirect('/');
};

async function baseQueryWithReAuth(args, api, extraOptions) {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    analytics.apiError({ error: result.error, api, args });
  } else {
    analytics.logEvent('api_response', { response: result, api, args });
  }

  // console.log(result, 'result');
  const isInvalidToken = result?.error?.data?.code === 'INVALID_TOKEN';
  const isUnAuthorized = result?.error?.data?.code === 'UNAUTHORIZED';
  if (isInvalidToken || isUnAuthorized) {
    const accessToken = await auth.currentUser?.getIdToken();

    localStorage.setItem('accessToken', accessToken || '');

    const newResult = await baseQuery(args, api, extraOptions);

    if (
      newResult?.meta?.response?.status === 401 ||
      newResult.error?.data?.code === 'INVALID_TOKEN' ||
      newResult.error?.data?.code === 'UNAUTHORIZED'
    ) {
      console.log('Invalid Token');
      store.dispatch(logoutUser(undefined));
      handleLogout();
      await auth.signOut();
    }
    return newResult;
  }
  return result;
}

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'MyProfile',
    'Search',
    'SingleProfile',
    'getVideos',
    'gradeInfo',
    'MyVideos',
    'CartItems',
    'WeeklyChallenge',
    'UserProfile',
    'AiChat',
  ],
  endpoints: (builder) => ({}),
});

export default apiSlice.reducer;
