import { useEffect, useState } from 'react';
import { Dna } from 'react-loader-spinner';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import AutoComplete from '../../components/input/autocomplete';
import SelectOption from '../../components/input/selectOption';
import TextField from '../../components/input/textField';
import sharedStyles from '../../sharedStyle.module.scss';
import { ReactComponent as UploadFileIcon } from '../upload_file.svg';
import CityIcon from './city.png';
import PersonIcon from './person.png';
import SchoolIcon from './school.png';
import styles from './schoolLeader.module.scss';
import TeachIcon from './teach.png';
import config from 'configs/config';
import analytics from 'utils/analytics';

const School_leader = ({ user, uuid, existingEmail }) => {
  const [image, setImage] = useState('');
  const [imageObject, setImageObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState('Mr');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [school, setSchool] = useState('');
  const [numberOfTeachers, setNumberOfTeachers] = useState('');
  const [email, setEmail] = useState('');

  console.log('school', school);

  const navigate = useNavigate();

  useEffect(() => {
    if (existingEmail) {
      setEmail(existingEmail);
    }
  }, [existingEmail]);

  function onSignUp() {
    if (!imageObject) {
      NotificationManager.error('Please upload an image', 'Error');
      return;
    }

    if (firstName == '' || lastName == '') {
      NotificationManager.error(
        'Please enter first name and last name',
        'Error',
      );
      return;
    }

    if (!school) {
      NotificationManager.error('Please select a school', 'Error');
      return;
    }

    if (numberOfTeachers == '' || isNaN(numberOfTeachers)) {
      console.log(numberOfTeachers);
      NotificationManager.error('Number of teachers must be a number', 'Error');
      return;
    }

    if (!email) {
      NotificationManager.error('Please enter email', 'Error');
      return;
    }

    // check if email is valid
    if (!/\S+@\S+\.\S+/.test(email)) {
      NotificationManager.error('Please enter a valid email', 'Error');
      return;
    }

    const schoolId = schoolData.find((v) => v.name == school).id;

    const data = new FormData();
    data.append('file', imageObject);
    data.append('name', firstName + ' ' + lastName);
    data.append('schoolId', schoolId);
    data.append('totalNoOfTeacher', numberOfTeachers);
    data.append('email', email);

    setLoading(true);
    axios
      .patch(`/school-leader/${uuid}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        console.log('response', response);
        setLoading(false);
        analytics.logEvent('complete_profile', {
          user_id: response?.data?.user?.id,
        });
        localStorage.removeItem('currentUser');
        const token = response.data.token;
        window.location.href = `${config.ADMIN_URL}/school-management/school/${response?.data?.school.id}?token=${token}`;
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
        NotificationManager.error('Error while completing profile', 'Error');
        analytics.logEvent('complete_profile_error', { user_id: uuid });
      });
  }

  const [schoolData, setSchoolData] = useState(null);

  const [state, setState] = useState('');
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    axios
      .get('/state')
      .then((response) => {
        setStateData(response.data.data);
        analytics.logEvent('get_states');
      })
      .catch((err) => {
        console.log(err);
        analytics.logEvent('get_states_error');
      });
  }, []);

  useEffect(() => {
    if (state) {
      axios
        .get(`/state/${state}/school`)
        .then((response) => {
          setSchoolData(response.data.data);
          analytics.logEvent('get_schools', { state: state });
        })
        .catch((error) => {
          console.log(error);
          analytics.logEvent('get_schools_error', { state: state });
        });
    }
  }, [state]);

  return (
    <div className={sharedStyles.centerContainer}>
      <div
        className={`${styles.schoolLeader} ${sharedStyles.whiteContainerBox}`}
      >
        <h1 className={styles.title}>Please complete your profile.</h1>
        <h1 className={styles.title}>
          Don't worry, your data will remain private and only you can see it.
        </h1>

        <label className={sharedStyles.uploadImage}>
          {image ? <img src={image} alt={'profile'} /> : <UploadFileIcon />}

          <input
            type={'file'}
            name={'upload_image'}
            onChange={(target) => {
              setImage(URL.createObjectURL(target.target.files[0]));
              setImageObject(target.target.files[0]);
            }}
          />
        </label>

        <div className={styles.row}>
          <SelectOption options={['Mr', 'Mrs']} setSelected={setGender} />
          <TextField
            type={'text'}
            placeholder={'First Name'}
            icon={PersonIcon}
            onChange={setFirstName}
          />

          <TextField
            type={'text'}
            placeholder={'Last Name'}
            icon={PersonIcon}
            onChange={setLastName}
          />
        </div>

        <div className={styles.row}>
          <AutoComplete
            placeholder={'Select State'}
            icon={SchoolIcon}
            onChange={setState}
            value={state}
            options={stateData}
          />
          <AutoComplete
            placeholder={'School'}
            icon={SchoolIcon}
            onChange={setSchool}
            value={school}
            options={(schoolData || []).map((school) => school.name)}
          />
        </div>

        <TextField
          type={'number'}
          placeholder={'Number of Teachers'}
          icon={TeachIcon}
          onChange={setNumberOfTeachers}
        />
        <TextField
          type={'text'}
          value={email}
          placeholder={'Enter your email'}
          icon={SchoolIcon}
          onChange={setEmail}
        />

        <button
          className={sharedStyles.loginButton}
          onClick={onSignUp}
          style={{
            backgroundColor: '#C73E9D',
            color: '#F5F5F5',
            width: '200px',
          }}
        >
          Sign Up
        </button>

        {loading && (
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        )}
      </div>
    </div>
  );
};

export default School_leader;
