import dayjs from 'dayjs';
import Markdown from 'react-markdown';
import React from 'react';
import cn from 'utils/cn';
import CodeBlock from './CodeBlock';

const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

const Component = (
  {
    message = 'Hi, how can I help you today?',
    time,
    user = 'G3MS',
    isTyping = false,
    children,
    className = '',
  },
  ref,
) => {
  return (
    <div className="w-full flex items-center justify-end py-4" ref={ref}>
      <div
        className={cn(
          'flex flex-col gap-2 max-w-[70%] xl:max-w-[60%]',
          className,
        )}
      >
        <div className="flex justify-between items-center space-x-5">
          <p className="text-base text-[#344054] font-medium">{user}</p>
          {!isTyping && time && (
            <p className="md:text-xs text-[#475467]">{dayjs(time).fromNow()}</p>
          )}
        </div>
        <div className="g3ms-chat p-4 rounded-s-lg rounded-b-lg min-w-32 flex justify-end">
          {isTyping ? (
            <span className="loader block black mx-auto"></span>
          ) : (
            <div className="space-y-4">
              <article className=" text-[#2C3E50] prose prose-sm sm:prose lg:prose-xl xl:prose-2xl">
                <Markdown
                  components={{
                    pre({ node, inline, className, children, ...props }) {
                     
                      const language = className?.replace('language-', '');
                      return (
                        <CodeBlock language={language} value={children} />
                      );
                    },
                  }}
                >
                  {message}
                </Markdown>
              </article>

              <div className="">{children}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const BotChat = React.forwardRef(Component);

export default BotChat;
