import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import userPic from '../../../../../asset/challenges/user.png';
import styles from './chatbot.module.scss';

// chatbot response UI components
import { useAiBot } from 'context/AibBotContext';
import { Send2 } from 'iconsax-react';
import BotChat from 'pages/Aichat/componnts/BotChat';
import UserChat from 'pages/Aichat/componnts/UserChat';
import { useGetMyProfileQuery } from 'store/apis/profile';
import cn from 'utils/cn';
import ColorsPicker from './chatElementsPicker/color';
import MaterialPicker from './chatElementsPicker/material';
import MovementPicker from './chatElementsPicker/movement';
import YesNoResBtn from './chatElementsPicker/YesNoResBtn';
import YesResBtn from './chatElementsPicker/YesResBtn';

const OptionsToRender = (item) => {
  const { selectedCanvasObj, setSelectedCanvasObj, messages, onOptionSelect } =
    useAiBot();
  console.log('opTionType item', item?.optionType);

  if (item?.optionType === 'COLOR_SELECTION') {
    return (
      <ColorsPicker
        endPointOptions={item?.options}
        options={item?.predefinedResponseOption}
        handleOptionSelect={onOptionSelect}
        selectedCanvasObj={selectedCanvasObj}
        setSelectedCanvasObj={setSelectedCanvasObj}
        isDisabled={item.isDisabled}
      />
    );
  }

  if (item?.optionType === 'TEXTURE_SELECTION') {
    return (
      <MaterialPicker
        endPointOptions={item?.options}
        options={item?.predefinedResponseOption}
        handleOptionSelect={onOptionSelect}
        selectedCanvasObj={selectedCanvasObj}
        setSelectedCanvasObj={setSelectedCanvasObj}
        isDisabled={item.isDisabled}
      />
    );
  }

  if (item?.optionType === 'PATTERN_SELECTION') {
    return (
      <MovementPicker
        endPointOptions={item?.options}
        options={item?.predefinedResponseOption}
        handleOptionSelect={onOptionSelect}
        selectedCanvasObj={selectedCanvasObj}
        setSelectedCanvasObj={setSelectedCanvasObj}
        isDisabled={item.isDisabled}
      />
    );
  }
  if (item?.optionType === 'PART_SELECTION') {
    return (
      <YesNoResBtn
        title={item?.message}
        options={item?.predefinedResponseOption}
        handleOptionSelect={onOptionSelect}
        selectedCanvasObj={selectedCanvasObj}
        setSelectedCanvasObj={setSelectedCanvasObj}
        isDisabled={item.isDisabled}
        type={item?.optionType}
      />
    );
  }

  if (item?.options?.length === 1) {
    return (
      <YesResBtn
        options={item?.predefinedResponseOption}
        handleOptionSelect={onOptionSelect}
        isDisabled={item.isDisabled}
      />
    );
  }

  return (
    <YesNoResBtn
      title={item?.message}
      options={item?.predefinedResponseOption}
      handleOptionSelect={onOptionSelect}
      selectedCanvasObj={selectedCanvasObj}
      setSelectedCanvasObj={setSelectedCanvasObj}
      isDisabled={item.isDisabled}
      type={item?.optionType}
    />
  );
};

const Index = () => {
  const {
    loading,
    messages,
    isTyping = false,
    onSend = () => {},
    isToolbarDisabled,
  } = useAiBot();

  const mainContainerRef = useRef(null);
  const toolbarRef = useRef(null);
  const chatContentRef = useRef(null);

  const [prompt, setPrompt] = useState('');
  const { data: userProfile } = useGetMyProfileQuery();
  const profileImage = userProfile?.data?.profileImage;

  useLayoutEffect(() => {
    const chatContent = chatContentRef.current;
    const toolbar = toolbarRef.current;
    const mainContainer = mainContainerRef.current;
    if (chatContent && toolbar && mainContainer) {
      chatContent.scrollTo({
        top: chatContent.scrollHeight,
        behavior: 'smooth',
      });
      const mainContainerHeight = mainContainer.getBoundingClientRect().height;
      const toolbarHeight = toolbar.getBoundingClientRect().height;
      chatContent.style.setProperty(
        '--max-height',
        `${mainContainerHeight - toolbarHeight}px`,
      );
    }
  }, [messages]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('focus', () => {
        mainContainerRef.current.scrollTo({
          top: mainContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      });
    }
  }, [messages]);

  return (
    <div className={cn(styles.chatSection, 'w-full grid rounded-xl')}>
      <div className="w-full grid p-4 h-full" ref={mainContainerRef}>
        <div
          className={cn(
            styles.chatbotScroller,
            'h-full max-h-[calc(100%-var(--toolbar-height))] overflow-y-auto scrollbar-hidden',
          )}
          ref={chatContentRef}
        >
          {messages?.map((msg, index) => (
            <div className="pb-2 w-full">
              {msg.sender === 'user' ? (
                <UserChat
                  key={index}
                  message={msg.message}
                  time={msg.time}
                  avatar={msg.profileImage || profileImage || userPic}
                  className="max-w-[90%] xl:max-w-[90%]"
                />
              ) : (
                <BotChat
                  message={msg.message}
                  time={msg.time}
                  className="max-w-[90%] xl:max-w-[90%]"
                >
                  {msg?.options && msg?.options.length > 0 && (
                    <OptionsToRender
                      {...msg}
                      isDisabled={index !== messages.length - 1}
                    />
                  )}
                </BotChat>
              )}
            </div>
          ))}
          {isTyping && <BotChat isTyping />}
        </div>

        <div
          className={cn('flex flex-col justify-end', {
            'pointer-events-none opacity-50': isToolbarDisabled,
          })}
          ref={toolbarRef}
        >
          <div className="chat-toolbar h-[4.5rem] rounded-full px-2 items-center py-2 grid grid-cols-[1fr,auto] ">
            <input
              name=""
              id=""
              className="border border-[#00000033] focus:outline-none rounded-full w-full h-full py-4 px-6 font-bold text-sm text-[#0F1017] scrollbar-hidden"
              placeholder="Need help from AI tutor?"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && prompt) {
                  onSend(prompt);
                  setPrompt('');
                }
              }}
              ref={inputRef}
            />
            <button
              className="size-14 rounded-full  border border-[#00000033] grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => {
                if (!prompt) return;
                onSend(prompt);
                setPrompt('');
              }}
              disabled={loading || !prompt}
            >
              <Send2 className="text-[#C44297] text-5" variant="Bold" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
