import { Alert, Box, Grid, Snackbar, Typography } from '@mui/material';
import MuxPlayer from '@mux/mux-player-react/lazy';
import dayjs from 'dayjs';
import '../../../../components/main/elements/assignments/VideoPlayer/VideoPlayer.scss';
import VideoSideBar from '../VideoSideBar';
import LessonComment from './LessonComment';

import { useQuery } from 'api/hooks';
import EmptyVideo from 'app/student/components/emptyVideo';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Circles } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { autoAssignVideo } from 'requests/Video';
import {
  moveToNextSkills,
  removeVideoActivityList,
  removeifAvaliable,
  setCurrentVideo
} from 'store/Slice/videoSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { updateUserProfile } from 'store/Thunk/authThunk';
import {
  createStudentVideoActivity,
  getAllVideoList,
  getAllVideoListReq,
  getStudentVideoActivityReq,
  updateStudentVideoActivity,
} from 'store/Thunk/videoThunk';
import './videoTab.scss';

function DailyLesson() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const topic = query.get('topic');
  const videoId = query.get('videoId');

  const videoLoading = useAppSelector(
    (state) => state.videoReducer.videoLoading,
  );
  const videoListing = useAppSelector(
    (state) => state.videoReducer.videoListing,
  );
  const currentVideo = useAppSelector(
    (state) => state.videoReducer.currentVideo,
  );
  const { user } = useAppSelector((state) => state.authReducer);

  const { userData } = useAppSelector((state) => state.userReducer);
  const playerRef = useRef(null);
  const [noMore, setNoMore] = useState(false);
  const [alreadyCompleted, setAlreadyCompleted] = useState(false);
  const [totalVideoDuration, setTotalVideoDuration] = useState(0);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [paused, setPaused] = useState(true);
  const [videoTopic, setVideoTopic] = useState('');
  const [showVideo, setshowVideo] = useState(false);
  const { videoActivity: videoActivityListing } = useAppSelector(
    (state) => state.videoReducer,
  );

  const [videoActivity, setVideoActivity] = useState({});

  const initialFunction = useCallback(() => {
    dispatch(setCurrentVideo(null));
    setVideoActivity({});
    dispatch(getStudentVideoActivityReq({ userId: userData?.id }));
    dispatch(getAllVideoListReq({
      pageIndex: 0,
      topic: videoTopic,
      pageSize: 100,
      userId: userData?.id,
      gradeId: userData?.grade?.length ? userData?.grade[0]?.gradeId : '',
    })).unwrap().then((res) => {
      let idx = 0;

      if (videoId) {
        idx = res.findIndex((item) => item.id === videoId);
        if (idx === -1) {
          navigate('/app/student');
        }
      }

      dispatch(setCurrentVideo(res?.[idx]));
    })
      .catch(console.error);
  }, [userData?.id, userData?.grade, videoTopic, videoId]);

  useEffect(() => {
    if (currentVideo) {
      setshowVideo(true);
      setNoMore(false);
      setTimeout(() => setshowVideo(false), 3000);
    }
  }, [currentVideo]);

  useEffect(() => {
    if (topic) {
      setVideoTopic(topic);
    } else {
      setVideoTopic('');
    }
  }, [topic]);

  console.log('currentVideo', currentVideo);

  useEffect(() => {
    if (userData?.id) {
      console.log("INITIALIZING")
      initialFunction();
    }
  }, [videoTopic, userData?.id]);

  const fetchData = () => {
    dispatch(
      getAllVideoList({
        pageIndex: 0,
        topic: videoTopic,
        pageSize: 100,
        userId: user?.id,
        gradeId: userData?.grade?.length ? userData?.grade[0]?.gradeId : '',
      }),
    );
  };

  console.log("===========> videoLoading <============", videoLoading);
  console.log("===========> videoActivity <============", videoActivity);
  console.log("===========> currentVideo <============", currentVideo);

  const handleSnackbar = () => {
    setOpenSnackBar(!openSnackBar);
  };

  useEffect(() => {
    let found = false;
    if (videoActivityListing?.length && currentVideo) {
      for (let i = 0; i < videoActivityListing.length; i++) {
        if (videoActivityListing?.[i]?.videoId === currentVideo.id) {
          for (const assignment of currentVideo.Assignments) {
            if (assignment.assignmentId === videoActivityListing[i].assignmentId) {
              setVideoActivity(videoActivityListing[i]);
              found = true;
              break;
            }
          }
        }
      }
    }
    if (videoActivityListing.length === 0 || !found) {
      setVideoActivity({});
    }
  }, [videoActivityListing, currentVideo]);

  const onVideoPlayAndPause = (e) => {
    if (Object.keys(videoActivity).length > 0) {
      dispatch(
        updateStudentVideoActivity({
          id: videoActivity.id,
          videoId: currentVideo.id,
          watchedAt: dayjs(),
          completed: e.target.ended,
          timeWatched: e.target.ended
            ? totalVideoDuration
            : e.target.currentTime,
          assignmentId: currentVideo.Assignments?.[0]?.assignmentId,
        }),
      ).unwrap().then((res) => {
        if (e.target.ended) {
          dispatch(updateUserProfile());
        }
      });
    } else {
      dispatch(
        createStudentVideoActivity({
          userId: userData.id,
          videoId: currentVideo.id,
          assignmentId: currentVideo.Assignments?.[0]?.assignmentId,
          watchedAt: dayjs(),
          completed: false,
          timeWatched: e.target.ended
            ? totalVideoDuration
            : e.target.currentTime,
        }),
      ).unwrap().then((res) => {
        if (e.target.ended) {
          dispatch(updateUserProfile());
        }
      });
    }
  };

  const moreExample = async (e) => {
    autoAssignVideo({
      userId: userData.id,
      skillId: currentVideo?.skill?.[0]?.skillId,
      classroomId: currentVideo?.Assignments?.[0]?.assignment?.classroomId,
      assignmentId: currentVideo?.Assignments?.[0]?.assignmentId,
    })
      .then((result) => {
        if (result.message) {
          setNoMore(true);
        } else {
          dispatch(removeifAvaliable());
          if (result.newlyAssigned) {
            dispatch(setCurrentVideo(result.video));
            dispatch(
              createStudentVideoActivity({
                userId: userData.id,
                videoId: result.video.id,
                assignmentId: result.video.Assignments?.[0]?.assignmentId,
                watchedAt: dayjs(),
                completed: false,
                timeWatched: 0,
              }),
            );
          } else {
            dispatch(removeVideoActivityList());
            dispatch(setCurrentVideo(result));
          }
        }
      })
      .catch((err) => {
        console.log('🚀 More example error', err);
      });
  };


  const moveToNextSkill = async (updateActivity = false) => {
    if (videoListing.length === 1) {
      // No more videos
      navigate('/app/student');
    }
    dispatch(moveToNextSkills());
  };

  function removeSeqN(text) {
    const arrs = text.split('_');
    return arrs[arrs.length - 1];
  }

  //
  return (
    <div className="h-screen p-5">
      {videoLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Circles
            height="80"
            width="80"
            color="#20A58A"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className='h-full grid grid-rows-[auto,1fr]'>
          <button
            onClick={() => navigate(-1)}
            className="flex items-center space-x-2 p-2  mb-2"
          >
            <img src="/svg/go_back_icon.svg" alt="arrow-left" />
          </button>
          <Grid container direction="row">
            {currentVideo ? (
              <>
                <Grid item xs={12} sm={12} lg={7} xl={7} pr={'6px'}>
                  <Grid
                    container
                    sx={{
                      height: '100%',
                      background: '#fff',
                      borderRadius: '30px',
                    }}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid
                      item
                      /* xs={9}
                      sm={9}
                      md={9}
                      lg={9} */
                      sx={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        padding: '1rem 0',
                      }}
                    >
                      <Box
                        id="scrollDemo"
                        sx={{
                          width: '100%',
                          height: '100%',
                          overflowY: 'scroll',
                          boxSizing: 'content-box',
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: '#000',
                            height: '100%',
                            borderRadius: '30px',
                            overflow: 'hidden',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          {!showVideo && (
                            <MuxPlayer
                              ref={playerRef}
                              startTime={Number(
                                videoActivity?.timeWatched ?? 0,
                              )}
                              preload="auto"
                              metadata={{
                                video_id: currentVideo?.id,
                                video_title: removeSeqN(currentVideo?.title),
                                viewer_user_id: userData.id,
                              }}
                              metadataVideoId={currentVideo?.playbackId}
                              playbackId={currentVideo?.playbackId}
                              title={removeSeqN(currentVideo?.title)}
                              streamType="on-demand"
                              onLoadedData={(e) => {
                                setTotalVideoDuration(e.target.duration);
                              }}
                              onPlay={(e) => {
                                onVideoPlayAndPause(e);
                                setPaused(false);
                              }}
                              style={{
                                // "--seek-backward-button": "none",
                                // "--seek-forward-button": "none",
                                '--time-range': 'none',

                                aspectRatio: '16:9',
                              }}
                              onPause={(e) => {
                                onVideoPlayAndPause(e);
                                setPaused(true);
                              }}
                              autoPlay
                              // onEnded={}
                            />
                          )}
                        </Box>
                      </Box>
                      <Box sx={{ my: 3, textAlign: 'center' }}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: '700', color: '#131515' }}
                        >
                          {removeSeqN(currentVideo?.title)}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                      <VideoSideBar
                        video={currentVideo}
                        fetchData={fetchData}
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} lg={5} xl={5} pl={'6px'}>
                  <LessonComment
                    ref={playerRef}
                    video={currentVideo}
                    fetchData={fetchData}
                    moreExample={moreExample}
                    moveToNextSkill={moveToNextSkill}
                    paused={paused}
                    noMore={noMore}
                    videoActivity={videoActivity}
                    alreadyCompleted={alreadyCompleted}
                    totalVideoDuration={totalVideoDuration}
                  />
                </Grid>
              </>
            ) : (
              <Box width={'100%'} sx={{ mt: 2 }}>
                <EmptyVideo />
              </Box>
            )}
          </Grid>
        </div>
      )}
      {openSnackBar && (
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={handleSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbar}
            severity="warning"
            sx={{ width: '100%' }}
          >
            No more video found
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default DailyLesson;
