import React, { useRef, useState } from 'react';
import sharedStyles from '../../../sharedStyle.module.scss';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Logo from '../../../components/logo.png';
import profileAvatar from '../../../components/profileAvatar.png';
import Webcam from 'react-webcam';
import { useAppDispatch } from 'store/store';
import { uploadProfilePicture } from 'store/Thunk/registerThunk';
import { NotificationManager } from 'react-notifications';
import { setUserDetails } from 'store/Slice/authSlice';
import { buildStyles } from 'react-circular-progressbar';
import CloseIcon from '@mui/icons-material/Close';

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export default function UploadPicture({ setStep, open, onClose }) {
  const dispatch = useAppDispatch();
  const webcamRef = useRef(null);
  const fileRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [openWebcam, setOpenWebcam] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [picture, setPicture] = useState(null);
  const handleRetake = () => {
    setPicture(null);
    setCapturedImage(null);
  };
  const handlePicture = async (e) => {
    setCapturedImage(null);
    e.target.files?.length > 0 &&
      (e.target.files[0]?.type?.split('/')[1]?.toLowerCase() === 'jpeg' ||
        e.target.files[0]?.type?.split('/')[1]?.toLowerCase() === 'png' ||
        e.target.files[0]?.type?.split('/')[1]?.toLowerCase() === 'jpg') &&
      setPicture(e.target.files[0]);
    console.log('::PICTURE::', e.target.files[0]);
  };

  const captureImage = () => {
    if (openWebcam) {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log('::IMAGE SRC::', imageSrc);
      setCapturedImage(imageSrc);
      setPicture(dataURLtoFile(imageSrc, 'profile.jpg'));
    }
    setOpenWebcam(true);
  };

  const handleImageUpload = () => {
    const formData = new FormData();
    formData.append('profileImage', picture);

    setUploading(true);
    dispatch(uploadProfilePicture(formData))
      .unwrap()
      .then((data) => {
        setUploading(false);
        localStorage.setItem('userData', JSON.stringify(data.data.user));
        dispatch(setUserDetails(data.data.user));
      })
      .catch(() => {
        setUploading(false);
        NotificationManager.error(
          'Error while uploading profile picture',
          'Error',
          3000,
        );
      });
  };

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <Stack className="flex flex-col w-full justify-center relative">
        <Box sx={{ position: 'absolute', right: '10px', top: '10px' }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="w-full flex flex-col items-center py-6 justify-center gap-4">
          <Box className="flex w-full justify-center">
            <img
              style={{
                borderRadius: '100%',
                height: '200px',
                width: '200px',
                objectFit: 'cover',
              }}
              src={
                capturedImage
                  ? capturedImage
                  : !picture
                  ? profileAvatar
                  : URL.createObjectURL(picture)
              }
              alt={'logo'}
            />
          </Box>
          <Stack sx={{ '& .MuiTypography-root': { textAlign: 'center' } }}>
            <Typography
              className="!font-semibold"
              style={{ color: '#131515', fontSize: '32px' }}
            >
              Upload Your Profile Picture
            </Typography>
            <Typography
              className="!font-normal"
              style={{ color: '#979C9E', fontSize: '20px' }}
            >
              profile picture.
            </Typography>
          </Stack>

          <Stack className="w-[90%] sm:w-[60%]" gap={4}>
            {
              <input
                type="file"
                name={'logo'}
                id={'logo'}
                onChange={(e) => handlePicture(e)}
                accept=".png,.jpeg,.jpg"
                style={{ position: 'absolute', zIndex: 444, opacity: 0 }}
                ref={fileRef}
              />
            }

            <label htmlFor={'logo'}>
              <Button
                variant={'contained'}
                size="large"
                fullWidth
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 500,
                  fontSize: '20px',
                  borderWidth: '2px',
                }}
                className={'primary_bg_btn'}
                onClick={() => fileRef.current.click()}
              >
                Upload Picture
              </Button>
            </label>
          </Stack>
        </Box>
      </Stack>
    </Dialog>
  );
}
