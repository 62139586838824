import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React from 'react';

import Logo from '../../../../asset/logo.svg';

import OTPInput from 'otp-input-react';
import { useGetMyProfileQuery } from 'store/apis/profile';
import {
  useResendEmailOtpMutation,
  useVerifyEmailOtpMutation,
} from 'store/apis/otp';

import { NotificationManager } from 'react-notifications';
import cn from 'utils/cn';

export default function VerifyEmail() {
  const { data, isLoading } = useGetMyProfileQuery();
  const [sendOtp, { isLoading: isOtpLoading }] = useResendEmailOtpMutation();

  const [verifyEmail, { isLoading: isEmailVerifyLoading }] =
    useVerifyEmailOtpMutation();

  // const isEmailVerified = data?.data?.isEmailVerified;
  const isEmailVerified = true

  const [showOtpModal, setShowOtpModal] = React.useState(false);
  const [otp, setOtp] = React.useState('');

  if (isEmailVerified || isLoading) return null;

  const handleSndOtp = async () => {
    try {
      NotificationManager.info('Sending OTP...', 'Info', 3000);
      await sendOtp().unwrap();
      NotificationManager.success('OTP sent successfully', 'Success', 3000);
      setShowOtpModal(true);
    } catch (error) {
      NotificationManager.error(error?.data?.message, 'Error', 3000);
    }
  };

  const handleVerifyEmail = async () => {
    try {
      if (otp.length < 6) {
        NotificationManager.error('Please enter valid OTP', 'Error', 3000);
        return;
      }

      NotificationManager.info('Verifying OTP...', 'Info', 3000);
      await verifyEmail({
        emailCode: otp,
      }).unwrap();
      NotificationManager.success(
        'Email verified successfully',
        'Success',
        3000,
      );
      setShowOtpModal(false);
    } catch (error) {
      console.log('error', error);
      NotificationManager.error(error?.data?.message, 'Error', 3000);
    }
  };

  return (
    <>
      <div
        className={cn(
          'bg-black flex items-center gap-4 !h-[70px] lg:!h-[80px] px-6 fixed top-0 w-full z-[1000]',
        )}
      >
        <p className="text-white text-center max-w-[50ch] mx-auto">
          Your email is not verified. Some features may be restricted. Please{' '}
          <button
            className="text-dark-900 font-bold underline"
            onClick={handleSndOtp}
          >
            {' '}
            verify
          </button>{' '}
          your email.
        </p>
      </div>

      <Modal
        open={showOtpModal}
        onClose={() => setShowOtpModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 5000 }}
      >
        <div
          className="flex items-center justify-center flex-col
           bg-white p-8 w-[calc(100vh-1rem)] rounded-md max-w-xl gap-4 px-8 py-10 transform translate-x-[-50%] translate-y-[-50%] top-1/2 left-1/2 absolute
          "
          onPointerDown={(e) => e.stopPropagation()}
        >
          <Box className={''}>
            <img src={Logo} alt={'logo'} />
          </Box>

          <Stack className="space-y-5 flex items-center justify-center flex-col">
            <Typography
              className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
              style={{ color: '#131515' }}
            >
              Verify your email
            </Typography>
            <Typography className="!font-normal !text-2xl mt-4 ">
              Enter the OTP sent to your{' '}
              <span style={{ color: '#C73E9D' }}>email</span>
            </Typography>
          </Stack>

          <div className="py-8 flex items-center justify-center flex-col">
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              inputClassName="border-2 border-gray-300 rounded-md !w-12 !h-12 lg:!w-14 lg:!h-14 text-center focus:outline-none focus:border-primary"
            />
          </div>

          <Button
            variant={'contained'}
            size="large"
            sx={{
              width: '60%',
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
            className={'primary_bg_btn'}
            onClick={handleVerifyEmail}
          >
            Continue
          </Button>
        </div>
      </Modal>
    </>
  );
}
