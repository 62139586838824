import styles from './student.module.scss';
import sharedStyles from '../../sharedStyle.module.scss';
import { ReactComponent as UploadFileIcon } from '../upload_file.svg';
import SelectOption from '../../components/input/selectOption';
import PersonIcon from './person.png';
import TextField from '../../components/input/textField';
import CityIcon from './city.png';
import SchoolIcon from './school.png';
import TeachIcon from './teach.png';
import { useEffect, useState } from 'react';
import RadioButtons from '../../components/input/radioButtons';
import axios from '../../../api/axios';
import { analytics, auth, setAnalyticsUserId } from '../../../api/firebase';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../components/dropdown/dropdown';
import ArrowDownIcon from '../../components/input/arrow_down.png';
import AutoComplete from '../../components/input/autocomplete';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { Dna } from 'react-loader-spinner';
import SelectOrAdd from '../../components/input/selectOrAdd';
import Modal from '../../components/modal/modal';
import StudentSelectAvatar from './studentSelectAvatar';
import { setUserDetails } from 'store/Slice/authSlice';
import { useDispatch } from 'react-redux';

const Student = ({ user, uuid, existingEmail }) => {
  const dispatch = useDispatch();
  const [city, setCity] = useState('');
  const [grade, setGrade] = useState('');
  const [state, setState] = useState('');
  const [email, setEmail] = useState('');
  const [parent, setParent] = useState('');

  const [school, setSchool] = useState('');
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [image, setImage] = useState(null);

  const [schoolData, setSchoolData] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [gradeData, setGradeData] = useState([]);

  console.log('email', email);

  useEffect(() => {
    axios.get('/state').then((response) => {
      setStateData(response.data.data);
    });

    axios.get('/grades').then((response) => {
      setGradeData(response.data.data);
    });
  }, []);

  useEffect(() => {
    if (existingEmail) {
      setEmail(existingEmail);
    }
  }, [existingEmail]);

  useEffect(() => {
    if (state) {
      axios
        .get(`/state/${state}/school`)
        .then((response) => {
          setSchoolData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [state]);

  console.log('existingEmail', existingEmail);

  function signUp() {
    if (!firstName || !image || !school || !grade) {
      NotificationManager.error('Please fill all the fields', 'Error');
      return;
    }
    const schoolId = schoolData.find((v) => v.name === school).id;
    const gradeId = gradeData.find((v) => v.name === grade).id;
    const formData = new FormData();
    formData.append('file', image);
    formData.append('name', firstName + ' ' + lastName);
    // formData.append('email', email);
    formData.append('schoolId', schoolId);
    formData.append('gradeId', gradeId);

    axios
      .patch(`/students/${uuid}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        setLoading(false);

        if (response.status == 200) {
          localStorage.setItem('uuid', response?.data?.data?.id);
          localStorage.setItem(
            'userData',
            JSON.stringify(response?.data?.data),
          );
          localStorage.removeItem('currentUser');
          console.log('call navigate');
          dispatch(setUserDetails(response?.data?.data));
          navigate('/app/student');
        }
      });
  }

  return (
    <>
      <div className={sharedStyles.centerContainer}>
        <div
          className={`${styles.completeProfile} ${sharedStyles.whiteContainerBox}`}
        >
          <h1 className={styles.title}>Please complete your profile.</h1>
          <h1 className={styles.title}>
            Don't worry, your data will remain private and only you can see it.
          </h1>

          <div
            className={sharedStyles.uploadImage}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            {image ? (
              <img src={URL.createObjectURL(image)} alt={'avatar'} />
            ) : (
              <UploadFileIcon />
            )}
          </div>

          <div className={styles.row}>
            <TextField
              type={'text'}
              placeholder={'First Name'}
              icon={PersonIcon}
              onChange={setFirstName}
            />
            <TextField
              type={'text'}
              placeholder={'Last Name'}
              icon={PersonIcon}
              onChange={setLastName}
            />
          </div>

          <div className={styles.row}>
            <AutoComplete
              placeholder={'Select Grade'}
              onChange={setGrade}
              value={grade}
              icon={CityIcon}
              options={gradeData.map((grade) => grade.name)}
            />
            <AutoComplete
              placeholder={'Select State'}
              icon={SchoolIcon}
              onChange={setState}
              value={state}
              options={stateData}
            />
          </div>

          <TextField
            type={'text'}
            value={email}
            placeholder={'Enter your email (Optional)'}
            icon={SchoolIcon}
            onChange={setEmail}
          />

          <AutoComplete
            placeholder={'Select your School or Parent'}
            icon={SchoolIcon}
            onChange={setSchool}
            value={school}
            options={(schoolData || []).map((school) => school.name)}
          />

          <button
            onClick={signUp}
            className={sharedStyles.loginButton}
            style={{ backgroundColor: '#C73E9D', color: '#F5F5F5' }}
          >
            Sign Up
          </button>

          {loading && (
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          )}
        </div>
      </div>

      <Modal open={modalOpen} setOpen={setModalOpen}>
        <StudentSelectAvatar
          setImage={(image) => {
            setModalOpen(false);
            setImage(image);
          }}
        />
      </Modal>
    </>
  );
};

export default Student;
